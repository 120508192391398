import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ReusableSelect from 'components/common/ReusableSelect';
import ReusableButton from 'components/common/ReusableButton';

class DeleteEmailTemplateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      template: props.currentTemplate,
    };
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDeleteTemplate = () => {
    const payload = {
      id: this.state.template.id,
    };
    this.props.handleDeleteTemplate(payload);
  };

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.handleCloseDeleteTemplate}>
        <DialogTitle>Delete Email Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select a template to delete.
          </DialogContentText>
          <ReusableSelect
            id="delete"
            label="Delete: "
            options={this.props.templates}
            value={this.state.template}
            onChange={this.handleChange('template')}
          />
        </DialogContent>
        <DialogActions>
          <ReusableButton
            onClick={this.props.handleCloseDeleteTemplate}
            value="Cancel"
          />
          <ReusableButton
            onClick={this.handleDeleteTemplate}
            value="Delete"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DeleteEmailTemplateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  currentTemplate: PropTypes.object.isRequired,
  templates: PropTypes.array.isRequired,
  handleCloseDeleteTemplate: PropTypes.func.isRequired,
  handleDeleteTemplate: PropTypes.func.isRequired,
};

export default DeleteEmailTemplateDialog;
