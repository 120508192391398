import { createSelector } from 'reselect';

export const selectEmailTemplatesLoading = createSelector(
  (state) => state.getIn(['emails', 'emailTemplatesLoading']),
  (emailTemplatesLoading) => emailTemplatesLoading,
);

export const selectEmailTemplates = createSelector(
  (state) => state.getIn(['emails', 'emailTemplates']),
  (emailTemplates) => emailTemplates.toJS(),
);

export const selectEmailMessagesLoading = createSelector(
  (state) => state.getIn(['emails', 'emailMessagesLoading']),
  (emailMessagesLoading) => emailMessagesLoading,
);

export const selectEmailMessages = createSelector(
  (state) => state.getIn(['emails', 'emailMessages']),
  (emailMessages) => emailMessages.toJS(),
);

export const selectApplicantsSelected = createSelector(
  (state) => state.getIn(['emails', 'applicantsSelected']),
  (applicantsSelected) => (applicantsSelected),
);

export const selectRecruitersSelected = createSelector(
  (state) => state.getIn(['emails', 'recruitersSelected']),
  (recruitersSelected) => (recruitersSelected),
);
