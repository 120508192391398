import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.scss';

const ErrorPage = (props) => (
  <div id="maincontent">
    <div className="wdn-band">
      <div className="wdn-inner-wrapper">
        <div className={styles['info-container']}>
          <h2>
            Error&nbsp;
            {props.match.params.errorCode}
          </h2>
          <div>
            An unexpected error occurred. Please contact the Math Day administrator.
          </div>
        </div>
      </div>
    </div>
  </div>
);

ErrorPage.propTypes = {
  match: PropTypes.object,
};

export default ErrorPage;
