import { createSelector } from 'reselect';

export const selectRecruitersLoading = createSelector(
  (state) => state.getIn(['recruiters', 'loading']),
  (loading) => loading,
);

export const selectRecruiters = createSelector(
  (state) => state.getIn(['recruiters', 'data']),
  (data) => data.toJS(),
);
