import React from 'react';
import ReusableTable from 'components/common/ReusableTable';
import ReusableSelect from 'components/common/ReusableSelect';
import { formatTime } from 'utils/date';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import Tooltip from '@material-ui/core/Tooltip';
import dayjs from 'dayjs';

import { taskTimeInApplicantTime, customFilterByApplicantName, nameSortComparator } from '../utils';
import styles from '../style.module.scss';

const ToolTipTAA = withStyles({
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    boxSizing: 'border-box',
    fontSize: 'inherit',
    fontFamily: '"Gotham SSm A", "Gotham SSm B", Verdana, sans-serif',
    fontWeight: 'inherit',
    whiteSpace: 'pre-line',
    height: 'fit-content',
    maxWidth: 'none',
  },
})(Tooltip);

const ButtonTAA = withStyles({
  root: {
    padding: 0,
    lineHeight: 1,
    fontSize: 'inherit',
    fontFamily: 'inherit',
    textTransform: 'none',
  },
  label: {
    color: 'inherit',
    boxSizing: 'border-box',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    justifyContent: 'start',
  },
})(Button);

class TaskApplicantAssignTable extends React.Component {
  constructor(props) {
    super(props);

    const allStatuses = {};
    this.props.data.forEach(applicant => {
      if (applicant.status) {
        allStatuses[applicant.status.abbr] = applicant.status.abbr;
      }
    });

    this.state = {
      // TAA Table (Task +)
      assigneeColumns: [
        {
          title: 'L(B)',
          tooltip: 'Toggle L(B)',
          render: (applicant) => this.TAAtoggleButton(applicant.id),
        },
        {
          title: 'Name',
          field: 'name',
          tooltip: 'First initial. Last name',
          customSort: (a1, a2) => nameSortComparator(a1, a2),
          render: (rowData) => this.renderAssigneeNameWithTooltip(rowData),
          customFilterAndSearch: customFilterByApplicantName,
        },
        {
          title: 'Status',
          field: 'status.abbr',
          tooltip: 'Status',
          lookup: allStatuses,
        },
        {
          title: 'Available',
          tooltip: 'The time window(s) the applicant is available',
          render: (applicant) => this.renderAvailableColumn(applicant),
        },
      ],
      assigneeOptions: {
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 700,
        maxWidth: 100,
        selection: true,
        selectionProps: applicant => ({
          disabled: this.props?.selectedTask && (!taskTimeInApplicantTime(this.props.applicantMap.get(applicant.id), this.props.selectedTask)),
        }),
        search: false,
        hideDeleteButton: true,
        actionsColumnIndex: -1,
        showFull: true,
        paging: true,

      },
      assigneeActions: [
        {
          icon: 'check',
          tooltip: 'Assign',
          displayDuringSelect: true,
          onClick: (event, data) => this.props.handleAssign(data),
        },
        {
          icon: 'filter_alt',
          tooltip: 'Filter',
          position: 'toolbar',
          isFreeAction: true,
          onClick: () => this.props.handleOpenTAAFilter(),
        },
      ],
    };
  }

  TAAtoggleButton = (applicantID) => (
    <ToggleButtonGroup onChange={() => this.props.handleTAAtoggle(applicantID)} size="small">
      <ToggleButton value="job" selected={this.props.applicantLoadBackupToggle.get(applicantID)} size="small" style={{ height: '10px' }}>
        ld
      </ToggleButton>
      <ToggleButton value="backup" selected={!this.props.applicantLoadBackupToggle.get(applicantID)} size="small" style={{ height: '10px' }}>
        bkp
      </ToggleButton>
    </ToggleButtonGroup>
  );

  renderAssigneeNameWithTooltip = (applicant) => {
    if (!this.props.selectedTask) {
      return 'No task selected';
    }

    const task = this.props.selectedTask;
    if (applicant.year === this.props.year) {
      let color = '';
      applicant.task_preferences?.forEach(preference => {
        if (preference.task_id === task.id) {
          if (preference.preference === 4) {
            // preferred task (green)
            color = '#009E60';
          } else if (preference.preference === 3) {
            // acceptable task (yellow)
            color = '#FFC300';
          } else if (preference.preference === 2) {
            // avoid task (red)
            color = '#EE4B2B';
          } else if (preference.preference === 1) {
            // cant do (gray)
            color = '#D8D8D8';
          }
        }
      });
      const applicantLastName = applicant.user?.last_name.length > 9 ? `${applicant.user?.last_name.slice(0, 7)}...` : applicant.user?.last_name;
      return (
        <ToolTipTAA
          PopperProps={{
            disablePortal: true,
          }}
          open={this.props.applicantPrevTasksOpenTAA[`${applicant.user.id}`]}
          placement="right"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          className={styles['nowrap']}
          title={(
            <>
              {this.props.openApplicantPrevTasksDialog(applicant)}
            </>
          )}
        >
          <ButtonTAA className={styles['nowrap']} onClick={() => { this.props.toggleApplicantPrevTasksTAA(applicant); }}>
            <p title={`${applicant.user?.first_name} ${applicant.user?.last_name}`} className={styles['nowrap']} style={{ color, margin: 0 }}>{`${applicant.user?.first_name.charAt(0)}. ${applicantLastName}`}</p>
          </ButtonTAA>
        </ToolTipTAA>
      );
    }
    return (
      <div>
        -
      </div>
    );
  };

  renderAssignTime = (assignee) => {
    let start = this?.props?.currentStartTimeMap[[assignee?.user.id]] && `${dayjs(this.props.currentStartTimeMap[[assignee.user.id]][1]).hour()}:${dayjs(this.props.currentStartTimeMap[[assignee.user.id]][1]).minute() < 10
      ? `0${dayjs(this.props.currentStartTimeMap[[assignee.user.id]][1]).minute()}`
      : dayjs(this.props.currentStartTimeMap[[assignee.user.id]][1]).minute()}`;
    let end = this?.props?.currentEndTimeMap[[assignee?.user.id]] && `${dayjs(this.props.currentEndTimeMap[[assignee.user.id]][1]).hour()}:${dayjs(this.props.currentEndTimeMap[[assignee.user.id]][1]).minute() < 10
      ? `0${dayjs(this.props.currentEndTimeMap[[assignee.user.id]][1]).minute()}`
      : dayjs(this.props.currentEndTimeMap[[assignee.user.id]][1]).minute()}`;
    if (this?.props?.currentStartTimeMap[[assignee.user.id]] && this.props.currentStartTimeMap[[assignee.user.id]][2] === 1 && this?.props?.currentEndTimeMap[[assignee.user.id]] && this?.props?.currentEndTimeMap[[assignee.user.id]][2] === 1) {
      start = `${dayjs(this.props.currentStartTimeMap[[assignee.user.id]][0]).hour()}:${dayjs(this.props.currentStartTimeMap[[assignee.user.id]][0]).minute() < 10
        ? `0${dayjs(this.props.currentStartTimeMap[[assignee.user.id]][0]).minute()}`
        : dayjs(this.props.currentStartTimeMap[[assignee.user.id]][0]).minute()}`;
      end = `${dayjs(this.props.currentEndTimeMap[[assignee.user.id]][0]).hour()}:${dayjs(this.props.currentEndTimeMap[[assignee.user.id]][0]).minute() < 10
        ? `0${dayjs(this.props.currentEndTimeMap[[assignee.user.id]][0]).minute()}`
        : dayjs(this.props.currentEndTimeMap[[assignee.user.id]][0]).minute()}`;
    }
    return (
      <Tooltip title="Click to edit time">
        <div className={styles['applicant-button']}>
          <Button onClick={() => { this.props.openEditTimeDialog(assignee); }}>
            { start }
            –
            { end }
          </Button>
        </div>
      </Tooltip>
    );
  };

  // renders applicants availability times in a dropdown format
  renderAvailableColumn = (applicant) => {
    // const timeWindows = this.props.data.filter((a) => a.id === applicant.id)?.[0]?.time_windows;
    const timeWindows = this.props.applicantMap.get(applicant?.id).time_windows;
    if (timeWindows && timeWindows.length !== 0) {
      if (timeWindows.length === 1) {
        return `${formatTime(timeWindows[0].starts_at)}-${formatTime(timeWindows[0].ends_at)}`;
      }
      const timeWindowsSorted = timeWindows.sort((a, b) => a.starts_at.localeCompare(b.starts_at));
      const timeWindowOptions = timeWindowsSorted.map(timeWindow => ({
        display: `${formatTime(timeWindow?.starts_at)}-${formatTime(timeWindow?.ends_at)}`,
        value: `${formatTime(timeWindow?.starts_at)}-${formatTime(timeWindow?.ends_at)}`,
      }));
      const taskStartsAt = this.props.selectedTask.starts_at;
      const taskEndsAt = this.props.selectedTask.ends_at;
      let defaultTimeValue = timeWindowOptions[0].value;
      // find the first available time that intersects with the task time
      for (let i = 0; i < timeWindowsSorted.length; ++i) {
        const timeWindow = timeWindowsSorted[i];
        if ((taskStartsAt <= timeWindow?.starts_at && timeWindow?.starts_at <= taskEndsAt)
          || (timeWindow?.starts_at <= taskStartsAt && taskStartsAt <= timeWindow?.ends_at)) {
          defaultTimeValue = `${formatTime(timeWindow?.starts_at)}-${formatTime(timeWindow?.ends_at)}`;
          break;
        }
      }
      return (
        <ReusableSelect
          id="preferred"
          className={styles['task-preference']}
          value={defaultTimeValue}
          options={timeWindowOptions}
          useDefaultVariant
          isTransparent
          displayEmpty
          excludeNoneOption
        />
      );
    }

    return (
      <div>
        None
      </div>
    );
  };

  render() {
    return (
      <div className={styles['task-applicant-assign-table-container']}>
        <ReusableTable
          title={`Unassigned (${this.props.data.length})`}
          columns={this.state.assigneeColumns}
          data={this.props.data}
          options={this.state.assigneeOptions}
          isLoading={this.props.applicantsLoading}
          actions={this.state.assigneeActions}
          tableRef={this.props.tableRefTAA}
        />
      </div>
    );
  }
}

TaskApplicantAssignTable.propTypes = {
  data: PropTypes.array.isRequired,
  applicantsLoading: PropTypes.bool.isRequired,
  selectedTask: PropTypes.object,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  applicantPrevTasksOpenTAA: PropTypes.array.isRequired,
  openApplicantPrevTasksDialog: PropTypes.func.isRequired,
  toggleApplicantPrevTasksTAA: PropTypes.func.isRequired,
  openEditTimeDialog: PropTypes.func.isRequired,
  tableRefTAA: PropTypes.object.isRequired,
  currentStartTimeMap: PropTypes.object.isRequired,
  currentEndTimeMap: PropTypes.object.isRequired,
  handleAssign: PropTypes.func.isRequired,
  handleTAAtoggle: PropTypes.func.isRequired,
  applicantLoadBackupToggle: PropTypes.object.isRequired,
  handleOpenTAAFilter: PropTypes.func.isRequired,
  applicantMap: PropTypes.any.isRequired,
};

export default TaskApplicantAssignTable;
