import { fromJS } from 'immutable';

import {
  GET_ANNOUNCEMENTS_SENT,
  GET_ANNOUNCEMENTS_SUCCESSFUL,
  CREATE_ANNOUNCEMENT_SENT,
  CREATE_ANNOUNCEMENT_SUCCESSFUL,
  UPDATE_ANNOUNCEMENT_SENT,
  UPDATE_ANNOUNCEMENT_SUCCESSFUL,
} from 'actions/atomic/announcements';

const initialState = fromJS({
  loading: false,
  data: [],
});

export default function recruiters(state = initialState, action) {
  switch (action.type) {
  case GET_ANNOUNCEMENTS_SENT:
    return state.merge({
      loading: true,
      data: fromJS([]),
    });
  case GET_ANNOUNCEMENTS_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: fromJS(action.data),
    });
  case CREATE_ANNOUNCEMENT_SENT:
    return state.set('loading', true);
  case CREATE_ANNOUNCEMENT_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: state.get('data').push(fromJS(action.data)),
    });
  case UPDATE_ANNOUNCEMENT_SENT:
    return state.set('loading', true);
  case UPDATE_ANNOUNCEMENT_SUCCESSFUL:
    const updatedAnnouncement = action.data;
    const index = state.get('data').findIndex(announcement => announcement.get('id') === updatedAnnouncement.id);
    return state.merge({
      loading: false,
      data: state.get('data').set(index, fromJS(updatedAnnouncement)),
    });
  default:
    return state;
  }
}
