import { connect } from 'react-redux';

import { selectIsLoggedIn, selectIsAdmin, selectLoginValidationComplete } from 'selectors/authenticationSelectors';
import { logout, validateLogin } from 'actions/thunks/authentication';
import { selectYear } from 'selectors/systemSettingsSelectors';
import { getYear } from 'actions/thunks/systemSettings';
import { selectEventDates } from 'selectors/eventDatesSelectors';
import { getEventDates } from 'actions/thunks/eventDates';
import App from 'components/common/App';

const mapStateToProps = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  isAdmin: selectIsAdmin(state),
  loginValidationComplete: selectLoginValidationComplete(state),
  currentYear: selectYear(state),
  eventDates: selectEventDates(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  validateLogin: () => dispatch(validateLogin()),
  getYear: () => dispatch(getYear()),
  getEventDates: () => dispatch(getEventDates()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
