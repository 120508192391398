import React from 'react';
import PropTypes from 'prop-types';

import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableSelect from 'components/common/ReusableSelect';
import ReusableTextField from 'components/common/ReusableTextField';
import debounce from 'lodash/debounce';
import history from 'wrappers/history';
import styles from './style.module.scss';
import { yearOptions } from '../AdminUtils.ts';

class SystemSettingsPage extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }

    this.state = {
      motd: '',
      emailSignature: '',
      yaspFormLink: '',
    };
  }

  async componentDidMount() {
    if (this.props.eventDates.length === 0) {
      this.props.getEventDates();
    }
    if (this.props.year === '') {
      this.props.getYear();
    }
    if (this.props.motd === '') {
      await this.props.getMOTD();
    }
    if (this.props.emailSignature === '') {
      await this.props.getEmailSignature();
    }
    if (this.props.yaspFormLink === '') {
      await this.props.getYaspFormLink();
    }
    this.setState({
      motd: this.props.motd,
      emailSignature: this.props.emailSignature,
      yaspFormLink: this.props.yaspFormLink,
    });
    this.updateMOTD = debounce(this.updateMOTD, 500);
    this.updateEmailSignature = debounce(this.updateEmailSignature, 500);
    this.updateYaspFormLink = debounce(this.updateYaspFormLink, 500);
  }

  handleYearChange(event) {
    const newYear = { 'year': event.target.value };
    this.props.updateYear(newYear);
  }

  handleMOTDChange(event) {
    this.setState({ motd: event.target.value });
    const newMOTD = { 'motd': event.target.value };
    this.updateMOTD(newMOTD);
  }

  handleEmailSignatureChange(event) {
    this.setState({ emailSignature: event.target.value });
    const newEmailSignature = { 'email_signature': event.target.value };
    this.updateEmailSignature(newEmailSignature);
  }

  handleYaspFormLinkChange(event) {
    this.setState({ yaspFormLink: event.target.value });
    const newYaspFormLink = { 'yasp_form_link': event.target.value };
    this.updateYaspFormLink(newYaspFormLink);
  }

  retryUpdate = () => {
    const newMOTD = { 'motd': this.state.motd };
    const newEmailSignature = { 'email_signature': this.state.emailSignature };
    this.props.updateMOTD(newMOTD);
    this.props.updateEmailSignature(newEmailSignature);
  };

  updateMOTD(newMOTD) {
    this.props.updateMOTD(newMOTD);
  }

  updateEmailSignature(newEmailSignature) {
    this.props.updateEmailSignature(newEmailSignature);
  }

  updateYaspFormLink(newYaspFormLink) {
    this.props.updateYaspFormLink(newYaspFormLink);
  }

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <h2 className={styles['no-margin-top']}>
              System Settings
            </h2>
            <hr className={styles['divider']} />
            <div className={styles['grid-container']}>
              <h5 className={styles['header-white']}>
                Current Event Date
              </h5>
              <div className={styles['grid-item-white']}>
                <div className={styles['year-select-container']}>
                  <ReusableSelect
                    id="year"
                    label="Event Date"
                    className={styles['year-select']}
                    value={this.props.year}
                    options={yearOptions(this.props.eventDates)}
                    onChange={(event) => this.handleYearChange(event)}
                    excludeNoneOption
                  />
                </div>
              </div>

              <h5 className={styles['header-gray']}>
                Message of the Day
              </h5>
              <div className={styles['grid-item-gray']}>
                <ReusableTextField
                  id="motd"
                  label="MOTD"
                  multiline
                  value={this.state.motd}
                  onChange={(event) => this.handleMOTDChange(event)}
                />
              </div>

              <h5 className={styles['header-white']}>
                YASP Form Link
              </h5>
              <h5 className={styles['grid-item-white']}>
                <ReusableTextField
                  id="yaspFormLink"
                  label="YASP Form Link"
                  multiline
                  value={this.state.yaspFormLink}
                  onChange={(event) => this.handleYaspFormLinkChange(event)}
                />
              </h5>

              <h5 className={styles['header-gray']}>
                Email Signature
              </h5>
              <div className={styles['grid-item-gray']}>
                <ReusableTextField
                  id="emailSignature"
                  label="Email Signature"
                  multiline
                  value={this.state.emailSignature}
                  onChange={(event) => this.handleEmailSignatureChange(event)}
                />
              </div>
            </div>
            <ReusableSnackbar
              open={this.props.isError}
              variant="error"
              message="You have unsaved changes."
              optionalButtonMessage="Retry"
              optionalButtonOnClick={this.retryUpdate}
            />
          </div>
        </div>
      </div>
    );
  }
}

SystemSettingsPage.propTypes = {
  isError: PropTypes.bool.isRequired,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  motd: PropTypes.string.isRequired,
  emailSignature: PropTypes.string.isRequired,
  eventDates: PropTypes.array.isRequired,
  getYear: PropTypes.func.isRequired,
  updateYear: PropTypes.func.isRequired,
  getMOTD: PropTypes.func.isRequired,
  updateMOTD: PropTypes.func.isRequired,
  getEmailSignature: PropTypes.func.isRequired,
  updateEmailSignature: PropTypes.func.isRequired,
  getEventDates: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  yaspFormLink: PropTypes.string.isRequired,
  getYaspFormLink: PropTypes.func.isRequired,
  updateYaspFormLink: PropTypes.func.isRequired,
};

export default SystemSettingsPage;
