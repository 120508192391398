export const GET_YEAR_SENT = 'GET_YEAR_SENT';
export const GET_YEAR_SUCCESSFUL = 'GET_YEAR_SUCCESSFUL';
export const UPDATE_YEAR_SENT = 'UPDATE_YEAR_SENT';
export const UPDATE_YEAR_SUCCESSFUL = 'UPDATE_YEAR_SUCCESSFUL';
export const GET_MOTD_SENT = 'GET_MOTD_SENT';
export const GET_MOTD_SUCCESSFUL = 'GET_MOTD_SUCCESSFUL';
export const UPDATE_MOTD_SENT = 'UPDATE_MOTD_SENT';
export const UPDATE_MOTD_SUCCESSFUL = 'UPDATE_MOTD_SUCCESSFUL';
export const UPDATE_MOTD_FAILED = 'UPDATE_MOTD_FAILED';
export const GET_EMAIL_SIGNATURE_SENT = 'GET_EMAIL_SIGNATURE_SENT';
export const GET_EMAIL_SIGNATURE_SUCCESSFUL = 'GET_EMAIL_SIGNATURE_SUCCESSFUL';
export const UPDATE_EMAIL_SIGNATURE_SENT = 'UPDATE_EMAIL_SIGNATURE_SENT';
export const UPDATE_EMAIL_SIGNATURE_SUCCESSFUL = 'UPDATE_EMAIL_SIGNATURE_SUCCESSFUL';
export const UPDATE_EMAIL_SIGNATURE_FAILED = 'UPDATED_EMAIL_SIGNATURE_FAILED';
export const GET_YASP_FORM_LINK_SENT = 'GET_YASP_FORM_LINK_SENT';
export const GET_YASP_FORM_LINK_SUCCESSFUL = 'GET_YASP_FORM_LINK_SUCCESSFUL';
export const UPDATE_YASP_FORM_LINK_SENT = 'UPDATE_YASP_FORM_LINK_SENT';
export const UPDATE_YASP_FORM_LINK_SUCCESSFUL = 'UPDATE_YASP_FORM_LINK_SUCCESSFUL';
export const UPDATE_YASP_FORM_LINK_FAILED = 'UPDATED_YASP_FORM_LINK_FAILED';

export const getYearRequestSent = () => ({ type: GET_YEAR_SENT });

export const getYearRequestSuccessful = (data) => ({ type: GET_YEAR_SUCCESSFUL, data });

export const updateYearRequestSent = () => ({ type: UPDATE_YEAR_SENT });

export const updateYearRequestSuccessful = (data) => ({ type: UPDATE_YEAR_SUCCESSFUL, data });

export const getMOTDRequestSent = () => ({ type: GET_MOTD_SENT });

export const getMOTDRequestSuccessful = (data) => ({ type: GET_MOTD_SUCCESSFUL, data });

export const updateMOTDRequestSent = () => ({ type: UPDATE_MOTD_SENT });

export const updateMOTDRequestSuccessful = (data) => ({ type: UPDATE_MOTD_SUCCESSFUL, data });

export const updateMOTDRequestFailed = () => ({ type: UPDATE_MOTD_FAILED });

export const getEmailSignatureRequestSent = () => ({ type: GET_EMAIL_SIGNATURE_SENT });

export const getEmailSignatureRequestSuccessful = (data) => ({ type: GET_EMAIL_SIGNATURE_SUCCESSFUL, data });

export const updateEmailSignatureRequestSent = () => ({ type: UPDATE_EMAIL_SIGNATURE_SENT });

export const updateEmailSignatureRequestSuccessful = (data) => ({ type: UPDATE_EMAIL_SIGNATURE_SUCCESSFUL, data });

export const updateEmailSignatureRequestFailed = () => ({ type: UPDATE_EMAIL_SIGNATURE_FAILED });

export const getYaspFormLinkRequestSent = () => ({ type: GET_YASP_FORM_LINK_SENT });

export const getYaspFormLinkRequestSuccessful = (data) => ({ type: GET_YASP_FORM_LINK_SUCCESSFUL, data });

export const updateYaspFormLinkRequestSent = () => ({ type: UPDATE_YASP_FORM_LINK_SENT });

export const updateYaspFormLinkRequestSuccessful = (data) => ({ type: UPDATE_YASP_FORM_LINK_SUCCESSFUL, data });

export const updateYaspFormLinkRequestFailed = () => ({ type: UPDATE_YASP_FORM_LINK_FAILED });
