import React from 'react';
import PropTypes from 'prop-types';

import ReusableButton from 'components/common/ReusableButton';
import UserAccountForm from 'components/common/UserAccountForm';
import history from 'wrappers/history';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';

class AccountCreationPage extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.isLoggedIn) {
      history.push('/');
    }
  }

  componentWillUnmount = () => {
    this.props.resetCreateUserFields();
  };

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['create-account-form-container']}>
              <h2 className={styles['page-header']}>
                Create New Account
              </h2>
              {!this.props.createUserRequestComplete
              && (
                <UserAccountForm
                  submitButtonText="Create My Account"
                  handleSubmit={this.props.createAccount}
                  genderOptions={this.props.genderOptions}
                />
              )}
              {this.props.createUserRequestComplete
              && this.props.creationSuccessful
              && (
                <div className={styles['message-container']}>
                  <div className={styles['message']}>
                    Your account has been created successfully. Please check your email to activate your account and login once you have done so.
                  </div>
                  <ReusableButton
                    value="Return Home"
                    hasTopMargin
                    onClick={() => history.push('/')}
                  />
                </div>
              )}
              {this.props.createUserRequestComplete
              && this.props.userExists
              && !this.props.activated
              && (
                <div className={styles['message-container']}>
                  <div className={styles['message']}>
                    An account with this email already exists but has not been activated. Please check your email to activate your account and login once you have done so.
                  </div>
                  <ReusableButton
                    value="Return Home"
                    hasTopMargin
                    onClick={() => history.push('/')}
                  />
                </div>
              )}
              {this.props.createUserRequestComplete
              && this.props.userExists
              && this.props.activated
              && (
                <div className={styles['message-container']}>
                  <div className={styles['message']}>
                    An active account already exists with this email. Are you trying to login?
                  </div>
                  <Link to="/forgot-password">Forgot your password?</Link>
                  <ReusableButton
                    value="Login"
                    hasTopMargin
                    onClick={() => history.push('/login')}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AccountCreationPage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  createAccount: PropTypes.func.isRequired,
  createUserRequestComplete: PropTypes.bool.isRequired,
  creationSuccessful: PropTypes.bool,
  userExists: PropTypes.bool,
  activated: PropTypes.bool,
  genderOptions: PropTypes.array.isRequired,
  resetCreateUserFields: PropTypes.func.isRequired,
};

export default AccountCreationPage;
