import { connect } from 'react-redux';
import ContactPage from 'components/pages/ContactPage';
import { selectEmailSuccessful } from 'selectors/contactUsSelectors';
import {
  sendContactUsEmail,
} from 'actions/thunks/contactUs';

const mapStateToProps = state => ({
  emailSuccessful: selectEmailSuccessful(state),
});

const mapDispatchToProps = dispatch => ({
  sendContactUsEmail: (payload) => dispatch(sendContactUsEmail(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactPage);
