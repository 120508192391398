import React from 'react';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';
import ReusableClickOutsideAction from '../ReusableClickOutsideAction';

import styles from './style.module.scss';

class ReusableColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colorPickerOpen: false,
    };
  }

  handleClick = () => {
    this.setState((prevState) => ({
      colorPickerOpen: !prevState.colorPickerOpen,
    }));
  };

  handleClose = () => {
    this.setState({
      colorPickerOpen: false,
    });
  };

  render() {
    const color = {
      backgroundColor: this.props.color,
    };
    return (
      <div className={styles[this.props.className]}>
        <div onClick={this.handleClick} onKeyDown={this.handleClick} style={color} className={styles['color-picker-button']} />
        {this.state.colorPickerOpen ? (
          <div className={styles['color-picker-popover']}>
            <ReusableClickOutsideAction action={this.handleClose}>
              <SketchPicker color={this.props.color} onChange={this.props.onChange} />
            </ReusableClickOutsideAction>
          </div>
        ) : null }
      </div>
    );
  }
}

ReusableColorPicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  color: PropTypes.string.isRequired,
};

export default ReusableColorPicker;
