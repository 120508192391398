import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import UserAccountForm from 'components/common/UserAccountForm';
import ReusableTextField from 'components/common/ReusableTextField';
import ReusableButton from 'components/common/ReusableButton';
import ReusableSnackbar from 'components/common/ReusableSnackbar';

import ApplicationStep from 'components/signup/ApplicationStep';
import TaskSignupStep from 'components/signup/TaskSignupStep';

import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import styles from './style.module.scss';

const dialogTitleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(dialogTitleStyles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const initialState = {
  searchEmail: '',
  searchFirstName: '',
  searchLastName: '',
  user: null,
  applicant: null,
  successSnackbarOpen: false,
  successSnackbarMessage: '',
  confirmCloseDialogOpen: false,
  changesMade: false,

  // Application Fields
  applicationSpeaker: false,
  applicationDepartmentDisplay: false,
  applicationActivityWorker: true,
  applicationStatus: null,
  applicationAffiliation: null,
  applicationRecruiter: null,
  applicationRecruitedFrom: null,
  comments: '',

  // Yasp Form Fields
  yaspFormSigned: false,
  yaspFormSignedDate: null,

  // Task Signup Fields
  taskSignupCurrentStartTime: null,
  taskSignupCurrentEndTime: null,
  taskSignupEnteredTimeWindows: [],
  taskPreferences: {},
};

class AddEditApplicantDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = cloneDeep(initialState);
    this.state.stage = props.applicant ? 'applicantFound' : 'searchUsers';
  }

  componentDidMount = () => {
    if (this.props.statuses.length === 0) {
      this.props.getStatuses();
    }

    if (this.props.affiliations.length === 0) {
      this.props.getAffiliations();
    }

    if (this.props.recruiters.length === 0) {
      this.props.getRecruiters();
    }

    if (this.props.classes.length === 0) {
      this.props.getClasses();
    }

    if (this.props.tasks.length === 0) {
      this.props.getTasks();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.applicant !== this.props.applicant) {
      this.updateStateFieldsForApplicant(this.props.applicant);
    }
  };

  get statusList() {
    const sortedStatuses = sortBy(this.props.statuses.concat(), ['priority']);
    const approvedStatuses = sortedStatuses.filter(status => status.approved && status.active);
    return approvedStatuses;
  }

  get affiliationList() {
    const sortedAffiliations = sortBy(this.props.affiliations.concat(), ['priority', 'name']);
    const approvedAffiliations = sortedAffiliations.filter(affiliation => affiliation.approved);
    return approvedAffiliations.map(affiliation => ({ ...affiliation, grouping: `------ ${affiliation.priority} ------` }));
  }

  get recruiterList() {
    const sortedRecruiters = sortBy(this.props.recruiters.concat(), ['last_name', 'first_name']);
    const approvedRecruiters = sortedRecruiters.filter(recruiter => recruiter.approved);
    return approvedRecruiters.map(recruiter => ({ ...recruiter, display: `${recruiter.last_name}, ${recruiter.first_name}` }));
  }

  get classList() {
    const sortedClasses = sortBy(this.props.classes.concat(), ['name']);
    const approvedClasses = sortedClasses.filter(classRecruited => classRecruited.approved && classRecruited.active);
    return approvedClasses;
  }

  get currentYearTasks() {
    return this.props.tasks.filter(task => task.year === this.props.year);
  }

  get visibleSortedTasks() {
    const visibleTasks = this.currentYearTasks.filter(task => {
      const visibleToIds = task.visible_to.map(status => status.id);

      return visibleToIds.includes(this.state.applicationStatus ? this.state.applicationStatus.id : null);
    });

    const sortedTasks = sortBy(visibleTasks.concat(), ['starts_at', 'display_string']);
    return sortedTasks;
  }

  get tasksAvailableForApplicant() {
    return this.visibleSortedTasks.filter(task => this.isApplicantAvailableForTask(task));
  }

  get joinedVisibleTasks() {
    const tasksGroupedByDisplayString = {};
    this.visibleSortedTasks.forEach(task => {
      if (tasksGroupedByDisplayString[task.display_string] !== undefined) {
        if (tasksGroupedByDisplayString[task.display_string][this.formatTaskTimeWindow(task)] !== undefined) {
          tasksGroupedByDisplayString[task.display_string][this.formatTaskTimeWindow(task)].push(task);
        } else {
          tasksGroupedByDisplayString[task.display_string][this.formatTaskTimeWindow(task)] = [task];
        }
      } else {
        tasksGroupedByDisplayString[task.display_string] = { [this.formatTaskTimeWindow(task)]: [task] };
      }
    });

    const joinedVisibleTasks = [];
    Object.keys(tasksGroupedByDisplayString).forEach(taskDisplay => {
      const tasksByTime = tasksGroupedByDisplayString[taskDisplay];
      Object.keys(tasksByTime).forEach(taskTime => {
        const tasks = tasksByTime[taskTime];
        const taskGrouping = {
          starts_at: tasks[0].starts_at,
          ends_at: tasks[0].ends_at,
          display_string: taskDisplay,
          name: tasks[0].name,
          description: tasks[0].description,
          task_ids: tasks.map(task => task.id),
          tasks,
        };
        joinedVisibleTasks.push(taskGrouping);
      });
    });

    return joinedVisibleTasks;
  }

  get timeEntryInvalid() {
    return this.state.taskSignupCurrentStartTime >= this.state.taskSignupCurrentEndTime
     || this.state.taskSignupCurrentStartTime < dayjs('1941-12-07 6:00')
     || this.state.taskSignupCurrentEndTime > dayjs('1941-12-07 17:30');
  }

  updateStateFieldsForApplicant = (applicant) => {
    this.setState({ applicant });

    const preferenceStateFields = {};
    this.currentYearTasks.forEach(task => {
      // Set a default value of acceptable
      preferenceStateFields[`task${task.id}`] = 3;
    });

    this.setState({
      taskPreferences: preferenceStateFields,
    });

    if (applicant) {
      const existingTimeWindows = applicant.time_windows.map(timeWindow => ({ start_at: dayjs(timeWindow.starts_at), end_at: dayjs(timeWindow.ends_at) }));
      const existingPreferences = {};
      applicant.task_preferences.forEach(preference => {
        existingPreferences[`task${preference.task_id}`] = preference.preference;
      });

      this.setState(prevState => ({
        stage: 'applicantFound',
        user: applicant.user,
        applicationSpeaker: applicant.speaker,
        applicationDepartmentDisplay: applicant.department_display,
        applicationActivityWorker: applicant.activity_worker,
        applicationStatus: applicant.status ? this.statusList.find(status => status.id === applicant.status.id) : null,
        applicationAffiliation: applicant.affiliation ? this.affiliationList.find(affiliation => affiliation.id === applicant.affiliation.id) : null,
        applicationRecruiter: applicant.recruiter ? this.recruiterList.find(recruiter => recruiter.id === applicant.recruiter.id) : null,
        applicationRecruitedFrom: applicant.math_class ? this.classList.find(mathClass => mathClass.id === applicant.math_class.id) : null,
        comments: applicant.comments,
        yaspFormSignedDate: applicant.youth_form_date ? dayjs(applicant.youth_form_date) : null,
        sorCheckDate: applicant.sor_check ? dayjs(applicant.sor_check) : null,
        taskSignupEnteredTimeWindows: existingTimeWindows,
        taskPreferences: {
          ...prevState.taskPreferences,
          ...existingPreferences,
        },
      }));
    }
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value, changesMade: true });
  };

  handleCheckboxChange = name => event => {
    this.setState({ [name]: event.target.checked, changesMade: true });
  };

  handleAutocompleteChange = name => (event, value) => {
    this.setState({ [name]: value, changesMade: true });
  };

  handleDateChange = name => date => {
    this.setState({ [name]: date, changesMade: true });
  };

  handleTimeChange = name => newTime => {
    // For some reason, the month setter uses zero indexing, but the year and date do not. Fun.
    // Actual date is 1941-12-07. We have to set the correct date here because the keyboard picker chooses the current date
    // while the popup picker chooses the original date.
    if (newTime !== null) {
      let newTimeWithFixedDate = newTime.year(1941);
      newTimeWithFixedDate = newTimeWithFixedDate.month(11);
      newTimeWithFixedDate = newTimeWithFixedDate.date(7);
      this.setState({ [name]: newTimeWithFixedDate, changesMade: true });
    } else {
      this.setState({ [name]: newTime, changesMade: true });
    }
  };

  addTimeWindow = () => {
    const newTimeWindows = [...this.state.taskSignupEnteredTimeWindows, { start_at: this.state.taskSignupCurrentStartTime, end_at: this.state.taskSignupCurrentEndTime }];
    const sortedTimeWindows = sortBy(newTimeWindows, 'start_at');
    const mergedTimeWindows = [];

    sortedTimeWindows.forEach(timeWindow => {
      if (mergedTimeWindows.length === 0 || mergedTimeWindows[mergedTimeWindows.length - 1].end_at < timeWindow.start_at) {
        mergedTimeWindows.push(timeWindow);
      } else if (mergedTimeWindows[mergedTimeWindows.length - 1].end_at < timeWindow.end_at) {
        mergedTimeWindows[mergedTimeWindows.length - 1].end_at = timeWindow.end_at;
      }
    });

    this.setState({
      taskSignupEnteredTimeWindows: mergedTimeWindows,
      changesMade: true,
    });
  };

  removeTimeWindow = (removedTimeWindow) => {
    this.setState(prevState => ({
      taskSignupEnteredTimeWindows: prevState.taskSignupEnteredTimeWindows.filter(timeWindow => timeWindow !== removedTimeWindow),
      changesMade: true,
    }));
  };

  formatTimeWindow = (timeWindow) => `${timeWindow.start_at.format('h:mm A')} - ${timeWindow.end_at.format('h:mm A')}`;

  formatTaskTimeWindow = task => {
    const startTime = dayjs(task.starts_at);
    const endTime = dayjs(task.ends_at);

    return `${startTime.format('h:mm A')} - ${endTime.format('h:mm A')}`;
  };

  isApplicantAvailableForTask = task => {
    const taskStartTime = dayjs(task.starts_at);
    const taskEndTime = dayjs(task.ends_at);
    return this.state.taskSignupEnteredTimeWindows.some(timeWindow => {
      const taskContainedInTimeWindow = timeWindow.start_at <= taskStartTime && timeWindow.end_at >= taskEndTime;
      const taskPreemtableAndOverlapping = task.preemtable && taskStartTime <= timeWindow.end_at && timeWindow.start_at < taskEndTime;
      return taskContainedInTimeWindow || taskPreemtableAndOverlapping;
    });
  };

  handleTaskPreferenceChange = taskGrouping => event => {
    const updatedTaskPreferences = {};
    taskGrouping.task_ids.forEach(taskId => {
      updatedTaskPreferences[`task${taskId}`] = event.target.value;
    });

    this.setState(prevState => ({
      taskPreferences: {
        ...prevState.taskPreferences,
        ...updatedTaskPreferences,
      },
      changesMade: true,
    }));
  };

  handleSearchClicked = () => {
    const payload = {
      email: this.state.searchEmail,
      first_name: this.state.searchFirstName,
      last_name: this.state.searchLastName,
    };
    this.props.findExistingUsers(payload);
  };

  openConfirmCloseDialog = () => {
    if (this.state.changesMade) {
      this.setState({ confirmCloseDialogOpen: true });
    } else {
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.setState(cloneDeep(initialState));
    this.setState({ stage: 'searchUsers', confirmCloseDialogOpen: false, changesMade: false });
    this.props.resetDialog();
    this.props.handleClose();
  };

  selectExistingUser = user => () => {
    const userApplicationForCurrentYear = this.props.applicants.filter(existingApplicant => {
      const userMatches = existingApplicant.user && existingApplicant.user.id === user.id;
      const yearMatches = existingApplicant.year === this.props.year;
      return userMatches && yearMatches;
    });

    if (userApplicationForCurrentYear.length === 1) {
      this.updateStateFieldsForApplicant(userApplicationForCurrentYear[0]);
    } else {
      const preferenceStateFields = {};
      this.currentYearTasks.forEach(task => {
        // Set a default value of acceptable
        preferenceStateFields[`task${task.id}`] = 3;
      });
      this.setState({ user, stage: 'userFoundNoApplication', taskPreferences: preferenceStateFields });
    }
  };

  createNewUserFromSearch = () => {
    this.setState({ stage: 'newUser' });
  };

  createUser = async payload => {
    const newUser = await this.props.createUser(payload.user);
    const preferenceStateFields = {};
    this.currentYearTasks.forEach(task => {
      // Set a default value of acceptable
      preferenceStateFields[`task${task.id}`] = 3;
    });
    this.setState({ stage: 'userFoundNoApplication', user: newUser, taskPreferences: preferenceStateFields });
  };

  updateUser = async payload => {
    await this.props.updateUser(payload.user);
    this.setState({ successSnackbarOpen: true, successSnackbarMessage: 'User updated successfully' });
    this.props.getApplicants();
  };

  saveApplicant = () => {
    const timeWindows = this.state.taskSignupEnteredTimeWindows.map(timeWindow => ({
      starts_at: timeWindow.start_at.format(),
      ends_at: timeWindow.end_at.format(),
    }));
    const taskPreferences = this.tasksAvailableForApplicant.map(task => ({
      task_id: task.id,
      preference: this.state.taskPreferences[`task${task.id}`],
    }));
    const payload = {
      activity_worker: this.state.applicationActivityWorker,
      department_display: this.state.applicationDepartmentDisplay,
      speaker: this.state.applicationSpeaker,
      status: this.state.applicationStatus ? this.state.applicationStatus.id : null,
      affiliation: this.state.applicationAffiliation ? this.state.applicationAffiliation.id : null,
      recruiter: this.state.applicationRecruiter ? this.state.applicationRecruiter.id : null,
      math_class: this.state.applicationRecruitedFrom ? this.state.applicationRecruitedFrom.id : null,
      comments: this.state.comments,
      yasp_signed_date: this.state.yaspFormSignedDate ? this.state.yaspFormSignedDate.format() : null,
      sor_check: this.state.sorCheckDate ? this.state.sorCheckDate.format() : null,
      time_windows: timeWindows,
      task_preferences: taskPreferences,
    };

    if (this.state.stage === 'applicantFound') {
      payload.id = this.state.applicant.id;
      this.props.updateApplicant(payload);
    } else if (this.state.stage === 'userFoundNoApplication') {
      payload.user_id = this.state.user.id;
      this.props.createApplicant(payload);
    }
    this.setState({ changesMade: false });
  };

  closeSuccessSnackbar = () => {
    this.setState({ successSnackbarOpen: false, successSnackbarMessage: '' });
  };

  renderSearchUsers() {
    return (
      <Dialog open={this.props.open} onClose={this.closeDialog} maxWidth="lg">
        <DialogTitle onClose={this.closeDialog}>
          Create New Applicant
        </DialogTitle>
        <DialogContent className={styles['add-edit-applicant-dialog']}>
          <ReusableTextField
            id="searchEmail"
            label="Email"
            value={this.state.searchEmail}
            onChange={this.handleChange('searchEmail')}
          />
          <div className={styles['row']}>
            <ReusableTextField
              id="searchFirstName"
              label="First Name"
              value={this.state.searchFirstName}
              onChange={this.handleChange('searchFirstName')}
              className={styles['right-margin']}
            />
            <ReusableTextField
              id="searchLastName"
              label="Last Name"
              value={this.state.searchLastName}
              onChange={this.handleChange('searchLastName')}
            />
          </div>
          <div className={styles['search-button-row']}>
            <ReusableButton
              value="Search"
              onClick={this.handleSearchClicked}
            />
          </div>
          {this.props.existingUsers.length > 0
          && (
            <div className={styles['search-results']}>
              <hr />
              <h5>Search Results</h5>
              Found&nbsp;
              {this.props.existingUsers.length}
              &nbsp;users. Select a user to create or edit his/her application.
              <div className={styles['existing-users-table-container']}>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          First Name
                        </TableCell>
                        <TableCell>
                          Last Name
                        </TableCell>
                        <TableCell>
                          Email
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.existingUsers.map(user => (
                        <TableRow
                          hover
                          key={user.id}
                          onClick={this.selectExistingUser(user)}
                        >
                          <TableCell>
                            {user.first_name}
                          </TableCell>
                          <TableCell>
                            {user.last_name}
                          </TableCell>
                          <TableCell>
                            {user.email}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={styles['create-new-user-row']}>
                  <div>
                    Do you want to create a new user?
                  </div>
                  <ReusableButton
                    value="Create User"
                    onClick={this.createNewUserFromSearch}
                  />
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  }

  renderEditApplicant() {
    return (
      <>
        <Dialog open={this.props.open} onClose={this.openConfirmCloseDialog} maxWidth="lg">
          <DialogTitle onClose={this.openConfirmCloseDialog}>
            {this.state.stage === 'applicantFound' ? 'Editing' : 'Creating'}
            &nbsp;Applicant
          </DialogTitle>
          <DialogContent className={styles['edit-applicant-dialog-content']}>
            <div className={styles['application-edit-fields-container']}>
              {this.state.stage === 'newUser'
              && (
                <div className={styles['box']}>
                  <UserAccountForm
                    genderOptions={this.props.genderOptions}
                    handleSubmit={this.createUser}
                    submitButtonText="Create User"
                    fromAdmin
                  />
                </div>
              )}
              {this.state.stage !== 'newUser'
              && (
                <>
                  <div className={styles['box']}>
                    <UserAccountForm
                      existingUser={this.state.user}
                      genderOptions={this.props.genderOptions}
                      handleSubmit={this.updateUser}
                      submitButtonText="Save User"
                      fromAdmin
                    />
                  </div>
                  <div className={styles['box']}>
                    <ApplicationStep
                      speaker={this.state.applicationSpeaker}
                      departmentDisplay={this.state.applicationDepartmentDisplay}
                      activityWorker={this.state.applicationActivityWorker}
                      handleCheckboxChange={this.handleCheckboxChange}
                      handleAutocompleteChange={this.handleAutocompleteChange}
                      status={this.state.applicationStatus}
                      statusList={this.statusList}
                      affiliation={this.state.applicationAffiliation}
                      affiliationList={this.affiliationList}
                      recruiter={this.state.applicationRecruiter}
                      recruiterList={this.recruiterList}
                      recruitedFrom={this.state.applicationRecruitedFrom}
                      recruitedFromList={this.classList}
                      comments={this.state.comments}
                      handleCommentsChange={this.handleChange}
                      fromAdmin
                      yaspFormSignedDate={this.state.yaspFormSignedDate}
                      sorCheckDate={this.state.sorCheckDate}
                      handleDateChange={this.handleDateChange}
                    />
                  </div>
                  <div className={styles['box']}>
                    <TaskSignupStep
                      addTimeWindow={this.addTimeWindow}
                      timeEntryInvalid={this.timeEntryInvalid}
                      currentStartTime={this.state.taskSignupCurrentStartTime}
                      currentEndTime={this.state.taskSignupCurrentEndTime}
                      handleTimeChange={this.handleTimeChange}
                      enteredTimeWindows={this.state.taskSignupEnteredTimeWindows}
                      formatTimeWindow={this.formatTimeWindow}
                      removeTimeWindow={this.removeTimeWindow}
                      comments={this.state.comments}
                      handleChange={this.handleChange}
                      tasks={this.joinedVisibleTasks}
                      isApplicantAvailableForTask={this.isApplicantAvailableForTask}
                      formatTaskTimeWindow={this.formatTaskTimeWindow}
                      handleTaskPreferenceChange={this.handleTaskPreferenceChange}
                      taskPreferences={this.state.taskPreferences}
                      currentEventDate={this.currentEventDate}
                      fromAdmin
                    />
                  </div>
                </>
              )}
              <Dialog open={this.state.confirmCloseDialogOpen}>
                <DialogTitle>
                  Are you sure you wish to cancel?
                </DialogTitle>
                <DialogContent>
                  Pressing Yes will cause all changes to be lost.
                </DialogContent>
                <DialogActions>
                  <div className={styles['save-button-row']}>
                    <ReusableButton
                      value="Cancel"
                      onClick={() => this.setState({ confirmCloseDialogOpen: false })}
                    />
                    <div style={{ width: '10px' }} />
                    <ReusableButton
                      value="Yes"
                      onClick={this.closeDialog}
                    />
                  </div>
                </DialogActions>
              </Dialog>
            </div>
          </DialogContent>
          <DialogActions>
            <div className={styles['save-button-row']}>
              <ReusableButton
                value={`${this.state.stage === 'userFoundNoApplication' ? 'Create' : 'Save'} Application`}
                onClick={this.saveApplicant}
              />
            </div>
          </DialogActions>
        </Dialog>
        <ReusableSnackbar
          open={this.state.successSnackbarOpen}
          onClose={this.closeSuccessSnackbar}
          variant="success"
          message={this.state.successSnackbarMessage}
        />
      </>
    );
  }

  render() {
    return this.state.stage === 'searchUsers' ? this.renderSearchUsers() : this.renderEditApplicant();
  }
}

AddEditApplicantDialog.propTypes = {
  applicants: PropTypes.array,
  applicant: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  findExistingUsers: PropTypes.func.isRequired,
  existingUsers: PropTypes.array,
  resetDialog: PropTypes.func.isRequired,
  genderOptions: PropTypes.array.isRequired,
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  getApplicants: PropTypes.func.isRequired,
  statuses: PropTypes.array,
  getStatuses: PropTypes.func.isRequired,
  affiliations: PropTypes.array,
  getAffiliations: PropTypes.func.isRequired,
  recruiters: PropTypes.array,
  getRecruiters: PropTypes.func.isRequired,
  classes: PropTypes.array,
  getClasses: PropTypes.func.isRequired,
  tasks: PropTypes.array,
  getTasks: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
  createApplicant: PropTypes.func.isRequired,
  updateApplicant: PropTypes.func.isRequired,
};

export default AddEditApplicantDialog;
