export const GET_RECRUITERS_SENT = 'GET_RECRUITERS_SENT';
export const GET_RECRUITERS_SUCCESSFUL = 'GET_RECRUITERS_SUCCESSFUL';
export const CREATE_RECRUITER_SENT = 'CREATE_RECRUITER_SENT';
export const CREATE_RECRUITER_SUCCESSFUL = 'CREATE_RECRUITER_SUCCESSFUL';
export const UPDATE_RECRUITER_SENT = 'UPDATE_RECRUITER_SENT';
export const UPDATE_RECRUITER_SUCCESSFUL = 'UPDATE_RECRUITER_SUCCESSFUL';

export const getRecruitersRequestSent = () => ({ type: GET_RECRUITERS_SENT });

export const getRecruitersRequestSuccessful = (data) => ({ type: GET_RECRUITERS_SUCCESSFUL, data });

export const createRecruiterRequestSent = () => ({ type: CREATE_RECRUITER_SENT });

export const createRecruiterRequestSuccessful = (data) => ({ type: CREATE_RECRUITER_SUCCESSFUL, data });

export const updateRecruiterRequestSent = () => ({ type: UPDATE_RECRUITER_SENT });

export const updateRecruiterRequestSuccessful = (data) => ({ type: UPDATE_RECRUITER_SUCCESSFUL, data });
