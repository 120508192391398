import React from 'react';
import PropTypes from 'prop-types';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import styles from './style.module.scss';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function CustomSnackbarContentWrapper(props) {
  const { message, variant, onClose } = props;
  const Icon = variantIcon[variant];
  const actionList = [];
  if (props.onClose) {
    actionList.push(
      <IconButton onClick={onClose} key="close" aria-label="close" color="inherit">
        <CloseIcon className={`${styles['icon']}`} />
      </IconButton>
    );
  }
  if (props.optionalButtonMessage && props.optionalButtonOnClick) {
    actionList.unshift(
      <Button key="optionalButton" onClick={props.optionalButtonOnClick} color="inherit">
        {props.optionalButtonMessage}
      </Button>
    );
  }

  return (
    <SnackbarContent
      className={`${styles['margin']} ${styles[variant]}`}
      aria-describedby="client-snackbar"
      message={(
        <span id="client-snackbar" className={`${styles['message']}`}>
          <Icon className={`${styles['icon-variant']} ${styles['icon']}`} />
          {message}
        </span>
      )}
      action={actionList}
    />
  );
}

CustomSnackbarContentWrapper.propTypes = {
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  optionalButtonMessage: PropTypes.string,
  optionalButtonOnClick: PropTypes.func,
};

export default CustomSnackbarContentWrapper;
