import moment from 'moment/moment';

export function formatHumanDate(yyyymmdd) {
  return moment(yyyymmdd, 'YYYY-MM-DD').format('ll');
}

export function yearOptions(eventDates) {
  return eventDates
    .sort((a, b) => b.held_on.localeCompare(a.held_on))
    .map((eventDate) => ({
      value: eventDate.event_id,
      display: formatHumanDate(eventDate.held_on),
    }));
}
