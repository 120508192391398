/* eslint-disable jsx-a11y/label-has-associated-control */
// Justification: Part of UNL WDN template, not changing anything for now

import React from 'react';
import PropTypes from 'prop-types';

import Banner from 'components/common/Banner';
import Navbar from 'components/common/Navbar';

import styles from './style.module.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keepNavbarOpen: false,
      scrollPosition: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleWindowScroll);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleWindowScroll);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleMenuButtonClicked = () => {
    this.setState((previousState) => ({ keepNavbarOpen: !previousState.keepNavbarOpen }));
  };

  handleWindowScroll = () => {
    const scrollPosition = document.body.scrollTop || document.documentElement.scrollTop;

    // The header minus the navbar is 130px tall, and 101px tall at certain sizes
    const offset = window.innerWidth >= 700 && window.innerWidth < 768 ? 101 : 130;
    this.setState({ scrollPosition: Math.min(offset, scrollPosition) });
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  setMenuRef = node => {
    this.menuRef = node;
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && !this.menuRef.contains(event.target)) {
      this.setState({ keepNavbarOpen: false });
    }
  };

  render() {
    return (
      <div className={styles['header']} style={{ top: `-${this.state.scrollPosition}px` }}>
        <Banner />
        <div className="wdn-menu-trigger wdn-content-slide">
          <button
            type="button"
            className={`wdn-nav-toggle ${styles['menu-button']} ${this.state.keepNavbarOpen ? styles['offset-on-mobile'] : ''}`}
            ref={this.setMenuRef}
          >
            <span className="wdn-icon-menu" aria-hidden="true" onClick={this.handleMenuButtonClicked} />
            <span className="wdn-text-hidden">
              Menu
            </span>
          </button>
          <div className="wdn-share-this-page wdn-dropdown-widget-content" id="wdn-main-share-button">
            <div className="wdn-share-button">
              <input type="checkbox" id="wdn-main-share-button-wdn-share-toggle" value="Show share options" className="wdn-input-driver wdn-dropdown-widget-toggle" />
              <label htmlFor="wdn-main-share-button-wdn-share-toggle">
                <span className="wdn-icon-share" aria-hidden="true" />
                <span className="wdn-text-hidden">Share This Page</span>
              </label>
            </div>
          </div>
        </div>
        <Navbar
          remainOpen={this.state.keepNavbarOpen}
          isLoggedIn={this.props.isLoggedIn}
          isAdmin={this.props.isAdmin}
          logout={this.props.logout}
          currentYear={this.props.currentYear}
          getYear={this.props.getYear}
          eventDates={this.props.eventDates}
          getEventDates={this.props.getEventDates}
          setWrapperRef={this.setWrapperRef}
        />
      </div>
    );
  }
}

Header.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  currentYear: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  getYear: PropTypes.func.isRequired,
  eventDates: PropTypes.array.isRequired,
  getEventDates: PropTypes.func.isRequired,
};

export default Header;
