import { createSelector } from 'reselect';

export const selectLoading = createSelector(
  (state) => state.getIn(['assignments', 'loading']),
  (loading) => loading,
);

export const selectLocations = createSelector(
  (state) => state.getIn(['assignments', 'locations']),
  (data) => data.toJS(),
);

export const selectAssignments = createSelector(
  (state) => state.getIn(['assignments', 'data']),
  (data) => data.toJS(),
);
