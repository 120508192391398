import ApiWrapper from 'wrappers/api';
import fileDownload from 'js-file-download';

import {
  getApplicantsRequestSent,
  getApplicantsRequestSuccessful,
  updateApplicationRequestSent,
  updateApplicationRequestSuccessful,
  findExistingUsersRequestSent,
  findExistingUsersRequestSuccessful,
  resetAddCreateApplicantDialog,
  createApplicationFromTableRequestSent,
  createApplicationFromTableRequestSuccessful,
  updateApplicationFromTableRequestSent,
  updateApplicationFromTableRequestSuccessful,
} from 'actions/atomic/applicants';

import {
  updateCurrentUser,
} from 'actions/atomic/authentication';

export function getApplicants() {
  return (dispatch) => {
    dispatch(getApplicantsRequestSent());
    return ApiWrapper.get('/applicants')
      .then(response => {
        dispatch(getApplicantsRequestSuccessful(response.data));
      });
  };
}

export function downloadApplicants(year) {
  return () => ApiWrapper.get(`/applicants/download?year=${year}`, { responseType: 'blob' })
    .then(response => fileDownload(response.data, 'applicants.xlsx'));
}

export function findExistingUsers(payload) {
  return (dispatch) => {
    dispatch(findExistingUsersRequestSent());
    return ApiWrapper.post('/applicants/find-existing-users', payload)
      .then(response => {
        dispatch(findExistingUsersRequestSuccessful(response.data));
      });
  };
}

export function createApplication(callback) {
  return () => ApiWrapper.post('/applicants/current-application')
    .then(response => {
      if (callback) {
        callback(null, response);
      }
    })
    .catch(error => {
      if (callback) {
        callback(error);
      }
    });
}

export function updateApplication(payload) {
  return (dispatch) => {
    dispatch(updateApplicationRequestSent());
    return ApiWrapper.put('/applicants/current-application', payload)
      .then(response => {
        dispatch(updateApplicationRequestSuccessful(response.data));
        dispatch(updateCurrentUser(response.data));
      });
  };
}

export function resetDialog() {
  return (dispatch) => {
    dispatch(resetAddCreateApplicantDialog());
  };
}

export function createUserFromDialog(newUser) {
  return () => ApiWrapper.post('/users/table', newUser)
    .then(response => response.data);
}

export function createApplicantFromTable(payload) {
  return (dispatch) => {
    dispatch(createApplicationFromTableRequestSent());
    return ApiWrapper.post('/applicants/table', payload)
      .then(response => {
        dispatch(createApplicationFromTableRequestSuccessful(response.data));
      });
  };
}

export function updateApplicantFromTable(payload) {
  return (dispatch) => {
    dispatch(updateApplicationFromTableRequestSent());
    return ApiWrapper.put(`/applicants/table/${payload.id}`, payload)
      .then(response => {
        dispatch(updateApplicationFromTableRequestSuccessful(response.data));
      });
  };
}
