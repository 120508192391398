import { fromJS } from 'immutable';

import {
  GET_AFFILIATIONS_SENT,
  GET_AFFILIATIONS_SUCCESSFUL,
  UPDATE_AFFILIATION_SENT,
  UPDATE_AFFILIATION_SUCCESSFUL,
  CREATE_AFFILIATION_SENT,
  CREATE_AFFILIATION_SUCCESSFUL,
} from 'actions/atomic/affiliations';

const initialState = fromJS({
  loading: false,
  data: [],
});

export default function classes(state = initialState, action) {
  switch (action.type) {
  case GET_AFFILIATIONS_SENT:
    return state.merge({
      loading: true,
      data: fromJS([]),
    });
  case GET_AFFILIATIONS_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: fromJS(action.data),
    });
  case CREATE_AFFILIATION_SENT:
    return state.set('loading', true);
  case CREATE_AFFILIATION_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: state.get('data').push(fromJS(action.data)),
    });
  case UPDATE_AFFILIATION_SENT:
    return state.set('loading', true);
  case UPDATE_AFFILIATION_SUCCESSFUL:
    const updatedAffiliation = action.data;
    const index = state.get('data').findIndex(affiliation => affiliation.get('id') === updatedAffiliation.id);
    return state.merge({
      loading: false,
      data: state.get('data').set(index, fromJS(updatedAffiliation)),
    });
  default:
    return state;
  }
}
