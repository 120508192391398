export const writeTokenToLocalStorage = token => {
  localStorage.setItem('authToken', token);
};

export const retrieveTokenFromLocalStorage = () => {
  const authToken = localStorage.getItem('authToken');
  return authToken;
};

export const clearTokenFromLocalStorage = () => {
  localStorage.removeItem('authToken');
};
