import { createSelector } from 'reselect';

export const selectIsLoggedIn = createSelector(
  (state) => state.getIn(['authentication', 'isLoggedIn']),
  (isLoggedIn) => isLoggedIn,
);

export const selectIsAdmin = createSelector(
  (state) => state.getIn(['authentication', 'isAdmin']),
  (isAdmin) => isAdmin,
);

export const selectLoginValidationComplete = createSelector(
  (state) => state.getIn(['authentication', 'loginValidationComplete']),
  (loginValidationComplete) => loginValidationComplete,
);

export const selectLoginRequestFailed = createSelector(
  (state) => state.getIn(['authentication', 'loginRequestFailed']),
  (loginRequestFailed) => loginRequestFailed,
);

export const selectCurrentUser = createSelector(
  (state) => state.getIn(['authentication', 'currentUser']),
  (currentUser) => currentUser,
);
