export const GET_EVENT_DATES_SENT = 'GET_EVENT_DATES_SENT';
export const GET_EVENT_DATES_SUCCESSFUL = 'GET_EVENT_DATES_SUCCESSFUL';
export const CREATE_EVENT_DATE_SENT = 'CREATE_EVENT_DATE_SENT';
export const CREATE_EVENT_DATE_SUCCESSFUL = 'CREATE_EVENT_DATE_SUCCESSFUL';
export const UPDATE_EVENT_DATE_SENT = 'UPDATE_EVENT_DATE_SENT';
export const UPDATE_EVENT_DATE_SUCCESSFUL = 'UPDATE_EVENT_DATE_SUCCESSFUL';

export const getEventDatesRequestSent = () => ({ type: GET_EVENT_DATES_SENT });

export const getEventDatesRequestSuccessful = (data) => ({ type: GET_EVENT_DATES_SUCCESSFUL, data });

export const createEventDateRequestSent = () => ({ type: CREATE_EVENT_DATE_SENT });

export const createEventDateRequestSuccessful = (data) => ({ type: CREATE_EVENT_DATE_SUCCESSFUL, data });

export const updateEventDateRequestSent = () => ({ type: UPDATE_EVENT_DATE_SENT });

export const updateEventDateRequestSuccessful = (data) => ({ type: UPDATE_EVENT_DATE_SUCCESSFUL, data });
