import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import ReusableButton from 'components/common/ReusableButton';
import history from 'wrappers/history';
import styles from './style.module.scss';

class AccountActivationPage extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.isLoggedIn) {
      history.push('/');
    }

    this.props.activateAccount({ token: this.activationToken });
  }

  get activationToken() {
    const values = queryString.parse(this.props.location.search);
    return values.token;
  }

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['info-container']}>
              {!this.props.accountActivated
              && !this.props.invalidActivationToken
              && !this.props.expiredActivationToken
              && !this.props.invalidUser
              && (
                <>
                  <h2>
                    Please wait...
                  </h2>
                  <div>
                    Your account is being activated.
                  </div>
                </>
              )}
              {!this.props.accountActivated
              && (this.props.invalidActivationToken || this.props.expiredActivationToken)
              && (
                <>
                  <h2>
                    Error:
                  </h2>
                  <div>
                    This activation link is no longer valid. Please check your email for a new activation link.
                  </div>
                </>
              )}
              {!this.props.accountActivated
              && this.props.invalidUser
              && (
                <>
                  <h2>
                    Error:
                  </h2>
                  <div>
                    This activation link is not associated with a valid account.
                  </div>
                </>
              )}
              {this.props.accountActivated
              && (
                <>
                  <h2>
                    Success!
                  </h2>
                  <div>
                    Your account has been activated or is already active.
                  </div>
                  <ReusableButton
                    value="Login"
                    hasTopMargin
                    onClick={() => history.push('/login')}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AccountActivationPage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  location: PropTypes.object,
  activateAccount: PropTypes.func.isRequired,
  accountActivated: PropTypes.bool.isRequired,
  invalidActivationToken: PropTypes.bool,
  expiredActivationToken: PropTypes.bool,
  invalidUser: PropTypes.bool,
};

export default AccountActivationPage;
