/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
// Justification: Part of UNL WDN template, not changing anything for now

import React from 'react';

import styles from './style.module.scss';

class Banner extends React.Component {
  render() {
    return (
      <header id="header" role="banner" className="wdn-content-slide wdn-band">
        <div id="wdn_header_top">
          <span id="wdn_institution_title"><a href="http://www.unl.edu/">University of Nebraska&ndash;Lincoln</a></span>
          <div id="wdn_resources">
            <div id="wdn_search" role="search">
              <input type="checkbox" id="wdn_search_toggle" className={`wdn_search_toggle wdn-input-driver wdn-dropdown-widget-toggle visible-at-full-nav ${styles['hide-search-button']}`} aria-controls="wdn_search_form" value="Show search form" />
              <label htmlFor="wdn_search_toggle" id="wdn_search_toggle_label" className={`wdn-icon-search ${styles['hide-search-button']}`}>
                <span className="wdn-text-hidden">
                  Show search form
                </span>
              </label>
              <form id="wdn_search_form" className="wdn-dropdown-widget-content" action="https://search.unl.edu/" method="get">
                <label htmlFor="wdn_search_query" className={styles['hide-search-button']}>Search</label>
                {/* eslint-disable-next-line jsx-a11y/no-access-key */}
                <input required accessKey="f" id="wdn_search_query" name="q" type="search" />
                <button type="submit">
                  <span className="wdn-icon-search" aria-hidden="true" />
                  <span className="wdn-text-hidden">
                    Search
                  </span>
                </button>
              </form>
            </div>
          </div>
        </div>
        <div id="wdn_logo_lockup">
          <div className="wdn-inner-wrapper">
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a href="http://www.unl.edu/" id="logo" />
            <span id="wdn_site_affiliation" />
            <span id="wdn_site_title">Math Day</span>
          </div>
        </div>
      </header>
    );
  }
}

export default Banner;
