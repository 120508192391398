export const GET_CLASSES_SENT = 'GET_CLASSES_SENT';
export const GET_CLASSES_SUCCESSFUL = 'GET_CLASSES_SUCCESSFUL';

export const UPDATE_CLASS_SENT = 'UPDATE_CLASS_SENT';
export const UPDATE_CLASS_SUCCESSFUL = 'UPDATE_CLASS_SUCCESSFUL';

export const CREATE_CLASS_SENT = 'CREATE_CLASS_SENT';
export const CREATE_CLASS_SUCCESSFUL = 'CREATE_CLASS_SUCCESSFUL';

export const getClassesRequestSent = () => ({ type: GET_CLASSES_SENT });

export const getClassesRequestSuccessful = (data) => ({ type: GET_CLASSES_SUCCESSFUL, data });

export const updateClassRequestSent = () => ({ type: UPDATE_CLASS_SENT });

export const updateClassRequestSuccessful = (data) => ({ type: UPDATE_CLASS_SUCCESSFUL, data });

export const createClassRequestSent = () => ({ type: CREATE_CLASS_SENT });

export const createClassRequestSuccessful = (data) => ({ type: CREATE_CLASS_SUCCESSFUL, data });
