import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import ApplicantsTable from 'components/common/ApplicantsTable';
import ReusableTable from 'components/common/ReusableTable';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableTextFieldValidator from 'components/common/ReusableTextFieldValidator';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { isFieldEmpty, fields } from 'utils/validation';
import history from 'wrappers/history';
import styles from './style.module.scss';

class StatusesPage extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }

    this.state = {
      nameError: false,
      abbrError: false,
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: '',
      applicants: [],
      applicantsTableOpen: false,
      columns: [
        {
          title: 'Priority',
          field: 'priority',
          tooltip: 'Order statuses appear on the application',
          editable: 'never',
          filtering: false,
          defaultSort: 'asc',
          render: status => <div className={styles['priority']}>{status.priority}</div>,
        },
        {
          title: 'Name',
          field: 'name',
          tooltip: 'The name of the status.',
          editComponent: (name) => (
            <ReusableTextFieldValidator
              text={name}
              onChange={(event) => name.onChange(event.target.value)}
              regExp={fields.statusName.regExp}
              error={this.state.nameError}
              onError={(error) => this.setState({ nameError: error })}
            />
          ),
        },
        {
          title: 'Abbr',
          field: 'abbr',
          tooltip: 'Abbreviation for the status',
          editComponent: (abbr) => (
            <ReusableTextFieldValidator
              text={abbr}
              onChange={(event) => abbr.onChange(event.target.value)}
              regExp={fields.statusAbbr.regExp}
              error={this.state.abbrError}
              onError={(error) => this.setState({ abbrError: error })}
            />
          ),
        },
        {
          title: '#Volunteers',
          editable: 'never',
          customSort: (a, b) => this.numberOfApplicants(a) - this.numberOfApplicants(b),
          render: status => (
            <div className={styles['applicant-button']}>
              <Button onClick={() => this.openApplicantsTable(status)}>
                {this.numberOfApplicants(status)}
              </Button>
            </div>
          ),
        },
        {
          title: 'Active',
          field: 'active',
          type: 'boolean',
          tooltip: 'Students are only able to sign up with statuses that are marked as active.',
        },
        {
          title: 'Approved',
          field: 'approved',
          type: 'boolean',
          tooltip: 'Only approved statuses show up in the list of statuses for students to select from.',
        },
      ],
      options: {
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 600,
        selection: false,
        hideDeleteButton: true,
      },
      editable: {
        isEditable: () => true,
        isDeletable: () => true,
        onRowAdd: (newStatus) => new Promise((resolve, reject) => {
          const newPriority = this.maxPriority() + 1;
          const newStatusWithPriority = { ...newStatus, priority: newPriority };
          const errorMessage = this.validateFields(newStatusWithPriority);
          if (errorMessage === '') {
            this.props.createStatus(newStatusWithPriority);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'success',
              snackbarMessage: `Status ${newStatusWithPriority.name} successfully added.`,
            });
            resolve();
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: errorMessage,
            });
            reject();
          }
        }),
        onRowUpdate: (updatedStatus) => new Promise((resolve, reject) => {
          const errorMessage = this.validateFields(updatedStatus);
          if (errorMessage === '') {
            this.props.updateStatus(updatedStatus);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'success',
              snackbarMessage: `Status ${updatedStatus.name} successfully updated.`,
            });
            resolve();
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: errorMessage,
            });
            reject();
          }
        }),
        onRowDelete: () => new Promise((resolve) => {
          resolve();
        }),
      },
      actions: [
        {
          icon: KeyboardArrowUpIcon,
          tooltip: 'Increase priority',
          position: 'row',
          onClick: (event, rowData) => this.incrementStatus(event, rowData),
        },
        {
          icon: KeyboardArrowDownIcon,
          tooltip: 'Decrease priority',
          position: 'row',
          onClick: (event, rowData) => this.decrementStatus(event, rowData),
        },
        {
          icon: 'replay',
          tooltip: 'Refetch Data',
          isFreeAction: true,
          onClick: () => this.props.getStatuses(),
        },
        {
          icon: 'save_alt',
          tooltip: 'Export',
          isFreeAction: true,
          onClick: () => {
            this.props.downloadStatuses();
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'info',
              snackbarMessage: 'Download in progress.',
            });
          },
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.statuses.length === 0) {
      this.props.getStatuses();
    }
    if (this.props.year === '') {
      this.props.getYear();
    }
    if (this.props.applicants.length === 0) {
      this.props.getApplicants();
    }
  }

  /**
   * increments the priority of the status (higher priority means smaller number,
   * so increment priority 5 makes it priority 4) and switches places with the
   * status that already had that priority
   * @param {*} event on click event
   * @param {*} status status object
   */
  incrementStatus = (_event, status) => {
    const oldPriority = status.priority;
    if (oldPriority !== 1) { // The lower bound on priorities is 1.
      const newPriority = oldPriority - 1;
      const statusToDecrement = this.getStatusWithPriority(newPriority);
      const decrementedStatus = { ...statusToDecrement, priority: oldPriority };
      const incrementedStatus = { ...status, priority: newPriority };
      this.props.updateStatus(decrementedStatus);
      this.props.updateStatus(incrementedStatus);
    }
  };

  /**
   * decrements the priority of the status (less priority means bigger number,
   * so decrement priority 5 makes it priority 6) and switches places with the
   * status that already had that priority
   * @param {*} event on click event
   * @param {*} status status object
   */
  decrementStatus = (_event, status) => {
    const oldPriority = status.priority;
    if (oldPriority !== this.maxPriority()) {
      const newPriority = oldPriority + 1;
      const statusToIncrement = this.getStatusWithPriority(newPriority);
      const incrementedStatus = { ...statusToIncrement, priority: oldPriority };
      const decrementedStatus = { ...status, priority: newPriority };
      this.props.updateStatus(incrementedStatus);
      this.props.updateStatus(decrementedStatus);
    }
  };

  maxPriority = () => {
    let maxPriority = 1;
    this.props.statuses.forEach(status => {
      if (status.priority > maxPriority) {
        maxPriority = status.priority;
      }
    });
    return maxPriority;
  };

  getStatusWithPriority = (priority) => {
    let statusWithPriority;
    this.props.statuses.forEach(status => {
      if (status.priority === priority) {
        statusWithPriority = status;
      }
    });
    return statusWithPriority;
  };

  numberOfApplicants = (status) => {
    let numberOfApplicants = 0;
    this.props.applicants.filter(applicant => applicant.year === this.props.year).forEach(applicant => {
      if (applicant.status && applicant.status.id === status.id) {
        ++numberOfApplicants;
      }
    });
    return numberOfApplicants;
  };

  openApplicantsTable = (status) => {
    const statusApplicants = [];
    this.props.applicants.forEach(applicant => {
      if (applicant.status && applicant.status.id === status.id) {
        statusApplicants.push(applicant);
      }
    });
    this.setState({ applicantsTableOpen: true, applicants: statusApplicants });
  };

  closeApplicantsTable = () => {
    this.setState({ applicantsTableOpen: false });
  };

  validateFields = (status) => {
    let errorMessage = '';
    if (this.state.nameError || isFieldEmpty(status.name)) {
      errorMessage += fields.statusName.errorMessage;
      this.setState({
        nameError: true,
      });
    }
    if (this.state.abbrError || isFieldEmpty(status.abbr)) {
      errorMessage += fields.statusAbbr.errorMessage;
      this.setState({
        abbrError: true,
      });
    }

    return errorMessage;
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['table-container']}>
              <ReusableTable
                title="Statuses"
                columns={this.state.columns}
                data={this.props.statuses}
                options={this.state.options}
                editable={this.state.editable}
                isLoading={this.props.statusesLoading || this.props.applicantsLoading}
                actions={this.state.actions}
              />
              <ReusableSnackbar
                open={this.state.snackbarOpen}
                onClose={this.handleSnackbarClose}
                variant={this.state.snackbarVariant}
                message={this.state.snackbarMessage}
              />
              <Dialog
                fullWidth
                maxWidth="lg"
                open={this.state.applicantsTableOpen}
                onClose={this.closeApplicantsTable}
              >
                <ApplicantsTable
                  loading={this.props.statusesLoading || this.props.applicantsLoading}
                  applicants={this.state.applicants}
                  year={this.props.year}
                  eventDates={this.props.eventDates}
                  getYear={this.props.getYear}
                  getEventDates={this.props.getEventDates}
                />
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StatusesPage.propTypes = {
  statuses: PropTypes.array.isRequired,
  statusesLoading: PropTypes.bool.isRequired,
  createStatus: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  getStatuses: PropTypes.func.isRequired,
  downloadStatuses: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  applicants: PropTypes.array.isRequired,
  applicantsLoading: PropTypes.bool.isRequired,
  getApplicants: PropTypes.func.isRequired,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  getYear: PropTypes.func.isRequired,
  eventDates: PropTypes.array.isRequired,
  getEventDates: PropTypes.func.isRequired,
};

export default StatusesPage;
