import { connect } from 'react-redux';

import {
  selectIsLoggedIn,
} from 'selectors/authenticationSelectors';
import {
  selectPasswordReset,
  selectInvalidToken,
} from 'selectors/usersSelectors';
import {
  resetPassword,
} from 'actions/thunks/users';
import ResetPasswordPage from 'components/pages/ResetPasswordPage';

const mapStateToProps = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  passwordReset: selectPasswordReset(state),
  invalidToken: selectInvalidToken(state),
});

const mapDispatchToProps = dispatch => ({
  resetPassword: payload => dispatch(resetPassword(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordPage);
