import { connect } from 'react-redux';

import InteractiveAssignmentPage from 'components/pages/admin/InteractiveAssignmentPage';
import { getTasks, getActivities } from 'actions/thunks/activities';
import {
  selectTasks, selectActivities, selectTasksLoading, selectActivitiesLoading,
} from 'selectors/activitiesSelectors';
import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';
import { getApplicants } from 'actions/thunks/applicants';
import { getYear } from 'actions/thunks/systemSettings';
import { getStatuses } from 'actions/thunks/statuses';
import { selectApplicants, selectApplicantsLoading } from 'selectors/applicantsSelectors';
import { selectYear } from 'selectors/systemSettingsSelectors';
import { selectEventDates } from 'selectors/eventDatesSelectors';
import { selectStatuses, selectStatusesLoading } from 'selectors/statusesSelectors';
import { selectTaskPreferences } from 'selectors/taskPreferencesSelectors';
import { getTaskPreferences } from 'actions/thunks/taskPreferences';
import { selectSolutionAssignments } from 'selectors/solutionAssignmentSelectors';
import {
  createSolutionAssignment, getSolutionAssignments, deleteSolutionAssignment, downloadSolutionAssignmentsTasks, downloadSolutionAssignmentsApplicants,
} from 'actions/thunks/solutionAssignments';
import { getAssignments } from 'actions/thunks/assignmentByUsers';
import { getEventDates } from 'actions/thunks/eventDates';
import { selectAssignments } from 'selectors/assignmentsSelectors';

const mapStateToProps = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  isAdmin: selectIsAdmin(state),
  tasks: selectTasks(state),
  tasksLoading: selectTasksLoading(state),
  activities: selectActivities(state),
  applicants: selectApplicants(state),
  applicantsLoading: selectApplicantsLoading(state),
  year: selectYear(state),
  statuses: selectStatuses(state),
  activitiesLoading: selectActivitiesLoading(state),
  statusesLoading: selectStatusesLoading(state),
  eventDates: selectEventDates(state),
  taskPreferences: selectTaskPreferences(state),
  solutionAssignments: selectSolutionAssignments(state),
  assignments: selectAssignments(state),
});

const mapDispatchToProps = dispatch => ({
  getTaskPreferences: () => dispatch(getTaskPreferences()),
  getTasks: () => dispatch(getTasks()),
  getActivities: () => dispatch(getActivities()),
  getApplicants: () => dispatch(getApplicants()),
  getYear: () => dispatch(getYear()),
  getStatuses: () => dispatch(getStatuses()),
  getSolutionAssignments: () => dispatch(getSolutionAssignments()),
  getAssignments: () => dispatch(getAssignments()),
  getEventDates: () => dispatch(getEventDates()),
  createSolutionAssignment: (newSolutionAssignment) => dispatch(createSolutionAssignment(newSolutionAssignment)),
  deleteSolutionAssignment: (oldSolutionAssignment) => dispatch(deleteSolutionAssignment(oldSolutionAssignment)),
  downloadSolutionAssignmentsTasks: () => dispatch(downloadSolutionAssignmentsTasks()),
  downloadSolutionAssignmentsApplicants: () => dispatch(downloadSolutionAssignmentsApplicants()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InteractiveAssignmentPage);
