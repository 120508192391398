import React from 'react';
import ReusableTable from 'components/common/ReusableTable';
import ReusableSelect from 'components/common/ReusableSelect';
import PropTypes from 'prop-types';
import { formatTime } from 'utils/date';

import {
  customFilterByTaskName, customFilterByTime,
} from '../../InteractiveAssignmentPage/utils';
import styles from '../style.module.scss';

class TaskAssignedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Task table
      taskColumns: [
        {
          title: 'Task',
          field: 'background_color',
          tooltip: 'Task name',
          defaultSort: 'desc',
          render: (task) => (task.preemtable && (`${task.abbr.trim() || task.name} (P)`)) || (task.abbr.trim() || task.name),
          customFilterAndSearch: customFilterByTaskName,
        },
        {
          title: 'Time',
          field: 'starts_at',
          tooltip: 'The time of the task.',
          defaultSort: 'asc',
          render: (task) => this.renderTaskTimeWindows(task),
          customFilterAndSearch: (term, task) => customFilterByTime(term, task),
          align: 'center',
        },
        {
          title: 'L',
          tooltip: 'The load of the task.',
          defaultSort: 'desc',
          align: 'right',
          // eslint-disable-next-line no-nested-ternary
          customSort: (a, b) => (this.props.assignments ? (this.calculateLoadBackup(a, false) > this.calculateLoadBackup(b, false) ? -1 : 1) : ''),
          render: (task) => (this.props.assignments ? this.renderLoadBackup(task, false) : ''),
        },
        {
          title: 'B',
          tooltip: 'The backup load of the task.',
          defaultSort: 'desc',
          align: 'right',
          // eslint-disable-next-line no-nested-ternary
          customSort: (a, b) => (this.props.assignments ? (this.calculateLoadBackup(a, true) > this.calculateLoadBackup(b, true) ? -1 : 1) : ''),
          render: (task) => (this.props.assignments ? this.renderLoadBackup(task, true) : ''),
        },
        {
          title: 'Asgn',
          tooltip: 'The applicants assigned to the task',
          render: (task) => (this.props.assignments ? this.renderTaskAssignedDropdown(task) : ''),
        },
      ],
      taskOptions: {
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 700,
        hideDeleteButton: true,
        actionsColumnIndex: -1,
        showFull: true,
        search: false,
        rowStyle: rowData => ({
          backgroundColor: rowData.background_color ? rowData.background_color : '',
          color: rowData.text_color ? rowData.text_color : '',
        }),
      },
      taskActions: [
        {
          icon: 'replay',
          tooltip: 'Refetch Data',
          position: 'toolbar',
          isFreeAction: true,
          onClick: () => this.props.getTasks(),
        },
        {
          icon: 'filter_alt',
          tooltip: 'Filter',
          position: 'toolbar',
          isFreeAction: true,
          onClick: () => this.props.handleOpenTaskFilter(),
        },
      ],
    };
  }

  calculateLoadBackup = (task, backup) => {
    let numFilled = 0;
    if (!this.props.assignments[task.id]) {
      return 0;
    }
    this.props.assignments[task.id].forEach(app => {
      numFilled += app.backup === backup ? 1 : 0;
    });
    return numFilled;
  };

  generateName = (applicant) => {
    const shortLastName = applicant?.user?.last_name.length > 9 ? `${applicant?.user?.last_name.slice(0, 7)}...` : applicant?.user?.last_name;
    return `${applicant?.user?.first_name.charAt(0)}. ${shortLastName}`;
  };

  renderLoadBackup = (task, backup) => {
    const numNeeded = backup ? task.backup_load : task.load;
    const numFilled = this.calculateLoadBackup(task, backup);

    return `${numFilled}/${numNeeded}`;
  };

  renderTaskTimeWindows = (task) => {
    if (task?.year === this.props.year) {
      return <div className={styles['nowrap']}>{`${formatTime(task?.starts_at)}-${formatTime(task?.ends_at)}`}</div>;
    }
    return (
      <div> - </div>
    );
  };

  renderTaskAssignedDropdown(task) {
    const assignments = this.props.assignments[task.id];
    if (!assignments) {
      return 'None';
    }
    const solutionAssignments = assignments.map(assignment => ({
      applicant: this.props.applicants.find(a => a.id === assignment.id),
      backup: assignment.backup,
    }));
    if (solutionAssignments.length !== 0) {
      const applicantTaskOptions = solutionAssignments.map(solutionAssignment => {
        const backup = solutionAssignment.backup ? ' (B)' : '';
        const pref = this.props.taskPreferences.find(tp => tp?.status_id === solutionAssignment?.applicant?.status?.id && tp?.task_id === task?.id);
        const prefMap = {
          undefined: 'black', 0: '#D8D8D8', 8: '#EE4B2B', 9: '#FFC300', 10: '#009E60',
        };
        const color = prefMap[pref?.preference];
        const display = <p className={styles['nowrap']} style={{ color, margin: 0 }}>{`${this.generateName(solutionAssignment.applicant)}${backup}`}</p>;
        return {
          display,
          value: `${this.generateName(solutionAssignment.applicant)}${backup}`,
        };
      });
      const backup = solutionAssignments[0].backup ? ' (B)' : '';
      const menuTask = `${this.generateName(solutionAssignments[0].applicant)}${backup}`;
      if (solutionAssignments.length === 1) {
        return <div className={styles['nowrap']}>{menuTask}</div>;
      }
      return (
        <>
          <div>
            <ReusableSelect
              id="preferred"
              className={styles['task-preference']}
              value={menuTask}
              options={applicantTaskOptions}
              useDefaultVariant
              isTransparent
              displayEmpty
              excludeNoneOption
              style={{ float: 'left', width: 10 }}
            />
            {/* TODO: Think about how to add later{
              solutionAssignments.length === 0 ? <></>
                : <Button style={{ height: 10, width: 10, justifyContent: 'flex-start' }} onClick={() => this.props.openApplicantSolutionAssignmentsDialog(applicant)}>...</Button>
            } */}
          </div>
        </>
      );
    }
    return 'None';
  }

  render() {
    return (
      <div className={styles['task-table-container']}>
        <ReusableTable
          title={`Tasks (${this.props.data.length})`}
          columns={this.state.taskColumns}
          data={this.props.data}
          options={this.state.taskOptions}
          isLoading={this.props.isLoading}
          actions={this.state.taskActions}
          jobSelected={this.props.jobSelected}
          renderYearOptions={this.props.renderYearOptions}
        />
      </div>
    );
  }
}

TaskAssignedTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  jobSelected: PropTypes.bool.isRequired,
  getTasks: PropTypes.func.isRequired,
  assignments: PropTypes.object.isRequired,
  handleOpenTaskFilter: PropTypes.func.isRequired,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  renderYearOptions: PropTypes.func.isRequired,
  applicants: PropTypes.array.isRequired,
  taskPreferences: PropTypes.array.isRequired,
};

export default TaskAssignedTable;
