import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import styles from './style.module.scss';

const ReusableTextField = (props) => (
  <div className={`${styles['text-field-container']} ${props.className}`}>
    <TextField
      id={props.id}
      label={props.label}
      inputRef={props.inputRef}
      InputProps={props.InputProps}
      className={styles['input-field']}
      value={props.value}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
      onClick={props.onClick}
      margin={props.margin ? props.margin : 'normal'}
      variant="outlined"
      type={props.type}
      error={props.error}
      required={props.required}
      multiline={props.multiline}
      rows={props.rows}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={props.short ? { style: { height: '10px' } } : {}} // Should be case sensitve, both props exist in amterial ui api
      disabled={props.disabled}
    />
  </div>
);

ReusableTextField.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  short: PropTypes.bool,
  margin: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inputRef: PropTypes.func,
  InputProps: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default ReusableTextField;
