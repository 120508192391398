import { connect } from 'react-redux';

import { selectStatusesLoading, selectStatuses } from 'selectors/statusesSelectors';
import {
  getStatuses,
  updateStatus,
  createStatus,
  downloadStatuses,
} from 'actions/thunks/statuses';
import StatusesPage from 'components/pages/admin/StatusesPage';
import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';
import { selectApplicantsLoading, selectApplicants } from 'selectors/applicantsSelectors';
import { getApplicants } from 'actions/thunks/applicants';
import { getYear } from 'actions/thunks/systemSettings';
import { getEventDates } from 'actions/thunks/eventDates';
import { selectYear } from 'selectors/systemSettingsSelectors';
import { selectEventDates } from 'selectors/eventDatesSelectors';

const mapStateToProps = state => ({
  statusesLoading: selectStatusesLoading(state),
  statuses: selectStatuses(state),
  isAdmin: selectIsAdmin(state),
  isLoggedIn: selectIsLoggedIn(state),
  year: selectYear(state),
  eventDates: selectEventDates(state),
  applicantsLoading: selectApplicantsLoading(state),
  applicants: selectApplicants(state),
});

const mapDispatchToProps = (dispatch) => ({
  getStatuses: () => dispatch(getStatuses()),
  updateStatus: (newStatus) => dispatch(updateStatus(newStatus)),
  createStatus: (newStatus) => dispatch(createStatus(newStatus)),
  downloadStatuses: () => dispatch(downloadStatuses()),
  getApplicants: () => dispatch(getApplicants()),
  getYear: () => dispatch(getYear()),
  getEventDates: () => dispatch(getEventDates()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatusesPage);
