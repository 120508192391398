import { connect } from 'react-redux';

import {
  attemptLogin,
  resetFailedLogin,
} from 'actions/thunks/authentication';
import {
  selectIsLoggedIn,
  selectLoginRequestFailed,
} from 'selectors/authenticationSelectors';
import LoginPage from 'components/pages/LoginPage';

const mapStateToProps = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  loginRequestFailed: selectLoginRequestFailed(state),
});

const mapDispatchToProps = dispatch => ({
  attemptLogin: (payload) => dispatch(attemptLogin(payload)),
  resetFailedLogin: () => dispatch(resetFailedLogin()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginPage);
