import dayjs from 'dayjs';

/**
 * @typedef {Object} Applicant
 * @property {boolean} activity_worker whether or not the applicant is an activity worker
 * @property {Object} affiliation the school/department the applicant is affiliated with
 * @property {boolean} application_finalized whether or not the applicant's application has been finalized
 * @property {boolean} application_step_completed whether or not the applicant's application step has been completed
 * @property {string} comments any comments the applicant left on their application
 * @property {boolean} department_display whether or not the applicant has a department display
 * @property {Array} final_assignments the assingments to a task of the applicant
 * @property {Number} id the applicant's id
 * @property {string|null} math_class the math class the applicant is in
 * @property {string|null} recruiter person that recruited the applicant
 * @property {boolean} soft_lock whether the applicant is under a soft lock
 * @property {boolean|null} sor_check whether the applicant has completed their sor check or not
 * @property {boolean} speaker whether or not the applicant is a speaker
 * @property {Object} status the status of the applicant
 * @property {Object} tableData no idea
 * @property {Array} task_preferences preferences applicant has towards certain tasks
 * @property {boolean} task_signup_step_completed whether or not the applicant completed the task sign up step
 * @property {Array} time_windows the applicant's time availability windows
 * @property {Object} user the applicant's user info (name, email, ...)
 * @property {boolean} yasp_step_completed whether or not the applicant has completed the yasp step
 * @property {Number} year the year for the application
 * @property {string} youth_form_date the date the youth form was completed
 */

/**
 * Checks if a task time has any overlap with another task's time
 * @param {*} t1 The first task
 * @param {*} t2 The second task
 * @returns {boolean} Whether or not the two tasks have any time overlap
 */
function taskTimeInTaskTime(t1, t2) {
  const t1Start = dayjs(t1.starts_at);
  const t1End = dayjs(t1.ends_at);
  const t2Start = dayjs(t2.starts_at);
  const t2End = dayjs(t2.ends_at);

  if (t1End.isAfter(t2Start) && t1Start.isBefore(t2End)) {
    return true;
  }

  return false;
}

function taskTimeIsTaskTime(t1, t2) {
  const t1String = `${t1.starts_at}${t1.ends_at}`;
  const t2String = `${t2.starts_at}${t2.ends_at}`;
  return t1String === t2String;
}

/**
 * Checks whether the task time is inside the applicant's availability, i.e. the applicant is available to do the task
 * @param {*} task The task
 * @param {*} applicant The applicant
 * @returns {boolean} Whether the task time is inside the applicant's availability, i.e. the applicant is available to do the task
 */
function taskTimeInApplicantTime(task, applicant) {
  let inside = false;
  for (let i = 0; i < applicant?.time_windows?.length; i++) {
    const endTime = applicant.time_windows[i]?.ends_at;
    const startTime = applicant.time_windows[i]?.starts_at;

    const timeTokens = endTime?.split(':');
    // parsing out the minute field in order to increment it by one
    // add a leading zero to single digit minute number
    // the minute is incremented in order to make sure the end time is not invalid due when it is equal to end of person's availability
    if (timeTokens?.length > 1) {
      timeTokens[1] = (Number(timeTokens[1]) + 1) < 10 ? `0${Number(timeTokens[1]) + 1}` : `${Number(timeTokens[1]) + 1}`;
    }
    const newTokens = timeTokens?.join(':');
    inside = inside
      || (dayjs(task.starts_at) >= dayjs(startTime)
        && dayjs(task?.ends_at) <= dayjs(newTokens))
      || (task.preemtable && (dayjs(task.starts_at) <= dayjs(startTime)
        && dayjs(task?.ends_at) >= dayjs(newTokens)));
  }
  return inside;
}

/**
 * Checks if two applicants are the same person
 * @param {*} applicant1 The first applicant
 * @param {*} applicant2 The second applicant
 * @returns {boolean} Whether the two applicants are the same person or not
 */
function taskMutex(applicant1, applicant2) {
  return applicant1.vName !== applicant2.vName;
}

/**
 * Generates the admin's preference for the applicant to do a task
 * @param {*} applicant The applicant
 * @param {*} taskPreference The taskPreference object
 * @returns true if the task is eight or above or the task does not have a defined preference, false otherwise
 */
function statusPreferences(applicant, taskPreference) {
  let statusPref = taskPreference?.preference;
  if (statusPref === undefined) {
    statusPref = -1;
  }
  return statusPref >= 8 || statusPref === -1;
}

// function volPreferences(volunteer, taskName) {
//   const pref = volunteer.taskPreferences.find(t => t?.task?.name === taskName)?.preference;
//   return pref >= 2 || pref === undefined;
// }

/**
 * Updates the maps to create the taskIntervalMap and update the intervals's needs
 * @param {*} taskIntervalMap The class's taskIntervalMap mapping tasks to the intervals they are part of
 * @param {*} intervals The class's intervals that contains all of the info about an interval
 * @param {*} task The task you are updating the maps with
 * @param {*} name The variable prefix for the task
 * @returns The updated maps in an array [taskIntervalMap, intervals]
 */
function updateTaskIntervalMap(taskIntervalMap, intervals, task, name) {
  const updatedTIMap = taskIntervalMap;
  const updatedIMap = intervals;
  const totalLoad = task.load + task.backup_load;

  const keys = Object.keys(updatedIMap);
  let i = 0;
  let fits = false;
  while (i < keys.length) {
    fits = taskTimeIsTaskTime(task, updatedIMap[keys[i]].time);
    if (fits) {
      if (!(name in updatedTIMap)) {
        updatedTIMap[name] = [];
      }
      updatedTIMap[name].push(keys[i]);
      updatedIMap[keys[i]].dS -= totalLoad;
    }
    i++;
  }

  return [updatedTIMap, updatedIMap];
}

/**
 * Adds applicants to the intervals to update the deficit/surplus field
 * @param {*} applicant The applicant to add
 * @param {*} intervals The map of intervals
 * @returns The intervals with updated deficit/surplus fields
 */
function addApplicantsIMap(applicant, intervals) {
  const updatedIMap = intervals;
  Object.keys(updatedIMap).forEach(key => {
    const fit = taskTimeInApplicantTime(updatedIMap[key].time, applicant);
    if (fit) {
      updatedIMap[key].dS += 1;
    }
  });
  return updatedIMap;
}

/**
 * Updates the weights for each interval
 * @param {*} intervals The map of intervals
 * @returns The intervals with updated weights
 */
function updateWeights(intervals) {
  const updatedMap = intervals;
  Object.keys(updatedMap).forEach(key => {
    updatedMap[key].weight = updatedMap[key].dS * updatedMap[key].length;
  });

  return updatedMap;
}

/**
 * Updates the variable conflict map to include an applicant. This map is needed so that we know what intervals need to be updated
 * when an assignment is made in Caleb's search
 * @param {*} applicant The applicant to be included in the map
 * @param {*} variables The variables for the current event
 * @param {*} variableConflictMap The current map that will be updated
 * @param {*} taskPreferences The task preferences for the current event
 */
function updateVariableConflictMap(applicant, variables, variableConflictMap, taskPreferences) {
  const availTasks = [];
  const filteredVars = variables.filter(element => element.name.includes('-0')); // Get one variable for each task

  // Find the variables the applicant is able to be assigned to
  filteredVars.forEach(variable => {
    const pref = taskPreferences.find(element => element.task_id === variable.task.id && element.status_id === applicant.status.id);
    if (taskTimeInApplicantTime(variable.task, applicant) && statusPreferences(applicant, pref)) {
      availTasks.push(variable);
    }
  });

  // Initiate map
  const taskConflicts = {};
  availTasks.forEach(variable => {
    taskConflicts[variable.name.split('-')[0]] = [];
  });

  // Create map that maps each task the applicant can be assigned to to the other tasks they can be assigned to that conflict with the task
  for (let i = 0; i < availTasks.length; i++) {
    for (let j = i + 1; j < availTasks.length; j++) {
      if (taskTimeInTaskTime(availTasks[i].task, availTasks[j].task)) {
        taskConflicts[availTasks[i].name.split('-')[0]].push(availTasks[j].name.split('-')[0]);
        taskConflicts[availTasks[j].name.split('-')[0]].push(availTasks[i].name.split('-')[0]);
      }
    }
  }

  const updatedTCMap = variableConflictMap;
  updatedTCMap[applicant.vName] = taskConflicts;
}

class CspModel {
  /** The array of tasks */
  tasks = [];
  /** The array of applicants */
  applicants = [];
  /** Map of tasks to the intervals they are part of */
  taskIntervalMap = {};
  /** Map of intervals to their interval characteristics */
  intervals = {};
  /** Map of an applicant to the variables they are available for and the variables that have conflicts with those variables */
  variableConflictMap = {};
  /** Task preferences for the current event */
  taskPreferences = [];

  constructor(tasks, applicants, intervals, taskPreferences) {
    this.tasks = tasks;
    this.applicants = applicants;
    this.intervals = intervals;
    this.taskPreferences = taskPreferences;
  }

  /**
   * Creates the model's domain for each of the variables
   * @returns The created domain
   */
  #createModelDomain() {
    const domain = [];
    const statusMap = new Map();
    this.applicants.forEach(applicant => {
      if (applicant?.status?.name) {
        statusMap.set(applicant.status.name, (statusMap.get(applicant.status.name) ?? 0) + 1);
        domain.push({
          aName: `${applicant.user.first_name[0]} ${applicant.user.last_name[0]}`,
          vName: `${applicant.status.name}-${statusMap.get(applicant.status.name)}`,
          status: applicant.status,
          time_windows: applicant.time_windows,
          taskPreferences: applicant.task_preferences,
          id: applicant.id,
        });

        addApplicantsIMap(applicant, this.intervals);
      }
    });
    return domain;
  }

  /**
   * Creates the variables for the CSP Model
   * @returns The variables for the CSP model
   */
  #createModelVariables() {
    let taskCount = 0;
    let loadCount = 0;
    let totalLoad = 0;

    const varDomain = this.#createModelDomain();
    const variables = [];
    this.tasks.forEach(task => {
      let loads = task.load;
      totalLoad = task.load + task.backup_load;
      for (loadCount = 0; loadCount < totalLoad; loadCount++) {
        const backup = loads < 1;
        loads--;
        variables.push({
          name: `V${taskCount}-${loadCount}`,
          domain: varDomain,
          task,
          isBackup: backup,
        });
      }

      [this.taskIntervalMap, this.intervals] = updateTaskIntervalMap(this.taskIntervalMap, this.intervals, task, `V${taskCount}`);
      taskCount++;
    });

    return variables;
  }

  /**
   * Creates the constraints for the CSP model
   * @returns The constraints for the CSP model
   */
  #createModelConstraints() {
    let totalLoad = 0;
    let task = null;
    let t1Load = 0;
    let t2Load = 0;
    const constraints = [];
    let i = 0;

    for (let j = 0; j < this.tasks.length; j++) {
      task = this.tasks[j];
      totalLoad = task.load + task.backup_load;
      for (let loadCount = 0; loadCount < totalLoad; loadCount++) {
        constraints.push({
          name: `C${i}`,
          arity: 1,
          scope: `V${j}-${loadCount}`,
          reference: taskTimeInApplicantTime,
        });

        i++;

        constraints.push({
          name: `C${i}-pref`,
          arity: 1,
          scope: `V${j}-${loadCount}`,
          reference: statusPreferences,
        });

        i++;

        // constraints.push({
        //   name: `C${i}-vol`,
        //   arity: 1,
        //   scope: `V${j}-${loadCount}`,
        //   reference: volPreferences,
        // });

        // i++;
      }
      for (let k = j; k < this.tasks.length; k++) {
        if (taskTimeInTaskTime(task, this.tasks[k])) {
          t1Load = totalLoad;
          t2Load = this.tasks[k].load + this.tasks[k].backup_load;
          for (let l = 0; l < t1Load; l++) {
            for (let m = (j === k ? l : 0); m < t2Load; m++) {
              if (j !== k || (j === k && l !== m)) {
                constraints.push({
                  name: `C${i}`,
                  arity: 2,
                  scope: `V${j}-${l} V${k}-${m}`,
                  reference: taskMutex,
                });
                i++;
              }
            }
          }
        }
      }
    }
    return constraints;
  }

  /**
   * Generates the CSP Model
   * @returns The modelVariables, the modelConstraints, the taskIntervalMap, and the intervals
   */
  generateCspModel() {
    const modelVariables = this.#createModelVariables();
    const modelConstraints = this.#createModelConstraints();

    this.intervals = updateWeights(this.intervals);
    const domains = this.#createModelDomain();
    domains.forEach(applicant => {
      updateVariableConflictMap(applicant, modelVariables, this.variableConflictMap, this.taskPreferences);
    });

    const { intervals, taskIntervalMap, variableConflictMap } = this;

    return {
      modelVariables, modelConstraints, taskIntervalMap, intervals, variableConflictMap,
    };
  }
}

export default CspModel;
