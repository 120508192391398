import { createSelector } from 'reselect';

export const selectActivitiesLoading = createSelector(
  (state) => state.getIn(['activities', 'activitiesLoading']),
  (activitiesLoading) => activitiesLoading,
);

export const selectTasksLoading = createSelector(
  (state) => state.getIn(['activities', 'tasksLoading']),
  (tasksLoading) => tasksLoading,
);

export const selectAssignmentPrioritiesLoading = createSelector(
  (state) => state.getIn(['activities', 'assignmentPrioritiesLoading']),
  (assignmentPrioritiesLoading) => assignmentPrioritiesLoading,
);

export const selectActivities = createSelector(
  (state) => state.getIn(['activities', 'activities']),
  (data) => data.toJS(),
);

export const selectTasks = createSelector(
  (state) => state.getIn(['activities', 'tasks']),
  (data) => data.toJS(),
);

export const selectAssignmentPriorities = createSelector(
  (state) => state.getIn(['activities', 'assignmentPriorities']),
  (data) => data.toJS(),
);
