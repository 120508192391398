import ApiWrapper from 'wrappers/api';
import fileDownload from 'js-file-download';

import {
  getSolutionAssignmentsRequestSent,
  getSolutionAssignmentsRequestSuccessful,
  createSolutionAssignmentRequestSent,
  createSolutionAssignmentRequestSuccessful,
  deleteSolutionAssignmentRequestSent,
  deleteSolutionAssignmentRequestSuccessful,
  updateSolutionAssignmentRequestSent,
  updateSolutionAssignmentRequestSuccessful,
} from 'actions/atomic/solutionAssignments';

export function getSolutionAssignments() {
  return (dispatch) => {
    dispatch(getSolutionAssignmentsRequestSent());
    return ApiWrapper.get('/solution_assignments')
      .then(response => {
        dispatch(getSolutionAssignmentsRequestSuccessful(response.data));
      });
  };
}

export function downloadSolutionAssignmentsTasks() {
  return () => ApiWrapper.get('/solution_assignments/download_tasks', { responseType: 'blob' })
    .then(response => fileDownload(response.data, 'solution_assignments_tasks.xlsx'));
}

export function downloadSolutionAssignmentsApplicants() {
  return () => ApiWrapper.get('/solution_assignments/download_applicants', { responseType: 'blob' })
    .then(response => fileDownload(response.data, 'solution_assignments_applicants.xlsx'));
}

export function createSolutionAssignment(newSolutionAssignment) {
  return (dispatch) => {
    dispatch(createSolutionAssignmentRequestSent());
    return ApiWrapper.post('/solution_assignments', newSolutionAssignment)
      .then(response => {
        dispatch(createSolutionAssignmentRequestSuccessful(response.data));
      });
  };
}

export function updateSolutionAssignment(newSolutionAssignment) {
  return (dispatch) => {
    dispatch(updateSolutionAssignmentRequestSent());
    return ApiWrapper.put(`/solution_assignments/${newSolutionAssignment.id}`, newSolutionAssignment)
      .then(response => {
        dispatch(updateSolutionAssignmentRequestSuccessful(response.data));
      });
  };
}

export function deleteSolutionAssignment(oldSolutionAssignment) {
  return (dispatch) => {
    dispatch(deleteSolutionAssignmentRequestSent());
    return ApiWrapper.delete(`/solution_assignments/${oldSolutionAssignment.id}`, oldSolutionAssignment)
      .then((response) => {
        dispatch(deleteSolutionAssignmentRequestSuccessful(response.data));
      });
  };
}
