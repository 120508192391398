import React from 'react';
import PropTypes from 'prop-types';

import SummaryStep from 'components/signup/SummaryStep';

import ReusableButton from 'components/common/ReusableButton';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import history from 'wrappers/history';
import styles from './style.module.scss';
import { formatHumanDate } from '../admin/AdminUtils.ts';

class VolunteerPastApplicationPage extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.isLoggedIn) {
      history.push('/login');
    }
  }

  componentDidMount = () => {
    if (this.props.currentYear === '') {
      this.props.getYear();
    }

    if (this.props.eventDates.length === 0) {
      this.props.getEventDates();
    }

    if (this.props.tasks.length === 0) {
      this.props.getTasks();
    }
  };

  get urlYear() {
    return parseInt(this.props.match.params.year, 10);
  }

  get urlYearMatchesCurrentYear() {
    return this.props.currentYear === this.urlYear;
  }

  get currentApplication() {
    if (!this.props.currentUser) {
      return null;
    }
    const currentYearApplication = this.props.currentUser.applications.filter(application => application.year === this.urlYear);
    return currentYearApplication.length > 0 ? currentYearApplication[0] : null;
  }

  get selectedRoles() {
    return [
      { name: 'Activity Worker', value: this.currentApplication ? this.currentApplication.activity_worker : false },
      { name: 'Department Display', value: this.currentApplication ? this.currentApplication.department_display : false },
      { name: 'Speaker', value: this.currentApplication ? this.currentApplication.speaker : false },
    ].filter(role => role.value).map(role => role.name);
  }

  get enteredTimeWindows() {
    if (this.currentApplication) {
      return this.currentApplication.time_windows.map(timeWindow => ({ start_at: dayjs(timeWindow.starts_at), end_at: dayjs(timeWindow.ends_at) }));
    }
    return [];
  }

  get currentYearTasks() {
    if (this.props.tasks.length > 0) {
      return this.props.tasks.filter(task => task.year === this.urlYear);
    }
    return [];
  }

  get visibleSortedTasks() {
    if (!this.currentApplication.status) {
      return [];
    }

    const visibleTasks = this.currentYearTasks.filter(task => {
      const visibleToIds = task.visible_to.map(status => status.id);

      return visibleToIds.includes(this.currentApplication.status.id);
    });

    const sortedTasks = sortBy(visibleTasks.concat(), ['starts_at', 'display_string']);
    return sortedTasks;
  }

  get tasksAvailableForApplicant() {
    return this.visibleSortedTasks.filter(task => this.isApplicantAvailableForTask(task));
  }

  get taskPreferences() {
    const existingPreferences = {};
    this.currentApplication.task_preferences.forEach(preference => {
      existingPreferences[`task${preference.task_id}`] = preference.preference;
    });
    return existingPreferences;
  }

  get currentEventDate() {
    return this.props.eventDates.filter(eventDate => dayjs(eventDate.held_on).year() === this.props.currentYear)[0];
  }

  get currentDateInApplicationPeriod() {
    if (this.currentEventDate) {
      return dayjs(this.currentEventDate.application_open) <= dayjs() && dayjs() <= dayjs(this.currentEventDate.hard_lock);
    }

    return false;
  }

  isApplicantAvailableForTask = task => {
    const taskStartTime = dayjs(task.starts_at);
    const taskEndTime = dayjs(task.ends_at);
    return this.enteredTimeWindows.some(timeWindow => {
      const taskContainedInTimeWindow = timeWindow.start_at <= taskStartTime && timeWindow.end_at >= taskEndTime;
      const taskPreemtableAndOverlapping = task.preemtable && taskStartTime <= timeWindow.end_at && timeWindow.start_at < taskEndTime;
      return taskContainedInTimeWindow || taskPreemtableAndOverlapping;
    });
  };

  formatTimeWindow = (timeWindow) => `${timeWindow.start_at.format('h:mm A')} - ${timeWindow.end_at.format('h:mm A')}`;

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['dashboard-container']}>
              {this.urlYearMatchesCurrentYear
              && this.currentDateInApplicationPeriod
              && (
                <div>
                  You cannot view the application for the current year (
                  {this.props.match.params.year}
                  ) in this form because applications are still open.
                  <ReusableButton
                    hasTopMargin
                    onClick={() => history.push('/volunteer/dashboard')}
                    value="Return to Dashboard"
                  />
                </div>
              )}
              {((!this.urlYearMatchesCurrentYear && !this.currentApplication)
              || (this.urlYearMatchesCurrentYear && !this.currentApplication && !this.currentDateInApplicationPeriod))
              && (
                <div>
                  You do not have an application for the year&nbsp;
                  {this.props.match.params.year}
                  .
                  <ReusableButton
                    hasTopMargin
                    onClick={() => history.push('/volunteer/dashboard')}
                    value="Return to Dashboard"
                  />
                </div>
              )}
              {(!this.urlYearMatchesCurrentYear || !this.currentDateInApplicationPeriod)
              && this.currentApplication
              && (
                <div>
                  <h2 className={styles['page-header']}>
                    Application&nbsp;for&nbsp;
                    {formatHumanDate(this.props.match.params.year)}
                  </h2>
                  <ReusableButton
                    hasTopMargin
                    onClick={() => history.push('/volunteer/dashboard')}
                    value="Return to Dashboard"
                  />
                  <SummaryStep
                    currentUser={this.props.currentUser}
                    selectedRoles={this.selectedRoles}
                    status={this.currentApplication ? this.currentApplication.status : null}
                    affiliation={this.currentApplication ? this.currentApplication.affiliation : null}
                    recruiter={this.currentApplication ? this.currentApplication.recruiter : null}
                    recruitedFrom={this.currentApplication ? this.currentApplication.math_class : null}
                    applicationComments={this.currentApplication ? this.currentApplication.comments : ''}
                    yaspFormSignedDate={this.currentApplication ? dayjs(this.currentApplication.youth_form_date) : ''}
                    enteredTimeWindows={this.enteredTimeWindows}
                    formatTimeWindow={this.formatTimeWindow}
                    tasksAvailableForApplicant={this.tasksAvailableForApplicant}
                    taskPreferences={this.taskPreferences}
                    // taskSignupComments={this.state.taskSignupComments}
                    onPastApplicationPage
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VolunteerPastApplicationPage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentYear: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  getYear: PropTypes.func.isRequired,
  eventDates: PropTypes.array.isRequired,
  getEventDates: PropTypes.func.isRequired,
  tasks: PropTypes.array.isRequired,
  getTasks: PropTypes.func.isRequired,
  match: PropTypes.object,
};

export default VolunteerPastApplicationPage;
