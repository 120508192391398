import React from 'react';

import ReusableButton from 'components/common/ReusableButton';
import history from 'wrappers/history';
import styles from './style.module.scss';

const ForbiddenPage = () => (
  <div id="maincontent">
    <div className="wdn-band">
      <div className="wdn-inner-wrapper">
        <div className={styles['info-container']}>
          <h2>
            Forbidden
          </h2>
          <div>
            You do not have permission to access this resource.
          </div>
          <ReusableButton
            value="Return Home"
            hasTopMargin
            onClick={() => history.push('/')}
          />
        </div>
      </div>
    </div>
  </div>
);

export default ForbiddenPage;
