import React from 'react';
import PropTypes from 'prop-types';

import ReusableTable from 'components/common/ReusableTable';
import { CheckCircle, Cancel } from '@material-ui/icons';
import { formatDate } from 'utils/date';
import DatePopup from 'components/common/DatePopup';
import dayjs from 'dayjs';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableTextFieldValidator from 'components/common/ReusableTextFieldValidator';
import { isFieldEmpty, fields } from 'utils/validation';
import history from 'wrappers/history';
import styles from './style.module.scss';

class AnnouncementsPage extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }

    this.state = {
      authorError: false,
      messageError: false,
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: '',
      columns: [
        {
          title: 'Author',
          field: 'author',
          tooltip: 'The author of the announcement.',
          editComponent: (author) => (
            <ReusableTextFieldValidator
              text={author}
              onChange={(event) => author.onChange(event.target.value)}
              regExp={fields.announcementAuthor.regExp}
              error={this.state.authorError}
              onError={(error) => this.setState({ authorError: error })}
            />
          ),
        },
        {
          title: 'Message',
          field: 'content',
          tooltip: 'The content of the announcement.',
          editComponent: (message) => (
            <ReusableTextFieldValidator
              multiline
              text={message}
              onChange={(event) => message.onChange(event.target.value)}
              regExp={fields.announcementMessage.regExp}
              error={this.state.messageError}
              onError={(error) => this.setState({ messageError: error })}
            />
          ),
        },
        {
          title: 'Start',
          field: 'start_at',
          type: 'date',
          tooltip: 'The start date of the announcement',
          editComponent: (row) => <DatePopup value={row.value} onChange={row.onChange} />,
          render: (announcement) => <div className={styles['date']}>{formatDate(announcement.start_at)}</div>,
        },
        {
          title: 'End',
          field: 'end_at',
          type: 'date',
          defaultSort: 'desc',
          tooltip: 'The end date of the announcement',
          editComponent: (row) => <DatePopup value={row.value} onChange={row.onChange} />,
          render: (announcement) => <div className={styles['date']}>{formatDate(announcement.end_at)}</div>,
        },
        {
          title: 'Status',
          render: (announcement) => this.announcementStatus(announcement),
        },
      ],
      options: {
        filtering: false,
        padding: 'dense',
        maxBodyHeight: 600,
        selection: false,
        hideDeleteButton: true,
      },
      editable: {
        isEditable: () => true,
        isDeletable: () => false,
        onRowAdd: (newAnnouncement) => new Promise((resolve, reject) => {
          const errorMessage = this.validateFields(newAnnouncement);
          if (errorMessage === '') {
            this.props.createAnnouncement(newAnnouncement);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'success',
              snackbarMessage: 'Announcement successfully added.',
            });
            resolve();
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: errorMessage,
            });
            reject();
          }
        }),
        onRowUpdate: (updatedAnnouncement) => new Promise((resolve, reject) => {
          const errorMessage = this.validateFields(updatedAnnouncement);
          if (errorMessage === '') {
            this.props.updateAnnouncement(updatedAnnouncement);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'success',
              snackbarMessage: 'Announcement successfully updated.',
            });
            resolve();
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: errorMessage,
            });
            reject();
          }
        }),
        onRowDelete: () => new Promise((resolve) => {
          resolve();
        }),
      },
      actions: [
        {
          icon: 'replay',
          tooltip: 'Refetch Data',
          isFreeAction: true,
          onClick: () => this.props.getAnnouncements(),
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.announcements.length === 0) {
      this.props.getAnnouncements();
    }
  }

  announcementStatus = (announcement) => {
    // status is blank when adding a row
    if (!announcement) {
      return null;
    }

    const openDate = announcement.start_at;
    const closeDate = announcement.end_at;
    const today = dayjs().format('YYYY-MM-DD');

    return today >= openDate && closeDate > today
      ? (
        <div className={`${styles['visible']} ${styles['status']}`}>
          <CheckCircle className={styles['icon']} />
          Visible
        </div>
      )
      : (
        <div className={`${styles['not-visible']} ${styles['status']}`}>
          <Cancel className={styles['icon']} />
          Not Visible
        </div>
      );
  };

  validateFields = (announcement) => {
    let errorMessage = '';
    if (this.state.authorError || isFieldEmpty(announcement.author)) {
      errorMessage += fields.announcementAuthor.errorMessage;
      this.setState({
        authorError: true,
      });
    }
    if (this.state.messageError || isFieldEmpty(announcement.content)) {
      errorMessage += fields.announcementMessage.errorMessage;
      this.setState({
        messageError: true,
      });
    }

    return errorMessage;
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['table-container']}>
              <ReusableTable
                title="Announcements"
                columns={this.state.columns}
                data={this.props.announcements}
                options={this.state.options}
                editable={this.state.editable}
                isLoading={this.props.loading}
                actions={this.state.actions}
              />
              <ReusableSnackbar
                open={this.state.snackbarOpen}
                onClose={this.handleSnackbarClose}
                variant={this.state.snackbarVariant}
                message={this.state.snackbarMessage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AnnouncementsPage.propTypes = {
  announcements: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getAnnouncements: PropTypes.func.isRequired,
  createAnnouncement: PropTypes.func.isRequired,
  updateAnnouncement: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default AnnouncementsPage;
