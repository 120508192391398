import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const ReusableTooltip = withStyles({
  tooltip: {
    color: 'black',
    backgroundColor: 'yellow',
  },
})(Tooltip);

export default ReusableTooltip;
