import React from 'react';
import ReusableTable from 'components/common/ReusableTable';
// import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import ReusableSelect from 'components/common/ReusableSelect';
import Typography from '@material-ui/core/Typography';
import { formatTime } from 'utils/date';

import { Tooltip } from '@material-ui/core';
import { customFilterByApplicantName, nameSortComparator } from '../utils';
import styles from '../style.module.scss';

class ApplicantTable extends React.Component {
  constructor(props) {
    super(props);

    this.statusLookup = {};

    this.state = {
      // Applicant Table
      applicantColumns: [
        {
          title: 'Name',
          field: 'name',
          tooltip: 'First initial. Last name',
          customSort: (a1, a2) => nameSortComparator(a1, a2),
          render: (applicant) => this.renderName(applicant),
          customFilterAndSearch: customFilterByApplicantName,
        },
        {
          title: 'Status',
          field: 'status.abbr',
          tooltip: 'The status of the applicant.',
          lookup: this.statusLookup,
        },
        {
          title: 'Avail',
          tooltip: 'The time windows that the applicant entered.',
          render: (rowData) => this.renderAvailableColumn(rowData),
        },
        {
          title: 'Asgn',
          tooltip: 'The tasks assigned to the applicant',
          render: (applicant) => this.renderApplicantAssignedDropdown(applicant),
        },
      ],
      applicantOptions: {
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 700,
        hideDeleteButton: true,
        showFull: true,
        actionsColumnIndex: -1,
        search: false,
      },
      applicantActions: [
        {
          icon: 'add',
          tooltip: 'Assign',
          position: 'row',
          onClick: (_event, applicant) => { this.props.openApplicantTaskAssignDialog(applicant); },
        },
        {
          icon: 'replay',
          tooltip: 'Refetch Data',
          position: 'toolbar',
          isFreeAction: true,
          onClick: () => this.props.getApplicants(),
        },
        {
          icon: 'save_alt',
          tooltip: 'Export',
          position: 'toolbar',
          isFreeAction: true,
          onClick: () => {
            this.props.downloadSolutionAssignmentsApplicants();
            this.props.handleDownloadSnackbar();
          },
        },
        {
          icon: 'filter_alt',
          tooltip: 'Filter',
          position: 'toolbar',
          isFreeAction: true,
          onClick: () => this.props.handleOpenApplicantFilter(),
        },
      ],
    };
  }

  renderName = (applicant) => {
    const shortLastName = applicant.user?.last_name.length > 9 ? `${applicant.user?.last_name.slice(0, 7)}...` : applicant.user?.last_name;
    return <Tooltip title={`${applicant.user?.first_name} ${applicant.user?.last_name}`} placement="bottom-start"><p className={styles['nowrap']} style={{ margin: 0 }}>{`${applicant.user?.first_name.charAt(0)}. ${shortLastName}`}</p></Tooltip>;
  };

  renderApplicantAssigned(applicant) {
    const solutionAssignments = this.props.solutionAssignments.filter(solutionAssignment => solutionAssignment.applicant.id === applicant.id);
    let tasks = '';
    let i;
    for (i = 0; i < solutionAssignments.length - 1; i++) {
      tasks = `${tasks}${solutionAssignments[i].task.abbr.trim() ? solutionAssignments[i].task.abbr.trim() : solutionAssignments[i].task.name}, `;
    }
    if (solutionAssignments.length !== 0) {
      return (
        <Typography style={{
          color: 'inherit', boxSizing: 'border-box', fontSize: 'inherit', fontFamily: 'Gotham SSm A, Gotham SSm B, Verdana, sans-serif', fontWeight: 'inherit',
        }}
        // eslint-disable-next-line indent
          onClick={() => { this.props.openApplicantSolutionAssignmentsDialog(applicant); }}
        >
          {tasks}
          {solutionAssignments[i].task.abbr.trim() ? solutionAssignments[i].task.abbr.trim() : solutionAssignments[i].task.name}
        </Typography>
      );
    }
    return 'None';
  }

  // renders the tasks that shown in the applicant table in a dropdown format
  renderApplicantAssignedDropdown(applicant) {
    const solutionAssignments = this.props.solutionAssignments.filter(solutionAssignment => solutionAssignment.applicant.id === applicant.id);
    if (solutionAssignments.length !== 0) {
      const applicantTaskOptions = solutionAssignments.map(solutionAssignment => ({
        display: `${solutionAssignment.task.abbr.trim() ? `${solutionAssignment.task.abbr.trim()}` : `${solutionAssignment.task.name}`}${solutionAssignment.task.preemtable ? ' (P)' : ''}`,
        value: `${solutionAssignment.task.abbr.trim() ? `${solutionAssignment.task.abbr.trim()}` : `${solutionAssignment.task.name}`}${solutionAssignment.task.preemtable ? ' (P)' : ''}`,
      }));
      const menuTask = `${solutionAssignments[0].task.abbr.trim() ? solutionAssignments[0].task.abbr.trim() : solutionAssignments[0].task.name}${solutionAssignments[0].task.preemtable ? ' (P)' : ''}`;
      if (solutionAssignments.length === 1) {
        return <div className={styles['nowrap']}>{menuTask}</div>;
      }
      return (
        <>
          <div>
            <ReusableSelect
              id="preferred"
              className={styles['task-preference']}
              value={menuTask}
              options={applicantTaskOptions}
              useDefaultVariant
              isTransparent
              displayEmpty
              excludeNoneOption
              style={{ float: 'left', width: 10 }}
            />
            {/* TODO: Think about how to add later{
              solutionAssignments.length === 0 ? <></>
                : <Button style={{ height: 10, width: 10, justifyContent: 'flex-start' }} onClick={() => this.props.openApplicantSolutionAssignmentsDialog(applicant)}>...</Button>
            } */}
          </div>
        </>
      );
    }
    return 'None';
  }

  /**
   * renders applicant's availability times in a dropdown format
   * @param {applicant} applicant
   * @returns dropdown with available times else None if no availability
   */
  renderAvailableColumn = (applicant) => {
    const timeWindows = this.props.applicants?.get(applicant.id)?.time_windows;
    if (timeWindows && timeWindows.length !== 0) {
      const timeWindowOptions = timeWindows.map(timeWindow => ({
        display: `${formatTime(timeWindow?.starts_at)}-${formatTime(timeWindow?.ends_at)}`,
        value: `${formatTime(timeWindow?.starts_at)}-${formatTime(timeWindow?.ends_at)}`,
      }));
      if (timeWindows.length === 1) {
        return (
          <div className={styles['nowrap']}>
            {`${formatTime(timeWindows[0].starts_at)}-${formatTime(timeWindows[0].ends_at)}`}
          </div>
        );
      }
      return (
        <ReusableSelect
          id="preferred"
          className={styles['task-preference']}
          value={timeWindowOptions[0].display}
          options={timeWindowOptions}
          useDefaultVariant
          isTransparent
          displayEmpty
          excludeNoneOption
        />
      );
    }

    return (
      <div className={styles['nowrap']}>
        None
      </div>
    );
  };

  render() {
    this.props.data.forEach(applicant => {
      if (applicant.status) {
        this.statusLookup[applicant.status.abbr] = applicant.status.abbr;
      }
    });
    return (
      <div id="applicant-table-container" className={styles['applicant-table-container']}>
        <ReusableTable
          title={`Applicants (${this.props.data.length})`}
          columns={this.state.applicantColumns}
          data={this.props.data}
          options={this.state.applicantOptions}
          actions={this.state.applicantActions}
          isLoading={this.props.applicantsLoading}
          renderYearOptions={this.props.renderYearOptions}
        />
      </div>
    );
  }
}

ApplicantTable.propTypes = {
  data: PropTypes.array.isRequired,
  applicantsLoading: PropTypes.bool.isRequired,
  downloadSolutionAssignmentsApplicants: PropTypes.func.isRequired,
  getApplicants: PropTypes.func.isRequired,
  solutionAssignments: PropTypes.array.isRequired,
  handleDownloadSnackbar: PropTypes.func.isRequired,
  openApplicantTaskAssignDialog: PropTypes.func.isRequired,
  handleOpenApplicantFilter: PropTypes.func.isRequired,
  openApplicantSolutionAssignmentsDialog: PropTypes.func.isRequired,
  applicants: PropTypes.object.isRequired,
  renderYearOptions: PropTypes.func.isRequired,
};

export default ApplicantTable;
