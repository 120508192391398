import React from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import ReusableTooltip from 'components/common/ReusableTooltip';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';

import ReusableTextField from 'components/common/ReusableTextField';
import styles from './style.module.scss';

class ApplicationStep extends React.Component {
  render() {
    return (
      <div className={styles['general-information-form']}>
        <div className={styles['general-row']}>
          <div className={styles['general-roles']}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Select all that apply</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={(
                    <ReusableTooltip title="Select this designation if you are helping in some capacity other than being a speaker at the opening ceremony or representing a department/program at a booth.">
                      <Checkbox color="default" checked={this.props.activityWorker} onChange={this.props.handleCheckboxChange('applicationActivityWorker')} />
                    </ReusableTooltip>
                  )}
                  label="Activity Worker"
                />
                <FormControlLabel
                  control={(
                    <ReusableTooltip title="Select this designation if you are volunteering as a representative of a department/program at a display booth over the lunch hour.">
                      <Checkbox color="default" checked={this.props.departmentDisplay} onChange={this.props.handleCheckboxChange('applicationDepartmentDisplay')} />
                    </ReusableTooltip>
                  )}
                  label="Department Display"
                />
                <FormControlLabel
                  control={(
                    <ReusableTooltip title="Select this designation if you are speaking at the opening ceremony.">
                      <Checkbox color="default" checked={this.props.speaker} onChange={this.props.handleCheckboxChange('applicationSpeaker')} />
                    </ReusableTooltip>
                  )}
                  label="Speaker"
                />
              </FormGroup>
            </FormControl>
          </div>
          <div className={styles['general-status-recruitment-block']}>
            {this.props.fromAdmin
            && (
              <div className={styles['general-row']}>
                <MuiPickersUtilsProvider utils={DayJsUtils}>
                  <DatePicker
                    style={{ marginRight: '20px' }}
                    label="YASP signed on"
                    inputVariant="outlined"
                    clearable
                    disableFuture
                    format="MM/DD/YYYY"
                    value={this.props.yaspFormSignedDate}
                    onChange={this.props.handleDateChange('yaspFormSignedDate')}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DayJsUtils}>
                  <DatePicker
                    label="Police check on"
                    inputVariant="outlined"
                    clearable
                    disableFuture
                    format="MM/DD/YYYY"
                    value={this.props.sorCheckDate}
                    onChange={this.props.handleDateChange('sorCheckDate')}
                  />
                </MuiPickersUtilsProvider>
              </div>
            )}
            <div className={styles['general-row']}>
              <Autocomplete
                id="generalStatus"
                className={`${styles['general-select']} ${styles['mobile']}`}
                style={{ marginRight: '20px' }}
                onChange={this.props.handleAutocompleteChange('applicationStatus')}
                options={this.props.statusList}
                value={this.props.status}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Status"
                    value={this.props.status}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
              <Autocomplete
                id="generalAffiliation"
                className={`${styles['general-select']} ${styles['mobile']}`}
                groupBy={option => option.grouping}
                onChange={this.props.handleAutocompleteChange('applicationAffiliation')}
                options={this.props.affiliationList}
                value={this.props.affiliation}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Affiliation"
                    value={this.props.affiliation}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <div className={`${styles['general-row']} ${this.props.status && this.props.status.id === 1 ? '' : styles['hidden']}`}>
              <Autocomplete
                id="generalRecruiter"
                className={styles['general-select']}
                style={{ marginRight: '20px' }}
                onChange={this.props.handleAutocompleteChange('applicationRecruiter')}
                options={this.props.recruiterList}
                value={this.props.recruiter}
                getOptionLabel={option => option.display}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Faculty recruiter"
                    value={this.props.recruiter}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              <Autocomplete
                id="generalRecruitedFrom"
                className={`${styles['general-select']} ${styles['mobile']}`}
                options={this.props.recruitedFromList}
                onChange={this.props.handleAutocompleteChange('applicationRecruitedFrom')}
                value={this.props.recruitedFrom}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Recruited from"
                    value={this.props.recruitedFrom}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className={styles['general-row']}>
          <ReusableTextField
            id="generalComments"
            label="Comments"
            multiline
            rows="4"
            value={this.props.comments}
            onChange={this.props.handleCommentsChange('comments')}
          />
        </div>
      </div>
    );
  }
}

ApplicationStep.propTypes = {
  speaker: PropTypes.bool.isRequired,
  departmentDisplay: PropTypes.bool.isRequired,
  activityWorker: PropTypes.bool.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleAutocompleteChange: PropTypes.func.isRequired,
  status: PropTypes.object,
  statusList: PropTypes.array.isRequired,
  affiliation: PropTypes.object,
  affiliationList: PropTypes.array.isRequired,
  recruiter: PropTypes.object,
  recruiterList: PropTypes.array.isRequired,
  recruitedFrom: PropTypes.object,
  recruitedFromList: PropTypes.array.isRequired,
  comments: PropTypes.string,
  handleCommentsChange: PropTypes.func.isRequired,
  fromAdmin: PropTypes.bool,
  yaspFormSignedDate: PropTypes.object,
  sorCheckDate: PropTypes.object,
  handleDateChange: PropTypes.func,
};

export default ApplicationStep;
