import { createSelector } from 'reselect';

export const selectClassesLoading = createSelector(
  (state) => state.getIn(['classes', 'loading']),
  (loading) => loading,
);

export const selectClasses = createSelector(
  (state) => state.getIn(['classes', 'data']),
  (data) => data.toJS(),
);
