import { connect } from 'react-redux';

import { selectTaskPreferences, selectTaskPreferencesLoading } from 'selectors/taskPreferencesSelectors';
import {
  getTaskPreferences,
  updateTaskPreference,
  createTaskPreference,
} from 'actions/thunks/taskPreferences';
import TaskPreferencesPage from 'components/pages/admin/TaskPreferencesPage';
import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';
import { getEventDates } from 'actions/thunks/eventDates';
import { selectYear } from 'selectors/systemSettingsSelectors';
import { selectEventDates } from 'selectors/eventDatesSelectors';
import { getYear } from 'actions/thunks/systemSettings';
import { getStatuses } from 'actions/thunks/statuses';
import { selectStatuses, selectStatusesLoading } from 'selectors/statusesSelectors';
import { selectTasks } from 'selectors/activitiesSelectors';
import { getTasks } from 'actions/thunks/activities';

const mapStateToProps = state => ({
  taskPreferencesLoading: selectTaskPreferencesLoading(state),
  taskPreferences: selectTaskPreferences(state),
  isAdmin: selectIsAdmin(state),
  isLoggedIn: selectIsLoggedIn(state),
  eventDates: selectEventDates(state),
  year: selectYear(state),
  statuses: selectStatuses(state),
  statusesLoading: selectStatusesLoading(state),
  tasks: selectTasks(state),
});

const mapDispatchToProps = (dispatch) => ({
  getTaskPreferences: () => dispatch(getTaskPreferences()),
  updateTaskPreference: (newTaskPreference) => dispatch(updateTaskPreference(newTaskPreference)),
  createTaskPreference: (newTaskPreference) => dispatch(createTaskPreference(newTaskPreference)),
  getYear: () => dispatch(getYear()),
  getEventDates: () => dispatch(getEventDates()),
  getStatuses: () => dispatch(getStatuses()),
  getTasks: () => dispatch(getTasks()),
  // downloadStatuses: () => dispatch(downloadTaskPreferences()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskPreferencesPage);
