import React from 'react';
import { BrowserRouter as Link } from 'react-router-dom';

import mathDayLogo from 'assets/logos/mathday-logo.png';
import consystlabLogo from 'assets/logos/consystlab-logo.png';
import nsfLogo from 'assets/logos/nsf-logo.png';
import styles from './style.module.scss';

class Footer extends React.Component {
  render() {
    return (
      <div>
        <footer id="footer" role="contentinfo" className="wdn-content-slide">
          <div id="wdn_optional_footer" className="wdn-band wdn-footer-optional">
            <div className="wdn-inner-wrapper" />
          </div>
          <div id="wdn_local_footer" className="wdn-band wdn-footer-local">
            <div className="wdn-inner-wrapper">
              <div className="wdn-grid-set wdn-footer-links-local">
                <div className="wdn-col-full bp960-wdn-col-one-half">
                  <div className="wdn-footer-module">
                    <span className="wdn-footer-heading" role="heading" aria-level="1">
                      Welcome to Nebraska
                      <span className="wdn-text-hidden"> Contact Information</span>
                    </span>
                    <div className="wdn-grid-set-full">
                      <div className="wdn-col">
                        <p className="wdn-icon-location">
                          1400 R Street
                          <br />
                          Lincoln,&nbsp;
                          <abbr title="Nebraska">NE</abbr>
                          &nbsp;68588
                        </p>
                        <p className="wdn-icon-phone">
                          <a href="tel:+14024727211">402-472-7211</a>
                        </p>
                        <p>
                          <span className="wdn-icon-mail" aria-hidden="true" />
                          <span className="wdn-text-hidden">mail icon</span>
                          <Link to="/contact">Contact Us</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wdn-col-full bp960-wdn-col-one-half">
                  <div className="wdn-footer-module">
                    <div id="leftcollinks" className="region region-leftcollinks leftcollinks">
                      <span className="wdn-footer-heading" role="heading" aria-level="1">Affiliates</span>
                      <div className={styles['logos']}>
                        <a href="http://www.math.unl.edu/programs/mathday">
                          <img className={styles['logo']} src={mathDayLogo} alt="Math Day" />
                        </a>
                        <a href="http://consystlab.unl.edu">
                          <img className={styles['logo']} src={consystlabLogo} alt="UNL Consystlab" />
                        </a>
                        <a href="http://www.nsf.gov">
                          <img className={styles['logo']} src={nsfLogo} alt="National Science Foundation" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="wdn_global_footer" className="wdn-band wdn-footer-global">
            <div className="wdn-inner-wrapper">
              <div className="wdn-grid-set bp480-wdn-grid-set-thirds bp960-wdn-grid-set-fourths">
                <div className="wdn-col">
                  <div className="wdn-footer-module wdn-footer-social">
                    <span role="heading" aria-level="1">Connect with #UNL</span>
                    <ul className="wdn-footer-list">
                      <li>
                        <a href="http://www.facebook.com/UNLincoln" className="wdn-footer-list-item">
                          <span className="wdn-icon-facebook" aria-hidden="true" />
                          UNLincoln
                          <span className="wdn-text-hidden">
                            on Facebook
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/UNLincoln" className="wdn-footer-list-item">
                          <span className="wdn-icon-twitter" aria-hidden="true" />
                          @UNLincoln
                          <span className="wdn-text-hidden">
                            on Twitter
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/user/unl" className="wdn-footer-list-item">
                          <span className="wdn-icon-youtube-play" aria-hidden="true" />
                          UNL
                          <span className="wdn-text-hidden">
                            on YouTube
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="https://instagram.com/unlincoln/" className="wdn-footer-list-item">
                          <span className="wdn-icon-instagram" aria-hidden="true" />
                          @unlincoln
                          <span className="wdn-text-hidden">
                            on Instagram
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/edu/school?id=18836" className="wdn-footer-list-item">
                          <span className="wdn-icon-linkedin-squared" aria-hidden="true" />
                          University of Nebraska&ndash;Lincoln
                          <span className="wdn-text-hidden">
                            on LinkedIn
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.pinterest.com/unlincoln/boards/" className="wdn-footer-list-item">
                          <span className="wdn-icon-pinterest" aria-hidden="true" />
                          unlincoln
                          <span className="wdn-text-hidden">
                            on Pinterest
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="snapchat://?u=unlincoln" className="wdn-footer-list-item wdn-icon-snapchat">
                          UNLincoln
                          <span className="wdn-text-hidden">
                            on Snapchat
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="wdn-col">
                  <div className="wdn-footer-module wdn-footer-campus">
                    <span role="heading" aria-level="1">
                      Campus
                      <span className="wdn-text-hidden">
                        Links
                      </span>
                    </span>
                    <ul className="wdn-footer-list">
                      <li><a href="http://directory.unl.edu/" className="wdn-footer-list-item">Directory</a></li>
                      <li><a href="https://employment.unl.edu/" className="wdn-footer-list-item">Employment</a></li>
                      <li><a href="http://events.unl.edu/" className="wdn-footer-list-item">Events</a></li>
                      <li><a href="http://libraries.unl.edu/" className="wdn-footer-list-item">Libraries</a></li>
                      <li><a href="http://maps.unl.edu/" className="wdn-footer-list-item">Maps</a></li>
                      <li><a href="http://www.unl.edu/chancellor/" className="wdn-footer-list-item">Office of the Chancellor</a></li>
                      <li><a href="http://news.unl.edu/newsrooms/unltoday/" className="wdn-footer-list-item">UNL Today</a></li>
                    </ul>
                  </div>
                </div>
                <div className="wdn-col">
                  <div className="wdn-footer-module wdn-footer-policies">
                    <span role="heading" aria-level="1">Policies</span>
                    <ul className="wdn-footer-list">
                      <li><a href="http://emergency.unl.edu/" className="wdn-footer-list-item">Emergency Planning and Preparedness</a></li>
                      <li><a href="http://www.unl.edu/equity/" className="wdn-footer-list-item">Institutional Equity and Compliance</a></li>
                      <li><a href="http://www.unl.edu/equity/notice-nondiscrimination" className="wdn-footer-list-item">Notice of Nondiscrimination</a></li>
                      <li><a href="http://its.unl.edu/unlprivacypolicy" className="wdn-footer-list-item">Privacy Policy</a></li>
                    </ul>

                    <a href="http://go.unl.edu/86bw" id="tips_wordmark" className="wdn-footer-logo"><span className="wdn-text-hidden">TIPS Incident Reporting</span></a>
                  </div>
                </div>
              </div>
              <div className="wdn-grid-set">
                <div className="bp960-wdn-col-three-fourths">
                  <div className="wdn-footer-end">
                    <div className="wdn-grid-set">
                      <div className="wdn-col-one-third">
                        <div className="wdn-footer-logos">
                          <div className="wdn-grid-set">
                            <div className="bp480-wdn-col-two-thirds bp1280-wdn-col-one-half">
                              <a href="http://www.unl.edu/" id="unl_wordmark" className="wdn-footer-logo"><span className="wdn-text-hidden">University of Nebraska&ndash;Lincoln</span></a>
                            </div>
                            <div className="bp480-wdn-col-one-third bp1280-wdn-col-one-half">
                              <div className="wdn-grid-set-halves bp480-wdn-grid-set-full bp1280-wdn-grid-set-halves">
                                <div className="wdn-col">
                                  <a href="http://www.bigten.org/" id="b1g_wordmark" className="wdn-footer-logo"><span className="wdn-text-hidden">Big Ten Conference</span></a>
                                </div>
                                <div className="wdn-col">
                                  <a href="http://www.cic.net/home" id="cic_wordmark" className="wdn-footer-logo"><span className="wdn-text-hidden">Committee on Institutional Cooperation</span></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wdn-col-two-thirds bp640-wdn-col-two-thirds">
                        <span className="wdn-wdn-qa">
                          UNL web framework and quality assurance provided by the
                          <a href="http://wdn.unl.edu/">
                            Web&nbsp;Developer&nbsp;Network
                          </a>
                          &nbsp;&middot;&nbsp;
                          <a href="https://webaudit.unl.edu/qa-test/">
                            QA&nbsp;Test
                          </a>
                        </span>
                        <span className="wdn-copyright-phone">
                          &copy; 2016
                          <a href="http://www.unl.edu/about-unl/">
                            University of Nebraska&ndash;Lincoln
                          </a>
                          &middot;
                          <a href="tel:+14024727211">
                            402-472-7211
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
