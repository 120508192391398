import { fromJS } from 'immutable';

import {
  GET_EMAIL_TEMPLATES_SENT,
  GET_EMAIL_TEMPLATES_SUCCESSFUL,
  UPDATE_EMAIL_TEMPLATE_SENT,
  UPDATE_EMAIL_TEMPLATE_SUCCESSFUL,
  CREATE_EMAIL_TEMPLATE_SENT,
  CREATE_EMAIL_TEMPLATE_SUCCESSFUL,
  GET_EMAIL_MESSAGES_SENT,
  GET_EMAIL_MESSAGES_SUCCESSFUL,
  CREATE_EMAIL_MESSAGE_SENT,
  CREATE_EMAIL_MESSAGE_SUCCESSFUL,
  DELETE_EMAIL_TEMPLATE_SENT,
  DELETE_EMAIL_TEMPLATE_SUCCESSFUL,
  SET_APPLICANTS_SELECTED,
  SET_RECRUITERS_SELECTED,
} from 'actions/atomic/emails';
import { formatDate, formatTime } from 'utils/date';

const initialState = fromJS({
  emailTemplatesLoading: false,
  emailTemplates: [],
  emailMessagesLoading: false,
  emailMessages: [],
  recruitersSelected: {},
  applicantsSelected: {},
});

export default function emails(state = initialState, action) {
  switch (action.type) {
  case GET_EMAIL_TEMPLATES_SENT:
    return state.merge({
      emailTemplatesLoading: true,
      emailTemplates: fromJS([]),
    });
  case GET_EMAIL_TEMPLATES_SUCCESSFUL:
    return state.merge({
      emailTemplatesLoading: false,
      emailTemplates: fromJS(action.data),
    });
  case UPDATE_EMAIL_TEMPLATE_SENT:
    return state.set('emailTemplatesLoading', true);
  case UPDATE_EMAIL_TEMPLATE_SUCCESSFUL:
    const updatedTemplate = action.data;
    const index = state.get('emailTemplates').findIndex(template => template.get('id') === parseInt(updatedTemplate.id, 10));
    return state.merge({
      emailTemplatesLoading: false,
      emailTemplates: state.get('emailTemplates').set(index, fromJS(updatedTemplate)),
    });
  case CREATE_EMAIL_TEMPLATE_SENT:
    return state.set('emailTemplatesLoading', true);
  case CREATE_EMAIL_TEMPLATE_SUCCESSFUL:
    return state.merge({
      emailTemplatesLoading: false,
      emailTemplates: state.get('emailTemplates').push(fromJS(action.data)),
    });
  case DELETE_EMAIL_TEMPLATE_SENT:
    return state.set('emailTemplatesLoading', true);
  case DELETE_EMAIL_TEMPLATE_SUCCESSFUL:
    return state.merge({
      'emailTemplatesLoading': false,
      'emailTemplates': state.get('emailTemplates').filter((template) => template.get('id') !== parseInt(action.data.id, 10)),
    });

  case GET_EMAIL_MESSAGES_SENT:
    return state.merge({
      emailMessagesLoading: true,
      emailMessages: fromJS([]),
    });
  case GET_EMAIL_MESSAGES_SUCCESSFUL:
    const sentAtFrequency = {};
    for (let i = 0; i !== action.data.length; ++i) {
      const emailMessage = action.data[i];
      if (sentAtFrequency[emailMessage.sent_at]) {
        ++sentAtFrequency[emailMessage.sent_at];
      } else {
        sentAtFrequency[emailMessage.sent_at] = 1;
      }
    }
    const formattedEmailMessages = action.data.map(emailMessage => {
      const formattedEmailMessage = emailMessage;
      formattedEmailMessage['sent_at'] = `${formatDate(emailMessage.sent_at)}, ${formatTime(emailMessage.sent_at)} Messages: ${sentAtFrequency[emailMessage.sent_at]}`;
      return formattedEmailMessage;
    });
    return state.merge({
      emailMessagesLoading: false,
      emailMessages: fromJS(formattedEmailMessages),
    });
  case CREATE_EMAIL_MESSAGE_SENT:
    return state.set('emailMessagesLoading', true);
  case CREATE_EMAIL_MESSAGE_SUCCESSFUL:
    return state.merge({
      emailMessagesLoading: false,
      emailMessages: state.get('emailMessages').push(...fromJS(action.data)),
    });
  case SET_APPLICANTS_SELECTED:
    return state.set('applicantsSelected', action.data);
  case SET_RECRUITERS_SELECTED:
    return state.set('recruitersSelected', action.data);
  default:
    return state;
  }
}
