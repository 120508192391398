import React from 'react';
import PropTypes from 'prop-types';
import history from 'wrappers/history';
import dayjs from 'dayjs';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableSelect from 'components/common/ReusableSelect';
import { formatTime } from 'utils/date';
import ReusableButton from 'components/common/ReusableButton';
import { TextField } from '@material-ui/core';
import styles from './style.module.scss';
import { yearOptions } from '../AdminUtils.ts';

import { taskTimeInApplicantTime } from '../InteractiveAssignmentPage/utils';
import TaskAssignedTable from './Tables/TaskAssignedTable';
import ApplicantAssignedTable from './Tables/ApplicantAssignedTable';
import ApplicantTableFilterDialog from '../InteractiveAssignmentPage/Dialogs/ApplicantTableFilterDialog';
import TaskTableFilterDialog from '../InteractiveAssignmentPage/Dialogs/TaskTableFilterDialog';

import { nodeConsistency } from './CSP/NodeConsistency';
import CspModel from './CSP/CspModel';
import Csp from './CSP/createCSP';
import ERA from './CSP/ERA';

class ERAPage extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }

    // Bindings needed so that the correct state is changed when passing in functions as props
    // Functions needed for TaskTable
    this.handleOpenTaskFilter = this.handleOpenTaskFilter.bind(this);

    // Functions needed for ApplicantTable
    this.handleOpenApplicantFilter = this.handleOpenApplicantFilter.bind(this);

    // Functions needed for Applicant Table Filter
    this.handleSelectNoneStatuses = this.handleSelectNoneStatuses.bind(this);
    this.handleSelectAllStatuses = this.handleSelectAllStatuses.bind(this);
    this.handleStatusCheckbox = this.handleStatusCheckbox.bind(this);
    this.handleClearApplicantTimeFilter = this.handleClearApplicantTimeFilter.bind(this);
    this.handleBeginningApplicantTimeChange = this.handleBeginningApplicantTimeChange.bind(this);
    this.handleEndingApplicantTimeChange = this.handleEndingApplicantTimeChange.bind(this);
    this.handleCloseApplicantFilter = this.handleCloseApplicantFilter.bind(this);

    // Functions needed for Task Table Filter
    this.handleSelectNoneTasks = this.handleSelectNoneTasks.bind(this);
    this.handleSelectAllTasks = this.handleSelectAllTasks.bind(this);
    this.handleTaskCheckbox = this.handleTaskCheckbox.bind(this);
    this.handleClearTaskTimeFilter = this.handleClearTaskTimeFilter.bind(this);
    this.handleBeginningTaskTimeChange = this.handleBeginningTaskTimeChange.bind(this);
    this.handleEndingTaskTimeChange = this.handleEndingTaskTimeChange.bind(this);
    this.handleCloseTaskFilter = this.handleCloseTaskFilter.bind(this);

    this.state = {
      year: this.props.year,
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: '',
      selectedTask: null,
      applicants: new Map(),
      checkedStatuses: {},
      checkedTasks: {},
      selectedApplicant: null,
      jobSelected: true,
      beginningTaskTimeFilter: '',
      endingTaskTimeFilter: '',
      beginningApplicantTimeFilter: '',
      endingApplicantTimeFilter: '',
      taskFilterOpen: false,
      applicantFilterOpen: false,
      taskAssignments: {},
      applicantAssignments: {},
      eraIsLoading: true,
      satisfiedTasks: 0,
      unsatisfiedTasks: 0,
      unassignedStatusMap: {},
      overConstrainedMap: {},
      underConstrainedMap: {},
      eraStarted: false,
      eraIsFinished: false,
      iterations: 5,
      steps: 200,
    };
  }

  async componentDidMount() {
    if (this.props.year === '') {
      await this.props.getYear();
    }
    if (this.props.applicants.length === 0) {
      await this.props.getApplicants();
    }
    if (this.props.tasks.length === 0) {
      await this.props.getTasks();
    }
    if (this.props.taskPreferences.length === 0) {
      await this.props.getTaskPreferences();
    }
    if (this.props.activities.length === 0) {
      await this.props.getActivities();
    }
    if (this.props.statuses.length === 0) {
      await this.props.getStatuses();
    }

    this.setState({ year: this.props.year });

    // Statuses in the Applicant table filter are initially all checked
    const checkedStatuses = {};
    const approvedStatuses = this.props.statuses.filter(status => status.active && status.approved);
    approvedStatuses.forEach(status => { checkedStatuses[`${status.id}`] = true; });

    // Tasks in the Task table filter are initially all checked
    const checkedTasks = {};
    this.tasksForCurrentYear.forEach(task => { checkedTasks[`${task.id}`] = true; });

    // a map that maps an applicant to their id
    const applicants = new Map();
    this.applicantsForCurrentYear.forEach(applicant => {
      if (applicant.year === this.state.year) {
        applicants.set(applicant.id, applicant);
      }
    });
    this.setState({ applicants });

    const tasks = this.props.tasks.filter(task => task.year === this.state.year);

    const taskLoadBackupToggle = new Map();
    tasks.forEach(task => {
      taskLoadBackupToggle.set(task.id, true);
    });

    const applicantLoadBackupToggle = new Map();
    this.props.applicants.forEach(applicant => {
      applicantLoadBackupToggle.set(applicant.id, true);
    });

    // Previous Task tooltips all start out closed
    const applicantPrevTasksOpenTAA = [];
    this.props.applicants.forEach(applicant => { applicantPrevTasksOpenTAA[`${applicant.user.id}`] = false; });

    this.setState({ applicants });

    this.setState({
      checkedTasks, checkedStatuses,
    });

    setTimeout(this.updateLookups, 50);
  }

  /**
   * Returns the applicants whose year is the state's year
   */
  get applicantsForCurrentYear() {
    // For some reason loading the page for the first time, switching to a different page, and switching back
    // breaks the page. This fixes it.
    if (!this.state) {
      return [];
    }
    return this.props.applicants.filter(applicant => applicant.year === this.state.year);
  }

  /**
   * returns a map that maps applicant id to applicant object and loops through solution assignments removing assigned times from applicant availability
   */
  get applicantMapForCurrentYear() {
    // a map that maps an applicant to their id
    const applicants = new Map();
    this.props.applicants.forEach(applicant => {
      if (applicant.year === this.state.year) {
        applicants.set(applicant.id, applicant);
      }
    });

    return applicants;
  }

  /**
   * Returns the tasks whose year is the state's year
   */
  get tasksForCurrentYear() {
    // For some reason loading the page for the first time, switching to a different page, and switching back
    // breaks the page. This fixes it.
    if (!this.state) {
      return [];
    }
    return this.props.tasks.filter(task => task.year === this.state.year);
  }

  /** Opens the task filter */
  handleOpenTaskFilter = () => {
    this.setState({ taskFilterOpen: true });
  };

  /** Closes the task filter */
  handleCloseTaskFilter = () => {
    this.setState({ taskFilterOpen: false });
  };

  /** Opens the applicant filter */
  handleOpenApplicantFilter = () => {
    this.setState({ applicantFilterOpen: true });
  };

  /** Closes the applicant filter */
  handleCloseApplicantFilter = () => {
    this.setState({ applicantFilterOpen: false });
  };

  /** Handles closing snackbar */
  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  /** Handles year change triggered by using dropdown */
  handleYearChange = (event) => {
    this.setState({
      year: event.target.value,
    });

    const applicants = new Map();
    this.props.applicants.forEach(applicant => {
      if (applicant.year === event.target.value) {
        applicants.set(applicant.id, applicant);
      }
    });

    const checkedTasks = {};
    this.props.tasks.filter(task => task.year === event.target.value).forEach(task => { checkedTasks[`${task.id}`] = true; });
    this.setState({ checkedTasks });
  };

  /** Hanldes clicking a status checkbox in the filter */
  handleStatusCheckbox = event => {
    const { checkedStatuses } = this.state;
    checkedStatuses[event.target.name] = event.target.checked;
    this.setState({ checkedStatuses });
  };

  /** Handles clicking a task checkbox in the filter */
  handleTaskCheckbox = event => {
    const { checkedTasks } = this.state;
    checkedTasks[event.target.name] = event.target.checked;
    this.setState({ checkedTasks });
  };

  /** Handles selecting all statuses for Applicant table filter */
  handleSelectAllStatuses = () => {
    const { checkedStatuses } = this.state;
    Object.keys(checkedStatuses).forEach(statusID => { checkedStatuses[statusID] = true; });
    this.setState({ checkedStatuses });
  };

  /** Handles deselecting all statuses for Applicant table filter */
  handleSelectNoneStatuses = () => {
    const { checkedStatuses } = this.state;
    Object.keys(checkedStatuses).forEach(statusID => { checkedStatuses[statusID] = false; });
    this.setState({ checkedStatuses });
  };

  /** Handles selecting all tasks for Task table filter */
  handleSelectAllTasks = () => {
    const { checkedTasks } = this.state;
    Object.keys(checkedTasks).forEach(taskID => { checkedTasks[taskID] = true; });
    this.setState({ checkedTasks });
  };

  /** Handles deselecting all tasks for Task table filter */
  handleSelectNoneTasks = () => {
    const { checkedTasks } = this.state;
    Object.keys(checkedTasks).forEach(taskID => { checkedTasks[taskID] = false; });
    this.setState({ checkedTasks });
  };

  /** Handles clearing the task time filter */
  handleClearTaskTimeFilter = () => {
    this.setState({ beginningTaskTimeFilter: '', endingTaskTimeFilter: '' });
  };

  /** Handles clearing the applicant time filter */
  handleClearApplicantTimeFilter = () => {
    this.setState({ beginningApplicantTimeFilter: '', endingApplicantTimeFilter: '' });
  };

  /** Checks if the applicant has availability inside the filter */
  applicantInsideTimeFilter = (applicant) => {
    let inside = false;
    for (let i = 0; i < applicant?.time_windows.length; i++) {
      const timeTokens = applicant.time_windows[i]?.ends_at.split(':');
      // parsing out the minute field in order to increment it by one
      // add a leading zero to single digit minute number
      // the minute is incremented in order to make sure the end time is not invalid due when it is equal to end of person's availability
      timeTokens[1] = (Number(timeTokens[1]) + 1) < 10 ? `0${Number(timeTokens[1]) + 1}` : `${Number(timeTokens[1]) + 1}`;
      const newTokens = timeTokens.join(':');

      let applicantStartsAtHour = dayjs(applicant.time_windows[i]?.starts_at).hour();
      let applicantStartsAtMinute = dayjs(applicant.time_windows[i]?.starts_at).minute();
      // add a zero if the number is less than 10, so that 01:01 won't appear as 1:1
      applicantStartsAtHour = applicantStartsAtHour < 10 ? `0${applicantStartsAtHour}` : applicantStartsAtHour;
      applicantStartsAtMinute = applicantStartsAtMinute < 10 ? `0${applicantStartsAtMinute}` : applicantStartsAtMinute;

      let applicantEndsAtHour = dayjs(newTokens).hour();
      let applicantEndsAtMinute = dayjs(newTokens).minute();
      // add a zero if the number is less than 10, so that 01:01 won't appear as 1:1
      applicantEndsAtHour = applicantEndsAtHour < 10 ? `0${applicantEndsAtHour}` : applicantEndsAtHour;
      applicantEndsAtMinute = applicantEndsAtMinute < 10 ? `0${applicantEndsAtMinute}` : applicantEndsAtMinute;

      const applicantStartsAt = `${applicantStartsAtHour}:${applicantStartsAtMinute}`;
      const applicantEndsAt = `${applicantEndsAtHour}:${applicantEndsAtMinute}`;

      inside = inside
        || (this.state.beginningApplicantTimeFilter <= applicantStartsAt
        && this.state.endingApplicantTimeFilter >= applicantEndsAt);
    }
    return inside;
  };

  /** Checks if the task time is inside of the filter */
  taskInsideTimeFilter = (task) => {
    let taskStartsAtHour = dayjs(task?.starts_at).hour();
    let taskStartsAtMinute = dayjs(task?.starts_at).minute();
    // add a zero if the number is less than 10, so that 01:01 won't appear as 1:1
    taskStartsAtHour = taskStartsAtHour < 10 ? `0${taskStartsAtHour}` : taskStartsAtHour;
    taskStartsAtMinute = taskStartsAtMinute < 10 ? `0${taskStartsAtMinute}` : taskStartsAtMinute;

    let taskEndsAtHour = dayjs(task?.ends_at).hour();
    let taskEndsAtMinute = dayjs(task?.ends_at).minute();
    // add a zero if the number is less than 10, so that 01:01 won't appear as 1:1
    taskEndsAtHour = taskEndsAtHour < 10 ? `0${taskEndsAtHour}` : taskEndsAtHour;
    taskEndsAtMinute = taskEndsAtMinute < 10 ? `0${taskEndsAtMinute}` : taskEndsAtMinute;

    const taskStartsAt = `${taskStartsAtHour}:${taskStartsAtMinute}`;
    const taskEndsAt = `${taskEndsAtHour}:${taskEndsAtMinute}`;

    return (this.state.beginningTaskTimeFilter <= taskStartsAt
        && this.state.endingTaskTimeFilter >= taskEndsAt);
  };

  /** Handles changing the beginning time in the task filter */
  handleBeginningTaskTimeChange = (event) => {
    const newTime = event.target.value;
    this.setState({ beginningTaskTimeFilter: newTime });
  };

  /** Handles changing the ending time in the task filter */
  handleEndingTaskTimeChange = (event) => {
    const newTime = event.target.value;
    this.setState({ endingTaskTimeFilter: newTime });
  };

  /** Handles changing the beginning time in the applicant filter */
  handleBeginningApplicantTimeChange = (event) => {
    const newTime = event.target.value;
    this.setState({ beginningApplicantTimeFilter: newTime });
  };

  /** Handles changing the ending time in the applicant filter */
  handleEndingApplicantTimeChange = (event) => {
    const newTime = event.target.value;
    this.setState({ endingApplicantTimeFilter: newTime });
  };

  /** Handles changing the iterations value to match the input */
  handleIterationsChange = (event) => {
    const newIterations = event.target.value;
    this.setState({ iterations: newIterations });
  };

  /** Handles changing the steps value to match the input */
  hanldeStepsChange = (event) => {
    const newSteps = event.target.value;
    this.setState({ steps: newSteps });
  };

  /** Runs the ERA algorithm when the button is clicked */
  handleClickRunERA = () => {
    if (this.state.eraStarted && !this.state.eraIsFinished) {
      return;
    }
    this.setState({ eraStarted: true });
    const intervals = this.createIntervals();

    const tasks = this.tasksForCurrentYear.filter(task => task.preemtable === false);
    const { applicants } = this.state;

    const model = new CspModel(tasks, applicants, intervals, this.props.taskPreferences);
    const cspInst = new Csp(model.generateCspModel(), this.props.taskPreferences);
    const nodeConstInst = nodeConsistency(cspInst.createCSPInstance());

    const era = new ERA(nodeConstInst, this.state.iterations, this.state.steps);
    const [taskAssignments, applicantAssignments, satisfiedTasks, unsatisfiedTasks, unassignedStatusMap, overConstrainedMap, underConstrainedMap] = era.era();

    this.setState({
      taskAssignments, applicantAssignments, satisfiedTasks, unsatisfiedTasks, unassignedStatusMap, overConstrainedMap, underConstrainedMap, eraIsLoading: false,
    });

    this.setState({ eraStarted: false, eraIsFinished: true });
  };

  sortTasksComparator = (task1, task2) => {
    const applicant = this.state.selectedApplicant;

    // sort by time availability
    const task1InTime = taskTimeInApplicantTime(applicant, task1);
    const task2InTime = taskTimeInApplicantTime(applicant, task2);

    if (task1InTime !== task2InTime) {
      return task1InTime ? -1 : 1;
    }

    // sort by preference
    const preferences = applicant.task_preferences;
    const appTask1Pref = preferences.find(element => task1.name === element.task.name)?.preference || 0;
    const appTask2Pref = preferences.find(element => task2.name === element.task.name)?.preference || 0;

    if (appTask1Pref !== appTask2Pref) {
      return appTask1Pref > appTask2Pref ? -1 : 1;
    }

    // sort by admin preference
    const applicantStatus = applicant?.status;
    const task1Pref = this.props.taskPreferences.find(element => element.task.name === task1.name
      && element.task.year === task1.year);
    const task2Pref = this.props.taskPreferences.find(element => element.task.name === task2.name
        && element.task.year === task2.year);

    const adminTask1Pref = task1Pref ? task1Pref[applicantStatus] : 0;
    const adminTask2Pref = task2Pref ? task2Pref[applicantStatus] : 0;

    if (adminTask1Pref !== adminTask2Pref) {
      return adminTask1Pref > adminTask2Pref ? -1 : 1;
    }

    // sort alphabetically
    return task1.name > task2.name ? -1 : 1;
  };

  sortApplicantsComparator = (applicant1, applicant2) => {
    // Fit time of task
    const applicant1Available = taskTimeInApplicantTime(this.state.applicants.get(applicant1.id), this.state.selectedTask);
    const applicant2Available = taskTimeInApplicantTime(this.state.applicants.get(applicant2.id), this.state.selectedTask);
    if (applicant1Available !== applicant2Available) {
      return applicant1Available ? -1 : 1;
    }

    // Admin's Preference
    const applicant1Status = applicant1?.status?.name;
    const applicant2Status = applicant2?.status?.name;
    const isDifferentStatus = applicant1Status !== applicant2Status;

    if (isDifferentStatus) {
      const taskPreference = this.props.taskPreferences.find(element => element.task.name === this.state.selectedTask.name
        && element.task.year === this.state.selectedTask.year);
      let adminPreferenceApplicant1 = taskPreference ? taskPreference[applicant1Status] : 0;
      let adminPreferenceApplicant2 = taskPreference ? taskPreference[applicant2Status] : 0;
      adminPreferenceApplicant1 = adminPreferenceApplicant1 || 0;
      adminPreferenceApplicant2 = adminPreferenceApplicant2 || 0;

      if (adminPreferenceApplicant1 !== adminPreferenceApplicant2) {
        return adminPreferenceApplicant1 > adminPreferenceApplicant2 ? -1 : 1;
      }
    }

    // Volunteer Preference
    let applicant1Preference = applicant1?.task_preferences?.find(element => element.task.name === this.state.selectedTask.name)?.preference;
    let applicant2Preference = applicant2?.task_preferences?.find(element => element.task.name === this.state.selectedTask.name)?.preference;
    applicant1Preference = applicant1Preference || 0;
    applicant2Preference = applicant2Preference || 0;
    const isDifferentPreference = applicant1Preference !== applicant2Preference;

    if (isDifferentPreference) {
      return applicant1Preference > applicant2Preference ? -1 : 1;
    }

    // Name
    const applicant1LastName = applicant1.user?.last_name;
    const applicant2LastName = applicant2.user?.last_name;
    const isDifferentLastName = applicant1LastName !== applicant2LastName;

    if (isDifferentLastName) {
      return applicant1LastName < applicant2LastName ? -1 : 1;
    }

    const applicant1FirstName = applicant1.user?.first_name;
    const applicant2FirstName = applicant2.user?.first_name;

    return applicant1FirstName <= applicant2FirstName ? -1 : 1;
  };

  sortApplicants(applicants) {
    if (!this.state.selectedTask) {
      return applicants;
    }
    return applicants.slice().sort((a, b) => this.sortApplicantsComparator(a, b));
  }

  sortTasks(tasks) {
    if (!this.state.selectedApplicant) {
      return tasks;
    }
    return tasks.slice().sort((a, b) => this.sortTasksComparator(a, b));
  }

  /** Creates the intervals */
  createIntervals() {
    const tasks = this.tasksForCurrentYear.filter(task => task.preemtable === false);
    const intervals = {};
    const map = {};
    tasks.forEach(t => {
      const start = t.starts_at;
      const end = t.ends_at;
      const time = `${start}?${end}`;
      if (time in map) {
        map[time] += 1;
      } else {
        map[time] = 1;
      }
    });

    let i = 0;
    const sortedKeys = Object.keys(map).sort();
    sortedKeys.forEach(time => {
      const name = `i${i}`;
      const [start, end] = time.split('?');
      intervals[name] = {
        length: map[time],
        dS: 0,
        weight: undefined,
        time: {
          starts_at: start,
          ends_at: end,
        },
      };
      i += 1;
    });

    return intervals;
  }

  /** Renders the portion of the page that allows the user to change input params for the ERA algorithm and run it */
  renderERAInput = () => (
    <div className={styles['era-input']}>
      <p className={styles['nowrap-era-input']} style={{ margin: 0 }}>Number of iterations: </p>
      <TextField className={styles['era-iterations-input']} inputProps={{ type: 'number' }} variant="outlined" size="small" onChange={this.handleIterationsChange} value={this.state.iterations} />
      <p className={styles['nowrap-era-input']} style={{ margin: 0 }}>Number of steps per iteration: </p>
      <TextField className={styles['era-steps-input']} inputProps={{ type: 'number' }} variant="outlined" size="small" onChange={this.hanldeStepsChange} value={this.state.steps} />
      <ReusableButton className={styles['era-run-era-button']} value="Run ERA" onClick={this.handleClickRunERA} disabled={this.props.tasks.length === 0} />
    </div>
  );

  /**
   * renders the year dropdown with a ReusableSelect component
   * @returns <ReusableSelect /> year dropdown
   */
  renderYearOptions = () => (
    <ReusableSelect
      id="year"
      label="Event Date"
      className={styles['year-options']}
      value={this.state.year}
      options={yearOptions(this.props.eventDates)}
      onChange={(event) => this.handleYearChange(event)}
      excludeNoneOption
      useDefaultVariant
    />
  );

  /** Renders the ERA results table and additional information */
  renderERAData() {
    const {
      satisfiedTasks, unsatisfiedTasks, unassignedStatusMap, overConstrainedMap, underConstrainedMap,
    } = this.state;
    const arr = Object.keys(unassignedStatusMap).map(a => <li>{`${a}: ${unassignedStatusMap[a]}`}</li>);
    const arr2 = Object.keys(overConstrainedMap).map(a => {
      const [name, stime, etime] = a.split(',');
      const fTime = `${formatTime(stime)}-${formatTime(etime)}`;
      return <li>{`${name}, ${fTime}: ${overConstrainedMap[a]}`}</li>;
    });
    const arr3 = Object.keys(underConstrainedMap).map(a => {
      const start = underConstrainedMap[a].time.starts_at;
      const end = underConstrainedMap[a].time.ends_at;
      const amount = underConstrainedMap[a].dS;
      return <li>{`${formatTime(start)}-${formatTime(end)}: ${amount}`}</li>;
    });
    return (
      <div className={styles['era-info']}>
        <p>
          {`Number of satisfied tasks: ${satisfiedTasks}`}
        </p>
        <p>
          {`Number of unsatisfied tasks: ${unsatisfiedTasks}`}
        </p>
        <p>
          Unassigned applicants by status:
        </p>
        <ul>{arr}</ul>
        <p>Over constrained tasks [Task name, time: amount]</p>
        <ul>
          {arr2}
        </ul>
        <p>Number of people available at each interval</p>
        <ul>
          {arr3}
        </ul>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderERAInput()}
        { !this.state.eraIsLoading && (
          <div>
            <div className={styles['interactive-table-container']}>
              <TaskAssignedTable
                data={this.tasksForCurrentYear.filter(task => this.state.checkedTasks[task.id] && (this.state.beginningTaskTimeFilter && this.state.endingTaskTimeFilter ? this.taskInsideTimeFilter(task) : true))}
                isLoading={this.props.tasksLoading || this.state.eraIsLoading}
                jobSelected={this.state.jobSelected}
                assignments={this.state.taskAssignments}
                getTasks={this.props.getTasks}
                handleOpenTaskFilter={this.handleOpenTaskFilter}
                renderYearOptions={this.renderYearOptions}
                year={this.state.year}
                applicants={this.applicantsForCurrentYear.filter(applicant => this.state.checkedStatuses[applicant.status?.id] && (this.state.beginningApplicantTimeFilter && this.state.endingApplicantTimeFilter ? this.applicantInsideTimeFilter(applicant) : true))}
                taskPreferences={this.props.taskPreferences}
              />
              <ReusableSnackbar
                open={this.state.snackbarOpen}
                onClose={this.handleSnackbarClose}
                variant={this.state.snackbarVariant}
                message={this.state.snackbarMessage}
              />
              <ApplicantAssignedTable
                data={this.applicantsForCurrentYear.filter(applicant => this.state.checkedStatuses[applicant.status?.id] && (this.state.beginningApplicantTimeFilter && this.state.endingApplicantTimeFilter ? this.applicantInsideTimeFilter(applicant) : true))}
                isLoading={this.props.applicantsLoading || this.state.eraIsLoading}
                getApplicants={this.props.getApplicants}
                assignments={this.state.applicantAssignments}
                handleOpenApplicantFilter={this.handleOpenApplicantFilter}
                applicants={this.applicantMapForCurrentYear}
                renderYearOptions={this.renderYearOptions}
                tasks={this.tasksForCurrentYear.filter(task => this.state.checkedTasks[task.id] && (this.state.beginningTaskTimeFilter && this.state.endingTaskTimeFilter ? this.taskInsideTimeFilter(task) : true))}
              />

              <TaskTableFilterDialog
                taskFilterOpen={this.state.taskFilterOpen}
                checkedTasks={this.state.checkedTasks}
                handleSelectNoneTasks={this.handleSelectNoneTasks}
                handleSelectAllTasks={this.handleSelectAllTasks}
                activitiesLoading={this.props.activitiesLoading}
                activities={this.props.activities}
                tasks={this.props.tasks}
                handleTaskCheckbox={this.handleTaskCheckbox}
                handleClearTaskTimeFilter={this.handleClearTaskTimeFilter}
                beginningTaskTimeFilter={this.state.beginningTaskTimeFilter}
                handleBeginningTaskTimeChange={this.handleBeginningTaskTimeChange}
                endingTaskTimeFilter={this.state.endingTaskTimeFilter}
                handleEndingTaskTimeChange={this.handleEndingTaskTimeChange}
                handleCloseTaskFilter={this.handleCloseTaskFilter}
                year={this.state.year}
              />

              <ApplicantTableFilterDialog
                applicantFilterOpen={this.state.applicantFilterOpen}
                checkedStatuses={this.state.checkedStatuses}
                handleSelectNoneStatuses={this.handleSelectNoneStatuses}
                handleSelectAllStatuses={this.handleSelectAllStatuses}
                statusesLoading={this.props.statusesLoading}
                statuses={this.props.statuses}
                handleStatusCheckbox={this.handleStatusCheckbox}
                handleClearApplicantTimeFilter={this.handleClearApplicantTimeFilter}
                beginningApplicantTimeFilter={this.state.beginningApplicantTimeFilter}
                handleBeginningApplicantTimeChange={this.handleBeginningApplicantTimeChange}
                endingApplicantTimeFilter={this.state.endingApplicantTimeFilter}
                handleEndingApplicantTimeChange={this.handleEndingApplicantTimeChange}
                handleCloseApplicantFilter={this.handleCloseApplicantFilter}
              />

            </div>
            <div>
              {this.renderERAData()}
            </div>
          </div>
        )}
      </div>
    );
  }
}

ERAPage.propTypes = {
  applicantsLoading: PropTypes.bool.isRequired,
  tasksLoading: PropTypes.bool.isRequired,
  activitiesLoading: PropTypes.bool.isRequired,
  statusesLoading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  getApplicants: PropTypes.func.isRequired,
  getTasks: PropTypes.func.isRequired,
  getStatuses: PropTypes.func.isRequired,
  getActivities: PropTypes.func.isRequired,
  applicants: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  taskPreferences: PropTypes.array.isRequired,
  getTaskPreferences: PropTypes.func.isRequired,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  getYear: PropTypes.func.isRequired,
  statuses: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  eventDates: PropTypes.array.isRequired,
};

export default ERAPage;
