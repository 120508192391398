import { connect } from 'react-redux';

import { selectLoading, selectUsers, selectGenderOptions } from 'selectors/usersSelectors';
import {
  getUsers,
  createUserFromTable,
  updateUserFromTable,
  downloadUsers,
} from 'actions/thunks/users';
import UsersPage from 'components/pages/admin/UsersPage';
import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';

const mapStateToProps = state => ({
  loading: selectLoading(state),
  users: selectUsers(state),
  isAdmin: selectIsAdmin(state),
  isLoggedIn: selectIsLoggedIn(state),
  genderOptions: selectGenderOptions(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => dispatch(getUsers()),
  createUser: (newUser) => dispatch(createUserFromTable(newUser)),
  updateUser: (updatedUser) => dispatch(updateUserFromTable(updatedUser)),
  downloadUsers: () => dispatch(downloadUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersPage);
