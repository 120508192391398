import React from 'react';
import PropTypes from 'prop-types';

import ReusableTable from 'components/common/ReusableTable';
import ReusableSelect from 'components/common/ReusableSelect';
import { formatDate, formatTime } from 'utils/date';
import styles from './style.module.scss';
import { yearOptions } from '../../pages/admin/AdminUtils.ts';

const preferenceMap = {
  'preferred': 4,
  'acceptable': 3,
  'avoid': 2,
  'cantdo': 1,
};

class ApplicantsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      year: this.props.year,
      columns: [
        {
          title: 'Last Name',
          field: 'user.last_name',
          tooltip: 'The last name of the applicant.',
          defaultSort: 'asc',
        },
        {
          title: 'First Name',
          field: 'user.first_name',
          tooltip: 'The first name of the applicant.',
        },
        {
          title: 'Status',
          field: 'status.abbr',
          tooltip: 'The status of the applicant.',
          lookup: this.statusLookup,
        },
        {
          title: 'Affiliation',
          field: 'affiliation.name',
          tooltip: 'The department/institution that the applicant is affiliated with.',
          lookup: this.affiliationLookup,
        },
        {
          title: 'Preferred',
          tooltip: 'The tasks that the applicant prefers.',
          render: (rowData) => this.renderTaskPreference(preferenceMap['preferred'], rowData.task_preferences),
        },
        {
          title: 'Acceptable',
          tooltip: 'The tasks that the applicant considers acceptable.',
          render: (rowData) => this.renderTaskPreference(preferenceMap['acceptable'], rowData.task_preferences),
        },
        {
          title: 'Avoid',
          tooltip: 'The tasks that the applicant would rather avoid.',
          render: (rowData) => this.renderTaskPreference(preferenceMap['avoid'], rowData.task_preferences),
        },
        {
          title: 'Can\'t Do',
          tooltip: 'The tasks that the applicant cannot do.',
          render: (rowData) => this.renderTaskPreference(preferenceMap['cantdo'], rowData.task_preferences),
        },
        {
          title: 'Time Windows',
          tooltip: 'The time windows that the applicant entered.',
          render: (rowData) => this.renderTimeWindows(rowData.time_windows),
        },
        {
          title: 'YASP',
          field: 'youth_form_date',
          type: 'date',
          tooltip: 'The date that the applicant filled out the YASP form.',
          render: (applicant) => (<div className={styles['nowrap']}>{formatDate(applicant.youth_form_date)}</div>),
        },
        {
          title: 'SOR Check',
          field: 'sor_check',
          type: 'date',
          tooltip: 'The date that the applicant was checked against the SOR.',
          render: (applicant) => (<div className={styles['nowrap']}>{formatDate(applicant.sor_check)}</div>),
        },
        {
          title: 'Phone',
          field: 'user.phone_number',
          tooltip: 'The phone number of the applicant.',
          render: (applicant) => (<div className={styles['nowrap']}>{applicant.user.phone_number}</div>),
        },
        {
          title: 'Email',
          field: 'user.email',
          tooltip: 'The email of the applicant.',
        },
      ],
      options: {
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 700,
        selection: true,
        hideDeleteButton: true,
      },
      editable: {
        isEditable: () => true,
        isDeletable: () => false,
        onRowAdd: () => new Promise((resolve) => {
          resolve();
        }),
        onRowUpdate: () => new Promise((resolve) => {
          resolve();
        }),
        onRowDelete: () => new Promise((resolve) => {
          resolve();
        }),
      },
    };
  }

  async componentDidMount() {
    if (this.props.year === '') {
      await this.props.getYear();
    }
    if (this.props.eventDates.length === 0) {
      await this.props.getEventDates();
    }
    this.setState({ year: this.props.year });
    setTimeout(this.updateLookups, 50);
  }

  get applicantsForCurrentYear() {
    // For some reason loading the page for the first time, switching to a different page, and switching back
    // breaks the page. This fixes it.
    if (!this.state) {
      return [];
    }
    return this.props.applicants.filter(applicant => applicant.year === this.state.year);
  }

  get statusLookup() {
    const statusOptions = this.applicantsForCurrentYear.map((applicant) => applicant.status);
    return statusOptions.reduce((map, statusOption) => {
      if (statusOption) {
        map[statusOption.abbr] = statusOption.abbr; // eslint-disable-line no-param-reassign
      }
      return map;
    }, {});
  }

  get affiliationLookup() {
    const affiliationOptions = this.applicantsForCurrentYear.map((applicant) => applicant.affiliation);
    return affiliationOptions.reduce((map, affiliationOption) => {
      if (affiliationOption) {
        map[affiliationOption.name] = affiliationOption.name; // eslint-disable-line no-param-reassign
      }
      return map;
    }, {});
  }

  getColumnByField = field => {
    const { columns } = this.state;
    return columns.filter((column) => column.field === field)[0];
  };

  updateLookups = () => {
    const { columns } = this.state;
    const statusColumn = this.getColumnByField('status.abbr');
    const affiliationColumn = this.getColumnByField('affiliation.name');
    statusColumn.lookup = this.statusLookup;
    affiliationColumn.lookup = this.affiliationLookup;
    this.setState({ columns });
  };

  handleYearChange = (event) => {
    this.setState({ year: event.target.value });
    setTimeout(this.updateLookups, 50);
  };

  renderYearOptions = () => (
    <ReusableSelect
      id="year"
      label="Event Date"
      className={styles['year-options']}
      value={this.state.year}
      options={yearOptions(this.props.eventDates)}
      onChange={(event) => this.handleYearChange(event)}
      excludeNoneOption
      useDefaultVariant
    />
  );

  renderTaskPreference = (preferenceValue, taskPreferences) => {
    const filteredTaskPreferences = taskPreferences
      ? taskPreferences.filter(taskPreference => taskPreference.preference === preferenceValue)
      : [];
    if (filteredTaskPreferences.length !== 0) {
      const taskPreferencesOptions = filteredTaskPreferences.map(taskPreference => ({
        display: taskPreference.task.abbr.trim() ? taskPreference.task.abbr : taskPreference.task.name,
        value: taskPreference.task.abbr.trim() ? taskPreference.task.abbr : taskPreference.task.name,
      }));
      return (
        <ReusableSelect
          id="preferred"
          className={styles['task-preference']}
          value=""
          emptyValue={filteredTaskPreferences.length}
          options={taskPreferencesOptions}
          useDefaultVariant
          isTransparent
          displayEmpty
        />
      );
    }

    return (
      <div>
        -
      </div>
    );
  };

  renderTimeWindows = (timeWindows) => {
    const filteredTimeWindows = timeWindows
      ? timeWindows.filter(timeWindow => timeWindow.year === this.state.year)
      : [];
    if (filteredTimeWindows.length !== 0) {
      const timeWindowOptions = filteredTimeWindows.map(timeWindow => ({
        display: `${formatTime(timeWindow.starts_at)}-${formatTime(timeWindow.ends_at)}`,
        value: `${formatTime(timeWindow.starts_at)}-${formatTime(timeWindow.ends_at)}`,
      }));
      return (
        <ReusableSelect
          id="preferred"
          className={styles['task-preference']}
          value=""
          emptyValue={filteredTimeWindows.length}
          options={timeWindowOptions}
          useDefaultVariant
          isTransparent
          displayEmpty
        />
      );
    }

    return (
      <div>
        -
      </div>
    );
  };

  render() {
    return (
      <ReusableTable
        title="Applicants"
        columns={this.state.columns}
        data={this.applicantsForCurrentYear}
        options={this.state.options}
        editable={this.state.editable}
        isLoading={this.props.loading}
        actions={this.state.actions}
        renderYearOptions={this.renderYearOptions}
      />
    );
  }
}

ApplicantsTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  applicants: PropTypes.array.isRequired,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  eventDates: PropTypes.array.isRequired,
  getYear: PropTypes.func.isRequired,
  getEventDates: PropTypes.func.isRequired,
};

export default ApplicantsTable;
