export const GET_EMAIL_TEMPLATES_SENT = 'GET_EMAIL_TEMPLATES_SENT';
export const GET_EMAIL_TEMPLATES_SUCCESSFUL = 'GET_EMAIL_TEMPLATES_SUCCESSFUL';

export const UPDATE_EMAIL_TEMPLATE_SENT = 'UDPATE_EMAIL_TEMPLATE_SENT';
export const UPDATE_EMAIL_TEMPLATE_SUCCESSFUL = 'UPDATE_EMAIL_TEMPLATE_SUCCESSFUL';

export const CREATE_EMAIL_TEMPLATE_SENT = 'CREATE_EMAIL_TEMPLATE_SENT';
export const CREATE_EMAIL_TEMPLATE_SUCCESSFUL = 'CREATE_EMAIL_TEMPLATE_SUCCESSFUL';

export const DELETE_EMAIL_TEMPLATE_SENT = 'DELETE_EMAIL_TEMPLATE_SENT';
export const DELETE_EMAIL_TEMPLATE_SUCCESSFUL = 'DELETE_EMAIL_TEMPLATE_SUCCESSFUL';

export const GET_EMAIL_MESSAGES_SENT = 'GET_EMAIL_MESSAGES_SENT';
export const GET_EMAIL_MESSAGES_SUCCESSFUL = 'GET_EMAIL_MESSAGES_SUCCESSFUL';

export const CREATE_EMAIL_MESSAGE_SENT = 'CREATE_EMAIL_MESSAGE_SENT';
export const CREATE_EMAIL_MESSAGE_SUCCESSFUL = 'CREATE_EMAIL_MESSAGE_SUCCESSFUL';

export const SET_APPLICANTS_SELECTED = 'SET_APPLICANTS_SELECTED';

export const SET_RECRUITERS_SELECTED = 'SET_RECRUITERS_SELECTED';

export const getEmailTemplatesRequestSent = () => ({ type: GET_EMAIL_TEMPLATES_SENT });

export const getEmailTemplatesRequestSuccessful = (data) => ({ type: GET_EMAIL_TEMPLATES_SUCCESSFUL, data });

export const updateEmailTemplateRequestSent = () => ({ type: UPDATE_EMAIL_TEMPLATE_SENT });

export const updateEmailTemplateRequestSuccessful = (data) => ({ type: UPDATE_EMAIL_TEMPLATE_SUCCESSFUL, data });

export const createEmailTemplateRequestSent = () => ({ type: CREATE_EMAIL_TEMPLATE_SENT });

export const createEmailTemplateRequestSuccessful = (data) => ({ type: CREATE_EMAIL_TEMPLATE_SUCCESSFUL, data });

export const deleteEmailTemplateRequestSent = (data) => ({ type: DELETE_EMAIL_TEMPLATE_SENT, data });

export const deleteEmailTemplateRequestSuccessful = (data) => ({ type: DELETE_EMAIL_TEMPLATE_SUCCESSFUL, data });

export const getEmailMessagesRequestSent = () => ({ type: GET_EMAIL_MESSAGES_SENT });

export const getEmailMessagesRequestSuccessful = (data) => ({ type: GET_EMAIL_MESSAGES_SUCCESSFUL, data });

export const createEmailMessagesRequestSent = () => ({ type: CREATE_EMAIL_MESSAGE_SENT });

export const createEmailMessagesRequestSuccessful = (data) => ({ type: CREATE_EMAIL_MESSAGE_SUCCESSFUL, data });

export const setApplicantsSelectedSent = (data) => ({ type: SET_APPLICANTS_SELECTED, data });

export const setRecruitersSelectedSent = (data) => ({ type: SET_RECRUITERS_SELECTED, data });
