import { connect } from 'react-redux';

import AboutPage from 'components/pages/AboutPage';

const mapStateToProps = () => ({

});

const mapDispatchToProps = () => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AboutPage);
