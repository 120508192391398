import React from 'react';
import PropTypes from 'prop-types';
import ReusableTextField from 'components/common/ReusableTextField';
import ReusableSelect from 'components/common/ReusableSelect';
import ReusableButton from 'components/common/ReusableButton';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import styles from './style.module.scss';

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameError: false,
      receiver: '',
      receiverError: false,
      receiverOptions: [
        {
          value: 'mathday@unl.edu',
          display: 'Math Day administrator: Questions/Comments',
        },
        {
          value: 'mathday@cse.unl.edu',
          display: 'Technical support: Website issues',
        },
      ],
      subject: '',
      subjectError: false,
      message: '',
      messageError: false,
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: '',
      replyEmail: '',
      replyEmailError: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.emailSuccessful && this.props.emailSuccessful) {
      this.setState({ snackbarVariant: 'success', snackbarOpen: true, snackbarMessage: 'Email Sent' });
      this.clearFields();
    }
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });

    if (name === 'receiver') {
      this.setState({ receiverError: false });
    }
    if (name === 'name') {
      this.setState({ nameError: false });
    }
    if (name === 'subject') {
      this.setState({ subjectError: false });
    }
    if (name === 'message') {
      this.setState({ messageError: false });
    }
    if (name === 'replyEmail') {
      this.setState({ replyEmailError: false });
    }
  };

  validateFields = () => {
    let errorMessage = '';
    if (this.state.receiver === '') {
      errorMessage += 'Please select an option for receiver.\n';
      this.setState({
        receiverError: true,
      });
    }
    if (this.state.name === '') {
      errorMessage += 'Name cannot be empty.\n';
      this.setState({
        nameError: true,
      });
    }
    if (this.state.subject === '') {
      errorMessage += 'Subject cannot be empty.\n';
      this.setState({
        subjectError: true,
      });
    }
    if (this.state.message === '') {
      errorMessage += 'Message cannot be empty.\n';
      this.setState({
        messageError: true,
      });
    }
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(this.state.replyEmail)) {
      errorMessage += 'Reply Email is not in a valid format or is empty.\n';
      this.setState({
        replyEmailError: true,
      });
    }

    errorMessage = errorMessage.trim();
    this.setState({ snackbarMessage: errorMessage, snackbarVariant: 'error', snackbarOpen: errorMessage !== '' });
    return errorMessage === '';
  };

  closeSnackbar = () => {
    this.setState({ snackbarOpen: false, snackbarMessage: '' });
  };

  clearFields = () => {
    this.state.receiver = '';
    this.state.name = '';
    this.state.subject = '';
    this.state.body = '';
    this.state.message = '';
    this.state.replyEmail = '';
  };

  handleSendButtonClicked = () => {
    const allFieldsValid = this.validateFields();
    if (!allFieldsValid) {
      return;
    }

    const payload = {
      receiver: this.state.receiver,
      name: this.state.name,
      subject: this.state.subject,
      message: this.state.message,
      replyEmail: this.state.replyEmail,
    };

    this.props.sendContactUsEmail(payload);
  };

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['info-container']}>
              <h1>
                Contact Math Day

              </h1>
              <ReusableSelect
                id="receiver"
                label="Receiver"
                value={this.state.receiver}
                options={this.state.receiverOptions}
                onChange={this.handleChange('receiver')}
                error={this.state.receiverError}
                required
              />
              <ReusableTextField
                id="name"
                label="Your Name"
                value={this.state.name}
                onChange={this.handleChange('name')}
                error={this.state.nameError}
                required
              />
              <ReusableTextField
                id="subject"
                label="Subject"
                value={this.state.subject}
                onChange={this.handleChange('subject')}
                error={this.state.subjectError}
                required
              />
              <ReusableTextField
                id="message"
                label="Message"
                value={this.state.message}
                onChange={this.handleChange('message')}
                error={this.state.messageError}
                multiline="true"
                required
              />
              <ReusableTextField
                id="replyEmail"
                label="Your Email Address"
                value={this.state.replyEmail}
                onChange={this.handleChange('replyEmail')}
                error={this.state.replyEmailError}
                required
              />
              <ReusableButton
                value="Send Message"
                hasTopMargin
                onClick={this.handleSendButtonClicked}
              />
              <ReusableSnackbar
                open={this.state.snackbarOpen}
                onClose={this.closeSnackbar}
                variant={this.state.snackbarVariant}
                message={this.state.snackbarMessage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContactPage.propTypes = {
  sendContactUsEmail: PropTypes.func.isRequired,
  emailSuccessful: PropTypes.bool.isRequired,
};

export default ContactPage;
