import { fromJS } from 'immutable';

import {
  CREATE_SOLUTION_ASSIGNMENT_SENT,
  CREATE_SOLUTION_ASSIGNMENT_SUCCESSFUL,
  GET_SOLUTION_ASSIGNMENTS_SENT,
  GET_SOLUTION_ASSIGNMENTS_SUCCESSFUL,
  UPDATE_SOLUTION_ASSIGNMENT_SENT,
  UPDATE_SOLUTION_ASSIGNMENT_SUCCESSFUL,
  DELETE_SOLUTION_ASSIGNMENT_SENT,
  DELETE_SOLUTION_ASSIGNMENT_SUCCESSFUL,
} from 'actions/atomic/solutionAssignments';

const initialState = fromJS({
  loading: false,
  data: [],
});

export default function solutionAssignments(state = initialState, action) {
  switch (action.type) {
  case GET_SOLUTION_ASSIGNMENTS_SENT:
    return state.merge({
      loading: true,
      data: fromJS([]),
    });
  case GET_SOLUTION_ASSIGNMENTS_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: fromJS(action.data),
    });
  case CREATE_SOLUTION_ASSIGNMENT_SENT:
    return state.merge({
      loading: true,
    });
  case CREATE_SOLUTION_ASSIGNMENT_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: state.get('data').push(fromJS(action.data)),
    });
  case UPDATE_SOLUTION_ASSIGNMENT_SENT:
    return state.set('loading', true);
  case UPDATE_SOLUTION_ASSIGNMENT_SUCCESSFUL:
    const updatedSolutionAssignment = action.data;
    const index = state.get('data').findIndex(solutionAssignment => solutionAssignment.get('id') === parseInt(updatedSolutionAssignment.id, 10));
    return state.merge({
      loading: false,
      data: state.get('data').set(index, fromJS(updatedSolutionAssignment)),
    });
  case DELETE_SOLUTION_ASSIGNMENT_SENT:
    return state.set('loading', true);
  case DELETE_SOLUTION_ASSIGNMENT_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: state.get('data').filter((solutionAssignment => solutionAssignment.get('id') !== parseInt(action.data.id, 10))),
    });
  default:
    return state;
  }
}
