import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import ReusableSelect from 'components/common/ReusableSelect';

import styles from './style.module.scss';
import { yearOptions } from '../../../pages/admin/AdminUtils.ts';

class ActivitySidebar extends React.Component {
  render() {
    return (
      <div className={styles['sidebar']}>
        <div className={styles['row-space']}>
          <Typography className={styles['year']} variant="body1">Event:</Typography>
          <ReusableSelect
            id="yearSelector"
            className={styles['year-options']}
            value={`${this.props.year}`}
            onChange={this.props.handleYearChange}
            options={yearOptions(this.props.eventDates)}
            excludeNoneOption
          />
        </div>
        <div className={`${styles['title-container']} ${styles['row-space']}`}>
          <Typography className={styles['sidebar-headers']} align="left" variant="body2">
            Activity
          </Typography>
          <Typography className={styles['sidebar-headers']} align="right" variant="body2">
            Task
          </Typography>
        </div>
        <List className={styles['activity-list']}>
          {this.props.isLoading ? <></> : this.props.activities.map((activity) => (
            <>
              <ListItem
                button
                className={styles['tight-item']}
                value={activity.id}
                key={activity.id}
                style={{ background: `${activity.background_color}` }}
              >
                <ListItemText onClick={() => this.props.handleActivityClick(activity.id)} style={{ color: `${activity.text_color}` }} primary={activity.name} />
                {this.props.openedActivities[activity.id] ? <ExpandLess onClick={() => this.props.handleExpandActivities(activity.id)} style={{ fill: `${activity.text_color}` }} /> : <ExpandMore onClick={() => this.props.handleExpandActivities(activity.id)} style={{ fill: `${activity.text_color}` }} />}
              </ListItem>
              <Collapse in={this.props.openedActivities[activity.id]}>
                {this.props.tasks.filter((task) => task.activity_id === activity.id).map((task) => (
                  <ListItem
                    key={task.id}
                    button
                    className={styles['tight-item']}
                    onClick={() => this.props.handleTaskClick(task.id)}
                    style={{ background: `${task.background_color}` }}
                  >
                    <ListItemText style={{ textAlign: 'right', color: `${task.text_color}` }} primary={task.name} />
                  </ListItem>
                ))}
              </Collapse>
            </>
          ))}
        </List>
      </div>
    );
  }
}

ActivitySidebar.propTypes = {
  handleTaskClick: PropTypes.func.isRequired,
  handleActivityClick: PropTypes.func.isRequired,
  handleExpandActivities: PropTypes.func.isRequired,
  handleYearChange: PropTypes.func.isRequired,
  activities: PropTypes.array.isRequired,
  openedActivities: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  year: PropTypes.number.isRequired,
  eventDates: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
};

export default ActivitySidebar;
