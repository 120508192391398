import React from 'react';
import PropTypes from 'prop-types';

import AnnouncementsBar from 'components/common/AnnouncementsBar';
import ReusableButton from 'components/common/ReusableButton';
import history from 'wrappers/history';
import mathDayLogo from 'assets/logos/mathday-logo.png';
import dayjs from 'dayjs';
import styles from './style.module.scss';

class HomePage extends React.Component {
  async componentDidMount() {
    if (this.props.motd === '') {
      this.props.getMOTD();
    }
    if (this.props.announcements.length === 0) {
      await this.props.getAnnouncements();
    }
  }

  filterAnnouncements(announcements) {
    const today = dayjs().format('YYYY-MM-DD');
    const visibleAnnouncements = announcements.filter((announcement) => (
      today >= announcement.start_at && announcement.end_at > today));

    return visibleAnnouncements.sort((a, b) => (a.start_at < b.start_at ? 1 : -1));
  }

  render() {
    return (
      <div id="maincontent">
        <AnnouncementsBar announcements={this.filterAnnouncements(this.props.announcements)} />
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['info-container']}>
              <div className={styles['motd-container']}>
                <h2>
                  Math Day Volunteers Site
                </h2>
                <hr className={styles['divider']} />
                {this.props.motd}
                {!this.props.isLoggedIn
                && (
                  <div className={styles['buttons-container']}>
                    <ReusableButton
                      value="Login"
                      hasTopMargin
                      className={styles['login-button']}
                      onClick={() => history.push('/login')}
                    />
                    <ReusableButton
                      value="Create New Account"
                      hasTopMargin
                      onClick={() => history.push('/create-account')}
                    />
                  </div>
                )}
              </div>
              <div className={styles['logo-container']}>
                <img className={styles['logo']} src={mathDayLogo} alt="Math Day" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomePage.propTypes = {
  announcements: PropTypes.array.isRequired,
  motd: PropTypes.string.isRequired,
  getAnnouncements: PropTypes.func.isRequired,
  getMOTD: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default HomePage;
