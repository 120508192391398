class Csp {
  /** The CSP model variables passed in */
  modelVariables;
  /** The CSP model constraints passed in */
  modelConstraints;
  /** The admin's preference for which statuses do which tasks */
  taskPreferences;
  /** The map of tasks to the intervals that are inside of them */
  taskIntervalMap = {};
  /** The map of intervals to its interval characteristics */
  intervals = {};
  /** Map of an applicant to the variables they are available for and the variables that have conflicts with those variables */
  variableConflictMap = {};

  constructor(cspModel, taskPreferences) {
    this.modelVariables = cspModel.modelVariables;
    this.modelConstraints = cspModel.modelConstraints;
    this.taskIntervalMap = cspModel.taskIntervalMap;
    this.intervals = cspModel.intervals;
    this.variableConflictMap = cspModel.variableConflictMap;
    this.taskPreferences = taskPreferences;
  }

  /**
   * Creates the variables for the CSP
   * @returns The variables and a map of the variable name to the variable
   */
  #createVariables() {
    const variables = [];
    const variableMap = new Map();
    let v = {};
    let domain = [];
    this.modelVariables.forEach(variable => {
      domain = [];
      if (variable.task.name === 'Main Command Center Coordinator') {
        const stephanie = variable.domain.filter(d => d.vName === 'Staff-1');
        domain.push(stephanie[0]);
      } else {
        variable.domain.forEach(d => {
          domain.push(d);
        });
      }
      v = {
        varRef: variable,
        variableName: variable.name,
        initialDomain: variable.domain,
        currentDomain: domain,
        constraints: [],
        neighbors: [],
        taskName: variable.task.name,
        taskYear: variable.task.year,
        startTime: variable.task.starts_at,
        endTime: variable.task.ends_at,
        isBackup: variable.isBackup,
        id: variable.task.id,
      };
      variables.push(v);
      variableMap.set(variable.name, v);
    });
    return { variables, variableMap };
  }

  /**
   * Creates the constraints for the CSP instance
   * @returns The constraints for the CSP instance
   */
  #createConstraints() {
    const constraints = [];
    this.modelConstraints.forEach(constraint => {
      constraints.push({
        conRef: constraint,
        name: constraint.name,
        variables: [],
        definition: constraint.reference,
      });
    });
    return constraints;
  }

  /**
   * Creates the CSP instance
   * @returns The CSP instance containing the variables, variableMap, constraints, constraintMap, taskPreferences, intervals, taskIntervalMap
   */
  createCSPInstance() {
    const {
      taskPreferences, intervals, taskIntervalMap, variableConflictMap,
    } = this;
    const { variables, variableMap } = this.#createVariables(this.modelVariables);
    const constraints = this.#createConstraints(this.modelConstraints);
    const constraintMap = new Map();

    constraints.forEach(constraint => {
      const splitVariables = constraint.conRef.scope.split(' ');
      for (let i = 0; i < constraint.conRef.arity; i++) {
        const varAdd = variableMap.get(splitVariables[i]);
        varAdd.constraints.push(constraint);
        for (let j = 0; j < constraint.conRef.arity; j++) {
          if (i !== j) {
            varAdd.neighbors.push(variableMap.get(splitVariables[j]));
          }
        }

        constraint.variables.push(varAdd);
        if (constraint.conRef.arity === 2) {
          constraintMap.set(`${splitVariables[0]} ${splitVariables[1]}`, constraint);
        }
      }
    });

    return {
      variables, variableMap, constraints, constraintMap, taskPreferences, intervals, taskIntervalMap, variableConflictMap,
    };
  }
}

export default Csp;
