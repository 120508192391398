import React from 'react';
import PropTypes from 'prop-types';
import ReusableSelect from 'components/common/ReusableSelect';
import ReusableTable from 'components/common/ReusableTable';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import history from 'wrappers/history';
import styles from './style.module.scss';
import { yearOptions } from '../AdminUtils.ts';

class TaskPreferencesPage extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }

    this.state = {
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: '',
      year: this.props.year,
      options: {
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 600,
        selection: false,
        hideAddButton: true,
        hideDeleteButton: true,
      },
      editable: {
        isEditable: () => true,
        isDeletable: () => false,
        onRowUpdate: (updatedTaskPreference) => new Promise((resolve, reject) => {
          if (this.validateTaskPreference(updatedTaskPreference)) {
            this.handleUpdateTaskPreference(updatedTaskPreference);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'success',
              snackbarMessage: `Status ${updatedTaskPreference.task.name} successfully updated.`,
            });
            resolve();
          } else {
            this.setState({
              snackbarVariant: 'error',
              snackbarOpen: true,
            });
            reject();
          }
        }),
        onRowDelete: () => new Promise((resolve) => {
          resolve();
        }),
      },
      actions: [
        {
          icon: 'replay',
          tooltip: 'Refetch Data',
          isFreeAction: true,
          onClick: () => this.props.getTaskPreferences(),
        },
        // {
        //   icon: 'save_alt',
        //   tooltip: 'Export',
        //   isFreeAction: true,
        //   onClick: () => {
        //     this.props.downloadStatuses();
        //     this.setState({
        //       snackbarOpen: true,
        //       snackbarVariant: 'info',
        //       snackbarMessage: 'Download in progress.',
        //     });
        //   },
        // },
      ],
    };
  }

  async componentDidMount() {
    if (this.props.year === '') {
      await this.props.getYear();
    }
    if (this.props.statuses.length === 0) {
      await this.props.getStatuses();
    }
    if (this.props.taskPreferences.length === 0) {
      await this.props.getTaskPreferences();
    }
    if (this.props.tasks.length === 0) {
      await this.props.getTasks();
    }
    this.setState({ year: this.props.year });
  }

  handleUpdateTaskPreference(row) {
    const statusMap = {};
    this.props.statuses.filter(status => status.active && status.approved).forEach((status) => {
      statusMap[status.name] = status.id;
    });
    const updateList = [];
    Object.keys(row).forEach(statusName => {
      const statusId = statusMap[statusName];
      if (statusId === undefined) {
        return;
      }
      updateList.push({
        status_id: statusId,
        task_id: row.task.id,
        preference: row[statusName],
      });
    });
    this.props.updateTaskPreference(updateList);
  }

  /**
   * change the value of the year in the state to be the year selected in the dropdown
   * @param {*} event
   */
  handleYearChange(event) {
    this.setState({ year: event.target.value });
  }

  /**
   * get task preferences where the task year is the same as the current year
   */
  get taskPreferencesForCurrentYear() {
    if (!this.state) {
      return [];
    }
    const taskYearMap = {};
    this.props.tasks.forEach(task => { taskYearMap[task.id] = task.year; });
    return this.props.taskPreferences.filter(
      taskPreference => taskYearMap[taskPreference.task_id] === this.state.year
    );
  }

  get tasksForCurrentYear() {
    if (!this.state) {
      return [];
    }
    const yearInt = parseInt(this.state.year, 10);
    return this.props.tasks.filter(task => task.year === yearInt);
  }

  get taskPreferencesForCurrentYearTableData() {
    const taskMap = {};
    this.tasksForCurrentYear.forEach((task) => {
      taskMap[task.id] = task;
    });

    const statusMap = {};
    this.props.statuses.filter(status => status.active && status.approved).forEach((status) => {
      statusMap[status.id] = status.name;
    });
    const resultMap = {};
    const result = [];
    this.taskPreferencesForCurrentYear.forEach(taskPref => {
      let obj = resultMap[taskPref.task_id];
      if (obj === undefined) {
        obj = {
          task: taskMap[taskPref.task_id],
        };
        resultMap[taskPref.task_id] = obj;
        result.push(obj);
      }
      const statusName = statusMap[taskPref.status_id];
      if (statusName !== undefined) {
        obj[statusName] = taskPref.preference;
      }
    });
    return result;
  }

  get tableColumns() {
    const columns = [
      {
        title: 'Task',
        field: 'task.name',
        tooltip: 'The name of the task',
        editable: 'never',
        render: (rowData) => rowData.task.abbr.trim() || rowData.task.name,
      },
    ];
    if (this.props.statuses) {
      this.props.statuses.filter(status => status.active && status.approved).sort((a, b) => a.priority - b.priority).forEach(status => {
        columns.push({
          title: status.abbr || status.name,
          field: status.name,
          type: 'numeric',
          align: 'right',
          tooltip: `Preference for applicants with status of ${status.name}`,
        });
      });
    }
    return columns;
  }

  validateTaskPreference = (payload) => {
    let validated = true;
    let errors = '';
    if (!payload.task.name) {
      validated = false;
      errors += 'Please choose a task name\n';
    }
    this.props.statuses.forEach((status) => {
      const pref = payload[status.name];
      if (pref === undefined) {
        return;
      }
      if (pref < 0 || pref > 10) {
        validated = false;
        errors += `Please insert an integer between 0 and 10 for ${status.name}\n`;
      }
    });
    if (!validated) {
      this.setState({
        snackbarMessage: errors,
      });
    }
    return validated;
  };

  getTaskPreferenceWithPriority = (priority) => {
    let statusWithPriority;
    this.props.taskPreferences.forEach(tp => {
      if (tp.priority === priority) {
        statusWithPriority = tp;
      }
    });
    return statusWithPriority;
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  /**
   * renders the year dropdown with a ReusableSelect component
   * @returns <ReusableSelect /> year dropdown
   */
  renderYearOptions = () => (
    <ReusableSelect
      id="year"
      label="Event Date"
      className={styles['year-options']}
      value={this.state.year}
      options={yearOptions(this.props.eventDates)}
      onChange={(event) => this.handleYearChange(event)}
      excludeNoneOption
      useDefaultVariant
    />
  );

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['table-container']}>
              <ReusableTable
                title="Task Preferences"
                columns={this.tableColumns}
                data={this.taskPreferencesForCurrentYearTableData}
                options={this.state.options}
                editable={this.state.editable}
                isLoading={this.props.taskPreferencesLoading}
                actions={this.state.actions}
                renderYearOptions={this.renderYearOptions}
              />
              <ReusableSnackbar
                open={this.state.snackbarOpen}
                onClose={this.handleSnackbarClose}
                variant={this.state.snackbarVariant}
                message={this.state.snackbarMessage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TaskPreferencesPage.propTypes = {
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  taskPreferences: PropTypes.array.isRequired,
  taskPreferencesLoading: PropTypes.bool.isRequired,
  updateTaskPreference: PropTypes.func.isRequired,
  getTaskPreferences: PropTypes.func.isRequired,
  getYear: PropTypes.func.isRequired,
  // downloadTaskPreferences: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  eventDates: PropTypes.array.isRequired,
  getStatuses: PropTypes.func.isRequired,
  statuses: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  getTasks: PropTypes.func.isRequired,
};

export default TaskPreferencesPage;
