import React from 'react';
import PropTypes from 'prop-types';

import ReusableButton from 'components/common/ReusableButton';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableTextField from 'components/common/ReusableTextField';
import history from 'wrappers/history';
import styles from './style.module.scss';

class PasswordResetRequestPage extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.isLoggedIn) {
      history.push('/');
    }

    this.state = {
      email: '',
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleRequestResetClicked = () => {
    const payload = {
      email: this.state.email,
    };

    this.props.requestPasswordReset(payload);
  };

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['password-reset-request-form-container']}>
              <h2 className={styles['page-header']}>
                Reset Password
              </h2>
              {!this.props.passwordResetEmailSent
              && (
                <>
                  <ReusableTextField
                    id="email"
                    label="Email"
                    value={this.state.email}
                    onChange={this.handleChange('email')}
                  />
                  <ReusableButton
                    value="Request Password Reset"
                    hasTopMargin
                    onClick={this.handleRequestResetClicked}
                  />
                  <ReusableSnackbar
                    open={this.props.userNotFound}
                    onClose={this.props.resetUserNotFound}
                    variant="error"
                    message="This email is either unactivated or is not associated with an account."
                  />
                </>
              )}
              {this.props.passwordResetEmailSent
              && (
                <div className={styles['message-container']}>
                  <div className={styles['message']}>
                    A password reset link has been sent to your email.
                  </div>
                  <ReusableButton
                    value="Return Home"
                    hasTopMargin
                    onClick={() => history.push('/')}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PasswordResetRequestPage.propTypes = {
  requestPasswordReset: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  passwordResetEmailSent: PropTypes.bool.isRequired,
  userNotFound: PropTypes.bool.isRequired,
  resetUserNotFound: PropTypes.func.isRequired,
};

export default PasswordResetRequestPage;
