import { fromJS } from 'immutable';

import {
  GET_CLASSES_SENT,
  GET_CLASSES_SUCCESSFUL,
  UPDATE_CLASS_SENT,
  UPDATE_CLASS_SUCCESSFUL,
  CREATE_CLASS_SENT,
  CREATE_CLASS_SUCCESSFUL,
} from 'actions/atomic/classes';

const initialState = fromJS({
  loading: false,
  data: [],
});

export default function classes(state = initialState, action) {
  switch (action.type) {
  case GET_CLASSES_SENT:
    return state.merge({
      loading: true,
      data: fromJS([]),
    });
  case GET_CLASSES_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: fromJS(action.data),
    });
  case CREATE_CLASS_SENT:
    return state.set('loading', true);
  case CREATE_CLASS_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: state.get('data').push(fromJS(action.data)),
    });
  case UPDATE_CLASS_SENT:
    return state.set('loading', true);
  case UPDATE_CLASS_SUCCESSFUL:
    const updatedClass = action.data;
    const index = state.get('data').findIndex(mathClass => mathClass.get('id') === updatedClass.id);
    return state.merge({
      loading: false,
      data: state.get('data').set(index, fromJS(updatedClass)),
    });
  default:
    return state;
  }
}
