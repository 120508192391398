import React from 'react';
import PropTypes from 'prop-types';

import ReusableTable from 'components/common/ReusableTable';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableTextFieldValidator from 'components/common/ReusableTextFieldValidator';
import history from 'wrappers/history';
import { Select, MenuItem } from '@material-ui/core';
import { isFieldEmpty, fields } from 'utils/validation';
import styles from './style.module.scss';

class UsersPage extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }

    this.state = {
      lastNameError: false,
      firstNameError: false,
      emailError: false,
      phoneNumberError: false,
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: '',
      columns: [
        {
          title: 'Last Name',
          field: 'last_name',
          editComponent: (lastName) => (
            <ReusableTextFieldValidator
              text={lastName}
              onChange={(event) => lastName.onChange(event.target.value)}
              regExp={fields.lastName.regExp}
              error={this.state.lastNameError}
              onError={(error) => this.setState({ lastNameError: error })}
            />
          ),
        },
        {
          title: 'First Name',
          field: 'first_name',
          editComponent: (firstName) => (
            <ReusableTextFieldValidator
              text={firstName}
              onChange={(event) => firstName.onChange(event.target.value)}
              regExp={fields.firstName.regExp}
              error={this.state.firstNameError}
              onError={(error) => this.setState({ firstNameError: error })}
            />
          ),
        },
        {
          title: 'Admin',
          field: 'admin',
          type: 'boolean',
        },
        {
          title: 'Email',
          field: 'email',
          editComponent: (email) => (
            <ReusableTextFieldValidator
              text={email}
              onChange={(event) => email.onChange(event.target.value)}
              regExp={fields.email.regExp}
              error={this.state.emailError}
              onError={(error) => this.setState({ emailError: error })}
            />
          ),
        },
        {
          title: 'Phone',
          field: 'phone_number',
          editComponent: (phoneNumber) => (
            <ReusableTextFieldValidator
              text={phoneNumber}
              onChange={(event) => phoneNumber.onChange(event.target.value)}
              regExp={fields.phoneNumber.regExp}
              error={this.state.phoneNumberError}
              onError={(error) => this.setState({ phoneNumberError: error })}
            />
          ),
        },
        {
          title: 'Gender',
          field: 'gender',
          initialEditValue: 'Prefer not to answer',
          lookup: this.props.genderOptions.reduce((map, genderOption) => {
            map[genderOption.value] = genderOption.display; // eslint-disable-line no-param-reassign
            return map;
          }, {}),
          editComponent: (gender) => (
            <Select
              value={gender.value}
              onChange={(event) => gender.onChange(event.target.value)}
            >
              {this.props.genderOptions.map((genderOption) => (
                <MenuItem key={genderOption.value} value={genderOption.value}>{genderOption.display}</MenuItem>
              ))}
            </Select>
          ),
        },
      ],
      options: {
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 600,
        selection: true,
        hideDeleteButton: true,
      },
      editable: {
        isEditable: () => true,
        isDeletable: () => false,
        onRowAdd: (newUser) => new Promise((resolve, reject) => {
          const errorMessage = this.validateUserFields(newUser);
          if (errorMessage === '') {
            this.props.createUser(newUser);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'success',
              snackbarMessage: `User ${newUser.first_name} ${newUser.last_name} successfully added.`,
            });
            resolve();
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: errorMessage,
            });
            reject();
          }
        }),
        onRowUpdate: (updatedUser) => new Promise((resolve, reject) => {
          const errorMessage = this.validateUserFields(updatedUser);
          if (errorMessage === '') {
            this.props.updateUser(updatedUser);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'success',
              snackbarMessage: `User ${updatedUser.first_name} ${updatedUser.last_name} successfully updated.`,
            });
            resolve();
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: errorMessage,
            });
            reject();
          }
        }),
        onRowDelete: () => new Promise((resolve) => {
          resolve();
        }),
      },
      actions: [
        {
          icon: 'replay',
          tooltip: 'Refetch Data',
          isFreeAction: true,
          onClick: () => this.props.getUsers(),
        },
        {
          icon: 'email',
          tooltip: 'Email Selected Users',
          displayDuringSelect: true,
          onClick: (event, data) => console.log(data),
        },
        {
          icon: 'save_alt',
          tooltip: 'Export',
          isFreeAction: true,
          onClick: () => {
            this.props.downloadUsers();
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'info',
              snackbarMessage: 'Download in progress.',
            });
          },
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.users.length === 0) {
      this.props.getUsers();
    }
  }

  validateUserFields = (user) => {
    let errorMessage = '';

    if (this.state.lastNameError || isFieldEmpty(user.last_name)) {
      errorMessage += fields.lastName.errorMessage;
    }
    if (this.state.firstNameError || isFieldEmpty(user.first_name)) {
      errorMessage += fields.firstName.errorMessage;
    }
    if (this.state.emailError || isFieldEmpty(user.email)) {
      errorMessage += fields.email.errorMessage;
    }
    if (this.state.phoneNumberError || isFieldEmpty(user.phone_number)) {
      errorMessage += fields.phoneNumber.errorMessage;
    }
    return errorMessage;
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['table-container']}>
              <ReusableTable
                title="Users"
                columns={this.state.columns}
                data={this.props.users}
                options={this.state.options}
                editable={this.state.editable}
                isLoading={this.props.loading}
                actions={this.state.actions}
              />
              <ReusableSnackbar
                open={this.state.snackbarOpen}
                onClose={this.handleSnackbarClose}
                variant={this.state.snackbarVariant}
                message={this.state.snackbarMessage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UsersPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  genderOptions: PropTypes.array.isRequired,
  getUsers: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  downloadUsers: PropTypes.func.isRequired,
};

export default UsersPage;
