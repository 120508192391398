import React from 'react';
import PropTypes from 'prop-types';

import ReusableButton from 'components/common/ReusableButton';
import ReusableTextField from 'components/common/ReusableTextField';
import styles from './style.module.scss';

class YaspStep extends React.Component {
  render() {
    return (
      <div className={styles['yasp-form-container']}>
        {this.props.yaspFormSigned && this.props.yaspFormSignedDate
        && (
          <div className={styles['info-text-container']}>
            <b>
              You signed the YASP form on&nbsp;
              {this.props.yaspFormSignedDate.format('MM/DD/YYYY')}
              &nbsp;at&nbsp;
              {this.props.yaspFormSignedDate.format('hh:mm A')}
              .
            </b>
          </div>
        )}
        <div className={styles['info-text-container']}>
          Please read the following Youth Activities Safety Policy (YASP) form and sign it below.
          <br />
          Completion of this form is required to volunteer for Math Day.
        </div>
        <embed
          src={this.props.yaspFormLink}
          width="75%"
          height="600"
          type="application/pdf"
        />
        <div className={styles['form-signing-container']}>
          <div className={styles['agreement-text-container']}>
            I have read and understand these policies and agree to abide by them.
          </div>
          <div className={styles['name-phone-number-form']}>
            <ReusableTextField
              id="name"
              label="Full Legal Name"
              className={styles['full-name-input']}
              required
              value={`${this.props.currentUser.first_name} ${this.props.currentUser.last_name}`}
            />
            <ReusableTextField
              id="phoneNumber"
              label="Phone Number"
              className={styles['phone-number-input']}
              required
              value={this.props.currentUser.phone_number}
            />
            <ReusableButton
              className={styles['sign-button']}
              onClick={this.props.signYaspForm}
              value="Sign"
              disabled={this.props.yaspFormSigned}
              tooltipText={this.props.yaspFormSigned ? 'You have already signed the YASP form.' : ''}
            />
          </div>
        </div>
      </div>
    );
  }
}

YaspStep.propTypes = {
  currentUser: PropTypes.object.isRequired,
  signYaspForm: PropTypes.func.isRequired,
  yaspFormSigned: PropTypes.bool.isRequired,
  yaspFormSignedDate: PropTypes.object.isRequired,
  yaspFormLink: PropTypes.string.isRequired,
};

export default YaspStep;
