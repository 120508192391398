export const GET_ANNOUNCEMENTS_SENT = 'GET_ANNOUNCEMENTS_SENT';
export const GET_ANNOUNCEMENTS_SUCCESSFUL = 'GET_ANNOUNCEMENTS_SUCCESSFUL';
export const CREATE_ANNOUNCEMENT_SENT = 'CREATE_ANNOUNCEMENT_SENT';
export const CREATE_ANNOUNCEMENT_SUCCESSFUL = 'CREATE_ANNOUNCEMENT_SUCCESSFUL';
export const UPDATE_ANNOUNCEMENT_SENT = 'UPDATE_ANNOUNCEMENT_SENT';
export const UPDATE_ANNOUNCEMENT_SUCCESSFUL = 'UPDATE_ANNOUNCEMENT_SUCCESSFUL';

export const getAnnouncementsRequestSent = () => ({ type: GET_ANNOUNCEMENTS_SENT });

export const getAnnouncementsRequestSuccessful = (data) => ({ type: GET_ANNOUNCEMENTS_SUCCESSFUL, data });

export const createAnnouncementRequestSent = () => ({ type: CREATE_ANNOUNCEMENT_SENT });

export const createAnnouncementRequestSuccessful = (data) => ({ type: CREATE_ANNOUNCEMENT_SUCCESSFUL, data });

export const updateAnnouncementRequestSent = () => ({ type: UPDATE_ANNOUNCEMENT_SENT });

export const updateAnnouncementRequestSuccessful = (data) => ({ type: UPDATE_ANNOUNCEMENT_SUCCESSFUL, data });
