import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ReusableButton from 'components/common/ReusableButton';

class UnsavedChangesDialog extends React.Component {
  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.handleCloseDialog}>
        <DialogTitle>Warning: Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Any unsaved changes will be lost. Are you sure you wish to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ReusableButton
            onClick={this.props.handleCloseDialog}
            value="Cancel"
          />
          <ReusableButton
            onClick={this.props.handleAccept}
            value="Ok"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

UnsavedChangesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleAccept: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default UnsavedChangesDialog;
