import ApiWrapper from 'wrappers/api';

import {
  getYearRequestSent,
  getYearRequestSuccessful,
  updateYearRequestSent,
  updateYearRequestSuccessful,
  getMOTDRequestSent,
  getMOTDRequestSuccessful,
  updateMOTDRequestSent,
  updateMOTDRequestSuccessful,
  updateMOTDRequestFailed,
  getEmailSignatureRequestSent,
  getEmailSignatureRequestSuccessful,
  updateEmailSignatureRequestSent,
  updateEmailSignatureRequestSuccessful,
  updateEmailSignatureRequestFailed,
  getYaspFormLinkRequestSent,
  getYaspFormLinkRequestSuccessful,
  updateYaspFormLinkRequestSent,
  updateYaspFormLinkRequestSuccessful,
  updateYaspFormLinkRequestFailed,
} from 'actions/atomic/systemSettings';

export function getYear() {
  return (dispatch) => {
    dispatch(getYearRequestSent());
    return ApiWrapper.get('/system_settings/year')
      .then(response => {
        dispatch(getYearRequestSuccessful(response.data));
      });
  };
}

export function updateYear(newYear) {
  return (dispatch) => {
    dispatch(updateYearRequestSent());
    return ApiWrapper.put('/system_settings/year', newYear)
      .then(response => {
        dispatch(updateYearRequestSuccessful(response.data));
      });
  };
}

export function getMOTD() {
  return (dispatch) => {
    dispatch(getMOTDRequestSent());
    return ApiWrapper.get('/system_settings/motd')
      .then(response => {
        dispatch(getMOTDRequestSuccessful(response.data));
      });
  };
}

export function updateMOTD(newMOTD) {
  return (dispatch) => {
    dispatch(updateMOTDRequestSent());
    return ApiWrapper.put('/system_settings/motd', newMOTD)
      .then(response => {
        dispatch(updateMOTDRequestSuccessful(response.data));
      })
      .catch(() => {
        dispatch(updateMOTDRequestFailed());
      });
  };
}

export function getYaspFormLink() {
  return (dispatch) => {
    dispatch(getYaspFormLinkRequestSent());
    return ApiWrapper.get('/system_settings/yasp_form_link')
      .then(response => {
        dispatch(getYaspFormLinkRequestSuccessful(response.data));
      });
  };
}

export function updateYaspFormLink(newYaspFormLink) {
  return (dispatch) => {
    dispatch(updateYaspFormLinkRequestSent());
    return ApiWrapper.put('/system_settings/yasp_form_link', newYaspFormLink)
      .then(response => {
        dispatch(updateYaspFormLinkRequestSuccessful(response.data));
      })
      .catch(() => {
        dispatch(updateYaspFormLinkRequestFailed());
      });
  };
}

export function getEmailSignature() {
  return (dispatch) => {
    dispatch(getEmailSignatureRequestSent());
    return ApiWrapper.get('/system_settings/email_signature')
      .then(response => {
        dispatch(getEmailSignatureRequestSuccessful(response.data));
      });
  };
}

export function updateEmailSignature(newEmailSignature) {
  return (dispatch) => {
    dispatch(updateEmailSignatureRequestSent());
    return ApiWrapper.put('/system_settings/email_signature', newEmailSignature)
      .then(response => {
        dispatch(updateEmailSignatureRequestSuccessful(response.data));
      })
      .catch(() => {
        dispatch(updateEmailSignatureRequestFailed());
      });
  };
}
