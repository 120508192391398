export const GET_APPLICANTS_SENT = 'GET_APPLICANTS_SENT';
export const GET_APPLICANTS_SUCCESSFUL = 'GET_APPLICANTS_SUCCESSFUL';
export const UPDATE_APPLICATION_SENT = 'UPDATE_APPLICATION_SENT';
export const UPDATE_APPLICATION_SUCCESSFUL = 'UPDATE_APPLICATION_SUCCESSFUL';
export const FIND_EXISTING_USERS_SENT = 'FIND_EXISTING_USERS_SENT';
export const FIND_EXISTING_USERS_SUCCESSFUL = 'FIND_EXISTING_USERS_SUCCESSFUL';
export const RESET_ADD_CREATE_DIALOG = 'RESET_ADD_CREATE_DIALOG';
export const CREATE_APPLICATION_FROM_TABLE_SENT = 'CREATE_APPLICATION_FROM_TABLE_SENT';
export const CREATE_APPLICATION_FROM_TABLE_SUCCESSFUL = 'CREATE_APPLICATION_FROM_TABLE_SUCCESSFUL';
export const UPDATE_APPLICATION_FROM_TABLE_SENT = 'UPDATE_APPLICATION_FROM_TABLE_SENT';
export const UPDATE_APPLICATION_FROM_TABLE_SUCCESSFUL = 'UPDATE_APPLICATION_FROM_TABLE_SUCCESSFUL';

export const getApplicantsRequestSent = () => ({ type: GET_APPLICANTS_SENT });

export const getApplicantsRequestSuccessful = (data) => ({ type: GET_APPLICANTS_SUCCESSFUL, data });

export const updateApplicationRequestSent = () => ({ type: UPDATE_APPLICATION_SENT });

export const updateApplicationRequestSuccessful = (data) => ({ type: UPDATE_APPLICATION_SUCCESSFUL, data });

export const findExistingUsersRequestSent = () => ({ type: FIND_EXISTING_USERS_SENT });

export const findExistingUsersRequestSuccessful = (data) => ({ type: FIND_EXISTING_USERS_SUCCESSFUL, data });

export const resetAddCreateApplicantDialog = () => ({ type: FIND_EXISTING_USERS_SENT });

export const createApplicationFromTableRequestSent = () => ({ type: CREATE_APPLICATION_FROM_TABLE_SENT });

export const createApplicationFromTableRequestSuccessful = (data) => ({ type: CREATE_APPLICATION_FROM_TABLE_SUCCESSFUL, data });

export const updateApplicationFromTableRequestSent = () => ({ type: UPDATE_APPLICATION_FROM_TABLE_SENT });

export const updateApplicationFromTableRequestSuccessful = (data) => ({ type: UPDATE_APPLICATION_FROM_TABLE_SUCCESSFUL, data });
