import { connect } from 'react-redux';

import VolunteerDashboardPage from 'components/pages/VolunteerDashboardPage';
import { selectIsLoggedIn, selectCurrentUser } from 'selectors/authenticationSelectors';
import { selectYear } from 'selectors/systemSettingsSelectors';
import { selectEventDates } from 'selectors/eventDatesSelectors';
import { selectGenderOptions, selectUpdateCurrentUserRequestComplete } from 'selectors/usersSelectors';
import { getYear } from 'actions/thunks/systemSettings';
import { validateLogin } from 'actions/thunks/authentication';
import { updateCurrentUser } from 'actions/thunks/users';
import { getEventDates } from 'actions/thunks/eventDates';

const mapStateToProps = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  currentUser: selectCurrentUser(state),
  currentYear: selectYear(state),
  genderOptions: selectGenderOptions(state),
  updateCurrentUserRequestComplete: selectUpdateCurrentUserRequestComplete(state),
  eventDates: selectEventDates(state),
});

const mapDispatchToProps = (dispatch) => ({
  getYear: () => dispatch(getYear()),
  updateCurrentUser: (updatedUser) => dispatch(updateCurrentUser(updatedUser)),
  validateLogin: () => dispatch(validateLogin()),
  getEventDates: () => dispatch(getEventDates()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VolunteerDashboardPage);
