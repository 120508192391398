export const GET_SOLUTION_ASSIGNMENTS_SENT = 'GET_SOLUTION_ASSIGNMENTS_SENT';
export const GET_SOLUTION_ASSIGNMENTS_SUCCESSFUL = 'GET_SOLUTION_ASSIGNMENTS_SUCCESSFUL';
export const CREATE_SOLUTION_ASSIGNMENT_SENT = 'CREATE_SOLUTION_ASSIGNMENT_SENT';
export const CREATE_SOLUTION_ASSIGNMENT_SUCCESSFUL = 'CREATE_SOLUTION_ASSIGNMENT_SUCCESSFUL';
export const UPDATE_SOLUTION_ASSIGNMENT_SENT = 'UPDATE_SOLUTION_ASSIGNMENT_SENT';
export const UPDATE_SOLUTION_ASSIGNMENT_SUCCESSFUL = 'UPDATE_SOLUTION_ASSIGNMENT_SUCCESSFUL';
export const DELETE_SOLUTION_ASSIGNMENT_SENT = 'DELETE_SOLUTION_ASSIGNMENT_SENT';
export const DELETE_SOLUTION_ASSIGNMENT_SUCCESSFUL = 'DELETE_SOLUTION_ASSIGNMENT_SUCCESSFUL';

export const getSolutionAssignmentsRequestSent = () => ({ type: GET_SOLUTION_ASSIGNMENTS_SENT });

export const getSolutionAssignmentsRequestSuccessful = (data) => ({ type: GET_SOLUTION_ASSIGNMENTS_SUCCESSFUL, data });

export const createSolutionAssignmentRequestSent = () => ({ type: CREATE_SOLUTION_ASSIGNMENT_SENT });

export const createSolutionAssignmentRequestSuccessful = (data) => ({ type: CREATE_SOLUTION_ASSIGNMENT_SUCCESSFUL, data });

export const updateSolutionAssignmentRequestSent = () => ({ type: UPDATE_SOLUTION_ASSIGNMENT_SENT });

export const updateSolutionAssignmentRequestSuccessful = (data) => ({ type: UPDATE_SOLUTION_ASSIGNMENT_SUCCESSFUL, data });

export const deleteSolutionAssignmentRequestSent = () => ({ type: DELETE_SOLUTION_ASSIGNMENT_SENT });

export const deleteSolutionAssignmentRequestSuccessful = (data) => ({ type: DELETE_SOLUTION_ASSIGNMENT_SUCCESSFUL, data });
