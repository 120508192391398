import ApiWrapper from 'wrappers/api';

import {
  getEventDatesRequestSent,
  getEventDatesRequestSuccessful,
  createEventDateRequestSent,
  createEventDateRequestSuccessful,
  updateEventDateRequestSent,
  updateEventDateRequestSuccessful,
} from 'actions/atomic/eventDates';

export function getEventDates() {
  return (dispatch) => {
    dispatch(getEventDatesRequestSent());
    return ApiWrapper.get('/event_dates')
      .then(response => {
        dispatch(getEventDatesRequestSuccessful(response.data));
      });
  };
}

export function createEventDate(newEventDate) {
  return (dispatch) => {
    dispatch(createEventDateRequestSent());
    return ApiWrapper.post('/event_dates', newEventDate)
      .then(response => {
        dispatch(createEventDateRequestSuccessful(response.data));
      });
  };
}

export function updateEventDate(newEventDate) {
  return (dispatch) => {
    dispatch(updateEventDateRequestSent());
    return ApiWrapper.put(`/event_dates/${newEventDate.id}`, newEventDate)
      .then(response => {
        dispatch(updateEventDateRequestSuccessful(response.data));
      });
  };
}
