import ApiWrapper from 'wrappers/api';
import fileDownload from 'js-file-download';

import {
  getAffiliationsRequestSent,
  getAffiliationsRequestSuccessful,
  updateAffiliationRequestSent,
  updateAffiliationRequestSuccessful,
  createAffiliationRequestSent,
  createAffiliationRequestSuccessful,
} from 'actions/atomic/affiliations';

export function getAffiliations() {
  return (dispatch) => {
    dispatch(getAffiliationsRequestSent());
    return ApiWrapper.get('/affiliations')
      .then(response => {
        dispatch(getAffiliationsRequestSuccessful(response.data));
      });
  };
}

export function updateAffiliation(newAffiliation) {
  return (dispatch) => {
    dispatch(updateAffiliationRequestSent());
    return ApiWrapper.put(`/affiliations/${newAffiliation.id}`, newAffiliation)
      .then(response => {
        dispatch(updateAffiliationRequestSuccessful(response.data));
      });
  };
}

export function createAffiliation(newAffiliation) {
  return (dispatch) => {
    dispatch(createAffiliationRequestSent());
    return ApiWrapper.post('/affiliations', newAffiliation)
      .then(response => {
        dispatch(createAffiliationRequestSuccessful(response.data));
      });
  };
}

export function downloadAffiliations() {
  return () => ApiWrapper.get('/affiliations/download', { responseType: 'blob' })
    .then(response => fileDownload(response.data, 'affiliations.xlsx'));
}
