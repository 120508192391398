import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import ReusableTextField from 'components/common/ReusableTextField';
import ReusableButton from 'components/common/ReusableButton';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import history from 'wrappers/history';
import styles from './style.module.scss';

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.isLoggedIn) {
      history.push('/');
    }

    this.state = {
      password: '',
      passwordConfirmation: '',
      passwordError: false,
      invalidFieldsSnackbarMessage: '',
      showInvalidFieldsSnackbar: false,
    };

    this.props.resetPassword({ token: this.passwordToken });
  }

  get passwordToken() {
    const values = queryString.parse(this.props.location.search);
    return values.token;
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
    this.setState({ passwordError: false });
  };

  validateFields = () => {
    let errorMessage = '';
    if (this.state.password.length === 0 || this.state.password !== this.state.passwordConfirmation) {
      errorMessage += 'Passwords do not match or are empty.\n';
      this.setState({
        passwordError: true,
      });
    }
    errorMessage = errorMessage.trim();
    this.setState({ invalidFieldsSnackbarMessage: errorMessage, showInvalidFieldsSnackbar: errorMessage !== '' });
    return errorMessage === '';
  };

  handleResetButtonClicked = () => {
    const allFieldsValid = this.validateFields();

    if (!allFieldsValid) {
      return;
    }

    const payload = {
      token: this.passwordToken,
      password: this.state.password,
    };

    this.props.resetPassword(payload);
  };

  closeInvalidFieldsSnackbar = () => {
    this.setState({ showInvalidFieldsSnackbar: false, invalidFieldsSnackbarMessage: '' });
  };

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['info-container']}>
              {this.props.invalidToken === undefined
              && !this.props.resetPassword
              && (
                <>
                  <h2>
                    Please wait...
                  </h2>
                  <div>
                    Your token is being verified.
                  </div>
                </>
              )}
              {this.props.invalidToken === true
              && (
                <>
                  <h2>
                    Error
                  </h2>
                  <div>
                    Your token has expired or is otherwise invalid. Please request a new token.
                  </div>
                  <ReusableButton
                    value="Request New Reset Token"
                    hasTopMargin
                    onClick={() => history.push('/forgot-password')}
                  />
                </>
              )}
              {this.props.passwordReset
              && (
                <>
                  <h2>
                    Success!
                  </h2>
                  <div>
                    Your password has been changed. Please try logging in again.
                  </div>
                  <ReusableButton
                    value="Login"
                    hasTopMargin
                    onClick={() => history.push('/login')}
                  />
                </>
              )}
              {this.props.invalidToken === false
              && (
                <>
                  <h2>
                    Reset your password:
                  </h2>
                  <ReusableTextField
                    id="password"
                    label="Password"
                    value={this.state.password}
                    onChange={this.handleChange('password')}
                    type="password"
                    error={this.state.passwordError}
                    required
                  />
                  <ReusableTextField
                    id="passwordConfirmation"
                    label="Confirm Password"
                    value={this.state.passwordConfirmation}
                    onChange={this.handleChange('passwordConfirmation')}
                    type="password"
                    error={this.state.passwordError}
                    required
                  />
                  <ReusableButton
                    value="Reset Password"
                    hasTopMargin
                    onClick={this.handleResetButtonClicked}
                  />
                </>
              )}
              <ReusableSnackbar
                open={this.state.showInvalidFieldsSnackbar}
                onClose={this.closeInvalidFieldsSnackbar}
                variant="error"
                message={this.state.invalidFieldsSnackbarMessage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResetPasswordPage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  location: PropTypes.object,
  resetPassword: PropTypes.func.isRequired,
  passwordReset: PropTypes.bool.isRequired,
  invalidToken: PropTypes.bool,
};

export default ResetPasswordPage;
