import { connect } from 'react-redux';

import {
  resetCreateUserFields,
} from 'actions/atomic/users';
import {
  createUser,
} from 'actions/thunks/users';
import {
  selectIsLoggedIn,
} from 'selectors/authenticationSelectors';
import {
  selectCreateUserRequestComplete,
  selectCreationSuccessful,
  selectUserExists,
  selectActivated,
  selectGenderOptions,
} from 'selectors/usersSelectors';
import AccountCreationPage from 'components/pages/AccountCreationPage';

const mapStateToProps = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  createUserRequestComplete: selectCreateUserRequestComplete(state),
  creationSuccessful: selectCreationSuccessful(state),
  userExists: selectUserExists(state),
  activated: selectActivated(state),
  genderOptions: selectGenderOptions(state),
});

const mapDispatchToProps = dispatch => ({
  createAccount: (payload) => dispatch(createUser(payload)),
  resetCreateUserFields: () => dispatch(resetCreateUserFields()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountCreationPage);
