import { fromJS } from 'immutable';

import {
  GET_TASK_PREFERENCES_SENT,
  GET_TASK_PREFERENCES_SUCCESSFUL,
  CREATE_TASK_PREFERENCE_SENT,
  CREATE_TASK_PREFERENCE_SUCCESSFUL,
  UPDATE_TASK_PREFERENCE_SENT,
  UPDATE_TASK_PREFERENCE_SUCCESSFUL,
} from 'actions/atomic/taskPreferences';

const initialState = fromJS({

  loading: false,

  data: [],

});

export default function recruiters(state = initialState, action) {
  switch (action.type) {
  case GET_TASK_PREFERENCES_SENT:
    return state.merge({
      loading: true,
      data: fromJS([]),
    });
  case GET_TASK_PREFERENCES_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: fromJS(action.data),
    });
  case CREATE_TASK_PREFERENCE_SENT:
    return state.set('loading', true);
  case CREATE_TASK_PREFERENCE_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: state.get('data').push(fromJS(action.data)),
    });
  case UPDATE_TASK_PREFERENCE_SENT:
    return state.set('loading', true);
  case UPDATE_TASK_PREFERENCE_SUCCESSFUL:
    const updatedTaskPreference = action.data;
    const newData = state.get('data').toJS();
    const dataMap = {};
    newData.forEach((taskPref, index) => { dataMap[taskPref.id] = index; });
    updatedTaskPreference.forEach(upd => {
      const index = dataMap[upd.id];
      if (index === undefined) {
        newData.push(upd);
      } else {
        newData[index].preference = upd.preference;
      }
    });
    return state.merge({
      loading: false,
      data: fromJS(newData),
    });
  default:
    return state;
  }
}
