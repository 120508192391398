import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button } from '@material-ui/core/';
import PropTypes from 'prop-types';

import styles from '../style.module.scss';

const dialogTitleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(dialogTitleStyles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const statusFilterCheckboxRowStyle = {
  marginTop: '-7px',
  marginBottom: '-7px',
  marginLeft: '2px',
};

class ApplicantTableFilterDialog extends React.Component {
  render() {
    return (
      <Dialog open={this.props.applicantFilterOpen} onClose={this.props.handleCloseApplicantFilter} style={{ width: '1000px' }}>
        <DialogTitle onClose={this.handleCloseApplicantFilter} />
        <DialogContent className={styles['confirm-dialog']}>
          <div className={`${styles['filter-non-checkbox-list-row']}`}>
            <b> Statuses: </b>
            {Object.keys(this.props.checkedStatuses).every(statusID => this.props.checkedStatuses[statusID] === true)
              ? <Button variant="contained" onClick={this.props.handleSelectNoneStatuses}>None</Button>
              : <Button variant="contained" onClick={this.props.handleSelectAllStatuses}>All</Button>}
          </div>
          <FormGroup>
            {(!this.props.statusesLoading) && (
              this.props.statuses.map(status => (
                <FormControlLabel
                  key={status.id}
                  control={(
                    <Checkbox
                      color="default"
                      size="small"
                      disableRipple
                      checked={this.props.checkedStatuses[status.id] ?? true}
                      name={`${status.id}`}
                      onChange={this.props.handleStatusCheckbox}
                    />
                  )}
                  label={status.name}
                  style={statusFilterCheckboxRowStyle}
                />
              ))
            )}
          </FormGroup>
          <br />
          <b>
            <div className={`${styles['filter-non-checkbox-list-row']}`}>
              <b>Time Filter</b>
              <Button disableRipple variant="contained" onClick={this.props.handleClearApplicantTimeFilter}>Clear</Button>
            </div>
          </b>
          <input
            type="time"
            id="begin_time"
            min="06:00"
            max="18:00"
            value={this.props.beginningApplicantTimeFilter}
            onChange={this.props.handleBeginningApplicantTimeChange}
            required
          />
          to
          <input
            type="time"
            id="end_time"
            min="06:00"
            max="18:00"
            value={this.props.endingApplicantTimeFilter}
            onChange={this.props.handleEndingApplicantTimeChange}
            required
          />
        </DialogContent>
      </Dialog>
    );
  }
}

ApplicantTableFilterDialog.propTypes = {
  applicantFilterOpen: PropTypes.bool.isRequired,
  checkedStatuses: PropTypes.object.isRequired,
  handleSelectNoneStatuses: PropTypes.func.isRequired,
  handleSelectAllStatuses: PropTypes.func.isRequired,
  statusesLoading: PropTypes.bool.isRequired,
  statuses: PropTypes.array.isRequired,
  handleStatusCheckbox: PropTypes.func.isRequired,
  handleClearApplicantTimeFilter: PropTypes.func.isRequired,
  beginningApplicantTimeFilter: PropTypes.string.isRequired,
  handleBeginningApplicantTimeChange: PropTypes.func.isRequired,
  endingApplicantTimeFilter: PropTypes.string.isRequired,
  handleEndingApplicantTimeChange: PropTypes.func.isRequired,
  handleCloseApplicantFilter: PropTypes.func.isRequired,
};

export default ApplicantTableFilterDialog;
