import React from 'react';
import ReusableTable from 'components/common/ReusableTable';
import PropTypes from 'prop-types';
import { formatTime } from 'utils/date';

import {
  calculateLoadBackup, customFilterByTaskName, customFilterByTime, renderLoadBackup,
} from '../utils';
import styles from '../style.module.scss';

class TaskTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Task table
      taskColumns: [
        {
          title: 'Task',
          field: 'background_color',
          tooltip: 'Task name',
          defaultSort: 'desc',
          render: (task) => (task.preemtable && (`${task.abbr.trim() || task.name} (P)`)) || (task.abbr.trim() || task.name),
          customFilterAndSearch: customFilterByTaskName,
        },
        {
          title: 'Time',
          field: 'starts_at',
          tooltip: 'The time of the task.',
          defaultSort: 'asc',
          render: (task) => this.renderTaskTimeWindows(task),
          customFilterAndSearch: (term, task) => customFilterByTime(term, task),
          align: 'center',
        },
        {
          title: 'L',
          tooltip: 'The load of the task.',
          defaultSort: 'desc',
          align: 'right',
          customSort: (a, b) => (calculateLoadBackup(a, this.props.solutionAssignments, false) > calculateLoadBackup(b, this.props.solutionAssignments, false) ? -1 : 1),
          render: (task) => renderLoadBackup(task, this.props.solutionAssignments, false),
        },
        {
          title: 'B',
          tooltip: 'The backup load of the task.',
          defaultSort: 'desc',
          align: 'right',
          customSort: (a, b) => (calculateLoadBackup(a, this.props.solutionAssignments, true) > calculateLoadBackup(b, this.props.solutionAssignments, true) ? -1 : 1),
          render: (task) => renderLoadBackup(task, this.props.solutionAssignments, true),
        },
      ],
      taskOptions: {
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 700,
        hideDeleteButton: true,
        actionsColumnIndex: -1,
        showFull: true,
        search: false,
        rowStyle: rowData => ({
          backgroundColor: rowData.background_color ? rowData.background_color : '',
          color: rowData.text_color ? rowData.text_color : '',
        }),
      },
      taskActions: [
        {
          icon: 'replay',
          tooltip: 'Refetch Data',
          position: 'toolbar',
          isFreeAction: true,
          onClick: () => this.props.getTasks(),
        },
        {
          icon: 'save_alt',
          tooltip: 'Export',
          position: 'toolbar',
          isFreeAction: true,
          onClick: () => {
            this.props.downloadSolutionAssignmentsTasks();
            this.props.handleDownloadSnackbar();
          },
        },
        {
          icon: 'add',
          tooltip: 'Assign',
          position: 'row',
          color: 'white',
          iconColor: 'white',
          onClick: (event, task) => { this.props.openAssigneeDialog(task); },
        },
        {
          icon: 'filter_alt',
          tooltip: 'Filter',
          position: 'toolbar',
          isFreeAction: true,
          onClick: () => this.props.handleOpenTaskFilter(),
        },
      ],
    };
  }

  renderTaskTimeWindows = (task) => {
    if (task?.year === this.props.year) {
      return <div className={styles['nowrap']}>{`${formatTime(task?.starts_at)}-${formatTime(task?.ends_at)}`}</div>;
    }
    return (
      <div> - </div>
    );
  };

  render() {
    return (
      <div className={styles['task-table-container']}>
        <ReusableTable
          title={`Tasks (${this.props.data.length})`}
          columns={this.state.taskColumns}
          data={this.props.data}
          options={this.state.taskOptions}
          isLoading={this.props.tasksLoading}
          actions={this.state.taskActions}
          jobSelected={this.props.jobSelected}
          renderYearOptions={this.props.renderYearOptions}
        />
      </div>
    );
  }
}

TaskTable.propTypes = {
  data: PropTypes.array.isRequired,
  tasksLoading: PropTypes.bool.isRequired,
  jobSelected: PropTypes.bool.isRequired,
  downloadSolutionAssignmentsTasks: PropTypes.func.isRequired,
  getTasks: PropTypes.func.isRequired,
  solutionAssignments: PropTypes.array.isRequired,
  handleDownloadSnackbar: PropTypes.func.isRequired,
  handleOpenTaskFilter: PropTypes.func.isRequired,
  openAssigneeDialog: PropTypes.func.isRequired,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  renderYearOptions: PropTypes.func.isRequired,
};

export default TaskTable;
