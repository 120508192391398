import { connect } from 'react-redux';

import { selectApplicantsLoading, selectApplicants } from 'selectors/applicantsSelectors';
import { getApplicants } from 'actions/thunks/applicants';
import { selectRecruitersLoading, selectRecruiters } from 'selectors/recruitersSelectors';

import { setRecruitersSelected } from 'actions/thunks/emails';

import {
  getRecruiters,
  updateRecruiter,
  createRecruiter,
  downloadRecruiters,
} from 'actions/thunks/recruiters';
import RecruitersPage from 'components/pages/admin/RecruitersPage';
import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';
import { getYear } from 'actions/thunks/systemSettings';
import { getEventDates } from 'actions/thunks/eventDates';
import { selectYear } from 'selectors/systemSettingsSelectors';
import { selectEventDates } from 'selectors/eventDatesSelectors';

const mapStateToProps = state => ({
  applicantsLoading: selectApplicantsLoading(state),
  applicants: selectApplicants(state),
  recruitersLoading: selectRecruitersLoading(state),
  recruiters: selectRecruiters(state),
  isAdmin: selectIsAdmin(state),
  isLoggedIn: selectIsLoggedIn(state),
  year: selectYear(state),
  eventDates: selectEventDates(state),
});

const mapDispatchToProps = (dispatch) => ({
  getApplicants: () => dispatch(getApplicants()),
  getRecruiters: () => dispatch(getRecruiters()),
  updateRecruiter: (newRecruiter) => dispatch(updateRecruiter(newRecruiter)),
  createRecruiter: (newRecruiter) => dispatch(createRecruiter(newRecruiter)),
  downloadRecruiters: () => dispatch(downloadRecruiters()),
  setRecruitersSelected: (recruitersSelected) => dispatch(setRecruitersSelected(recruitersSelected)),
  getYear: () => dispatch(getYear()),
  getEventDates: () => dispatch(getEventDates()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecruitersPage);
