import { createSelector } from 'reselect';

export const selectLoading = createSelector(
  (state) => state.getIn(['announcements', 'loading']),
  (loading) => loading,
);

export const selectAnnouncements = createSelector(
  (state) => state.getIn(['announcements', 'data']),
  (data) => data.toJS(),
);
