import { connect } from 'react-redux';

import { selectAnnouncements } from 'selectors/announcementsSelectors';
import { selectMOTD } from 'selectors/systemSettingsSelectors';
import { selectIsLoggedIn } from 'selectors/authenticationSelectors';
import { getAnnouncements } from 'actions/thunks/announcements';
import { getMOTD } from 'actions/thunks/systemSettings';
import HomePage from 'components/pages/HomePage';

const mapStateToProps = (state) => ({
  announcements: selectAnnouncements(state),
  motd: selectMOTD(state),
  isLoggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAnnouncements: () => dispatch(getAnnouncements()),
  getMOTD: () => dispatch(getMOTD()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomePage);
