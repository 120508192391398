import { fromJS } from 'immutable';

import {
  GET_YEAR_SENT,
  GET_YEAR_SUCCESSFUL,
  UPDATE_YEAR_SENT,
  UPDATE_YEAR_SUCCESSFUL,
  GET_MOTD_SENT,
  GET_MOTD_SUCCESSFUL,
  UPDATE_MOTD_SENT,
  UPDATE_MOTD_SUCCESSFUL,
  UPDATE_MOTD_FAILED,
  GET_EMAIL_SIGNATURE_SENT,
  GET_EMAIL_SIGNATURE_SUCCESSFUL,
  UPDATE_EMAIL_SIGNATURE_SENT,
  UPDATE_EMAIL_SIGNATURE_SUCCESSFUL,
  UPDATE_EMAIL_SIGNATURE_FAILED,
  GET_YASP_FORM_LINK_SENT,
  GET_YASP_FORM_LINK_SUCCESSFUL,
  UPDATE_YASP_FORM_LINK_SENT,
  UPDATE_YASP_FORM_LINK_SUCCESSFUL,
  UPDATE_YASP_FORM_LINK_FAILED,
} from 'actions/atomic/systemSettings';

const initialState = fromJS({
  isError: false,
  year: '',
  motd: '',
  emailSignature: '',
  yaspFormLink: '',
});

export default function systemSettings(state = initialState, action) {
  switch (action.type) {
  case GET_YEAR_SENT:
    return state.set('year', '');
  case GET_YEAR_SUCCESSFUL:
    return state.set('year', action.data.year);
  case UPDATE_YEAR_SENT:
    return state;
  case UPDATE_YEAR_SUCCESSFUL:
    const updatedYear = action.data.year;
    return state.set('year', updatedYear);
  case GET_MOTD_SENT:
    return state.set('motd', '');
  case GET_MOTD_SUCCESSFUL:
    return state.set('motd', action.data.motd);
  case UPDATE_MOTD_SENT:
    return state;
  case UPDATE_MOTD_SUCCESSFUL:
    const updatedMOTD = action.data.motd;
    return state.merge({
      isError: false,
      motd: updatedMOTD,
    });
  case UPDATE_MOTD_FAILED:
    return state.set('isError', true);
  case GET_EMAIL_SIGNATURE_SENT:
    return state.set('emailSignature', '');
  case GET_EMAIL_SIGNATURE_SUCCESSFUL:
    return state.set('emailSignature', action.data.email_signature);
  case UPDATE_EMAIL_SIGNATURE_SENT:
    return state;
  case UPDATE_EMAIL_SIGNATURE_SUCCESSFUL:
    const updatedEmailSignature = action.data.email_signature;
    return state.merge({
      isError: false,
      emailSignature: updatedEmailSignature,
    });
  case UPDATE_EMAIL_SIGNATURE_FAILED:
    return state.set('isError', true);
  case GET_YASP_FORM_LINK_SENT:
    return state.set('yaspFormLink', '');
  case GET_YASP_FORM_LINK_SUCCESSFUL:
    return state.set('yaspFormLink', action.data.yasp_form_link);
  case UPDATE_YASP_FORM_LINK_SENT:
    return state;
  case UPDATE_YASP_FORM_LINK_SUCCESSFUL:
    const updatedYaspFormLink = action.data.yasp_form_link;
    return state.merge({
      isError: false,
      yaspFormLink: updatedYaspFormLink,
    });
  case UPDATE_YASP_FORM_LINK_FAILED:
    return state.set('isError', true);
  default:
    return state;
  }
}
