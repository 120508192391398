import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styles from './style.module.scss';

const ReusableSelect = (props) => {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(props.label ? inputLabel.current.offsetWidth : 0);
  }, [props.label]);

  return (
    <div className={`${styles['select-field-container']} ${props.className}`}>
      <FormControl
        variant={!props.useDefaultVariant ? 'outlined' : 'standard'}
        className={`${!props.isTransparent ? styles['white-background'] : ''} ${styles['max-width']}`}
        error={props.error}
        required={props.required}
      >
        {props.label
        && (
          <InputLabel ref={inputLabel}>
            {props.label}
          </InputLabel>
        )}
        <Select
          id={props.id}
          value={props.value}
          onChange={props.onChange}
          labelWidth={labelWidth}
          displayEmpty={props.displayEmpty}
          style={{ fontFamily: '\'Gotham SSm A\',\'Gotham SSm B\',Verdana,sans-serif' }}
        >
          {
            props.placeholder && (
              <MenuItem value="" disabled>
                {props.placeholderText}
              </MenuItem>
            )
          }
          {!props.excludeNoneOption
          && (
            <MenuItem value="">
              {props.emptyValue ? props.emptyValue : 'None'}
            </MenuItem>
          )}
          {props.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>{option.display}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

ReusableSelect.propTypes = {
  placeholder: PropTypes.bool,
  placeholderText: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  required: PropTypes.bool,
  excludeNoneOption: PropTypes.bool,
  useDefaultVariant: PropTypes.bool,
  isTransparent: PropTypes.bool,
  emptyValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  displayEmpty: PropTypes.bool,
};

export default ReusableSelect;
