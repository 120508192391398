import React from 'react';
import ReusableTable from 'components/common/ReusableTable';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { formatTime } from 'utils/date';
import ReusableSnackbar from 'components/common/ReusableSnackbar';

import { renderATAConfirmName, renderAssignedType, renderAssignedTime } from '../utils';
import styles from '../style.module.scss';

class ATAConfirmTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          title: 'Name',
          tooltip: 'The name of the task',
          render: (rowData) => renderATAConfirmName(rowData[0]),
          customFilterAndSearch: (term, rowData) => (term === renderATAConfirmName(rowData[0]).slice(0, term.length)) || (term === `${rowData[0].task?.name}`.slice(0, term.length)),
        },
        {
          title: 'L/B',
          field: 'back_up',
          tooltip: 'The assignment type for the applicant (L or B)',
          render: (rowData) => renderAssignedType(rowData[0]),
        },
        {
          title: 'Time',
          tooltip: 'The time the task takes place',
          render: (rowData) => renderAssignedTime(rowData[0]),
        },
        {
          title: 'Asgn',
          tooltip: 'The time windows that the applicant entered.',
          render: (rowData) => this.renderTimePicker(rowData[0]),
        },
      ],
      options: {
        filtering: false,
        padding: 'dense',
        maxBodyHeight: 700,
        hideDeleteButton: true,
        selection: true,
        selectionProps: rowData => {
          const shouldBeDisabled = this.timeEntryInvalid(rowData[0]);
          const checked = shouldBeDisabled ? { checked: false } : {};
          if (shouldBeDisabled) {
            rowData.tableData['checked'] = false;
          }
          rowData.tableData.disabled = shouldBeDisabled;
          return ({
            disabled: shouldBeDisabled,
            ...checked,
          });
        },
        paging: false,
        showFull: true,
        search: false,
        showColumns: false,
        headerStyle: {
          fontFamily: 'Gotham SSm A, Gotham SSm B, Verdana, sans-serif',
          backgroundColor: '#eae9e6',
          color: '#d00000',
          whiteSpace: 'nowrap',
        },
      },
      actions: [
        {
          icon: 'check',
          tooltip: 'Assign',
          displayDuringSelect: true,
          onClick: (event, data) => this.handleCheck(data),
        },
      ],
      startTimes: [],
      endTimes: [],
      snackbarOpen: false,
    };
  }

  async componentDidMount() {
    if (this.props.assignedTasksData) {
      const startTimes = {};
      const endTimes = {};
      this.props.assignedTasksData.forEach(data => {
        let formattedStart = dayjs(data?.[1]?.starts_at);
        formattedStart = formattedStart.year(1941);
        formattedStart = formattedStart.month(11);
        formattedStart = formattedStart.date(7);

        let formattedEnd = dayjs(data?.[1]?.ends_at);
        formattedEnd = formattedEnd.year(1941);
        formattedEnd = formattedEnd.month(11);
        formattedEnd = formattedEnd.date(7);

        startTimes[data?.[0]?.id] = formattedStart;
        endTimes[data?.[0]?.id] = formattedEnd;
      });

      this.setState({ startTimes, endTimes });
    }
  }

  /**
   * Handles the onChange event when a new time is inputted
   * @param {*} event The onChange event
   * @param {"start" | "end"} name The time box that was changed
   */
  onTimeChange(event, name, id) {
    dayjs.extend(customParseFormat);
    let formattedTime = dayjs(event.target.value, 'HH:mm');
    formattedTime = formattedTime.year(1941);
    formattedTime = formattedTime.month(11);
    formattedTime = formattedTime.date(7);

    if (name === 'start') {
      this.setState(prevState => {
        const { startTimes } = prevState;
        startTimes[id] = formattedTime;
        return { startTimes };
      });
    } else {
      this.setState(prevState => {
        const { endTimes } = prevState;
        endTimes[id] = formattedTime;
        return { endTimes };
      });
    }
  }

  /**
   * Checks whether the assignment should be disabled because two tasks are being assigned to the applicant
   * at an overlapping time
   * @param {*} selectedData The data to be assigned
   * @returns True if the assignment is disabled, false if the assignment is allowed
   */
  assignDisabled = (selectedData) => {
    for (let i = 0; i < selectedData.length; i++) {
      const iId = selectedData[i]?.[0]?.id;
      for (let j = i + 1; j < selectedData.length; j++) {
        const jId = selectedData[j]?.[0]?.id;
        if (this.timeWindowsOverlap(this.state.startTimes[iId], this.state.endTimes[iId], this.state.startTimes[jId], this.state.endTimes[jId])) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Checks whether two times overlap
   * @param {*} startTime1 The start time for the first time
   * @param {*} endTime1 The end time for the first time
   * @param {*} startTime2 The start time for the second time
   * @param {*} endTime2 The end time for the second time
   * @returns True if the times overlap, False if the times don't overlap
   */
  timeWindowsOverlap = (startTime1, endTime1, startTime2, endTime2) => dayjs(endTime1).isAfter(dayjs(startTime2)) && dayjs(endTime2).isAfter(dayjs(startTime1));

  /**
   * Handles hiting the check mark when rows have been selected
   * @param {*} selectedData The data that has been selected in the table
   */
  handleCheck = (selectedData) => {
    const checkedData = selectedData.filter(row => row.tableData.checked);
    if (this.assignDisabled(checkedData)) {
      this.setState({
        snackbarOpen: true,
      });
      return;
    }
    checkedData.forEach(data => {
      data[1] = { starts_at: this.state.startTimes?.[data?.[0]?.id].format(), ends_at: this.state.endTimes?.[data?.[0]?.id].format() };
    });
    this.props.handleConfirm(checkedData);
  };

  /** Closes the snackbar when the X is hit */
  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  /**
   * Renders the time window for the task that the applicant will be assigned for
   * @param {*} task The task the applicant will be assigned to
   */
  renderTaskTimeWindows = (task) => {
    if (task?.year === this.props.year) {
      return <div className={styles['nowrap']}>{`${formatTime(task?.starts_at)}-${formatTime(task?.ends_at)}`}</div>;
    }
    return (
      <div> - </div>
    );
  };

  /**
   * Checks whether the time in the picker is a valid time for the person to work
   * It must be inside the task time and the assignees availability
   * @param {*} task The task you are assigning the person to
   * @returns {boolean} Whether the time in the picker is vailid
   */
  timeEntryInvalid = (task) => {
    const time = { starts_at: this.state.startTimes?.[task?.id], ends_at: this.state.endTimes?.[task?.id] };
    if (time.starts_at && time.ends_at) {
      return dayjs(time.starts_at) >= dayjs(time.ends_at)
      || !this.timeInsideAvailableWindows(this.props.selectedApplicant, time)
      || !this.timeInsideTaskTimeWindow(task, time);
    }
    return true;
  };

  /**
   * Checks whether the time in the picker is within the assignee's availability windows
   * @param {*} assignee The person being assigned a task
   * @param {*} time The time in the picker
   * @returns {boolean} Whether the time in the picker is inside the assignee's availability
   */
  timeInsideAvailableWindows = (assignee, time) => {
    let inside = false;
    for (let i = 0; i < assignee?.time_windows.length; i++) {
      // some time inputs for applicants include seconds. Setting the seconds to 0 removes any issues with that
      inside = inside
        || (dayjs(time.starts_at) >= dayjs(assignee.time_windows[i]?.starts_at).second(0)
        && dayjs(time.ends_at) <= dayjs(assignee.time_windows[i]?.ends_at).second(0));
    }
    return inside;
  };

  /**
   * Checks whether the time in the pickers is within the task time
   * @param {*} task The task that the person is being assigned to
   * @param {*} time The time in the picker
   * @returns {boolean} Whether the time in the picker is within the task's time
   */
  timeInsideTaskTimeWindow = (task, time) => {
    if (task && time.starts_at && time.ends_at) {
      return (dayjs(time.starts_at) >= dayjs(task?.starts_at)
      && dayjs(time.ends_at) <= dayjs(task?.ends_at));
    }
    return false;
  };

  /**
   * Renders a rows time picker for the user to change the time of the assignment
   * @param {*} rowData The data for the row whose time picker's need to be rendered
   * @returns The time pickers for the row
   */
  renderTimePicker = (rowData) => {
    const startTimeValue = this.state?.startTimes?.[rowData?.id];
    const endTimeValue = this.state?.endTimes?.[rowData?.id];
    return (
      <div className={styles['assignment-edit-time-dialog']}>
        <input
          type="time"
          id="begin_time"
          min="06:00"
          max="20:00"
          value={dayjs(startTimeValue).format('HH:mm')}
          onChange={event => this.onTimeChange(event, 'start', rowData?.id)}
          required
        />
        <div className={styles['time-window-to']}>to</div>
        <input
          type="time"
          id="end_time"
          min="06:00"
          max="20:00"
          value={dayjs(endTimeValue).format('HH:mm')}
          onChange={event => this.onTimeChange(event, 'end', rowData?.id)}
          required
        />
      </div>
    );
  };

  render() {
    return (
      <div className={styles['taa-confirm-table-container']}>
        <ReusableTable
          title=""
          columns={this.state.columns}
          options={this.state.options}
          data={this.props.assignedTasksData}
          actions={this.state.actions}
          tableRef={this.props.tableRefATAConfirm}
        />
        <ReusableSnackbar
          open={this.state.snackbarOpen}
          onClose={this.handleSnackbarClose}
          variant="error"
          message="The assignment times you have selected overlap, tasks cannot be assigned"
        />
      </div>
    );
  }
}

ATAConfirmTable.propTypes = {
  assignedTasksData: PropTypes.array.isRequired,
  selectedApplicant: PropTypes.object.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  tableRefATAConfirm: PropTypes.object.isRequired,
};

export default ATAConfirmTable;
