import { connect } from 'react-redux';

import { selectAssignmentPrioritiesLoading, selectAssignmentPriorities } from 'selectors/activitiesSelectors';
import {
  getAssignmentPriorities,
  updateAssignmentPriority,
} from 'actions/thunks/activities';
import AssignmentPrioritiesPage from 'components/pages/admin/AssignmentPrioritiesPage';
import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';
import { getYear } from 'actions/thunks/systemSettings';
import { getEventDates } from 'actions/thunks/eventDates';
import { selectYear } from 'selectors/systemSettingsSelectors';
import { selectEventDates } from 'selectors/eventDatesSelectors';

const mapStateToProps = state => ({
  assignmentPrioritiesLoading: selectAssignmentPrioritiesLoading(state),
  assignmentPriorities: selectAssignmentPriorities(state),
  isAdmin: selectIsAdmin(state),
  isLoggedIn: selectIsLoggedIn(state),
  year: selectYear(state),
  eventDates: selectEventDates(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAssignmentPriorities: () => dispatch(getAssignmentPriorities()),
  updateAssignmentPriority: (newAssignmentPriority) => dispatch(updateAssignmentPriority(newAssignmentPriority)),
  getYear: () => dispatch(getYear()),
  getEventDates: () => dispatch(getEventDates()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignmentPrioritiesPage);
