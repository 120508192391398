import ApiWrapper from 'wrappers/api';
import fileDownload from 'js-file-download';

import {
  getStatusesRequestSent,
  getStatusesRequestSuccessful,
  updateStatusRequestSent,
  updateStatusRequestSuccessful,
  createStatusRequestSent,
  createStatusRequestSuccessful,
} from 'actions/atomic/statuses';

export function getStatuses() {
  return (dispatch) => {
    dispatch(getStatusesRequestSent());
    return ApiWrapper.get('/statuses')
      .then(response => {
        dispatch(getStatusesRequestSuccessful(response.data));
      });
  };
}

export function createStatus(newStatus) {
  return (dispatch) => {
    dispatch(createStatusRequestSent());
    return ApiWrapper.post('/statuses', newStatus)
      .then(response => {
        dispatch(createStatusRequestSuccessful(response.data));
      });
  };
}

export function updateStatus(newStatus) {
  return (dispatch) => {
    dispatch(updateStatusRequestSent());
    return ApiWrapper.put(`/statuses/${newStatus.id}`, newStatus)
      .then(response => {
        dispatch(updateStatusRequestSuccessful(response.data));
      });
  };
}

export function downloadStatuses() {
  return () => ApiWrapper.get('/statuses/download', { responseType: 'blob' })
    .then(response => fileDownload(response.data, 'statuses.xlsx'));
}
