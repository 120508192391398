import { fromJS } from 'immutable';

import {
  GET_STATUSES_SENT,
  GET_STATUSES_SUCCESSFUL,
  UPDATE_STATUS_SENT,
  UPDATE_STATUS_SUCCESSFUL,
  CREATE_STATUS_SENT,
  CREATE_STATUS_SUCCESSFUL,
} from 'actions/atomic/statuses';

const initialState = fromJS({
  loading: false,
  data: [],
});

export default function statuses(state = initialState, action) {
  switch (action.type) {
  case GET_STATUSES_SENT:
    return state.merge({
      loading: true,
      data: fromJS([]),
    });
  case GET_STATUSES_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: fromJS(action.data),
    });
  case CREATE_STATUS_SENT:
    return state.set('loading', true);
  case CREATE_STATUS_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: state.get('data').push(fromJS(action.data)),
    });
  case UPDATE_STATUS_SENT:
    return state.set('loading', true);
  case UPDATE_STATUS_SUCCESSFUL:
    const updatedStatus = action.data;
    const index = state.get('data').findIndex(status => status.get('id') === updatedStatus.id);
    return state.merge({
      loading: false,
      data: state.get('data').set(index, fromJS(updatedStatus)),
    });
  default:
    return state;
  }
}
