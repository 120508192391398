export const LOGIN_REQUEST_SENT = 'LOGIN_REQUEST_SENT';
export const LOGIN_REQUEST_SUCCESSFUL = 'LOGIN_REQUEST_SUCCESSFUL';
export const LOGIN_REQUEST_FAILED = 'LOGIN_REQUEST_FAILED';
export const CLEAR_FAILED_LOGIN = 'CLEAR_FAILED_LOGIN';
export const VALIDATE_LOGIN_REQUEST_SUCCESSFUL = 'VALIDATE_LOGIN_REQUEST_SUCCESSFUL';
export const CLEAR_LOGIN_INFO = 'CLEAR_LOGIN_INFO';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';

export const loginRequestSent = () => ({ type: LOGIN_REQUEST_SENT });

export const loginRequestSuccessful = (data) => ({ type: LOGIN_REQUEST_SUCCESSFUL, data });

export const loginRequestFailed = () => ({ type: LOGIN_REQUEST_FAILED });

export const clearFailedLogin = () => ({ type: CLEAR_FAILED_LOGIN });

export const validateLoginRequestSuccessful = (data) => ({ type: VALIDATE_LOGIN_REQUEST_SUCCESSFUL, data });

export const clearLoginInfo = () => ({ type: CLEAR_LOGIN_INFO });

export const updateCurrentUser = (data) => ({ type: UPDATE_CURRENT_USER, data });
