import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button } from '@material-ui/core/';
import PropTypes from 'prop-types';

import styles from '../style.module.scss';

const dialogTitleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(dialogTitleStyles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

/** Generates styles for the checkbox rows in the filter dialog and changes the color of the label */
const statusFilterCheckboxRowStyle = (color) => ({
  marginTop: '-7px',
  marginBottom: '-7px',
  marginLeft: '2px',
  color,
});

class ATATableFilterDialog extends React.Component {
  render() {
    return (
      <Dialog open={this.props.filterOpen} onClose={this.props.handleCloseFilter} style={{ width: '1000px' }}>
        <DialogTitle />
        <DialogContent className={styles['confirm-dialog']}>
          <div className={`${styles['filter-non-checkbox-list-row']}`}>
            <b className={`${styles['ata-filter-preference-title']}`}> Preferences: </b>
            {Object.keys(this.props.checkedPreferenceTypes).every(preferenceType => this.props.checkedPreferenceTypes[preferenceType] === true)
              ? <Button size="small" variant="contained" onClick={this.props.handleSelectNonePreferenceTypes}>None</Button>
              : <Button size="small" variant="contained" onClick={this.props.handleSelectAllPreferenceTypes}>All</Button>}
          </div>
          <FormGroup>
            {(this.props.preferenceTypes) && (
              this.props.preferenceTypes.map(pt => (
                <FormControlLabel
                  key={pt.label}
                  control={(
                    <Checkbox
                      color="default"
                      size="small"
                      disableRipple
                      checked={this.props.checkedPreferenceTypes[pt.label] ?? true}
                      name={`${pt.label}`}
                      onChange={this.props.handlePreferenceTypeCheckbox}
                    />
                  )}
                  label={pt.label}
                  style={statusFilterCheckboxRowStyle(pt.color)}
                />
              ))
            )}
          </FormGroup>
          <br />
        </DialogContent>
      </Dialog>
    );
  }
}

ATATableFilterDialog.propTypes = {
  filterOpen: PropTypes.bool.isRequired,
  checkedPreferenceTypes: PropTypes.object.isRequired,
  preferenceTypes: PropTypes.array.isRequired,
  handleSelectNonePreferenceTypes: PropTypes.func.isRequired,
  handleSelectAllPreferenceTypes: PropTypes.func.isRequired,
  handlePreferenceTypeCheckbox: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
};

export default ATATableFilterDialog;
