import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ReusableButton from 'components/common/ReusableButton';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import UserAccountForm from 'components/common/UserAccountForm';

import dayjs from 'dayjs';
import history from 'wrappers/history';
import { formatHumanDate } from '../admin/AdminUtils.ts';
import styles from './style.module.scss';

class VolunteerDashboardPage extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.isLoggedIn) {
      history.push('/login');
    }

    this.state = {
      snackbarOpen: false,
    };
  }

  componentDidMount = () => {
    if (this.props.currentYear === '') {
      this.props.getYear();
    }

    if (this.props.eventDates.length === 0) {
      this.props.getEventDates();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.updateCurrentUserRequestComplete && this.props.updateCurrentUserRequestComplete) {
      this.setState({ snackbarOpen: true });
    }
  };

  get currentApplication() {
    if (!this.props.currentUser) {
      return null;
    }
    const currentYearApplication = this.props.currentUser.applications.filter(application => application.year === this.props.currentYear);
    return currentYearApplication.length > 0 ? currentYearApplication[0] : null;
  }

  get otherApplications() {
    if (!this.props.currentUser) {
      return [];
    }
    return this.props.currentUser.applications.filter(application => application.year !== this.props.currentYear);
  }

  get isApplicationComplete() {
    return this.currentApplication.application_finalized;
  }

  get currentEventDate() {
    return this.props.eventDates.filter(eventDate => eventDate.event_id === this.props.currentYear)[0];
  }

  get currentEventDateHumanReadable() {
    return this.formatEventDateHumanReadable(this.currentEventDate);
  }

  get formattedApplicationCloseDate() {
    if (this.currentEventDate) {
      return dayjs(this.currentEventDate.hard_lock).format('MMM D, YYYY');
    }

    return '';
  }

  get currentDateInApplicationPeriod() {
    if (this.currentEventDate) {
      return dayjs(this.currentEventDate.application_open) <= dayjs() && dayjs() <= dayjs(this.currentEventDate.hard_lock);
    }

    return false;
  }

  get currentDateBeforeApplicationOpen() {
    if (this.currentEventDate) {
      return dayjs() <= dayjs(this.currentEventDate.application_open);
    }
    return false;
  }

  getEventDateOf(eventId) {
    return this.props.eventDates.filter(eventDate => eventDate.event_id === eventId)[0];
  }

  closeSnackbar = () => {
    this.setState({ snackbarOpen: false });
  };

  formatRolesString = application => [
    { name: 'Activity Worker', value: application.activity_worker },
    { name: 'Department Display', value: application.department_display },
    { name: 'Speaker', value: application.speaker },
  ].filter(role => role.value).map(role => role.name);

  formatEventDateHumanReadable(eventDate) {
    if (!eventDate) {
      return 'N/A';
    }
    return formatHumanDate(eventDate.held_on);
  }

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['dashboard-container']}>
              <h2 className={styles['page-header']}>
                Dashboard
              </h2>
              <div className={styles['page-body']}>
                <div className={styles['account-info-container']}>
                  <h4>
                    My Account Information
                  </h4>
                  <hr />
                  <UserAccountForm
                    submitButtonText="Update Account Information"
                    handleSubmit={this.props.updateCurrentUser}
                    validateLogin={this.props.validateLogin}
                    genderOptions={this.props.genderOptions}
                    existingUser={this.props.currentUser}
                  />
                </div>
                <div className={styles['application-info-container']}>
                  <h4 className={styles['column-header']}>
                    My Applications
                  </h4>
                  <hr />
                  <div>
                    <h5>
                      Current Event (
                      {this.currentEventDateHumanReadable}
                      )
                    </h5>
                    {!this.currentApplication
                    && !this.currentDateInApplicationPeriod
                    && (
                      <div>
                        {this.props.currentYear === dayjs().year() && this.currentDateBeforeApplicationOpen
                          ? `Applications have not opened yet for the event on ${this.currentEventDateHumanReadable}.`
                          : `You did not submit an application for the event on ${this.currentEventDateHumanReadable}.`}
                      </div>
                    )}
                    {!this.currentApplication
                    && this.currentDateInApplicationPeriod
                    && (
                      <div>
                        You currently do not have an application for&nbsp;
                        {this.currentEventDateHumanReadable}
                        .
                        <ReusableButton
                          value="Start Application"
                          hasTopMargin
                          onClick={() => history.push(`/volunteer/process/${this.props.currentYear}`)}
                        />
                      </div>
                    )}
                    {this.currentApplication
                    && this.currentDateInApplicationPeriod
                    && (
                      <div>
                        Your application for&nbsp;
                        {this.currentEventDateHumanReadable}
                        &nbsp;event is currently
                        {this.isApplicationComplete ? ' complete' : ' incomplete'}
                        . You may continue to edit your application until&nbsp;
                        {this.formattedApplicationCloseDate}
                        .
                        <ReusableButton
                          value="Edit"
                          hasTopMargin
                          onClick={() => history.push(`/volunteer/process/${this.props.currentYear}`)}
                        />
                      </div>
                    )}
                    {this.currentApplication
                    && !this.currentDateInApplicationPeriod
                    && (
                      <div>
                        Your application for&nbsp;
                        {this.currentEventDateHumanReadable}
                        &nbsp;is currently
                        {this.isApplicationComplete ? ' complete' : ' incomplete'}
                        . The application period has closed, but you may continue to view your application.
                        <ReusableButton
                          value="View"
                          hasTopMargin
                          onClick={() => history.push(`/volunteer/past/${this.props.currentYear}`)}
                        />
                      </div>
                    )}
                    <h5>
                      Past Events
                    </h5>
                    {this.otherApplications.length === 0
                    && (
                      <div>
                        You do not have any other applications.
                      </div>
                    )}
                    {this.otherApplications.length > 0
                    && (
                      <TableContainer component={Paper}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell className={styles['smaller-mobile-margins']}>
                                Event Date
                              </TableCell>
                              <TableCell className={styles['smaller-mobile-margins']}>
                                Roles
                              </TableCell>
                              <TableCell className={styles['smaller-mobile-margins']}>
                                Status
                              </TableCell>
                              <TableCell className={styles['smaller-mobile-margins']}>
                                Tasks
                              </TableCell>
                              <TableCell align="right" className={styles['view-button']} />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.otherApplications.sort((a, b) => (a.year > b.year ? -1 : 1)).map(application => (
                              <TableRow key={application.id}>
                                <TableCell component="th" scope="row" className={styles['smaller-mobile-margins']}>
                                  {this.formatEventDateHumanReadable(this.getEventDateOf(application.year))}
                                </TableCell>
                                <TableCell className={styles['smaller-mobile-margins']}>
                                  {this.formatRolesString(application)}
                                </TableCell>
                                <TableCell className={styles['smaller-mobile-margins']}>
                                  {application.status ? application.status.name : 'N/A'}
                                </TableCell>
                                <TableCell className={styles['smaller-mobile-margins']}>
                                  {/* TODO: Implement display of assignments here when that's done */}
                                  None
                                </TableCell>
                                <TableCell align="right" className={styles['view-button']}>
                                  <ReusableButton
                                    value="View"
                                    onClick={() => history.push(`/volunteer/past/${application.year}`)}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </div>
                </div>
              </div>
              <ReusableSnackbar
                open={this.state.snackbarOpen}
                onClose={this.closeSnackbar}
                variant="success"
                message="Account information updated successfully."
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VolunteerDashboardPage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentYear: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  getYear: PropTypes.func.isRequired,
  genderOptions: PropTypes.array.isRequired,
  updateCurrentUser: PropTypes.func.isRequired,
  updateCurrentUserRequestComplete: PropTypes.bool.isRequired,
  validateLogin: PropTypes.func.isRequired,
  eventDates: PropTypes.array.isRequired,
  getEventDates: PropTypes.func.isRequired,
};

export default VolunteerDashboardPage;
