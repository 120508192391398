import { connect } from 'react-redux';

import { selectEventDatesLoading, selectEventDates } from 'selectors/eventDatesSelectors';
import {
  getEventDates,
  createEventDate,
  updateEventDate,
} from 'actions/thunks/eventDates';
import EventDatesPage from 'components/pages/admin/EventDatesPage';
import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';

const mapStateToProps = state => ({
  loading: selectEventDatesLoading(state),
  eventDates: selectEventDates(state),
  isAdmin: selectIsAdmin(state),
  isLoggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  getEventDates: () => dispatch(getEventDates()),
  createEventDate: (newEventDate) => dispatch(createEventDate(newEventDate)),
  updateEventDate: (newEventDate) => dispatch(updateEventDate(newEventDate)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventDatesPage);
