import React from 'react';
import PropTypes from 'prop-types';

import RepeatIcon from '@material-ui/icons/Repeat';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ReusableTooltip from 'components/common/ReusableTooltip';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import ReusableButton from 'components/common/ReusableButton';
import ReusableTextField from 'components/common/ReusableTextField';
import dayjs from 'dayjs';
import styles from './style.module.scss';

class TaskSignupStep extends React.Component {
  onTimeChange(event, name) {
    dayjs.extend(customParseFormat);
    const formattedTime = dayjs(event.target.value, 'HH:mm');
    this.props.handleTimeChange(name, formattedTime);
  }

  get formattedEventDate() {
    if (!this.props.currentEventDate) {
      return '';
    }

    return dayjs(this.props.currentEventDate.held_on).format('MMM D, YYYY');
  }

  render() {
    return (
      <div className={`${styles['task-signup-container']} ${this.props.fromAdmin ? styles['from-admin'] : ''}`}>
        <div className={styles['time-column']}>
          <div className={styles['time-window-container']}>
            {!this.props.fromAdmin
            && (
              <div className={styles['time-window-header']}>
                <h5 className={styles['no-top-margin']}>
                  1. When are you free on&nbsp;
                  {this.formattedEventDate}
                  ?
                  <ReusableTooltip title={'Using the ADD button, specify all your available time intervals. '
                  + 'As relevant tasks are highlighted on the right, specify your preferences. '
                  + 'Repeat as needed'}
                  >
                    <InfoIcon fontSize="small" color="action" />
                  </ReusableTooltip>
                </h5>
                <div className={styles['task-signup-arrows']}>
                  <RepeatIcon fontSize="large" />
                </div>
              </div>
            )}
            <div className={styles['time-window-entry']}>
              <div className={styles['time-entry-row']}>
                <ReusableButton
                  value="Add"
                  onClick={this.props.addTimeWindow}
                  disabled={this.props.timeEntryInvalid}
                  tooltipText={this.props.timeEntryInvalid ? 'Time interval is invalid. Start time must be before end time, the start time must be no earlier than 6 AM, and the end time must be no later than 5:30 PM.' : ''}
                  className={styles['add-time-window-button']}
                />
                <input
                  className={`${styles['time-window-picker']} ${styles['time-window-picker-spacing']}`}
                  type="time"
                  id="begin_time"
                  min="06:00"
                  max="20:00"
                  value={this.props.currentStartTime?.hour() ? dayjs(this.props.currentStartTime).format('HH:mm') : undefined}
                  onChange={event => this.onTimeChange(event, 'taskSignupCurrentStartTime')}
                  required
                />
                <div className={styles['time-window-to']}>to</div>
                <input
                  className={styles['time-window-picker']}
                  type="time"
                  id="end_time"
                  min="06:00"
                  max="20:00"
                  value={this.props.currentEndTime?.hour() ? dayjs(this.props.currentEndTime).format('HH:mm') : undefined}
                  onChange={event => this.onTimeChange(event, 'taskSignupCurrentEndTime')}
                  required
                />
              </div>
            </div>
            <div className={styles['selected-time-windows']}>
              {this.props.enteredTimeWindows.length > 0
                ? (
                  <div>
                    You are free during these times:
                    {this.props.enteredTimeWindows.map(timeWindow => (
                      <div key={this.props.formatTimeWindow(timeWindow)} className={styles['time-window-row']}>
                        {this.props.formatTimeWindow(timeWindow)}
                        <IconButton onClick={() => this.props.removeTimeWindow(timeWindow)}>
                          <CloseIcon fontSize="small" color="action" />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                )
                : (
                  <div className={styles['warning-text']}>
                    <b>
                      You have not entered your availability
                    </b>
                  </div>
                )}
            </div>
          </div>
          {!this.props.fromAdmin
          && (
            <div className={styles['comments-container']}>
              <h5>
                3. Anything else?
              </h5>
              <ReusableTextField
                id="taskSignupComments"
                label="Comments"
                multiline
                rows="4"
                value={this.props.comments}
                onChange={this.props.handleChange('comments')}
              />
            </div>
          )}
        </div>
        <div className={styles['task-column']}>
          {!this.props.fromAdmin && (
            <h5 className={styles['no-top-margin']}>
              2. Choose preferences for tasks
            </h5>
          )}
          <div>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Task
                    </TableCell>
                    <TableCell>
                      Time
                    </TableCell>
                    <TableCell>
                      Preference
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.tasks.map(taskGrouping => (
                    <tr className={this.props.isApplicantAvailableForTask(taskGrouping) ? styles['available-for-task'] : ''} key={taskGrouping.task_ids[0]}>
                      <TableCell scope="row">
                        <div className={styles['task-name-container']}>
                          {taskGrouping.display_string ? taskGrouping.display_string : taskGrouping.name}
                          {taskGrouping.description
                          && (
                            <ReusableTooltip title={taskGrouping.description}>
                              <InfoIcon fontSize="small" color="action" />
                            </ReusableTooltip>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={styles['task-time-window']}>
                          {this.props.formatTaskTimeWindow(taskGrouping)}
                        </div>
                      </TableCell>
                      <TableCell>
                        <FormControl>
                          <Select
                            id="demo-simple-select"
                            value={this.props.taskPreferences[`task${taskGrouping.task_ids[0]}`]}
                            onChange={this.props.handleTaskPreferenceChange(taskGrouping)}
                            disabled={!this.props.isApplicantAvailableForTask(taskGrouping)}
                          >
                            <MenuItem value={4}>Preferred</MenuItem>
                            <MenuItem value={3}>Acceptable</MenuItem>
                            <MenuItem value={2}>Avoid</MenuItem>
                            <MenuItem value={1}>Can&apos;t Do</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    </tr>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        {!this.props.fromAdmin
        && (
          <div className={styles['comments-container-mobile']}>
            <h5>
              3. Anything else?
            </h5>
            <ReusableTextField
              id="taskSignupComments"
              label="Comments"
              multiline
              rows="4"
              value={this.props.comments}
              onChange={this.props.handleChange('taskSignupComments')}
            />
          </div>
        )}
      </div>
    );
  }
}

TaskSignupStep.propTypes = {
  addTimeWindow: PropTypes.func.isRequired,
  timeEntryInvalid: PropTypes.bool.isRequired,
  currentStartTime: PropTypes.object,
  currentEndTime: PropTypes.object,
  handleTimeChange: PropTypes.func.isRequired,
  enteredTimeWindows: PropTypes.array.isRequired,
  formatTimeWindow: PropTypes.func.isRequired,
  removeTimeWindow: PropTypes.func.isRequired,
  comments: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  tasks: PropTypes.array.isRequired,
  isApplicantAvailableForTask: PropTypes.func.isRequired,
  formatTaskTimeWindow: PropTypes.func.isRequired,
  handleTaskPreferenceChange: PropTypes.func.isRequired,
  taskPreferences: PropTypes.object.isRequired,
  currentEventDate: PropTypes.object.isRequired,
  fromAdmin: PropTypes.bool,
};

export default TaskSignupStep;
