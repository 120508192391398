import { createSelector } from 'reselect';

export const selectAffiliationsLoading = createSelector(
  (state) => state.getIn(['affiliations', 'loading']),
  (loading) => loading,
);

export const selectAffiliations = createSelector(
  (state) => state.getIn(['affiliations', 'data']),
  (data) => data.toJS(),
);
