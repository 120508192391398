import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { CustomMTableBodyRow } from 'components/common/custom-material-table-components/CustomMTableBodyRow';
import CustomMTableHeader from 'components/common/custom-material-table-components/CustomMTableHeader';
import CustomMTableToolbar from 'components/common/custom-material-table-components/CustomMTableToolbar';
import './style.module.scss';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AccessTime from '@material-ui/icons/AccessTime';

const tableIcons = {
  AccessTime: forwardRef((props, ref) => <AccessTime {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class ReusableTable extends React.Component {
  constructor(props) {
    super(props);
    this.components = {
      Header: componentProps => (
        <CustomMTableHeader {...componentProps} />
      ),
      Row: componentProps => (
        <CustomMTableBodyRow {...componentProps} />
      ),
      Toolbar: componentProps => (
        <CustomMTableToolbar {...{
          ...componentProps, renderYearOptions: this.props.renderYearOptions, customToolbarElements: this.props.customToolbarElements, jobSelected: this.props.jobSelected,
        }}
        />
      ),
    };
  }

  render() {
    const columns = [];
    this.props.columns.forEach((column) => {
      columns.push({
        ...column,
        cellStyle: {
          fontFamily: 'Gotham SSm A, Gotham SSm B, Verdana, sans-serif',
          ...column.cellStyle,
        },
      });
    });
    const options = {
      paging: !this.props.options.showFull,
      pageSize: 10,
      pageSizeOptions: [10, 20, 40, this.props.data.length],
      addRowPosition: 'first',
      columnsButton: true,
      ...this.props.options,
      headerStyle: {
        fontFamily: 'Gotham SSm A, Gotham SSm B, Verdana, sans-serif',
        backgroundColor: '#eae9e6',
        color: '#d00000',
        whiteSpace: 'nowrap',
        ...this.props.options.headerStyle,
      },
    };
    return (
      <MaterialTable
        icons={tableIcons}
        title={this.props.title}
        columns={columns}
        options={options}
        data={this.props.data}
        editable={this.props.editable}
        isLoading={this.props.isLoading}
        components={this.components}
        actions={this.props.actions}
        tableRef={this.props.tableRef}
        onSelectionChange={this.props.onSelectionChange}
      />
    );
  }
}

ReusableTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  editable: PropTypes.object,
  isLoading: PropTypes.bool,
  columns: PropTypes.array,
  options: PropTypes.object,
  actions: PropTypes.array,
  renderYearOptions: PropTypes.func,
  customToolbarElements: PropTypes.array,
  jobSelected: PropTypes.bool,
  tableRef: PropTypes.object,
  onSelectionChange: PropTypes.func,
};

export default ReusableTable;
