import ApiWrapper from 'wrappers/api';

import {
  getEmailTemplatesRequestSent,
  getEmailTemplatesRequestSuccessful,
  updateEmailTemplateRequestSent,
  updateEmailTemplateRequestSuccessful,
  createEmailTemplateRequestSent,
  createEmailTemplateRequestSuccessful,
  deleteEmailTemplateRequestSent,
  deleteEmailTemplateRequestSuccessful,
  getEmailMessagesRequestSent,
  getEmailMessagesRequestSuccessful,
  createEmailMessagesRequestSent,
  createEmailMessagesRequestSuccessful,
  setApplicantsSelectedSent,
  setRecruitersSelectedSent,
} from 'actions/atomic/emails';

export function getEmailTemplates() {
  return (dispatch) => {
    dispatch(getEmailTemplatesRequestSent());
    return ApiWrapper.get('/emails/email_templates')
      .then(response => {
        dispatch(getEmailTemplatesRequestSuccessful(response.data));
      });
  };
}

export function createEmailTemplate(newEmailTemplate) {
  return (dispatch) => {
    dispatch(createEmailTemplateRequestSent());
    return ApiWrapper.post('/emails/email_templates', newEmailTemplate)
      .then(response => {
        dispatch(createEmailTemplateRequestSuccessful(response.data));
      });
  };
}

export function updateEmailTemplate(newEmailTemplate) {
  return (dispatch) => {
    dispatch(updateEmailTemplateRequestSent());
    return ApiWrapper.put(`/emails/email_templates/${newEmailTemplate.id}`, newEmailTemplate)
      .then(response => {
        dispatch(updateEmailTemplateRequestSuccessful(response.data));
      });
  };
}

export function deleteEmailTemplate(oldTemplate) {
  return (dispatch) => {
    dispatch(deleteEmailTemplateRequestSent());
    return ApiWrapper.delete(`/emails/email_templates/${oldTemplate.id}`, oldTemplate)
      .then((response) => {
        dispatch(deleteEmailTemplateRequestSuccessful(response.data));
      });
  };
}

export function getEmailMessages() {
  return (dispatch) => {
    dispatch(getEmailMessagesRequestSent());
    return ApiWrapper.get('/emails/email_messages')
      .then(response => {
        dispatch(getEmailMessagesRequestSuccessful(response.data));
      });
  };
}

export function createEmailMessage(newEmailMessage) {
  return (dispatch) => {
    dispatch(createEmailMessagesRequestSent());
    return ApiWrapper.post('/emails/email_messages', newEmailMessage)
      .then(response => {
        dispatch(createEmailMessagesRequestSuccessful(response.data));
      });
  };
}

export function setApplicantsSelected(newApplicants) {
  return (dispatch) => {
    dispatch(setApplicantsSelectedSent(newApplicants));
  };
}

export function setRecruitersSelected(newRecruiters) {
  return (dispatch) => {
    dispatch(setRecruitersSelectedSent(newRecruiters));
  };
}
