import { connect } from 'react-redux';

import {
  selectIsError,
  selectYear,
  selectMOTD,
  selectEmailSignature,
  selectYaspFormLink,
} from 'selectors/systemSettingsSelectors';
import { selectEventDates } from 'selectors/eventDatesSelectors';
import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';
import {
  getYear,
  updateYear,
  getMOTD,
  updateMOTD,
  getEmailSignature,
  updateEmailSignature,
  getYaspFormLink,
  updateYaspFormLink,
} from 'actions/thunks/systemSettings';
import { getEventDates } from 'actions/thunks/eventDates';
import SystemSettingsPage from 'components/pages/admin/SystemSettingsPage';

const mapStateToProps = (state) => ({
  isError: selectIsError(state),
  year: selectYear(state),
  motd: selectMOTD(state),
  emailSignature: selectEmailSignature(state),
  yaspFormLink: selectYaspFormLink(state),
  eventDates: selectEventDates(state),
  isAdmin: selectIsAdmin(state),
  isLoggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  getYear: () => dispatch(getYear()),
  updateYear: (newYear) => dispatch(updateYear(newYear)),
  getMOTD: () => dispatch(getMOTD()),
  updateMOTD: (newMOTD) => dispatch(updateMOTD(newMOTD)),
  getEmailSignature: () => dispatch(getEmailSignature()),
  updateEmailSignature: (newEmailSignature) => dispatch(updateEmailSignature(newEmailSignature)),
  getYaspFormLink: () => dispatch(getYaspFormLink()),
  updateYaspFormLink: (newYaspFormLink) => dispatch(updateYaspFormLink(newYaspFormLink)),
  getEventDates: () => dispatch(getEventDates()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemSettingsPage);
