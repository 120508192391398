import { formatTime } from 'utils/date';
import React from 'react';
import dayjs from 'dayjs';
import { Tooltip } from '@material-ui/core';
import styles from './style.module.scss';

/**
 * Checks whether the task fits inside the applicant's availability windows
 * @param {*} applicant The applicant being assigned
 * @param {*} task The task being assigned to the applicant
 * @returns {boolean} Whether the task is in the applicant's time
 */
export const taskTimeInApplicantTime = (applicant, task) => {
  let inside = false;
  for (let i = 0; i < applicant?.time_windows?.length; i++) {
    const endTime = applicant.time_windows[i]?.ends_at;
    const startTime = applicant.time_windows[i]?.starts_at;

    inside = inside
      || (dayjs(task.starts_at) >= dayjs(startTime)
        && dayjs(task?.ends_at) <= dayjs(endTime))
      || (task.preemtable && (dayjs(task.starts_at) <= dayjs(startTime)
        && dayjs(task?.ends_at) >= dayjs(endTime)))
      || (task.preemtable && (dayjs(task.starts_at) <= dayjs(startTime)) && (dayjs(task.ends_at) > dayjs(startTime)))
      || (task.preemtable && (dayjs(task.starts_at) < dayjs(endTime)) && (dayjs(task.ends_at) >= dayjs(endTime)));
  }
  return inside;
};

export const applicantInsideTimeFilter = (applicant, beginTime, endTime) => {
  let inside = false;
  for (let i = 0; i < applicant?.time_windows.length; i++) {
    const timeTokens = applicant.time_windows[i]?.ends_at.split(':');
    // parsing out the minute field in order to increment it by one
    // add a leading zero to single digit minute number
    // the minute is incremented in order to make sure the end time is not invalid due when it is equal to end of person's availability
    timeTokens[1] = (Number(timeTokens[1]) + 1) < 10 ? `0${Number(timeTokens[1]) + 1}` : `${Number(timeTokens[1]) + 1}`;
    const newTokens = timeTokens.join(':');

    let applicantStartsAtHour = dayjs(applicant.time_windows[i].starts_at).hour();
    let applicantStartsAtMinute = dayjs(applicant.time_windows[i].starts_at).minute();
    // add a zero if the number is less than 10, so that 01:01 won't appear as 1:1
    applicantStartsAtHour = applicantStartsAtHour < 10 ? `0${applicantStartsAtHour}` : applicantStartsAtHour;
    applicantStartsAtMinute = applicantStartsAtMinute < 10 ? `0${applicantStartsAtMinute}` : applicantStartsAtMinute;

    let applicantEndsAtHour = dayjs(newTokens).hour();
    let applicantEndsAtMinute = dayjs(newTokens).minute();
    // add a zero if the number is less than 10, so that 01:01 won't appear as 1:1
    applicantEndsAtHour = applicantEndsAtHour < 10 ? `0${applicantEndsAtHour}` : applicantEndsAtHour;
    applicantEndsAtMinute = applicantEndsAtMinute < 10 ? `0${applicantEndsAtMinute}` : applicantEndsAtMinute;

    const applicantStartsAt = `${applicantStartsAtHour}:${applicantStartsAtMinute}`;
    const applicantEndsAt = `${applicantEndsAtHour}:${applicantEndsAtMinute}`;

    inside = inside
      || (beginTime >= applicantStartsAt
      && endTime <= applicantEndsAt);
  }
  return inside;
};

export const taskInsideTimeFilter = (task, beginTime, endTime) => {
  let taskStartsAtHour = dayjs(task.starts_at).hour();
  let taskStartsAtMinute = dayjs(task.starts_at).minute();
  // add a zero if the number is less than 10, so that 01:01 won't appear as 1:1
  taskStartsAtHour = taskStartsAtHour < 10 ? `0${taskStartsAtHour}` : taskStartsAtHour;
  taskStartsAtMinute = taskStartsAtMinute < 10 ? `0${taskStartsAtMinute}` : taskStartsAtMinute;

  let taskEndsAtHour = dayjs(task?.ends_at).hour();
  let taskEndsAtMinute = dayjs(task?.ends_at).minute();
  // add a zero if the number is less than 10, so that 01:01 won't appear as 1:1
  taskEndsAtHour = taskEndsAtHour < 10 ? `0${taskEndsAtHour}` : taskEndsAtHour;
  taskEndsAtMinute = taskEndsAtMinute < 10 ? `0${taskEndsAtMinute}` : taskEndsAtMinute;

  const taskStartsAt = `${taskStartsAtHour}:${taskStartsAtMinute}`;
  const taskEndsAt = `${taskEndsAtHour}:${taskEndsAtMinute}`;

  return (beginTime >= taskStartsAt
      && endTime <= taskEndsAt);
};

export const renderAssignedTime = (solutionAssignment) => `${formatTime(solutionAssignment.starts_at)}-${formatTime(solutionAssignment?.ends_at)}`;

/**
 * returns a string for the assignment type for the solution assignment (L or B)
 * @param {solutionAssignment} solutionAssignment
 * @returns {string} 'L' if the solution assignment is a Load assignment, 'B' if the solution assignemnt is a Backup assignment
 */
export const renderAssignedType = (solutionAssignment) => {
  if (solutionAssignment.back_up) {
    return 'B';
  }
  return 'L';
};

/**
 * renders the applicant's availability time in string format
 * if all the time windows are selected 'all tasks selected' is returned to avoid empty array error
 * @param {applicant} assignee
 * @param {boolean} allSelected
 * @returns {String} string of assignee's time windows formatted with semicolon delimiter
 */
export const renderApplicantTimeWindows = (assignee, allSelected) => {
  if (allSelected) {
    return 'All tasks selected';
  }
  assignee?.time_windows.sort((a, b) => (dayjs(a.starts_at).isBefore(dayjs(b.starts_at)) || dayjs(a.starts_at).isSame(dayjs(b.starts_at)) ? -1 : 1));
  const numWindows = assignee?.time_windows.length;
  let assigneeTimes = '';
  for (let i = 0; i < numWindows - 1; i++) {
    assigneeTimes = `${assigneeTimes}${dayjs(assignee.time_windows[i]?.starts_at).hour()}:${dayjs(assignee.time_windows[i]?.starts_at).minute() < 10
      ? `0${dayjs(assignee.time_windows[i]?.starts_at).minute()}`
      : dayjs(assignee.time_windows[i]?.starts_at).minute()}—${dayjs(assignee.time_windows[i]?.ends_at).hour()}:${dayjs(assignee.time_windows[i]?.ends_at).minute() < 10
      // eslint-disable-next-line indent
        ? `0${dayjs(assignee.time_windows[i]?.ends_at).minute()}`
      // eslint-disable-next-line indent
        : dayjs(assignee.time_windows[i]?.ends_at).minute()}; \n`;
  }
  if (numWindows > 0) {
    return `${assigneeTimes}${dayjs(assignee?.time_windows[numWindows - 1]?.starts_at).hour()}:${dayjs(assignee?.time_windows[numWindows - 1]?.starts_at).minute() < 10
      ? `0${dayjs(assignee?.time_windows[numWindows - 1].starts_at).minute()}`
      : dayjs(assignee?.time_windows[numWindows - 1]?.starts_at).minute()}—${dayjs(assignee?.time_windows[numWindows - 1]?.ends_at).hour()}:${dayjs(assignee?.time_windows[numWindows - 1]?.ends_at).minute() < 10
      // eslint-disable-next-line indent
        ? `0${dayjs(assignee?.time_windows[numWindows - 1]?.ends_at).minute()}`
      // eslint-disable-next-line indent
        : dayjs(assignee?.time_windows[numWindows - 1]?.ends_at).minute()}`;
  }
  return 'None';
};

export const getAssigneeAvailabilityString = (assignee) => {
  const numWindows = assignee?.time_windows.length;
  const availability = 'The applicant is available from ';
  let assigneeTimes = '';
  for (let i = 0; i < numWindows - 1; i++) {
    assigneeTimes = `${assigneeTimes}${dayjs(assignee.time_windows[i].starts_at).hour()}:${dayjs(assignee.time_windows[i].starts_at).minute() < 10
      ? `0${dayjs(assignee.time_windows[i].starts_at).minute()}`
      : dayjs(assignee.time_windows[i].starts_at).minute()}-${dayjs(assignee.time_windows[i]?.ends_at).hour()}:${dayjs(assignee.time_windows[i]?.ends_at).minute() < 10
      // eslint-disable-next-line indent
        ? `0${dayjs(assignee.time_windows[i]?.ends_at).minute()}`
      // eslint-disable-next-line indent
        : dayjs(assignee.time_windows[i]?.ends_at).minute()} and `;
  }
  return `${availability}${assigneeTimes}${dayjs(assignee?.time_windows[numWindows - 1].starts_at).hour()}:${dayjs(assignee?.time_windows[numWindows - 1].starts_at).minute() < 10
    ? `0${dayjs(assignee?.time_windows[numWindows - 1].starts_at).minute()}`
    : dayjs(assignee?.time_windows[numWindows - 1].starts_at).minute()}-${dayjs(assignee?.time_windows[numWindows - 1]?.ends_at).hour()}:${dayjs(assignee?.time_windows[numWindows - 1]?.ends_at).minute() < 10
    // eslint-disable-next-line indent
      ? `0${dayjs(assignee?.time_windows[numWindows - 1]?.ends_at).minute()}`
    // eslint-disable-next-line indent
      : dayjs(assignee?.time_windows[numWindows - 1]?.ends_at).minute()}`;
};

export const getTaskTimeString = (task) => `The task time is from ${dayjs(task?.starts_at).hour()}:${dayjs(task?.starts_at).minute() < 10
  ? `0${dayjs(task?.starts_at).minute()}`
  : dayjs(task?.starts_at).minute()}-${dayjs(task?.ends_at).hour()}:${dayjs(task?.ends_at).minute() < 10
  // eslint-disable-next-line indent
    ? `0${dayjs(task?.ends_at).minute()}`
  // eslint-disable-next-line indent
    : dayjs(task?.ends_at).minute()}`;

/**
 * Comparator for sorting table based by applicant last name then first name
 * @param {*} a1 Applicant 1
 * @param {*} a2 Applicant 2
 * @returns Numerical value for which comes first
 */
export const nameSortComparator = (a1, a2) => {
  const a1Name = `${a1.user?.last_name} ${a1.user?.first_name}`;
  const a2Name = `${a2.user?.last_name} ${a2.user?.first_name}`;

  return a1Name.localeCompare(a2Name);
};

export const renderAssignedName = (solutionAssignment) => {
  const shortLastName = solutionAssignment.user?.last_name.length > 9 ? `${solutionAssignment.user?.last_name.slice(0, 7)}...` : solutionAssignment.user?.last_name;
  return <Tooltip title={`${solutionAssignment.user?.first_name} ${solutionAssignment.user?.last_name}`}><p className={styles['no-wrap-margin']}>{`${solutionAssignment.user?.first_name.charAt(0)}. ${shortLastName}`}</p></Tooltip>;
};

/** Renders the task name for the ATA unassign table */
export const renderAssignedTaskName = (solutionAssignment) => (solutionAssignment.task.preemtable ? `${solutionAssignment.task.abbr.trim() || solutionAssignment.task.name} (P)` : solutionAssignment.task.abbr.trim() || solutionAssignment.task.name);

/** Renders the task name for the ATA Confirm table */
export const renderATAConfirmName = (task) => (task.preemtable ? `${task.abbr.trim() || task.name} (P)` : task.abbr.trim() || task.name);

export const customFilterByApplicantName = (term, applicant) => {
  const prefix = term.toLowerCase();
  const { user } = applicant;
  const firstName = user.first_name.toLowerCase();
  const lastName = user.last_name.toLowerCase();
  return firstName.startsWith(prefix) || lastName.startsWith(prefix)
    || `${firstName.charAt(0)}. ${lastName}`.startsWith(prefix)
    || `${firstName} ${lastName}`.startsWith(prefix);
};

export const customFilterByTaskName = (term, task) => {
  const prefix = term.toLowerCase();
  const { abbr, name } = task;
  return abbr.toLowerCase().indexOf(prefix) !== -1
    || name.toLowerCase().indexOf(prefix) !== -1;
};

/**
 * Calculates the load or backup to display for a task:
 * The total number of filled load/backup for normal tasks, or
 * The minimum number of load/backup at any time point for preemptible tasks
 * @param {*} task The task to display the load or backup value for
 * @param {*} solutionAssignments The list of assignments that have been made
 * @param {*} backup Whether to calculate the backup or not
 * @returns The calculate load or backup
 */
export const calculateLoadBackup = (task, solutionAssignments, backup) => {
  const sols = solutionAssignments.filter(s => s.task.id === task.id && s.back_up === backup);
  let numFilled = sols.length;
  if (task.preemtable) {
    const endpoints = [];
    sols.forEach(s => {
      endpoints.push(`${s.starts_at}|S`);
      endpoints.push(`${s.ends_at}|E`);
    });
    endpoints.push(`${task.starts_at}|A`); // whole task starts
    endpoints.push(`${task.ends_at}|Z`); // whole task ends
    endpoints.sort();

    let counter = 0;
    let lastTimepoint = '';
    endpoints.forEach(e => {
      const [timepoint, pointType] = e.split('|');
      if (lastTimepoint !== '' && lastTimepoint !== timepoint) {
        numFilled = Math.min(numFilled, counter);
      }
      lastTimepoint = timepoint;
      if (pointType === 'S') {
        counter++;
      } else if (pointType === 'E') {
        counter--;
      }
    });
  }
  return numFilled;
};

/**
 * Creates the text to be rendered in the table column for load or backup
 * @param {*} task The task to render the load or backup for
 * @param {*} solutionAssignments The list of assignments that have been made
 * @param {*} backup Whether to calculate the backup load or not
 * @returns The text to be rendered in the table
 */
export const renderLoadBackup = (task, solutionAssignments, backup) => {
  const numRequired = backup ? task.backup_load : task.load;
  const numFilled = calculateLoadBackup(task, solutionAssignments, backup);

  return `${numFilled}/${numRequired}`;
};

export const customFilterByTime = (term, task) => {
  const prefix = term.toLowerCase();
  const time = `${formatTime(task?.starts_at)}-${formatTime(task?.ends_at)}`;
  const start = formatTime(task?.starts_at);
  const end = formatTime(task?.ends_at);
  return start.toLowerCase().indexOf(prefix) !== -1
    || end.toLowerCase().indexOf(prefix) !== -1
    || time.toLowerCase().indexOf(prefix) !== -1;
};
