export const GET_STATUSES_SENT = 'GET_STATUSES_SENT';
export const GET_STATUSES_SUCCESSFUL = 'GET_STATUSES_SUCCESSFUL';

export const UPDATE_STATUS_SENT = 'UPDATE_STATUS_SENT';
export const UPDATE_STATUS_SUCCESSFUL = 'UPDATE_STATUS_SUCCESSFUL';

export const CREATE_STATUS_SENT = 'CREATE_STATUS_SENT';
export const CREATE_STATUS_SUCCESSFUL = 'CREATE_STATUS_SUCCESSFUL';

export const getStatusesRequestSent = () => ({ type: GET_STATUSES_SENT });

export const getStatusesRequestSuccessful = (data) => ({ type: GET_STATUSES_SUCCESSFUL, data });

export const updateStatusRequestSent = () => ({ type: UPDATE_STATUS_SENT });

export const updateStatusRequestSuccessful = (data) => ({ type: UPDATE_STATUS_SUCCESSFUL, data });

export const createStatusRequestSent = () => ({ type: CREATE_STATUS_SENT });

export const createStatusRequestSuccessful = (data) => ({ type: CREATE_STATUS_SUCCESSFUL, data });
