import ApiWrapper, { addTokenToHeader, clearTokenFromHeader } from 'wrappers/api';

import {
  loginRequestSent,
  loginRequestSuccessful,
  loginRequestFailed,
  clearFailedLogin,
  validateLoginRequestSuccessful,
} from 'actions/atomic/authentication';
import history from 'wrappers/history';
import { writeTokenToLocalStorage, clearTokenFromLocalStorage } from 'utils/authentication';

export function attemptLogin(payload) {
  return (dispatch) => {
    dispatch(loginRequestSent());
    return ApiWrapper.post('/auth/login', payload)
      .then(response => {
        const { token } = response.data;
        writeTokenToLocalStorage(token);
        addTokenToHeader(token);

        history.push('/');
        dispatch(loginRequestSuccessful(response.data));
      })
      .catch(() => {
        dispatch(loginRequestFailed());
      });
  };
}

export function resetFailedLogin() {
  return dispatch => dispatch(clearFailedLogin());
}

export function validateLogin() {
  return dispatch => ApiWrapper.post('/auth/validate')
    .then(response => {
      dispatch(validateLoginRequestSuccessful(response.data));
    });
}

export function logout() {
  return () => {
    clearTokenFromLocalStorage();
    clearTokenFromHeader();
    window.location.reload();
  };
}
