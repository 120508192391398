import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import ReusableTooltip from 'components/common/ReusableTooltip';
import styles from './style.module.scss';

class ReusableButton extends React.Component {
  render() {
    return (
      <div className={`${styles['button-container']} ${this.props.className}`}>
        <ReusableTooltip title={this.props.tooltipText ? this.props.tooltipText : ''}>
          <div>
            <Button
              variant={this.props.variant ? this.props.variant : 'contained'}
              color={this.props.color ? this.props.color : 'primary'}
              disabled={this.props.disabled ? this.props.disabled : false}
              className={`${this.props.hasTopMargin ? styles['include-margin-top'] : ''} ${styles['button']} ${this.props.color ? '' : styles['button-color']}`}
              onClick={this.props.onClick}
            >
              {this.props.value}
            </Button>
          </div>
        </ReusableTooltip>
      </div>
    );
  }
}

ReusableButton.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  hasTopMargin: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
  tooltipText: PropTypes.string,
};

export default ReusableButton;
