import axios from 'axios';
import history from 'wrappers/history';
import { store } from 'wrappers/store';
import {
  clearTokenFromLocalStorage,
  retrieveTokenFromLocalStorage,
} from 'utils/authentication';
import { clearLoginInfo } from 'actions/atomic/authentication';

const baseURL = process.env.PUBLIC_URL ? `${process.env.PUBLIC_URL}/api/v1` : 'http://localhost:8081/api/v1';
const storedToken = retrieveTokenFromLocalStorage();

const headers = {};

const ApiWrapper = axios.create({
  baseURL,
  headers,
});

export const addTokenToHeader = token => {
  ApiWrapper.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const clearTokenFromHeader = () => {
  delete ApiWrapper.defaults.headers.common['Authorization'];
};

if (storedToken !== null) {
  addTokenToHeader(storedToken);
}

ApiWrapper.interceptors.response.use(response => response, error => {
  // The user is not logged in, but is trying to access a resource that needs authorization.
  if (error.response.status === 401) {
    store.dispatch(clearLoginInfo());
    clearTokenFromLocalStorage();
    clearTokenFromHeader();
    history.push('/login');
    return Promise.resolve(error);
  }

  // The user is logged in, but does not have permission for the requested resource.
  if (error.response.status === 403) {
    history.push('/forbidden');
    return Promise.resolve(error);
  }

  // Unhandled error, redirect user to a generic error page.
  history.push(`/error/${error.response.status}`);
  return Promise.reject(error);
});

export default ApiWrapper;
