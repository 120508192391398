import { fromJS } from 'immutable';

import {
  GET_EVENT_DATES_SENT,
  GET_EVENT_DATES_SUCCESSFUL,
  CREATE_EVENT_DATE_SENT,
  CREATE_EVENT_DATE_SUCCESSFUL,
  UPDATE_EVENT_DATE_SENT,
  UPDATE_EVENT_DATE_SUCCESSFUL,
} from 'actions/atomic/eventDates';

const initialState = fromJS({
  loading: false,
  data: [],
});

export default function eventDates(state = initialState, action) {
  switch (action.type) {
  case GET_EVENT_DATES_SENT:
    return state.merge({
      loading: true,
      data: fromJS([]),
    });
  case GET_EVENT_DATES_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: fromJS(action.data),
    });
  case CREATE_EVENT_DATE_SENT:
    return state.set('loading', true);
  case CREATE_EVENT_DATE_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: state.get('data').push(fromJS(action.data)),
    });
  case UPDATE_EVENT_DATE_SENT:
    return state.set('loading', true);
  case UPDATE_EVENT_DATE_SUCCESSFUL:
    const updatedEventDate = action.data;
    const index = state.get('data').findIndex(eventDate => eventDate.get('id') === updatedEventDate.id);
    return state.merge({
      loading: false,
      data: state.get('data').set(index, fromJS(updatedEventDate)),
    });
  default:
    return state;
  }
}
