import { fromJS } from 'immutable';

import {
  GET_RECRUITERS_SENT,
  GET_RECRUITERS_SUCCESSFUL,
  CREATE_RECRUITER_SENT,
  CREATE_RECRUITER_SUCCESSFUL,
  UPDATE_RECRUITER_SENT,
  UPDATE_RECRUITER_SUCCESSFUL,
} from 'actions/atomic/recruiters';

const initialState = fromJS({
  loading: false,
  data: [],
});

export default function recruiters(state = initialState, action) {
  switch (action.type) {
  case GET_RECRUITERS_SENT:
    return state.merge({
      loading: true,
      data: fromJS([]),
    });
  case GET_RECRUITERS_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: fromJS(action.data),
    });
  case CREATE_RECRUITER_SENT:
    return state.set('loading', true);
  case CREATE_RECRUITER_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: state.get('data').push(fromJS(action.data)),
    });
  case UPDATE_RECRUITER_SENT:
    return state.set('loading', true);
  case UPDATE_RECRUITER_SUCCESSFUL:
    const updatedRecruiter = action.data;
    const index = state.get('data').findIndex(recruiter => recruiter.get('id') === updatedRecruiter.id);
    return state.merge({
      loading: false,
      data: state.get('data').set(index, fromJS(updatedRecruiter)),
    });
  default:
    return state;
  }
}
