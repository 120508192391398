import React from 'react';
import ReusableTable from 'components/common/ReusableTable';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { formatTime } from 'utils/date';

import styles from '../style.module.scss';
import {
  taskTimeInApplicantTime, customFilterByTaskName, renderLoadBackup, customFilterByTime, calculateLoadBackup,
} from '../utils';

class ApplicantTaskAssignTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preferred: [],
      acceptable: [],
      avoid: [],
      cantDo: [],
      // ATA Table
      applicantTaskAssignColumns: [
        {
          title: 'L(B)',
          tooltip: 'Toggle L(B)',
          render: (task) => this.ATAtoggleButton(task.id),
        },
        {
          title: 'Task',
          field: 'name',
          tooltip: 'Task Name',
          render: (rowData) => this.renderApplicantTaskRowName(rowData),
          customFilterAndSearch: customFilterByTaskName,
        },
        {
          title: 'Time',
          field: 'starts_at',
          tooltip: 'The time of the task.',
          render: (rowData) => this.renderColoredTimeApplicantTask(rowData),
          customFilterAndSearch: (term, task) => customFilterByTime(term, task),
          align: 'center',
        },
        {
          title: 'L',
          tooltip: 'The number of filled load / the total required load of the task.',
          align: 'right',
          customSort: (a, b) => (calculateLoadBackup(a, this.props.solutionAssignments, false) > calculateLoadBackup(b, this.props.solutionAssignments, false) ? -1 : 1),
          render: (rowData) => this.renderLoad(rowData),
        },
        {
          title: 'B',
          tooltip: 'The number of filled backup / the total required backup of the task.',
          align: 'right',
          customSort: (a, b) => (calculateLoadBackup(a, this.props.solutionAssignments, true) > calculateLoadBackup(b, this.props.solutionAssignments, true) ? -1 : 1),
          render: (rowData) => this.renderBackup(rowData),
        },
      ],
      applicantTaskAssignOptions: {
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 700,
        maxWidth: 100,
        selection: true,
        selectionProps: rowData => ({
          disabled: this.props?.selectedApplicant && (!taskTimeInApplicantTime(this.props.selectedApplicant, rowData) && !this.props.oldApplicantTaskAssignSelection.find(task => task?.id === rowData?.id)),
        }),
        hideDeleteButton: true,
        actionsColumnIndex: -1,
        showFull: false,
        search: false,
      },
      applicantTaskAssignActions: [
        {
          icon: 'check',
          tooltip: 'Assign Selected Applicants',
          displayDuringSelect: true,
          onClick: (event, data) => this.props.handleApplicantTaskAssign(data),
        },
        {
          icon: 'filter_alt',
          tooltip: 'Filter',
          position: 'toolbar',
          isFreeAction: true,
          onClick: () => this.props.handleOpenATAFilter(),
        },
      ],
    };
  }

  async componentDidMount() {
    if (!this.props.selectedApplicant) {
      return;
    }
    const applicant = this.props.selectedApplicant;
    const preferences = applicant.task_preferences;

    const preferred = [];
    const acceptable = [];
    const avoid = [];
    const cantDo = [];

    preferences.forEach(element => {
      if (element.preference === 4) {
        preferred.push(element.task.abbr.trim() || element.task.name);
      } else if (element.preference === 3) {
        acceptable.push(element.task.abbr.trim() || element.task.name);
      } else if (element.preference === 2) {
        avoid.push(element.task.abbr.trim() || element.task.name);
      } else {
        cantDo.push(element.task.abbr.trim() || element.task.name);
      }
    });

    this.setState({
      preferred, acceptable, avoid, cantDo,
    });
  }

  ATAtoggleButton = (taskID) => (
    <ToggleButtonGroup onChange={() => this.props.handleATAtoggle(taskID)} size="small">
      <ToggleButton value="job" selected={this.props.taskLoadBackupToggle.get(taskID)} size="small" style={{ height: '10px' }}>
        ld
      </ToggleButton>
      <ToggleButton value="backup" selected={!this.props.taskLoadBackupToggle.get(taskID)} size="small" style={{ height: '10px' }}>
        bkp
      </ToggleButton>
    </ToggleButtonGroup>
  );

  renderApplicantTaskRowName = (rowDataTask) => {
    const taskName = rowDataTask.abbr.trim() || rowDataTask.name;
    if (this.state.preferred.includes(taskName)) {
      // Show task name in green
      return <p style={{ color: '#006400', margin: 0 }}>{rowDataTask.preemtable ? `${taskName} (P)` : taskName}</p>;
    }
    if (this.state.acceptable.includes(taskName)) {
      // Show task name in orange
      return <p style={{ color: '#FFA500', margin: 0 }}>{rowDataTask.preemtable ? `${taskName} (P)` : taskName}</p>;
    }
    if (this.state.avoid.includes(taskName)) {
      // Show task name in red
      return <p style={{ color: '#FF0000', margin: 0 }}>{rowDataTask.preemtable ? `${taskName} (P)` : taskName}</p>;
    }
    if (this.state.cantDo.includes(taskName)) {
      // Show task name in gray
      return <p style={{ color: '#D3D3D3', margin: 0 }}>{rowDataTask.preemtable ? `${taskName} (P)` : taskName}</p>;
    }
    // Show task name in black
    return rowDataTask.preemtable ? `${taskName} (P)` : taskName;
  };

  renderColoredTimeApplicantTask = (task) => {
    const isInTime = taskTimeInApplicantTime(this.props.selectedApplicant, task) || this.props.oldApplicantTaskAssignSelection.find(t => t?.id === task?.id);
    const time = this.renderTaskTimeWindows(task);

    if (isInTime) {
      // Black
      return time;
    }
    // Gray
    return <p style={{ color: '#D8D8D8', margin: 0 }}>{time}</p>;
  };

  renderTaskTimeWindows = (task) => {
    if (task?.year === this.props.year) {
      return <div className={styles['nowrap']}>{`${formatTime(task?.starts_at)}-${formatTime(task?.ends_at)}`}</div>;
    }
    return (
      <div> - </div>
    );
  };

  renderLoad = (task) => {
    const result = renderLoadBackup(task, this.props.solutionAssignments, false);

    // Gray load data if row selection is disabled
    const isInTime = taskTimeInApplicantTime(this.props.selectedApplicant, task) || this.props.oldApplicantTaskAssignSelection.find(t => t?.id === task?.id);
    if (!isInTime) {
      return <p style={{ color: '#D8D8D8', margin: 0 }}>{result}</p>;
    }

    return result;
  };

  renderBackup = (task) => {
    const result = renderLoadBackup(task, this.props.solutionAssignments, true);

    // Gray backup data if row selection is disabled
    const isInTime = taskTimeInApplicantTime(this.props.selectedApplicant, task) || this.props.oldApplicantTaskAssignSelection.find(t => t?.id === task?.id);
    if (!isInTime) {
      return <p style={{ color: '#D8D8D8', margin: 0 }}>{result}</p>;
    }

    return result;
  };

  render() {
    return (
      <div className={styles['applicant-task-assign-table-container']}>
        <ReusableTable
          title={`Unassigned (${this.props.data.length})`}
          columns={this.state.applicantTaskAssignColumns}
          data={this.props.data}
          options={this.state.applicantTaskAssignOptions}
          isLoading={this.props.tasksLoading}
          actions={this.state.applicantTaskAssignActions}
          onSelectionChange={this.props.updateTimeAvailabilityBasedOnSelection}
          tableRef={this.props.tableRefATA}
        />
      </div>
    );
  }
}

ApplicantTaskAssignTable.propTypes = {
  data: PropTypes.array.isRequired,
  tasksLoading: PropTypes.bool.isRequired,
  selectedApplicant: PropTypes.object,
  oldApplicantTaskAssignSelection: PropTypes.array.isRequired,
  handleApplicantTaskAssign: PropTypes.func.isRequired,
  handleATAtoggle: PropTypes.func.isRequired,
  taskLoadBackupToggle: PropTypes.object.isRequired,
  solutionAssignments: PropTypes.array.isRequired,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  updateTimeAvailabilityBasedOnSelection: PropTypes.func.isRequired,
  tableRefATA: PropTypes.object.isRequired,
  handleOpenATAFilter: PropTypes.func.isRequired,
};

export default ApplicantTaskAssignTable;
