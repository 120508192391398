import React from 'react';
import ReusableTable from 'components/common/ReusableTable';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ReusableSelect from 'components/common/ReusableSelect';
import { formatTime } from 'utils/date';

import { renderAssignedName, renderAssignedType } from '../utils';
import styles from '../style.module.scss';

class TAAConfirmTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          title: 'Name',
          tooltip: 'First initial. Last name',
          render: (solutionAssignment) => renderAssignedName(solutionAssignment),
          customFilterAndSearch: (term, solutionAssignment) => (term === renderAssignedName(solutionAssignment).slice(0, term.length)) || (term === `${solutionAssignment.user?.first_name} ${solutionAssignment.user?.last_name}`.slice(0, term.length)),
        },
        {
          title: 'L/B',
          field: 'back_up',
          tooltip: 'The assignment type for the applicant (L or B)',
          render: (solutionAssignment) => renderAssignedType(solutionAssignment),
        },
        {
          title: 'Available',
          tooltip: 'The time window(s) the applicant is available',
          render: (applicant) => this.renderAvailableColumn(applicant),
        },
        {
          title: 'Asgn',
          tooltip: 'The time to assign the applicant',
          render: (solutionAssignment) => this.renderTimePicker(solutionAssignment),
        },
      ],
      options: {
        filtering: false,
        padding: 'dense',
        maxBodyHeight: 700,
        hideDeleteButton: true,
        selection: true,
        selectionProps: applicant => {
          const shouldBeDisabled = this.timeEntryInvalid(this.props.applicantMap.get(applicant?.id), this.props.selectedTask);
          const checked = shouldBeDisabled ? { checked: false } : {};
          if (shouldBeDisabled) {
            applicant.tableData['checked'] = false;
          }
          applicant.tableData.disabled = shouldBeDisabled;
          return ({
            disabled: shouldBeDisabled,
            ...checked,
          });
        },
        paging: false,
        showFull: true,
        search: false,
        showColumns: false,
        headerStyle: {
          fontFamily: 'Gotham SSm A, Gotham SSm B, Verdana, sans-serif',
          backgroundColor: '#eae9e6',
          color: '#d00000',
          whiteSpace: 'nowrap',
        },
      },
      actions: [
        {
          icon: 'check',
          tooltip: 'Assign',
          displayDuringSelect: true,
          onClick: (event, data) => this.props.handleConfirm(data),
        },
      ],
    };
  }

  /**
   * Handles the onChange event when a new time is inputted
   * @param {*} event The onChange event
   * @param {"start" | "end"} name The time box that was changed
   */
  onTimeChange(event, name, id) {
    dayjs.extend(customParseFormat);
    const formattedTime = dayjs(event.target.value, 'HH:mm');
    if (name === 'start') {
      this.props.handleStartTimeChange(id, formattedTime);
    } else {
      this.props.handleEndTimeChange(id, formattedTime);
    }
  }

  /**
   * Checks whether the time in the time picker for the TAA assign is inside the tasks's time
   * @param {*} assignee The assignee that will do the task
   * @param {*} task The task that the assignee will be doing
   * @returns {boolean} Whether the time the applicant is being assigned for is inside the task time
   */
  timeInsideTaskTimeWindow = (assignee, task) => {
    if (task && assignee && this.props.currentStartTimeMap[[assignee.user.id]] && this.props.currentEndTimeMap[[assignee.user.id]]) {
      return (dayjs(this.props.currentStartTimeMap[[assignee.user.id]][0]) >= dayjs(task?.starts_at)
      && dayjs(this.props.currentEndTimeMap[[assignee.user.id]][0]) <= dayjs(task?.ends_at));
    }
    return false;
  };

  /**
   * Checks whether the assigned time for assignee in TAA table is inside their availability windows
   * @param {*} assignee The person being assigned the task
   * @returns Whether the time the assignee is being assined in TAA table is within their availability windows
   */
  timeInsideAvailableWindows = (assignee) => {
    let inside = false;
    for (let i = 0; i < assignee?.time_windows.length; i++) {
      // some time inputs for applicants include seconds. Setting the seconds to 0 removes any issues with that
      inside = inside
        || (dayjs(this.props.currentStartTimeMap[[assignee.user.id]][0]) >= dayjs(assignee.time_windows[i]?.starts_at).second(0)
        && dayjs(this.props.currentEndTimeMap[[assignee.user.id]][0]).second(0) <= dayjs(assignee.time_windows[i]?.ends_at));
    }
    return inside;
  };

  /**
   * Checks whether a time assignment for an assignee to a task in the TAA table is valid
   * @param {*} assignee The person being assigned to the task in TAA table
   * @param {*} task The task that people are being assigned to in TAA table
   * @returns Whether the time assigned to the assignee fits in the assignee and task windows
   */
  timeEntryInvalid = (assignee, task) => {
    if (this.props.currentStartTimeMap[[assignee?.user.id]] && this.props.currentEndTimeMap[[assignee?.user.id]]) {
      return dayjs(this.props.currentStartTimeMap[[assignee?.user.id]][0]) >= dayjs(this.props.currentEndTimeMap[[assignee?.user.id]][0])
      || !this.timeInsideAvailableWindows(assignee)
      || !this.timeInsideTaskTimeWindow(assignee, task);
    }
    return true;
  };

  /**
   * Renders applicant's availability windows
   * @param {*} applicant The applicant whose availability windows you would like to see
   * @returns The applicant's availability windows
   */
  renderAvailableColumn = (applicant) => {
    const timeWindows = this.props.applicantMap.get(applicant.id)?.time_windows;
    if (timeWindows && timeWindows.length !== 0) {
      if (timeWindows.length === 1) {
        return `${formatTime(timeWindows[0].starts_at)}-${formatTime(timeWindows[0].ends_at)}`;
      }
      const timeWindowsSorted = timeWindows.sort((a, b) => a.starts_at.localeCompare(b.starts_at));
      const timeWindowOptions = timeWindowsSorted.map(timeWindow => ({
        display: `${formatTime(timeWindow?.starts_at)}-${formatTime(timeWindow?.ends_at)}`,
        value: `${formatTime(timeWindow?.starts_at)}-${formatTime(timeWindow?.ends_at)}`,
      }));
      const taskStartsAt = this.props.selectedTask.starts_at;
      const taskEndsAt = this.props.selectedTask.ends_at;
      let defaultTimeValue = timeWindowOptions[0].value;
      // find the first available time that intersects with the task time
      for (let i = 0; i < timeWindowsSorted.length; ++i) {
        const timeWindow = timeWindowsSorted[i];
        if ((taskStartsAt <= timeWindow?.starts_at && timeWindow?.starts_at <= taskEndsAt)
          || (timeWindow?.starts_at <= taskStartsAt && taskStartsAt <= timeWindow?.ends_at)) {
          defaultTimeValue = `${formatTime(timeWindow?.starts_at)}-${formatTime(timeWindow?.ends_at)}`;
          break;
        }
      }
      return (
        <ReusableSelect
          id={`TAA ${applicant.id}`}
          className={styles['task-preference']}
          value={defaultTimeValue}
          options={timeWindowOptions}
          useDefaultVariant
          isTransparent
          displayEmpty
          excludeNoneOption
        />
      );
    }

    return (
      <div>
        None
      </div>
    );
  };

  /**
   * Renders a rows time picker for the user to change the time of the assignment
   * @param {*} rowData The data for the row whose time picker's need to be rendered
   * @returns The time pickers for the row
   */
  renderTimePicker = (rowData) => (
    <div className={styles['assignment-edit-time-dialog']}>
      <input
        type="time"
        id="begin_time"
        min="06:00"
        max="20:00"
        value={this.props.currentStartTimeMap[[rowData.user.id]] ? dayjs(this.props.currentStartTimeMap[[rowData.user.id]][0]).format('HH:mm') : null}
        onChange={event => this.onTimeChange(event, 'start', rowData.user.id)}
        required
      />
      <div className={styles['time-window-to']}>to</div>
      <input
        type="time"
        id="end_time"
        min="06:00"
        max="20:00"
        value={this.props.currentEndTimeMap[[rowData.user.id]] ? dayjs(this.props.currentEndTimeMap[[rowData.user.id]][0]).format('HH:mm') : null}
        onChange={event => this.onTimeChange(event, 'end', rowData.user.id)}
        required
      />
    </div>
  );

  render() {
    return (
      <div className={styles['taa-confirm-table-container']}>
        <ReusableTable
          title=""
          columns={this.state.columns}
          options={this.state.options}
          data={this.props.data}
          actions={this.state.actions}
          tableRef={this.props.tableRefTAAConfirm}
        />
      </div>
    );
  }
}

TAAConfirmTable.propTypes = {
  data: PropTypes.array.isRequired,
  currentStartTimeMap: PropTypes.object.isRequired,
  currentEndTimeMap: PropTypes.object.isRequired,
  selectedTask: PropTypes.object.isRequired,
  handleStartTimeChange: PropTypes.func.isRequired,
  handleEndTimeChange: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  tableRefTAAConfirm: PropTypes.object.isRequired,
  applicantMap: PropTypes.any.isRequired,
};

export default TAAConfirmTable;
