import ApiWrapper from 'wrappers/api';
import fileDownload from 'js-file-download';

import {
  getClassesRequestSent,
  getClassesRequestSuccessful,
  updateClassRequestSent,
  updateClassRequestSuccessful,
  createClassRequestSent,
  createClassRequestSuccessful,
} from 'actions/atomic/classes';

export function getClasses() {
  return (dispatch) => {
    dispatch(getClassesRequestSent());
    return ApiWrapper.get('/classes')
      .then(response => {
        dispatch(getClassesRequestSuccessful(response.data));
      });
  };
}

export function createClass(newClass) {
  return (dispatch) => {
    dispatch(createClassRequestSent());
    return ApiWrapper.post('/classes', newClass)
      .then(response => {
        dispatch(createClassRequestSuccessful(response.data));
      });
  };
}

export function updateClass(newClass) {
  return (dispatch) => {
    dispatch(updateClassRequestSent());
    return ApiWrapper.put(`/classes/${newClass.id}`, newClass)
      .then(response => {
        dispatch(updateClassRequestSuccessful(response.data));
      });
  };
}

export function downloadClasses() {
  return () => ApiWrapper.get('/classes/download', { responseType: 'blob' })
    .then(response => fileDownload(response.data, 'classes.xlsx'));
}
