import { createSelector } from 'reselect';

export const selectApplicantsLoading = createSelector(
  (state) => state.getIn(['applicants', 'loading']),
  (loading) => loading,
);

export const selectApplicants = createSelector(
  (state) => state.getIn(['applicants', 'data']),
  (data) => data.toJS(),
);

export const selectExistingUsers = createSelector(
  (state) => state.getIn(['applicants', 'existingUsers']),
  (existingUsers) => existingUsers.toJS(),
);
