import { connect } from 'react-redux';

import {
  resetUserNotFound,
} from 'actions/atomic/users';
import {
  requestPasswordReset,
} from 'actions/thunks/users';
import {
  selectIsLoggedIn,
} from 'selectors/authenticationSelectors';
import {
  selectPasswordResetEmailSent,
  selectUserNotFound,
} from 'selectors/usersSelectors';
import PasswordResetRequestPage from 'components/pages/PasswordResetRequestPage';

const mapStateToProps = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  passwordResetEmailSent: selectPasswordResetEmailSent(state),
  userNotFound: selectUserNotFound(state),
});

const mapDispatchToProps = dispatch => ({
  requestPasswordReset: (payload) => dispatch(requestPasswordReset(payload)),
  resetUserNotFound: () => dispatch(resetUserNotFound()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordResetRequestPage);
