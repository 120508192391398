import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import history from 'wrappers/history';
import styles from './style.module.scss';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navbarIsHovered: false,
      isOpening: false,
      isClosing: false,
      remainOpen: props.remainOpen,
    };
  }

  componentDidMount() {
    if (this.props.currentYear === '') {
      this.props.getYear();
    }

    if (this.props.eventDates.length === 0) {
      this.props.getEventDates();
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.remainOpen !== this.props.remainOpen) {
      this.setState({ remainOpen: this.props.remainOpen });
    }
  }

  get applicationUri() {
    if (!this.currentDateInApplicationPeriod) {
      return `/volunteer/past/${this.props.currentYear}`;
    }

    return `/volunteer/process/${this.props.currentYear}`;
  }

  get currentEventDate() {
    return this.props.eventDates.filter(eventDate => dayjs(eventDate.held_on).year() === this.props.currentYear)[0];
  }

  get currentDateInApplicationPeriod() {
    if (this.currentEventDate) {
      return dayjs(this.currentEventDate.application_open) <= dayjs() && dayjs() <= dayjs(this.currentEventDate.hard_lock);
    }

    return false;
  }

  handleOpenNavbar = () => {
    this.setState({ isOpening: true, isClosing: false });
    setTimeout(() => {
      if (this.state.isOpening) {
        this.setState({ navbarIsHovered: true, isOpening: false });
      }
    }, 200);
  };

  handleCloseNavbar = () => {
    this.setState({ isClosing: true, isOpening: false });
    setTimeout(() => {
      if (this.state.isClosing) {
        this.setState({ navbarIsHovered: false, isClosing: false });
      }
    }, 200);
  };

  handleLogoutClicked = () => {
    this.props.logout();
  };

  shouldShowNavbar = () => this.state.navbarIsHovered || this.state.remainOpen;

  render() {
    return (
      <div
        id="wdn_navigation_bar"
        className={`wdn-band ${styles['navbar']} ${this.shouldShowNavbar() ? styles['show-mobile-navbar'] : ''}`}
        ref={this.props.setWrapperRef}
      >
        <nav id="breadcrumbs" className="wdn-inner-wrapper" role="navigation" aria-label="breadcrumbs">
          <ul>
            <li>
              <a href="http://www.unl.edu/">
                UNL
              </a>
            </li>
            <li>
              <Link to="/">
                Math Day
              </Link>
            </li>
          </ul>
        </nav>
        <div id="wdn_navigation_wrapper">
          <nav
            id="navigation"
            role="navigation"
            aria-label="main navigation"
            onMouseEnter={this.handleOpenNavbar}
            onMouseLeave={this.handleCloseNavbar}
          >
            <ul className={styles['full-height']}>
              <li className={styles['full-height']}>
                <Link to="/" onClick={this.handleCloseNavbar}>
                  MATH DAY HOME
                </Link>
                <ul className={this.shouldShowNavbar() ? styles['dropdown'] : ''}>
                  <li className="first leaf">
                    <Link to="/about" onClick={this.handleCloseNavbar}>
                      About Us
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="expanded">
                <Link to={this.props.isLoggedIn ? '/volunteer/dashboard' : '/login'} onClick={this.handleCloseNavbar}>
                  Volunteer
                </Link>
                <ul className={this.shouldShowNavbar() ? styles['dropdown'] : ''}>
                  {this.props.isLoggedIn
                  && (
                    <>
                      <li className="first leaf">
                        <Link to="/volunteer/dashboard" onClick={this.handleCloseNavbar}>
                          Dashboard
                        </Link>
                      </li>
                      <li className="leaf">
                        <Link to={this.applicationUri} onClick={this.handleCloseNavbar}>
                          Application
                        </Link>
                      </li>
                    </>
                  )}
                  {!this.props.isLoggedIn
                  && (
                    <>
                      <li className="first leaf">
                        <Link to="/login" onClick={this.handleCloseNavbar}>
                          Login
                        </Link>
                      </li>
                      <li className="last leaf">
                        <Link to="/create-account" onClick={this.handleCloseNavbar}>
                          Create New Account
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </li>
              {this.props.isLoggedIn
              && this.props.isAdmin
              && (
                <li className="expanded">
                  <Link to="/admin" onClick={this.handleCloseNavbar}>
                    Admin
                  </Link>
                  <ul className={this.shouldShowNavbar() ? styles['dropdown'] : ''}>
                    <li className="first leaf">
                      <Link to="/admin" onClick={this.handleCloseNavbar}>
                        Dashboard
                      </Link>
                    </li>
                    <li className="leaf">
                      <Link to="/admin/activities" onClick={this.handleCloseNavbar}>
                        Activities and Tasks
                      </Link>
                    </li>
                    <li className="leaf">
                      <Link to="/admin/affiliations" onClick={this.handleCloseNavbar}>
                        Affiliations
                      </Link>
                    </li>
                    <li className="leaf">
                      <Link to="/admin/announcements" onClick={this.handleCloseNavbar}>
                        Announcements
                      </Link>
                    </li>
                    <li className="leaf">
                      <Link to="/admin/applicants" onClick={this.handleCloseNavbar}>
                        Applicants
                      </Link>
                    </li>
                    <li className="leaf">
                      <Link to="/admin/email" onClick={this.handleCloseNavbar}>
                        Email
                      </Link>
                    </li>
                    <li className="leaf">
                      <Link to="/admin/email-log" onClick={this.handleCloseNavbar}>
                        Email Log
                      </Link>
                    </li>
                    <li className="leaf">
                      <Link to="/admin/event-dates" onClick={this.handleCloseNavbar}>
                        Event Dates
                      </Link>
                    </li>
                    <li className="leaf">
                      <Link to="/admin/classes" onClick={this.handleCloseNavbar}>
                        Math Classes
                      </Link>
                    </li>
                    <li className="leaf">
                      <Link to="/admin/recruiters" onClick={this.handleCloseNavbar}>
                        Recruiters
                      </Link>
                    </li>
                    <li className="last leaf">
                      <Link to="/admin/statuses" onClick={this.handleCloseNavbar}>
                        Statuses
                      </Link>
                    </li>
                    <li className="leaf">
                      <Link to="/admin/system-settings" onClick={this.handleCloseNavbar}>
                        System Settings
                      </Link>
                    </li>
                    <li className="leaf">
                      <Link to="/admin/users" onClick={this.handleCloseNavbar}>
                        Users
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
              <li className="expanded">
                <Link to="/contact-us" onClick={this.handleCloseNavbar}>
                  Contact Us
                </Link>
              </li>
              <li className="expanded">
                {this.props.isLoggedIn
                && (
                  <Link to={history.location.pathname} onClick={this.handleLogoutClicked}>
                    Logout
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>

    );
  }
}

Navbar.propTypes = {
  remainOpen: PropTypes.bool,
  isLoggedIn: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  currentYear: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  getYear: PropTypes.func.isRequired,
  eventDates: PropTypes.array.isRequired,
  getEventDates: PropTypes.func.isRequired,
  setWrapperRef: PropTypes.func.isRequired,
};

export default Navbar;
