import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import ApplicantsTable from 'components/common/ApplicantsTable';
import ReusableTable from 'components/common/ReusableTable';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableTextFieldValidator from 'components/common/ReusableTextFieldValidator';
import { isFieldEmpty, fields } from 'utils/validation';
import history from 'wrappers/history';
import styles from './style.module.scss';

class AffiliationsPage extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }

    this.state = {
      nameError: false,
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: '',
      applicants: [],
      applicantsTableOpen: false,
      columns: [
        {
          title: 'Name',
          field: 'name',
          tooltip: 'The name of the affiliation of a student.',
          editComponent: (name) => (
            <ReusableTextFieldValidator
              text={name}
              onChange={(event) => name.onChange(event.target.value)}
              regExp={fields.affiliationName.regExp}
              error={this.state.nameError}
              onError={(error) => this.setState({ nameError: error })}
            />
          ),
        },
        {
          title: 'Priority',
          field: 'priority',
          tooltip: '',
        },
        {
          title: '#Volunteers',
          editable: 'never',
          customSort: (a, b) => this.numberOfApplicants(a) - this.numberOfApplicants(b),
          render: affiliation => (
            <div className={styles['applicant-button']}>
              <Button onClick={() => this.openApplicantsTable(affiliation)}>
                {this.numberOfApplicants(affiliation)}
              </Button>
            </div>
          ),
        },
        {
          title: 'Approved',
          field: 'approved',
          type: 'boolean',
          tooltip: 'Students are only able to sign up with affiliations that are marked as approved.',
        },

      ],
      options: {
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 600,
        selection: true,
        hideDeleteButton: true,
      },
      editable: {
        isEditable: () => true,
        isDeletable: () => false,
        onRowAdd: (newAffiliation) => new Promise((resolve, reject) => {
          const errorMessage = this.validateFields(newAffiliation);
          if (errorMessage === '') {
            this.props.createAffiliation(newAffiliation);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'success',
              snackbarMessage: `Affiliation ${newAffiliation.name} successfully added.`,
            });
            resolve();
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: errorMessage,
            });
            reject();
          }
        }),
        onRowUpdate: (updatedAffiliation) => new Promise((resolve, reject) => {
          const errorMessage = this.validateFields(updatedAffiliation);
          if (errorMessage === '') {
            this.props.updateAffiliation(updatedAffiliation);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'success',
              snackbarMessage: `Affiliation ${updatedAffiliation.name} successfully updated.`,
            });
            resolve();
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: errorMessage,
            });
            reject();
          }
        }),
        onRowDelete: () => new Promise((resolve) => {
          resolve();
        }),
      },
      actions: [
        {
          icon: 'replay',
          tooltip: 'Refetch Data',
          isFreeAction: true,
          onClick: () => this.props.getAffiliations(),
        },
        {
          icon: 'save_alt',
          tooltip: 'Export',
          isFreeAction: true,
          onClick: () => {
            this.props.downloadAffiliations();
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'info',
              snackbarMessage: 'Download in progress.',
            });
          },
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.affiliations.length === 0) {
      this.props.getAffiliations();
    }
    if (this.props.year === '') {
      this.props.getYear();
    }
    if (this.props.applicants.length === 0) {
      this.props.getApplicants();
    }
  }

  numberOfApplicants = (affiliation) => {
    let numberOfApplicants = 0;
    this.props.applicants.filter(applicant => applicant.year === this.props.year).forEach(applicant => {
      if (applicant.affiliation && applicant.affiliation.id === affiliation.id) {
        ++numberOfApplicants;
      }
    });
    return numberOfApplicants;
  };

  openApplicantsTable = (affiliation) => {
    const affiliationApplicants = [];
    this.props.applicants.forEach(applicant => {
      if (applicant.affiliation && applicant.affiliation.id === affiliation.id) {
        affiliationApplicants.push(applicant);
      }
    });
    this.setState({ applicantsTableOpen: true, applicants: affiliationApplicants });
  };

  closeApplicantsTable = () => {
    this.setState({ applicantsTableOpen: false });
  };

  validateFields = (affiliation) => {
    let errorMessage = '';
    if (this.state.nameError || isFieldEmpty(affiliation.name)) {
      errorMessage += fields.affiliationName.errorMessage;
      this.setState({
        nameError: true,
      });
    }

    return errorMessage;
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['table-container']}>
              <ReusableTable
                title="Affiliations"
                columns={this.state.columns}
                data={this.props.affiliations}
                options={this.state.options}
                editable={this.state.editable}
                isLoading={this.props.affiliationsLoading || this.props.applicantsLoading}
                actions={this.state.actions}
              />
              <ReusableSnackbar
                open={this.state.snackbarOpen}
                onClose={this.handleSnackbarClose}
                variant={this.state.snackbarVariant}
                message={this.state.snackbarMessage}
              />
              <Dialog
                fullWidth
                maxWidth="lg"
                open={this.state.applicantsTableOpen}
                onClose={this.closeApplicantsTable}
              >
                <ApplicantsTable
                  loading={this.props.affiliationsLoading || this.props.applicantsLoading}
                  applicants={this.state.applicants}
                  year={this.props.year}
                  eventDates={this.props.eventDates}
                  getYear={this.props.getYear}
                  getEventDates={this.props.getEventDates}
                />
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AffiliationsPage.propTypes = {
  affiliations: PropTypes.array.isRequired,
  affiliationsLoading: PropTypes.bool.isRequired,
  createAffiliation: PropTypes.func.isRequired,
  updateAffiliation: PropTypes.func.isRequired,
  getAffiliations: PropTypes.func.isRequired,
  downloadAffiliations: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  applicants: PropTypes.array.isRequired,
  applicantsLoading: PropTypes.bool.isRequired,
  getApplicants: PropTypes.func.isRequired,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  getYear: PropTypes.func.isRequired,
  eventDates: PropTypes.array.isRequired,
  getEventDates: PropTypes.func.isRequired,
};

export default AffiliationsPage;
