import React from 'react';
import PropTypes from 'prop-types';

import ReusableButton from 'components/common/ReusableButton';
import history from 'wrappers/history';
import routes from 'routes';
import styles from './style.module.scss';

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routeExclusions: ['/error', '/volunteer/process', '/volunteer/past'],
      shouldRender: false,
    };
  }

  componentDidMount = () => {
    this.setState({ shouldRender: !this.routeMatched(routes) });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ shouldRender: !this.routeMatched(routes) });
    }
  };

  routeIsExcluded = (route) => this.state.routeExclusions.some(excludedRoute => route.startsWith(excludedRoute));

  routeMatched = (routesToCheck) => routesToCheck.some(route => route.path === this.props.location.pathname
      || route.path === this.props.location.pathname.replace(/\/$/, '')
      || (route.routesToCheck && this.routeMatched(route.routesToCheck)))
      || this.routeIsExcluded(this.props.location.pathname);

  render() {
    if (this.state.shouldRender) {
      return (
        <div id="maincontent">
          <div className="wdn-band">
            <div className="wdn-inner-wrapper">
              <div className={styles['info-container']}>
                <h2>
                  404: Route Not Found
                </h2>
                <div>
                  It seems you ended up here accidentally:&nbsp;
                  {this.props.location.pathname}
                  &nbsp;is not a valid route.
                </div>
                <ReusableButton
                  value="Return Home"
                  hasTopMargin
                  onClick={() => history.push('/')}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

NotFoundPage.propTypes = {
  location: PropTypes.object,
};

export default NotFoundPage;
