import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import ToggleButton from '@material-ui/lab/ToggleButton';

import ReusableTextField from 'components/common/ReusableTextField';

import styles from './style.module.scss';

class EmailSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredName: '',
    };
  }

  get numberOfChecked() {
    if (this.props.type === 'recruiters') {
      return Object.values(this.props.recruitersChecked).filter((value) => value === true).length;
    }
    return Object.values(this.props.applicantsChecked).filter((value) => value === true).length;
  }

  get sortedApplicants() {
    return this.props.applicants.concat().sort((app1, app2) => (app1.user.last_name > app2.user.last_name ? 1 : -1));
  }

  get sortedRecruiters() {
    return this.props.recruiters.concat().sort((rec1, rec2) => (rec1.last_name > rec2.last_name ? 1 : -1));
  }

  get filteredNames() {
    if (this.props.type === 'recruiters') {
      return this.sortedRecruiters.filter((recruiter) => (this.getName(recruiter).toLowerCase().includes(this.state.filteredName)));
    }
    return this.sortedApplicants.filter((applicant) => (this.getName(applicant).toLowerCase().includes(this.state.filteredName)));
  }

  getName(person) {
    if (this.props.type === 'applicants') {
      return `${person.user.last_name}, ${person.user.first_name}`;
    }
    return `${person.last_name}, ${person.first_name}`;
  }

  handleFilter = (event) => {
    this.setState({
      filteredName: event.target.value.toLowerCase(),
    });
  };

  renderRecruiterFilterOptions = () => (
    <div>
      <ToggleButton
        className={this.props.allSelected ? `${styles['all-button']} ${styles['selected']}` : `${styles['all-button']}`}
        onChange={() => this.props.onSelectAll('all')}
        value={this.props.allSelected}
        selected={this.props.allSelected}
      >
        All
      </ToggleButton>
      <ToggleButton
        className={this.props.allWithVolunteersSelected ? `${styles['all-button']} ${styles['selected']}` : `${styles['all-button']}`}
        onChange={() => this.props.onSelectAll('withVolunteers')}
        value={this.props.allWithVolunteersSelected}
        selected={this.props.allWithVolunteersSelected}
      >
        All with volunteers
      </ToggleButton>
    </div>
  );

  renderApplicantFilterOptions = () => (
    <div>
      <ToggleButton
        className={this.props.allSelected ? `${styles['all-button']} ${styles['selected']}` : `${styles['all-button']}`}
        onChange={() => this.props.onSelectAll('all')}
        value={this.props.allSelected}
        selected={this.props.allSelected}
      >
        All
      </ToggleButton>
      <ToggleButton
        className={this.props.allWithAssignmentSelected ? `${styles['all-button']} ${styles['selected']}` : `${styles['all-button']}`}
        onChange={() => this.props.onSelectAll('withAssignment')}
        value={this.props.allWithAssignmentSelected}
        selected={this.props.allWithAssignmentSelected}
      >
        All with assignment
      </ToggleButton>
    </div>
  );

  renderListItems = () => {
    if (this.props.type === 'custom') {
      return <Typography style={{ margin: '4px' }} variant="caption">Enter emails manually in custom mode.</Typography>;
    }
    if (this.props.type === 'recruiters') {
      return (
        this.filteredNames.map((recruiter, index) => (
          <ListItem
            className={index % 2 === 0 ? `${styles['name-cell']} ${styles['white']}` : `${styles['name-cell']} ${styles['light-gray']}`}
            button
            key={`${recruiter.id} ${this.props.recruitersChecked[recruiter.id]}`}
            onClick={() => this.props.onCheckboxChanged(recruiter.id)}
          >
            <Checkbox
              color="default"
              edge="start"
              checked={this.props.recruitersChecked[recruiter.id]}
              disableRipple
              className={styles['checkbox']}
            />
            <ListItemText
              id={recruiter.id}
              className={styles['list-item-text']}
              primary={`${recruiter.last_name}, ${recruiter.first_name}`}
            />
          </ListItem>
        ))
      );
    }
    return (
      this.filteredNames.map((applicant, index) => (
        <ListItem
          className={index % 2 === 0 ? `${styles['name-cell']} ${styles['white']}` : `${styles['name-cell']} ${styles['light-gray']}`}
          button
          key={`${applicant.id} ${this.props.applicantsChecked[applicant.id]}`}
          onClick={() => this.props.onCheckboxChanged(applicant.id)}
        >
          <Checkbox
            color="default"
            edge="start"
            checked={this.props.applicantsChecked[applicant.id]}
            disableRipple
            className={styles['checkbox']}
          />
          <ListItemText
            id={applicant.id}
            className={styles['list-item-text']}
            key={applicant.id}
            primary={`${applicant.user.last_name}, ${applicant.user.first_name}`}
          />
        </ListItem>
      )));
  };

  render() {
    return (
      <div className={styles['sidebar']}>
        <div className={styles['applicants-sidebar']}>
          {this.props.loading ? (
            <div className={styles['center']}>
              <CircularProgress />
            </div>
          ) : (
            <div className={styles['column']}>
              <ReusableTextField
                id="nameFilter"
                className={styles['filter']}
                label="Filter"
                short
                value={this.state.filteredName}
                onChange={this.handleFilter}
              />
              <Typography className={styles['total-selected']} variant="caption">{`${this.numberOfChecked} selected`}</Typography>
              {this.props.type === 'recruiters' ? this.renderRecruiterFilterOptions() : this.renderApplicantFilterOptions()}
              <div className={styles['applicants-list']}>
                <List>
                  {this.renderListItems()}
                </List>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

EmailSidebar.propTypes = {
  loading: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['applicants', 'recruiters', 'custom']).isRequired,
  applicants: PropTypes.array.isRequired,
  recruiters: PropTypes.array.isRequired,
  applicantsChecked: PropTypes.object.isRequired,
  recruitersChecked: PropTypes.object.isRequired,
  onCheckboxChanged: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  allSelected: PropTypes.bool.isRequired,
  allWithVolunteersSelected: PropTypes.bool.isRequired,
  allWithAssignmentSelected: PropTypes.bool.isRequired,
};

export default EmailSidebar;
