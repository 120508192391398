import React from 'react';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import PropTypes from 'prop-types';

import styles from './style.module.scss';

class TimePopup extends React.Component {
  render() {
    return (
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <TimePicker
          disabled={this.props.disabled}
          margin="normal"
          id={this.props.id}
          label={this.props.label}
          value={this.props.time}
          inputVariant="outlined"
          className={this.props.hasSpacing ? `${styles['has-spacing']} ${styles['white']}` : `${styles['white']}`}
          onChange={(date) => this.props.handleTimeChange(date)}
          minutesStep={this.props.minutesStep || 1}
          error={this.props.error}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

TimePopup.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  time: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  handleTimeChange: PropTypes.func.isRequired,
  hasSpacing: PropTypes.bool,
  minutesStep: PropTypes.number,
  error: PropTypes.bool,
};

export default TimePopup;
