import { combineReducers } from 'redux-immutable';

import classes from './classes';
import recruiters from './recruiters';
import affiliations from './affiliations';
import statuses from './statuses';
import eventDates from './eventDates';
import authentication from './authentication';
import announcements from './announcements';
import activities from './activities';
import systemSettings from './systemSettings';
import users from './users';
import emails from './emails';
import applicants from './applicants';
import contactUs from './contactUs';
import assignments from './assignments';
import taskPreferences from './task_preferences';
import solutionAssignments from './solutionAssignments';

const reducers = combineReducers({
  classes,
  recruiters,
  affiliations,
  statuses,
  eventDates,
  authentication,
  announcements,
  activities,
  systemSettings,
  users,
  emails,
  applicants,
  contactUs,
  assignments,
  taskPreferences,
  solutionAssignments,
});

export default reducers;
