import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import InfoIcon from '@material-ui/icons/Info';
import ReusableColorPicker from 'components/common/ReusableColorPicker';

import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableButton from 'components/common/ReusableButton';
import ReusableTextField from 'components/common/ReusableTextField';
import ReusableTooltip from 'components/common/ReusableTooltip';
import styles from './style.module.scss';

class ActivityEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      abbr: '',
      description: '',
      backgroundColor: '',
      textColor: '',
      mode: props.mode,
      year: props.year,
      snackbarMessage: '',
      snackbarVariant: 'error',
      snackbarOpen: false,
    };
  }

  componentDidMount() {
    const { props } = this;
    if (this.state.mode === 'create') {
      this.setState({
        name: '',
        abbr: '',
        description: '',
        backgroundColor: '',
        textColor: '#000000',
      });
    } else {
      this.setState({
        name: props.activity.name,
        abbr: props.activity.abbr,
        description: props.activity.description,
        backgroundColor: props.activity.background_color,
        textColor: props.activity.text_color,
      });
    }
  }

  get onMathDayYear() {
    return this.props.year === this.props.currentMathDayYear;
  }

  validateActivity = () => {
    let validated = true;
    let errors = '';
    if (!this.state.name) {
      validated = false;
      errors += 'Please enter a name for the activity.\n';
    }
    if (!this.state.abbr) {
      validated = false;
      errors += 'Please enter an abbreviation for the activity.\n';
    }
    if (!this.state.description || this.state.description === '') {
      this.setState({ description: ' ' });
    }
    if (!validated) {
      this.setState({
        snackbarMessage: errors,
      });
    }
    return validated;
  };

  handleChange = name => (event, value) => {
    if (name === 'textColor') {
      this.setState({
        textColor: value === 'black' ? '#000000' : '#ffffff',
      });
    } else if (name === 'backgroundColor') {
      this.setState({
        backgroundColor: event.hex,
      });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleDeleteButtonClicked = () => {
    this.props.handleDeleteActivity(this.props.activity);
  };

  handleCreateButtonClicked = () => {
    const activityName = this.state.name;
    if (this.validateActivity()) {
      const payload = {
        name: this.state.name,
        abbr: this.state.abbr ? this.state.abbr : this.state.name,
        description: this.state.description,
        text_color: this.state.textColor ? this.state.textColor : '#000000',
        background_color: this.state.backgroundColor ? this.state.backgroundColor : '#ffffff',
        tasks: [],
        year: this.state.year,
      };
      this.setState({
        snackbarVariant: 'success',
        snackbarMessage: `Activity '${activityName}' successfully created.`,
        snackbarOpen: true,
      });
      this.props.handleCreateActivity(payload);
    } else {
      this.setState({
        snackbarVariant: 'error',
        snackbarOpen: true,
      });
    }
  };

  handleUpdateButtonClicked = () => {
    if (this.validateActivity()) {
      const payload = {
        id: this.props.activity.id,
        name: this.state.name,
        abbr: this.state.abbr,
        description: this.state.description,
        text_color: this.state.textColor,
        background_color: this.state.backgroundColor,
        tasks: this.props.activity.tasks,
      };
      this.props.handleUpdateActivity(payload);
    } else {
      this.setState({
        snackbarVariant: 'error',
        snackbarOpen: true,
      });
    }
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: '',
    });
  };

  render() {
    return (
      <div className={styles['editor']}>
        <div className={styles['bottom-border']}>
          <Typography variant="h7">
            {this.state.mode === 'create' ? 'Create ' : 'Edit '}
            Activity
          </Typography>
        </div>
        <div>
          <div className={styles['row']}>
            <ReusableTextField
              id="name"
              label="Name"
              required
              onChange={this.handleChange('name')}
              value={this.state.name}
              className={styles['has-spacing']}
            />
            <ReusableTextField
              id="abbr"
              label="Abbr"
              onChange={this.handleChange('abbr')}
              value={this.state.abbr}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <ReusableTooltip title="What the manager sees when browsing data">
                      <InfoIcon fontSize="small" />
                    </ReusableTooltip>
                  </InputAdornment>
                ),
              }}
            />
            <div className={`${styles['color-picker-container']}`}>
              <Typography className={styles['background-color-label']} variant="caption">Color</Typography>
              <ReusableColorPicker
                className={styles['color-picker']}
                color={this.state.backgroundColor}
                onChange={this.handleChange('backgroundColor')}
              />
            </div>
            <div>
              <Typography className={styles['toggle-button-label']} variant="caption">
                Text Color
              </Typography>
              <ToggleButtonGroup
                id="textColor"
                key={this.state.textColor}
                size="small"
                className={styles['toggle-button']}
                value={this.state.textColor === '#ffffff' ? 'white' : 'black'}
                onChange={this.handleChange('textColor')}
                exclusive
              >
                <ToggleButton value="black">Black</ToggleButton>
                <ToggleButton value="white">White</ToggleButton>
              </ToggleButtonGroup>
            </div>

          </div>
          <ReusableTextField
            id="description"
            multiline
            label="Description"
            onChange={this.handleChange('description')}
            value={this.state.description}
          />
          <div className={styles['row']}>
            <ReusableButton
              onClick={this.state.mode === 'create' ? this.handleCreateButtonClicked : this.handleUpdateButtonClicked}
              value={this.state.mode === 'create' ? 'Create Activity' : 'Update Activity'}
              className={styles['has-spacing']}
              hasTopMargin
            />
            <ReusableButton
              onClick={this.handleDeleteButtonClicked}
              value="Delete Activity"
              disabled={this.state.mode === 'create'}
              hasTopMargin
            />
          </div>
          <ReusableSnackbar
            open={this.state.snackbarOpen}
            onClose={this.handleSnackbarClose}
            variant={this.state.snackbarVariant}
            message={this.state.snackbarMessage}
          />
        </div>
      </div>
    );
  }
}

ActivityEditor.propTypes = {
  mode: PropTypes.oneOf(['create', 'edit']),
  activity: PropTypes.object.isRequired,
  year: PropTypes.number.isRequired,
  currentMathDayYear: PropTypes.number.isRequired,
  handleUpdateActivity: PropTypes.func.isRequired,
  handleCreateActivity: PropTypes.func.isRequired,
  handleDeleteActivity: PropTypes.func.isRequired,
};

export default ActivityEditor;
