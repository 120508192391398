import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ReusableSelect from 'components/common/ReusableSelect';
import ReusableButton from 'components/common/ReusableButton';

import { Typography } from '@material-ui/core';

import styles from './style.module.scss';

class PreviewEmailDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMessage: props.messages.length > 0 ? props.messages[0] : null,
    };
  }

  generateRecipientList = () => this.props.messages.map((message) => ({ value: message, display: this.formatNameAndEmail(message.recipient) }));

  handleFieldChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSendEmailClicked = () => {
    this.props.handleSendEmails(this.props.messages, this.props.recipientType);
    this.props.displaySnackbarMessage('Done sending emails', 'success');
  };

  formatNameAndEmail = (person) => {
    if (this.props.recipientType === 'custom') {
      return person;
    } if (this.props.recipientType === 'recruiters') {
      return `${person.last_name}, ${person.first_name} (${person.email})`;
    }
    return `${person.user.last_name}, ${person.user.first_name} (${person.user.email})`;
  };

  render() {
    return this.props.messages.length > 0
      ? (
        <Dialog className={styles['preview-dialog']} open={this.props.open} onClose={this.props.handleClosePreviewEmail}>
          <DialogTitle className={styles['preview-dialog']}>Preview Email</DialogTitle>
          <DialogContent>
            <div className={styles['email-field']}>
              <Typography className={styles['label']} variant="body1">From: </Typography>
              <Typography className={styles['item']} variant="body1">{this.state.selectedMessage.sent_from}</Typography>
            </div>
            <div className={styles['email-field']}>
              <Typography className={`${styles['label']} ${styles['recipient-label']}`} variant="body1">To: </Typography>
              <ReusableSelect
                id="recipient"
                label=""
                excludeNoneOption
                className={`${styles['recipient-list']} ${styles['item']}`}
                options={this.generateRecipientList()}
                value={this.state.selectedMessage}
                onChange={this.handleFieldChange('selectedMessage')}
              />
            </div>
            <div className={styles['email-field']}>
              <Typography className={styles['label']} variant="body1">Subject: </Typography>
              <Typography className={styles['item']} variant="body1">{this.state.selectedMessage.subject}</Typography>
            </div>
            <div className={styles['email-body']}>
              <div dangerouslySetInnerHTML={{ __html: this.state.selectedMessage.body }} />
            </div>
          </DialogContent>
          <DialogActions>
            <ReusableButton
              onClick={this.props.handleClosePreviewEmail}
              value="Cancel"
            />
            <ReusableButton
              value="Send Emails"
              onClick={this.handleSendEmailClicked}
            />
          </DialogActions>
        </Dialog>
      ) : null;
  }
}

PreviewEmailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  messages: PropTypes.array.isRequired,
  handleClosePreviewEmail: PropTypes.func.isRequired,
  handleSendEmails: PropTypes.func.isRequired,
  displaySnackbarMessage: PropTypes.func.isRequired,
  recipientType: PropTypes.oneOf(['applicants', 'recruiters', 'custom']).isRequired,
};

export default PreviewEmailDialog;
