import { connect } from 'react-redux';

import DashboardPage from 'components/pages/admin/DashboardPage';
import { selectIsAdmin, selectIsLoggedIn, selectCurrentUser } from 'selectors/authenticationSelectors';

const mapStateToProps = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  isAdmin: selectIsAdmin(state),
  currentUser: selectCurrentUser(state),
});

const mapDispatchToProps = () => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardPage);
