import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import ActivitySidebar from 'components/common/ActivitiesPanels/ActivitySidebar';
import ActivityEditor from 'components/common/ActivitiesPanels/ActivityEditor';
import TaskEditor from 'components/common/ActivitiesPanels/TaskEditor';
import ReusableButton from 'components/common/ReusableButton';
import ReusableSnackbar from 'components/common/ReusableSnackbar';

import history from 'wrappers/history';
import styles from './style.module.scss';

class ActivitiesPage extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }
    this.state = {
      createActivity: false,
      createTask: false,
      currentActivity: undefined,
      currentTask: undefined,
      openedActivities: {},
      year: undefined,
      currentMathDayYear: undefined,
      snackbarMessage: '',
      snackbarVariant: 'success',
      snackbarOpen: false,
    };
  }

  componentDidMount() {
    if (
      this.props.activities.length === 0
      || this.props.tasks.length === 0
      || this.props.statuses === 0
    ) {
      this.props.getActivities();
      this.props.getTasks();
      this.props.getStatuses();
      this.props.getEventDates();
      this.props.getYear();
      const opened = {};
      this.props.activities.forEach((activity) => {
        opened[activity.id] = false;
      });
      this.setState({
        openedActivities: opened,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.year
      && !prevState.year
      && nextProps.currentMathDayYear
      && !prevState.currentMathDayYear
    ) {
      return {
        year: nextProps.year,
        currentMathDayYear: nextProps.currentMathDayYear,
      };
    }
    return {};
  }

  get onMathDayYear() {
    return this.state.year === this.state.currentMathDayYear;
  }

  get isLoading() {
    if (this.props.activities.length === 0) {
      return true;
    }
    return (
      this.props.tasksLoading
      || this.props.activitiesLoading
      || this.props.statusesLoading
      || this.props.eventDatesLoading
    );
  }

  get years() {
    return this.props.eventDates
      .map((eventDate) => eventDate.held_on.slice(0, 4))
      .sort()
      .reverse();
  }

  handleSnackbarClose = () => {
    this.setState({
      snackbarMessage: '',
      snackbarOpen: false,
    });
  };

  handleYearChange = (event) => {
    this.setState({
      year: event.target.value,
      currentActivity: undefined,
      currentTask: undefined,
      createActivity: false,
      createTask: false,
    });
  };

  handleTaskClick = (taskId) => {
    this.setState({
      currentActivity: undefined,
      currentTask: this.findTaskById(taskId),
      createTask: false,
      createActivity: false,
    });
  };

  handleExpandActivities = (activityId) => {
    this.setState((previousState) => {
      const updatedOpenedActivities = previousState.openedActivities;
      const prevOpeningState = previousState.openedActivities[activityId];
      updatedOpenedActivities[activityId] = !prevOpeningState;
      return {
        openedActivities: updatedOpenedActivities,
      };
    });
  };

  handleActivityClick = (activityId) => {
    this.setState({
      currentActivity: this.findActivityById(activityId),
      currentTask: undefined,
      createActivity: false,
      createTask: false,
    });
  };

  handleExportActivitiesButtonClicked = () => {
    this.props.downloadActivities(this.state.year);
    this.setState({
      snackbarOpen: true,
      snackbarVariant: 'info',
      snackbarMessage: 'Download in progress.',
    });
  };

  handleExportTasksButtonClicked = () => {
    this.props.downloadTasks(this.state.year);
    this.setState({
      snackbarOpen: true,
      snackbarVariant: 'info',
      snackbarMessage: 'Download in progress.',
    });
  };

  handleAddActivityButtonClicked = () => {
    this.setState({
      currentActivity: undefined,
      currentTask: undefined,
      createTask: false,
      createActivity: true,
    });
  };

  handleAddTaskButtonClicked = () => {
    this.setState({
      currentActivity: undefined,
      currentTask: undefined,
      createTask: true,
      createActivity: false,
    });
  };

  findActivityById = (activityId) => {
    const index = this.props.activities.findIndex(
      (activity) => activity.id === activityId
    );
    return this.props.activities[index];
  };

  findTaskById = (taskId) => {
    const index = this.props.tasks.findIndex((task) => task.id === taskId);
    return this.props.tasks[index];
  };

  createActivity = (newData) => {
    this.props.createActivity(newData);
    this.setState({
      createActivity: false,
      createTask: false,
      currentActivity: undefined,
      currentTask: undefined,
      snackbarOpen: true,
      snackbarMessage: `Activity '${newData.name}' has been successfully created`,
    });
  };

  createTask = (newData) => {
    this.props.createTask(newData);
    this.setState({
      createActivity: false,
      createTask: false,
      currentActivity: undefined,
      currentTask: undefined,
      snackbarOpen: true,
      snackbarMessage: `Task '${newData.name}' has been successfully created`,
    });
  };

  updateActivity = (newData) => {
    this.props.updateActivity(newData);
    this.setState({
      snackbarOpen: true,
      snackbarMessage: `Activity '${newData.name}' has been successfully updated`,
    });
  };

  updateTask = (newData) => {
    this.props.updateTask(newData);
    this.setState({
      snackbarOpen: true,
      snackbarMessage: `Task '${newData.name}' has been successfully updated`,
    });
  };

  deleteTask = (task) => {
    this.props.deleteTask(task);
    const activityId = task.activity_id;
    this.setState({
      snackbarOpen: true,
      snackbarMessage: `Task '${task.name}' has been successfully deleted`,
      currentTask: undefined,
      currentActivity: activityId,
    });
  };

  deleteActivity = (activity) => {
    this.props.deleteActivity(activity);
    this.setState({
      snackbarOpen: true,
      snackbarMessage: `Activity '${activity.name}' has been successfully deleted`,
      currentActivity: undefined,
    });
  };

  renderSidebar = () => {
    if (this.isLoading) {
      return null;
    }
    return (
      <ActivitySidebar
        key={this.state.year}
        isLoading={this.isLoading}
        year={parseInt(this.state.year, 10)}
        eventDates={this.props.eventDates}
        handleYearChange={this.handleYearChange}
        handleActivityClick={this.handleActivityClick}
        handleTaskClick={this.handleTaskClick}
        tasks={this.props.tasks.filter(
          (task) => task.year === parseInt(this.state.year, 10)
        )}
        activities={this.props.activities.filter(
          (activity) => activity.year === parseInt(this.state.year, 10)
        )}
        openedActivities={this.state.openedActivities}
      />
    );
  };

  renderEditor = () => {
    if (this.state.currentActivity || this.state.createActivity) {
      return (
        <div className={styles['editor-container']}>
          <ActivityEditor
            key={
              this.state.currentActivity
                ? this.state.currentActivity.id
                : 'create'
            }
            activity={
              this.state.createActivity ? {} : this.state.currentActivity
            }
            handleUpdateActivity={this.updateActivity}
            handleCreateActivity={this.createActivity}
            handleDeleteActivity={this.deleteActivity}
            mode={this.state.createActivity ? 'create' : 'edit'}
            year={parseInt(this.state.year, 10)}
            currentMathDayYear={this.state.currentMathDayYear}
          />
        </div>
      );
    }
    if (this.state.currentTask || this.state.createTask) {
      return (
        <div className={styles['editor-container']}>
          <TaskEditor
            key={this.state.currentTask ? this.state.currentTask.id : 'create'}
            statuses={this.props.statuses}
            activity={
              this.state.createTask
                ? {}
                : this.findActivityById(this.state.currentTask.activity_id)
            }
            activities={this.props.activities}
            task={this.state.currentTask}
            handleUpdateTask={this.updateTask}
            handleCreateTask={this.createTask}
            handleDeleteTask={this.deleteTask}
            mode={this.state.createTask ? 'create' : 'edit'}
            year={parseInt(this.state.year, 10)}
            currentMathDayYear={this.state.currentMathDayYear}
          />
        </div>
      );
    }
    return (
      <div className={styles['editor-container']}>
        <Typography variant="body1">
          Click on an activity or task in the table on the left to bring up the
          details.
        </Typography>
      </div>
    );
  };

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={`${styles['header-container']}`}>
              <h2 className={styles['page-header']}>Activities</h2>
              <div className={styles['button-container']}>
                <ReusableButton
                  key="exportActivitiesButton"
                  onClick={this.handleExportActivitiesButtonClicked}
                  value="Export Activities"
                  className={`${styles['has-spacing']} ${styles['buttons']}`}
                />
                <ReusableButton
                  key="exportTasksButton"
                  onClick={this.handleExportTasksButtonClicked}
                  value="Export Tasks"
                  className={`${styles['has-spacing']} ${styles['buttons']}`}
                />
                <ReusableButton
                  key="addActivityButton"
                  onClick={this.handleAddActivityButtonClicked}
                  value="Add Activity"
                  className={`${styles['has-spacing']} ${styles['buttons']}`}
                />
                <ReusableButton
                  key="addTaskButton"
                  onClick={this.handleAddTaskButtonClicked}
                  value="Add Task"
                  className={styles['has-spacing']}
                />
              </div>
            </div>
            <div className={styles['page-container']}>
              <ActivitySidebar
                key={`${this.state.currentTask} ${this.state.currentActivity}`}
                isLoading={this.isLoading}
                year={parseInt(this.state.year, 10)}
                eventDates={this.props.eventDates}
                handleYearChange={this.handleYearChange}
                handleActivityClick={this.handleActivityClick}
                handleExpandActivities={this.handleExpandActivities}
                handleTaskClick={this.handleTaskClick}
                tasks={this.props.tasks.filter(
                  (task) => task.year === parseInt(this.state.year, 10)
                )}
                activities={this.props.activities.filter(
                  (activity) => activity.year === parseInt(this.state.year, 10)
                )}
                openedActivities={this.state.openedActivities}
              />
              {this.renderEditor()}
            </div>
            <ReusableSnackbar
              open={this.state.snackbarOpen}
              onClose={this.handleSnackbarClose}
              variant={this.state.snackbarVariant}
              message={this.state.snackbarMessage}
            />
          </div>
        </div>
      </div>
    );
  }
}

ActivitiesPage.propTypes = {
  getYear: PropTypes.func,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentMathDayYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getEventDates: PropTypes.func,
  eventDates: PropTypes.array,
  eventDatesLoading: PropTypes.bool.isRequired,
  statuses: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  getStatuses: PropTypes.func.isRequired,
  statusesLoading: PropTypes.bool.isRequired,
  activitiesLoading: PropTypes.bool.isRequired,
  tasksLoading: PropTypes.bool.isRequired,
  createActivity: PropTypes.func.isRequired,
  updateActivity: PropTypes.func.isRequired,
  deleteActivity: PropTypes.func.isRequired,
  getActivities: PropTypes.func.isRequired,
  getTasks: PropTypes.func.isRequired,
  createTask: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  downloadActivities: PropTypes.func.isRequired,
  downloadTasks: PropTypes.func.isRequired,
};

export default ActivitiesPage;
