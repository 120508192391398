import { connect } from 'react-redux';

import {
  selectActivitiesLoading,
  selectTasksLoading,
  selectActivities,
  selectTasks,
} from 'selectors/activitiesSelectors';

import {
  selectStatuses,
  selectStatusesLoading,
} from 'selectors/statusesSelectors';

import {
  getActivities,
  updateActivity,
  createActivity,
  downloadActivities,
  getTasks,
  updateTask,
  createTask,
  downloadTasks,
  deleteTask,
  deleteActivity,
} from 'actions/thunks/activities';

import {
  getStatuses,
} from 'actions/thunks/statuses';

import {
  getEventDates,
} from 'actions/thunks/eventDates';

import {
  getYear,
} from 'actions/thunks/systemSettings';

import { selectYear } from 'selectors/systemSettingsSelectors';

import { selectEventDates, selectEventDatesLoading } from 'selectors/eventDatesSelectors';

import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';
import ActivitiesPage from '../components/pages/admin/ActivitiesPage';

const mapStateToProps = state => ({
  currentMathDayYear: selectYear(state),
  year: selectYear(state),
  eventDates: selectEventDates(state),
  eventDatesLoading: selectEventDatesLoading(state),
  statuses: selectStatuses(state),
  statusesLoading: selectStatusesLoading(state),
  activitiesLoading: selectActivitiesLoading(state),
  activities: selectActivities(state),
  tasks: selectTasks(state),
  tasksLoading: selectTasksLoading(state),
  isAdmin: selectIsAdmin(state),
  isLoggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  getYear: () => dispatch(getYear()),
  getEventDates: () => dispatch(getEventDates()),
  getActivities: () => dispatch(getActivities()),
  getTasks: () => dispatch(getTasks()),
  getStatuses: () => dispatch(getStatuses()),
  updateActivity: (newActivity) => dispatch(updateActivity(newActivity)),
  createActivity: (newActivity) => dispatch(createActivity(newActivity)),
  downloadActivities: (year) => dispatch(downloadActivities(year)),
  updateTask: (newTask) => dispatch(updateTask(newTask)),
  createTask: (newTask) => dispatch(createTask(newTask)),
  downloadTasks: (year) => dispatch(downloadTasks(year)),
  deleteTask: (task) => dispatch(deleteTask(task)),
  deleteActivity: (activity) => dispatch(deleteActivity(activity)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivitiesPage);
