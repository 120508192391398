import { connect } from 'react-redux';

import VolunteerPastApplicationPage from 'components/pages/VolunteerPastApplicationPage';
import { selectIsLoggedIn, selectCurrentUser } from 'selectors/authenticationSelectors';
import { selectYear } from 'selectors/systemSettingsSelectors';
import { selectEventDates } from 'selectors/eventDatesSelectors';
import { getYear } from 'actions/thunks/systemSettings';
import { validateLogin } from 'actions/thunks/authentication';
import { getEventDates } from 'actions/thunks/eventDates';
import { selectTasks } from 'selectors/activitiesSelectors';
import { getTasks } from 'actions/thunks/activities';

const mapStateToProps = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  currentUser: selectCurrentUser(state),
  currentYear: selectYear(state),
  eventDates: selectEventDates(state),
  tasks: selectTasks(state),
});

const mapDispatchToProps = (dispatch) => ({
  getYear: () => dispatch(getYear()),
  validateLogin: () => dispatch(validateLogin()),
  getEventDates: () => dispatch(getEventDates()),
  getTasks: () => dispatch(getTasks()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VolunteerPastApplicationPage);
