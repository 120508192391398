/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-multi-str */
import React from 'react';
import PropTypes from 'prop-types';

import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableButton from 'components/common/ReusableButton';
import ReusableSelect from 'components/common/ReusableSelect';
import ReusableTextField from 'components/common/ReusableTextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { generalFields, applicantFields, recruiterFields } from 'utils/emails';
import { Editor } from '@tinymce/tinymce-react';
import { ReactMultiEmail, isEmail } from 'react-multi-email';

import styles from './style.module.scss';

class EmailEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      from: '',
      recipientType: props.recipientType,
      subject: '',
      body: '',
      signature: '',
      insertableFieldSelected: '',
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: '',
      emails: [],
    };
  }

  componentDidMount() {
    this.setState({
      name: this.isEmptyTemplate ? '' : this.props.template.name,
      recipientType: this.isEmptyTemplate || this.props.recipientType === 'custom' ? this.props.recipientType : this.props.template.recipient_type,
      from: this.isEmptyTemplate ? '' : this.props.template.sent_from,
      subject: this.isEmptyTemplate ? '' : this.props.template.subject,
      body: this.isEmptyTemplate ? '' : this.props.template.body,
      signature: this.props.signature,
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.signature !== nextProps.signature) {
      return {
        'signature': nextProps.signature,
      };
    }
    return {};
  }

  get isEmptyTemplate() {
    return this.props.template.id === -1;
  }

  get recipientTypeOptions() {
    return [{ display: 'Recruiters', value: 'recruiters' }, { display: 'Applicants', value: 'applicants' }, { display: 'Custom', value: 'custom' }];
  }

  get HTMLSignature() {
    return `<p>${this.state.signature.split('\n').join('<br/>')}</p>`;
  }

  clearCustomFields = () => {
    this.setState({
      emails: [],
      subject: '',
      body: '',
      from: '',
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleEditorChange = (event, ref) => {
    this.setState({
      body: ref.getContent(),
    });
  };

  handleInsertField = () => {
    this.setState((previousState) => {
      const fieldToBeInserted = `{${previousState.insertableFieldSelected}}`;
      if (!previousState.insertableFieldSelected) {
        return {
          'snackbarVariant': 'warning',
          'snackbarMessage': 'Please select a field to insert.',
          'snackbarOpen': true,
        };
      }
      this.tinymce.editor.execCommand('mceInsertContent', false, fieldToBeInserted);
      return {
        'body': this.tinymce.editor.getContent(),
      };
    });
  };

  handlePreviewClicked = () => {
    const payload = {
      body: `${this.state.body} ${this.HTMLSignature}`,
      subject: this.state.subject,
      sent_from: this.state.from,
      recipientType: this.state.recipientType,
      signature: this.state.signature,
    };
    if (this.validateFields(payload)) {
      this.props.handlePreviewClicked(payload);
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarVariant: 'error',
      });
    }
  };

  handleEditorFieldChange = name => (event) => {
    if (name === 'body') {
      this.setState({
        body: event.target.getContent(),
      });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
    if (name === 'recipientType') {
      this.props.onRecipientTypeChanged(event.target.value);
    }
  };

  validateFields = (payload) => {
    let errorMessage = '';
    let validated = true;
    if (!payload.body) {
      validated = false;
      errorMessage += 'Email needs a nonempty body.\n';
    }
    if (!payload.subject) {
      validated = false;
      errorMessage += 'Email needs a nonempty subject.\n';
    }
    if (!payload.sent_from) {
      validated = false;
      errorMessage += 'Email needs a nonempty sent from email.\n';
    }
    if (!this.validateEmailBody()) {
      validated = false;
      errorMessage += 'Number of opening curly braces must match the number of closing braces.\n';
    }
    this.setState({
      snackbarMessage: errorMessage,
    });
    return validated;
  };

  validateEmailBody = () => {
    const openingLength = this.state.body.split('{').length - 1;
    const closingLength = this.state.body.split('}').length - 1;
    return openingLength - closingLength === 0;
  };

  renderEmailEditor() {
    return (
      <div className={styles['column']}>
        <div className={`${styles['email-field']} ${styles['white']}`} style={{ display: this.isEmptyTemplate ? 'none' : 'show' }}>
          <Typography className={styles['field-label']} variant="body1">Template Name:</Typography>
          <ReusableTextField
            id="Template Name"
            label=""
            className={styles['email-text-field']}
            value={this.state.name}
            onChange={this.handleEditorFieldChange('name')}
          />
        </div>
        <div className={this.isEmptyTemplate ? `${styles['email-field']} ${styles['white']}` : `${styles['email-field']} ${styles['light-gray']}`}>
          <Typography className={styles['field-label']} variant="body1">To:</Typography>
          <div style={{ width: '50%' }} className={styles['column']}>
            <ReusableSelect
              id="recipientType"
              label=""
              excludeNoneOption
              className={styles['email-recipient-select']}
              value={this.state.recipientType}
              options={this.recipientTypeOptions}
              onChange={this.handleEditorFieldChange('recipientType')}
            />
            <ReactMultiEmail
              style={{ display: this.props.recipientType === 'custom' ? 'show' : 'none' }}
              emails={this.state.emails}
              onChange={(_emails) => {
                this.setState({ emails: _emails });
              }}
              validateEmail={email => isEmail(email)}
              getLabel={(email, index, removeEmail) => (
                <div data-tag key={index}>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                </div>
              )}
            />
          </div>

        </div>
        <div className={this.isEmptyTemplate ? `${styles['email-field']} ${styles['light-gray']}` : `${styles['email-field']} ${styles['white']}`}>
          <Typography className={styles['field-label']} variant="body1">Subject:</Typography>
          <ReusableTextField
            id="subject"
            label=""
            className={styles['email-text-field']}
            value={this.state.subject}
            onChange={this.handleEditorFieldChange('subject')}
            short
            margin="none"
          />
        </div>
        <div className={this.isEmptyTemplate ? `${styles['email-field']} ${styles['white']}` : `${styles['email-field']} ${styles['light-gray']}`}>
          <Typography className={styles['field-label']} variant="body1">From:</Typography>
          <ReusableTextField
            id="from"
            label=""
            className={styles['email-text-field']}
            value={this.state.from}
            onChange={this.handleEditorFieldChange('from')}
            short
            margin="none"
          />
        </div>
        <div className={this.isEmptyTemplate ? `${styles['email-text-container']} ${styles['light-gray']}` : `${styles['email-text-container']} ${styles['white']}`}>
          <div className={styles['row']}>
            <Typography className={styles['field-label']} variant="body1">Body:</Typography>
            <div className={styles['insertable-fields']}>
              <ReusableButton
                value="Insert"
                key={this.state.recipientType}
                hasSpace
                disabled={this.state.recipientType === 'custom'}
                onClick={this.handleInsertField}
              />
              <ReusableSelect
                id="insertableField"
                label=""
                excludeNoneOption
                displayEmpty
                placeholder
                placeholderText="Select"
                className={styles['insertable-field-select']}
                value={this.state.insertableFieldSelected}
                onChange={this.handleEditorFieldChange('insertableFieldSelected')}
                options={this.props.recipientType === 'recruiters' ? generalFields.concat(recruiterFields) : generalFields.concat(applicantFields)}
              />
              <ReusableButton
                id="previewEmail"
                value="Preview"
                className={styles['update-button']}
                onClick={this.handlePreviewClicked}
              />
            </div>
          </div>
          <Editor
            id="body"
            // eslint-disable-next-line no-return-assign
            ref={(editor) => this.tinymce = editor}
            apiKey="auvk5jrj9bf9uw3zbfhbvnerhias52hd0kh0rfj3djj80m8i"
            onFocusOut={(event, ref) => this.handleEditorChange(event, ref)}
            initialValue={`${this.state.body}`}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'paste link lists',
              ],
              toolbar:
              'undo redo | formatselect | bold italic backcolor forecolor | \
              alignleft aligncenter alignright alignjustify | \
              link numlist bullist outdent indent | removeformat | ',
            }}
            onEditorChange={(event, ref) => this.handleEditorFieldChange(event, ref)}
            onClick={(event, ref) => this.handleEditorFieldChange(event, ref)}
          />
        </div>
        <div className={styles['email-text-container']}>
          <Typography className={styles['field-label']} variant="body1">Signature:</Typography>
          <ReusableTextField
            id="signature"
            label=""
            multiline
            rows="10"
            type="text"
            className={styles['email-text']}
            value={this.state.signature}
            onChange={this.handleEditorFieldChange('signature')}
          />
        </div>
        <ReusableSnackbar
          open={this.state.snackbarOpen}
          onClose={this.handleSnackbarClose}
          variant={this.state.snackbarVariant}
          message={this.state.snackbarMessage}
        />
      </div>
    );
  }

  render() {
    if (this.props.loading) {
      return (
        <div className={styles['center']}>
          <CircularProgress />
        </div>
      );
    }
    return this.renderEmailEditor();
  }
}

EmailEditor.propTypes = {
  loading: PropTypes.bool.isRequired,
  signature: PropTypes.string.isRequired,
  template: PropTypes.object.isRequired,
  recipientType: PropTypes.oneOf(['recruiters', 'applicants', 'custom']),
  onRecipientTypeChanged: PropTypes.func.isRequired,
  handlePreviewClicked: PropTypes.func.isRequired,
};

export default EmailEditor;
