import { connect } from 'react-redux';

import { selectLoading, selectAnnouncements } from 'selectors/announcementsSelectors';
import {
  getAnnouncements,
  updateAnnouncement,
  createAnnouncement,
} from 'actions/thunks/announcements';
import AnnouncementsPage from 'components/pages/admin/AnnouncementsPage';
import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';

const mapStateToProps = (state) => ({
  loading: selectLoading(state),
  announcements: selectAnnouncements(state),
  isAdmin: selectIsAdmin(state),
  isLoggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAnnouncements: () => dispatch(getAnnouncements()),
  updateAnnouncement: (newAnnouncement) => dispatch(updateAnnouncement(newAnnouncement)),
  createAnnouncement: (newAnnouncement) => dispatch(createAnnouncement(newAnnouncement)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnnouncementsPage);
