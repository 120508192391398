import { createSelector } from 'reselect';

export const selectEventDatesLoading = createSelector(
  (state) => state.getIn(['eventDates', 'loading']),
  (loading) => loading,
);

export const selectEventDates = createSelector(
  (state) => state.getIn(['eventDates', 'data']),
  (data) => data.toJS(),
);
