import { connect } from 'react-redux';

import { getEmailMessages } from 'actions/thunks/emails';
import EmailLogPage from 'components/pages/admin/EmailLogPage';
import { selectEmailMessagesLoading, selectEmailMessages } from 'selectors/emailsSelectors';
import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';

const mapStateToProps = state => ({
  loading: selectEmailMessagesLoading(state),
  emailMessages: selectEmailMessages(state),
  isAdmin: selectIsAdmin(state),
  isLoggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  getEmailMessages: () => dispatch(getEmailMessages()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailLogPage);
