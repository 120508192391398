import { fromJS } from 'immutable';

import {
  GET_APPLICANTS_SENT,
  GET_APPLICANTS_SUCCESSFUL,
  FIND_EXISTING_USERS_SENT,
  FIND_EXISTING_USERS_SUCCESSFUL,
  RESET_ADD_CREATE_DIALOG,
  CREATE_APPLICATION_FROM_TABLE_SENT,
  CREATE_APPLICATION_FROM_TABLE_SUCCESSFUL,
  UPDATE_APPLICATION_FROM_TABLE_SENT,
  UPDATE_APPLICATION_FROM_TABLE_SUCCESSFUL,
} from 'actions/atomic/applicants';

const initialState = fromJS({
  loading: false,
  data: [],
  existingUsers: [],
});

export default function applicants(state = initialState, action) {
  switch (action.type) {
  case GET_APPLICANTS_SENT:
    return state.merge({
      loading: true,
      data: fromJS([]),
    });
  case GET_APPLICANTS_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: fromJS(action.data),
    });
  case FIND_EXISTING_USERS_SENT:
    return state.merge({
      existingUsers: fromJS([]),
    });
  case FIND_EXISTING_USERS_SUCCESSFUL:
    return state.merge({
      existingUsers: fromJS(action.data),
    });
  case RESET_ADD_CREATE_DIALOG:
    return state.merge({
      existingUsers: fromJS([]),
    });
  case CREATE_APPLICATION_FROM_TABLE_SENT:
    return state.merge({
      loading: true,
    });
  case CREATE_APPLICATION_FROM_TABLE_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: state.get('data').push(fromJS(action.data)),
    });
  case UPDATE_APPLICATION_FROM_TABLE_SENT:
    return state.merge({
      loading: true,
    });
  case UPDATE_APPLICATION_FROM_TABLE_SUCCESSFUL:
    const updatedApplicant = action.data;
    const index = state.get('data').findIndex(applicant => applicant.get('id') === updatedApplicant.id);
    return state.merge({
      loading: false,
      data: state.get('data').set(index, fromJS(updatedApplicant)),
    });
  default:
    return state;
  }
}
