import ApiWrapper from 'wrappers/api';
// import fileDownload from 'js-file-download';

import {
  getTaskPreferencesRequestSent,
  getTaskPreferencesRequestSuccessful,
  updateTaskPreferenceRequestSent,
  updateTaskPreferenceRequestSuccessful,
  createTaskPreferenceRequestSent,
  createTaskPreferenceRequestSuccessful,
} from 'actions/atomic/taskPreferences';

export function getTaskPreferences() {
  return (dispatch) => {
    dispatch(getTaskPreferencesRequestSent());
    return ApiWrapper.get('/task_preferences')
      .then(response => {
        dispatch(getTaskPreferencesRequestSuccessful(response.data));
      });
  };
}

export function createTaskPreference(newTaskPreference) {
  return (dispatch) => {
    dispatch(createTaskPreferenceRequestSent());
    return ApiWrapper.post('/task_preferences', newTaskPreference)
      .then(response => {
        dispatch(createTaskPreferenceRequestSuccessful(response.data));
      });
  };
}

export function updateTaskPreference(newTaskPreference) {
  return (dispatch) => {
    dispatch(updateTaskPreferenceRequestSent());
    return ApiWrapper.put('/task_preferences', { task_preference: newTaskPreference })
      .then(response => {
        dispatch(updateTaskPreferenceRequestSuccessful(response.data));
      });
  };
}

// export function downloadStatuses() {
//   return () => ApiWrapper.get('/task_preferences/download', { responseType: 'blob' })
//     .then(response => fileDownload(response.data, 'statuses.xlsx'));
// }
