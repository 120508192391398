import React from 'react';
import PropTypes from 'prop-types';

import CustomSnackbarContentWrapper from 'components/common/CustomSnackbarContentWrapper';
import Snackbar from '@material-ui/core/Snackbar';

const ReusableSnackbar = (props) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    key={props.message}
    open={props.open}
    autoHideDuration={6000}
    onClose={props.onClose}
    ClickAwayListenerProps={{ onClickAway: () => null }}
  >
    <CustomSnackbarContentWrapper
      onClose={props.onClose}
      variant={props.variant}
      message={props.message}
      optionalButtonMessage={props.optionalButtonMessage}
      optionalButtonOnClick={props.optionalButtonOnClick}
    />
  </Snackbar>
);

ReusableSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
  message: PropTypes.string.isRequired,
  optionalButtonMessage: PropTypes.string,
  optionalButtonOnClick: PropTypes.func,
};

export default ReusableSnackbar;
