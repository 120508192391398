export const GET_USERS_SENT = 'GET_USERS_SENT';
export const GET_USERS_SUCCESSFUL = 'GET_USERS_SUCCESSFUL';

export const CREATE_USER_SENT = 'CREATE_USER_SENT';
export const CREATE_USER_SUCCESSFUL = 'CREATE_USER_SUCCESSFUL';
export const RESET_CREATE_USER_FIELDS = 'RESET_CREATE_USER_FIELDS';

export const ACTIVATE_USER_SENT = 'ACTIVATE_USER_SENT';
export const ACTIVATE_USER_SUCCESSFUL = 'ACTIVATE_USER_SUCCESSFUL';

export const REQUEST_PASSWORD_RESET_REQUEST_SENT = 'REQUEST_PASSWORD_RESET_REQUEST_SENT';
export const REQUEST_PASSWORD_RESET_REQUEST_SUCCESSFUL = 'REQUEST_PASSWORD_RESET_REQUEST_SUCCESSFUL';
export const RESET_USER_NOT_FOUND = 'RESET_USER_NOT_FOUND';

export const RESET_PASSWORD_REQUEST_SENT = 'RESET_PASSWORD_REQUEST_SENT';
export const RESET_PASSWORD_REQUEST_SUCCESSFUL = 'RESET_PASSWORD_REQUEST_SUCCESSFUL';

export const CREATE_USER_FROM_TABLE_SENT = 'CREATE_USER_FROM_TABLE_SENT';
export const CREATE_USER_FROM_TABLE_SUCCESSFUL = 'CREATE_USER_FROM_TABLE_SUCCESSFUL';

export const UPDATE_USER_FROM_TABLE_SENT = 'UPDATE_USER_FROM_TABLE_SENT';
export const UPDATE_USER_FROM_TABLE_SUCCESSFUL = 'UPDATE_USER_FROM_TABLE_SUCCESSFUL';

export const UPDATE_CURRENT_USER_SENT = 'UPDATE_CURRENT_USER_SENT';
export const UPDATE_CURRENT_USER_SUCCESSFUL = 'UPDATE_CURRENT_USER_SUCCESSFUL';

export const getUsersRequestSent = () => ({ type: GET_USERS_SENT });

export const getUsersRequestSuccessful = (data) => ({ type: GET_USERS_SUCCESSFUL, data });

export const createUserRequestSent = () => ({ type: CREATE_USER_SENT });

export const createUserRequestSuccessful = (data) => ({ type: CREATE_USER_SUCCESSFUL, data });

export const resetCreateUserFields = () => ({ type: RESET_CREATE_USER_FIELDS });

export const activateUserRequestSent = () => ({ type: ACTIVATE_USER_SENT });

export const activateUserRequestSuccessful = (data) => ({ type: ACTIVATE_USER_SUCCESSFUL, data });

export const requestPasswordResetRequestSent = () => ({ type: REQUEST_PASSWORD_RESET_REQUEST_SENT });

export const requestPasswordResetRequestSuccessful = (data) => ({ type: REQUEST_PASSWORD_RESET_REQUEST_SUCCESSFUL, data });

export const resetUserNotFound = (data) => ({ type: RESET_USER_NOT_FOUND, data });

export const resetPasswordRequestSent = () => ({ type: RESET_PASSWORD_REQUEST_SENT });

export const resetPasswordRequestSuccessful = (data) => ({ type: RESET_PASSWORD_REQUEST_SUCCESSFUL, data });

export const createUserFromTableRequestSent = () => ({ type: CREATE_USER_FROM_TABLE_SENT });

export const createUserFromTableRequestSuccessful = (data) => ({ type: CREATE_USER_FROM_TABLE_SUCCESSFUL, data });

export const updateUserFromTableRequestSent = () => ({ type: UPDATE_USER_FROM_TABLE_SENT });

export const updateUserFromTableRequestSuccessful = (data) => ({ type: UPDATE_USER_FROM_TABLE_SUCCESSFUL, data });

export const updateCurrentUserRequestSent = () => ({ type: UPDATE_CURRENT_USER_SENT });

export const updateCurrentUserRequestSuccessful = (data) => ({ type: UPDATE_CURRENT_USER_SUCCESSFUL, data });
