import React from 'react';
import ReusableTable from 'components/common/ReusableTable';
import PropTypes from 'prop-types';
import ReusableSelect from 'components/common/ReusableSelect';
import { formatTime } from 'utils/date';
import dayjs from 'dayjs';

import { Tooltip } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import { customFilterByApplicantName, nameSortComparator } from '../../InteractiveAssignmentPage/utils';
import styles from '../style.module.scss';

class ApplicantAssignedTable extends React.Component {
  constructor(props) {
    super(props);

    this.statusLookup = {};

    this.state = {
      // Applicant Table
      applicantColumns: [
        {
          title: 'Name',
          field: 'name',
          tooltip: 'First initial. Last name',
          customSort: (a1, a2) => nameSortComparator(a1, a2),
          render: (applicant) => this.renderName(applicant),
          customFilterAndSearch: customFilterByApplicantName,
        },
        {
          title: 'Status',
          field: 'status.abbr',
          tooltip: 'The status of the applicant.',
          lookup: this.statusLookup,
        },
        {
          title: 'T Before',
          tooltip: 'The time windows the applicant is available for before assignment',
          render: (rowData) => this.renderBeforeAvailableColumn(rowData),
        },
        {
          title: 'T After',
          tooltip: 'The time windows that the applicant is available for after assignment',
          render: (rowData) => (this.props.assignments ? this.renderAfterAvailableColumn(rowData) : ''),
        },
        {
          title: 'Asgn',
          tooltip: 'The tasks assigned to the applicant',
          render: (applicant) => (this.props.assignments ? this.renderApplicantAssignedDropdown(applicant) : ''),
        },
      ],
      applicantOptions: {
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 700,
        hideDeleteButton: true,
        showFull: true,
        actionsColumnIndex: -1,
        search: false,
      },
      applicantActions: [
        {
          icon: 'replay',
          tooltip: 'Refetch Data',
          position: 'toolbar',
          isFreeAction: true,
          onClick: () => this.props.getApplicants(),
        },
        {
          icon: 'filter_alt',
          tooltip: 'Filter',
          position: 'toolbar',
          isFreeAction: true,
          onClick: () => this.props.handleOpenApplicantFilter(),
        },
      ],
    };
  }

  removeTimeFromApplicantAvailability(time, applicant) {
    const adjustedTimeWindows = [];
    applicant.time_windows.sort((a, b) => (dayjs(a.starts_at) <= dayjs(b.starts_at) ? -1 : 1));
    applicant.time_windows.forEach(timeWindow => {
      // availability window starts before time window starts
      if (dayjs(timeWindow?.starts_at).isBefore(dayjs(time?.starts_at))) {
        // availability window completely before time window
        if (dayjs(timeWindow?.ends_at).isBefore(dayjs(time?.starts_at))) {
          adjustedTimeWindows.push(timeWindow);
        }
        // availability window starts before time window but ends within time window
        if ((dayjs(timeWindow?.ends_at).isAfter(dayjs(time?.starts_at))) && ((dayjs(timeWindow?.ends_at).isBefore(dayjs(time?.ends_at))) || dayjs(timeWindow?.ends_at).isSame(time?.ends_at))) {
          adjustedTimeWindows.push({
            ends_at: time?.starts_at,
            id: timeWindow.id,
            starts_at: timeWindow?.starts_at,
            year: timeWindow.year,
          });
        }
        // availability window contains the entire time window
        if (dayjs(timeWindow?.ends_at).isAfter(dayjs(time?.ends_at))) {
          adjustedTimeWindows.push({
            ends_at: time?.starts_at,
            id: timeWindow.id,
            starts_at: timeWindow?.starts_at,
            year: timeWindow.year,
          });
          adjustedTimeWindows.push({
            ends_at: timeWindow?.ends_at,
            id: timeWindow.id,
            starts_at: time?.ends_at,
            year: timeWindow.year,
          });
        }
      }
      // availability window starts after time window starts
      if (dayjs(timeWindow?.starts_at).isAfter(dayjs(time?.starts_at))) {
        // availability window is completely contained within the time window
        if (dayjs(timeWindow?.ends_at).isBefore(dayjs(time?.ends_at)) || dayjs(timeWindow?.ends_at).isSame(dayjs(time?.ends_at))) {
          // we no longer need this time window
        }
        // availability window starts within time window and ends after time window ends
        if (dayjs(timeWindow?.starts_at).isBefore(dayjs(time?.ends_at)) && dayjs(timeWindow?.ends_at).isAfter(dayjs(time?.ends_at))) {
          adjustedTimeWindows.push({
            ends_at: timeWindow?.ends_at,
            id: timeWindow.id,
            starts_at: time?.ends_at,
            year: timeWindow.year,
          });
        }
        // availability window is completely after time window
        if (dayjs(timeWindow?.starts_at).isAfter(dayjs(time?.ends_at))) {
          adjustedTimeWindows.push(timeWindow);
        }
      }

      // availability window starts exactly when time window starts
      if (dayjs(timeWindow?.starts_at).isSame(dayjs(time?.starts_at))) {
        // availability window ends on or before time window ends: do nothing

        // availability window ends after time window ends
        if (dayjs(timeWindow?.ends_at).isAfter(dayjs(time?.ends_at))) {
          adjustedTimeWindows.push({
            ends_at: timeWindow?.ends_at,
            id: timeWindow.id,
            starts_at: time?.ends_at,
            year: timeWindow.year,
          });
        }
      }
    });
    applicant.time_windows = adjustedTimeWindows;
    return applicant;
  }

  renderName = (applicant) => {
    const shortLastName = applicant.user?.last_name.length > 9 ? `${applicant.user?.last_name.slice(0, 7)}...` : applicant.user?.last_name;
    return <Tooltip title={`${applicant.user?.first_name} ${applicant.user?.last_name}`} placement="bottom-start"><p className={styles['nowrap']} style={{ margin: 0 }}>{`${applicant.user?.first_name.charAt(0)}. ${shortLastName}`}</p></Tooltip>;
  };

  // renders the tasks that shown in the applicant table in a dropdown format
  renderApplicantAssignedDropdown(applicant) {
    const assignments = this.props.assignments[applicant.id];
    if (!assignments) {
      return 'None';
    }
    const solutionAssignments = assignments.map(assignment => ({
      task: this.props.tasks.find(t => t.id === assignment.id),
      backup: assignment.backup,
    }));
    if (solutionAssignments.length !== 0) {
      const applicantTaskOptions = solutionAssignments.map(solutionAssignment => {
        const backup = solutionAssignment.backup ? ' (B)' : '';
        const preference = applicant.task_preferences.find(tp => tp?.task_id === solutionAssignment?.task?.id);
        const prefMap = {
          undefined: 'black', 1: '#D8D8D8', 2: '#EE4B2B', 3: '#FFC300', 4: '#009E60',
        };
        const color = prefMap[preference?.preference];
        const display = (
          <p className={styles['nowrap']} style={{ color, margin: 0 }}>
            {
              `${solutionAssignment.task?.abbr ? `${solutionAssignment.task.abbr.trim()}${backup}` : `${solutionAssignment.task.name}${backup}`}${solutionAssignment.task.preemtable ? ' (P)' : ''}`
            }

          </p>
        );
        return {
          display,
          value: `${solutionAssignment.task.abbr ? `${solutionAssignment.task.abbr.trim()}${backup}` : `${solutionAssignment.task.name}${backup}`}${solutionAssignment.task.preemtable ? ' (P)' : ''}`,
        };
      });
      const backup = solutionAssignments[0].backup ? ' (B)' : '';
      const menuTask = `${solutionAssignments[0].task?.abbr ? solutionAssignments[0].task.abbr.trim() : solutionAssignments[0].task.name}${backup}${solutionAssignments[0].task.preemtable ? ' (P)' : ''}`;
      if (solutionAssignments.length === 1) {
        return <div className={styles['nowrap']}>{menuTask}</div>;
      }
      return (
        <>
          <div>
            <ReusableSelect
              id="preferred"
              className={styles['task-preference']}
              value={menuTask}
              options={applicantTaskOptions}
              useDefaultVariant
              isTransparent
              displayEmpty
              excludeNoneOption
              style={{ float: 'left', width: 10 }}
            />
            {/* TODO: Think about how to add later{
              solutionAssignments.length === 0 ? <></>
                : <Button style={{ height: 10, width: 10, justifyContent: 'flex-start' }} onClick={() => this.props.openApplicantSolutionAssignmentsDialog(applicant)}>...</Button>
            } */}
          </div>
        </>
      );
    }
    return 'None';
  }

  /**
   * renders applicant's availability times in a dropdown format
   * @param {applicant} applicant
   * @returns dropdown with available times else None if no availability
   */
  renderAfterAvailableColumn = (applicant) => {
    let updatedApplicant = cloneDeep(applicant);
    const { id } = applicant;
    const assigned = this.props.assignments[id];
    if (!assigned) {
      return this.renderBeforeAvailableColumn(applicant);
    }
    assigned.forEach(assignment => {
      const t = this.props.tasks.find(task => task.id === assignment.id);
      const time = {
        starts_at: t.starts_at,
        ends_at: t.ends_at,
      };
      updatedApplicant = this.removeTimeFromApplicantAvailability(time, updatedApplicant);
    });

    const timeWindows = updatedApplicant.time_windows;
    if (timeWindows && timeWindows.length !== 0) {
      const timeWindowOptions = timeWindows.map(timeWindow => ({
        display: `${formatTime(timeWindow?.starts_at)}-${formatTime(timeWindow?.ends_at)}`,
        value: `${formatTime(timeWindow?.starts_at)}-${formatTime(timeWindow?.ends_at)}`,
      }));
      if (timeWindows.length === 1) {
        return (
          <div className={styles['nowrap']}>
            {`${formatTime(timeWindows[0].starts_at)}-${formatTime(timeWindows[0].ends_at)}`}
          </div>
        );
      }
      return (
        <ReusableSelect
          id="preferred"
          className={styles['task-preference']}
          value={timeWindowOptions[0].display}
          options={timeWindowOptions}
          useDefaultVariant
          isTransparent
          displayEmpty
          excludeNoneOption
        />
      );
    }

    return (
      <div className={styles['nowrap']}>
        None
      </div>
    );
  };

  /**
   * renders applicant's availability times in a dropdown format
   * @param {applicant} applicant
   * @returns dropdown with available times else None if no availability
   */
  renderBeforeAvailableColumn = (applicant) => {
    const timeWindows = this.props.applicants?.get(applicant.id)?.time_windows;
    if (timeWindows && timeWindows.length !== 0) {
      const timeWindowOptions = timeWindows.map(timeWindow => ({
        display: `${formatTime(timeWindow?.starts_at)}-${formatTime(timeWindow?.ends_at)}`,
        value: `${formatTime(timeWindow?.starts_at)}-${formatTime(timeWindow?.ends_at)}`,
      }));
      if (timeWindows.length === 1) {
        return (
          <div className={styles['nowrap']}>
            {`${formatTime(timeWindows[0].starts_at)}-${formatTime(timeWindows[0].ends_at)}`}
          </div>
        );
      }
      return (
        <ReusableSelect
          id="preferred"
          className={styles['task-preference']}
          value={timeWindowOptions[0].display}
          options={timeWindowOptions}
          useDefaultVariant
          isTransparent
          displayEmpty
          excludeNoneOption
        />
      );
    }

    return (
      <div className={styles['nowrap']}>
        None
      </div>
    );
  };

  render() {
    this.props.data.forEach(applicant => {
      if (applicant.status) {
        this.statusLookup[applicant.status.abbr] = applicant.status.abbr;
      }
    });
    return (
      <div id="applicant-table-container" className={styles['applicant-table-container']}>
        <ReusableTable
          title={`Applicants (${this.props.data.length})`}
          columns={this.state.applicantColumns}
          data={this.props.data}
          options={this.state.applicantOptions}
          actions={this.state.applicantActions}
          isLoading={this.props.isLoading}
          renderYearOptions={this.props.renderYearOptions}
        />
      </div>
    );
  }
}

ApplicantAssignedTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getApplicants: PropTypes.func.isRequired,
  assignments: PropTypes.object.isRequired,
  handleOpenApplicantFilter: PropTypes.func.isRequired,
  applicants: PropTypes.object.isRequired,
  renderYearOptions: PropTypes.func.isRequired,
  tasks: PropTypes.array.isRequired,
};

export default ApplicantAssignedTable;
