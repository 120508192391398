import React from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import PropTypes from 'prop-types';

function DatePopup(props) {
  return (
    <MuiPickersUtilsProvider utils={DayJsUtils}>
      <DatePicker
        format="YYYY-MM-DD"
        value={props.value}
        onChange={(date) => props.onChange(date)}
      />
    </MuiPickersUtilsProvider>
  );
}

DatePopup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DatePopup;
