import { createSelector } from 'reselect';

export const selectIsError = createSelector(
  (state) => state.getIn(['systemSettings', 'isError']),
  (isError) => isError,
);

export const selectYear = createSelector(
  (state) => state.getIn(['systemSettings', 'year']),
  (year) => year,
);

export const selectMOTD = createSelector(
  (state) => state.getIn(['systemSettings', 'motd']),
  (motd) => motd,
);

export const selectEmailSignature = createSelector(
  (state) => state.getIn(['systemSettings', 'emailSignature']),
  (emailSignature) => emailSignature,
);

export const selectYaspFormLink = createSelector(
  (state) => state.getIn(['systemSettings', 'yaspFormLink']),
  (yaspFormLink) => yaspFormLink,
);
