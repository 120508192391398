import React from 'react';
import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number';

import ReusableButton from 'components/common/ReusableButton';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableTextField from 'components/common/ReusableTextField';
import ReusableSelect from 'components/common/ReusableSelect';
import styles from './style.module.scss';

const adminOptions = [
  { value: false, display: 'No' },
  { value: true, display: 'Yes' },
];

class UserAccountForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: props.existingUser && props.existingUser.first_name ? props.existingUser.first_name : '',
      firstNameError: false,
      middleName: props.existingUser && props.existingUser.middle_name ? props.existingUser.middle_name : '',
      lastName: props.existingUser && props.existingUser.last_name ? props.existingUser.last_name : '',
      lastNameError: false,
      preferredFirstName: props.existingUser && props.existingUser.preferred_name ? props.existingUser.preferred_name : '',
      phoneNumber: props.existingUser && props.existingUser.phone_number ? `+1 ${props.existingUser.phone_number}` : '',
      phoneNumberError: false,
      gender: props.existingUser && props.existingUser.gender ? props.existingUser.gender : '',
      genderError: false,
      email: props.existingUser && props.existingUser.email ? props.existingUser.email : '',
      emailError: false,
      password: '',
      passwordConfirmation: '',
      passwordError: false,
      showInvalidFieldsSnackbar: false,
      invalidFieldsSnackbarMessage: '',
    };

    if (props.fromAdmin) {
      this.state.admin = props.existingUser && props.existingUser.admin ? props.existingUser.admin : false;
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });

    if (name === 'firstName') {
      this.setState({ firstNameError: false });
    }
    if (name === 'lastName') {
      this.setState({ lastNameError: false });
    }
    if (name === 'gender') {
      this.setState({ genderError: false });
    }
    if (name === 'email') {
      this.setState({ emailError: false });
    }
    if (name === 'password' || name === 'passwordConfirmation') {
      this.setState({ passwordError: false });
    }
  };

  handlePhoneNumberChange = value => {
    this.setState({ phoneNumber: value, phoneNumberError: false });
  };

  validateFields = () => {
    let errorMessage = '';
    if (this.state.firstName === '') {
      errorMessage += 'First name cannot be empty.\n';
      this.setState({
        firstNameError: true,
      });
    }
    if (this.state.lastName === '') {
      errorMessage += 'Last name cannot be empty.\n';
      this.setState({
        lastNameError: true,
      });
    }
    // +1 (111) 111-1111
    // 3 characters for country code, 5 for area code, a space, 8 for full phone number with hyphen
    if (this.state.phoneNumber.length !== 17) {
      errorMessage += 'Phone number cannot be incomplete.\n';
      this.setState({
        phoneNumberError: true,
      });
    }
    if (this.state.gender === '') {
      errorMessage += 'Please select an option for gender.\n';
      this.setState({
        genderError: true,
      });
    }
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(this.state.email)) {
      errorMessage += 'Email is not in a valid format or is empty.\n';
      this.setState({
        emailError: true,
      });
    }
    if (!this.props.fromAdmin) {
      if ((!this.props.existingUser && this.state.password.length === 0) || this.state.password !== this.state.passwordConfirmation) {
        errorMessage += 'Passwords do not match or are empty.\n';
        this.setState({
          passwordError: true,
        });
      }
    }
    errorMessage = errorMessage.trim();
    this.setState({ invalidFieldsSnackbarMessage: errorMessage, showInvalidFieldsSnackbar: errorMessage !== '' });
    return errorMessage === '';
  };

  handleSubmitButtonClicked = async () => {
    const allFieldsValid = this.validateFields();

    if (!allFieldsValid) {
      return;
    }

    const payload = {
      user: {
        first_name: this.state.firstName,
        middle_name: this.state.middleName,
        last_name: this.state.lastName,
        preferred_name: this.state.preferredFirstName,
        phone_number: this.state.phoneNumber.slice(3), // Remove the country code before saving the phone number in the database
        gender: this.state.gender,
        email: this.state.email,
        password: this.state.password,
      },
    };

    if (!this.props.fromAdmin) {
      payload.user.password = this.state.password;
    } else {
      payload.user.admin = this.state.admin;
    }

    if (this.props.existingUser) {
      payload.user.id = this.props.existingUser.id;
    }
    await this.props.handleSubmit(payload);

    if (this.props.existingUser && !this.props.fromAdmin) {
      this.props.validateLogin();
    }
  };

  closeInvalidFieldsSnackbar = () => {
    this.setState({ showInvalidFieldsSnackbar: false, invalidFieldsSnackbarMessage: '' });
  };

  render() {
    return (
      <div>
        <div className={styles['row']}>
          <ReusableTextField
            className={styles['has-spacing']}
            id="firstName"
            label="First Name"
            value={this.state.firstName}
            onChange={this.handleChange('firstName')}
            error={this.state.firstNameError}
            required
          />
          <ReusableTextField
            className={styles['has-spacing']}
            id="middleName"
            label="Middle Name"
            value={this.state.middleName}
            onChange={this.handleChange('middleName')}
          />
          <ReusableTextField
            id="lastName"
            label="Last Name"
            value={this.state.lastName}
            onChange={this.handleChange('lastName')}
            error={this.state.lastNameError}
            required
          />
        </div>
        <div className={styles['row']}>
          <ReusableTextField
            className={styles['has-spacing']}
            id="preferredFirstName"
            label="Preferred Name"
            value={this.state.preferredFirstName}
            onChange={this.handleChange('preferredFirstName')}
          />
          <MuiPhoneNumber
            className={`${styles['has-spacing']} ${styles['full-width']} ${styles['white-input-field']}`}
            id="phoneNumber"
            label="Phone Number"
            defaultCountry="us"
            disableDropdown
            countryCodeEditable={false}
            margin="normal"
            variant="outlined"
            value={this.state.phoneNumber}
            onChange={this.handlePhoneNumberChange}
            error={this.state.phoneNumberError}
            required
          />
          <ReusableSelect
            id="gender"
            label="Gender"
            value={this.state.gender}
            options={this.props.genderOptions}
            onChange={this.handleChange('gender')}
            error={this.state.genderError}
            required
          />
        </div>
        {!this.props.fromAdmin
        && (
          <>
            <ReusableTextField
              id="email"
              label="Email"
              value={this.state.email}
              onChange={this.handleChange('email')}
              error={this.state.emailError}
              required
            />
            <ReusableTextField
              id="password"
              label="Password"
              value={this.state.password}
              onChange={this.handleChange('password')}
              type="password"
              error={this.state.passwordError}
              required
            />
            <ReusableTextField
              id="passwordConfirmation"
              label="Confirm Password"
              value={this.state.passwordConfirmation}
              onChange={this.handleChange('passwordConfirmation')}
              type="password"
              error={this.state.passwordError}
              required
            />
          </>
        )}
        {this.props.fromAdmin
        && (
          <div className={styles['row']}>
            <ReusableTextField
              className={styles['has-spacing']}
              id="email"
              label="Email"
              value={this.state.email}
              onChange={this.handleChange('email')}
              error={this.state.emailError}
              required
            />
            <ReusableSelect
              className={styles['has-spacing']}
              id="admin"
              label="Admin"
              value={this.state.admin}
              options={adminOptions}
              onChange={this.handleChange('admin')}
              required
              excludeNoneOption
            />
            <ReusableButton
              className={styles['extra-top-spacing']}
              value={this.props.submitButtonText}
              hasTopMargin
              onClick={this.handleSubmitButtonClicked}
            />
          </div>
        )}
        {!this.props.fromAdmin
        && (
          <ReusableButton
            value={this.props.submitButtonText}
            hasTopMargin
            onClick={this.handleSubmitButtonClicked}
          />
        )}
        <ReusableSnackbar
          open={this.state.showInvalidFieldsSnackbar}
          onClose={this.closeInvalidFieldsSnackbar}
          variant="error"
          message={this.state.invalidFieldsSnackbarMessage}
        />
      </div>
    );
  }
}

UserAccountForm.propTypes = {
  genderOptions: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  existingUser: PropTypes.object,
  validateLogin: PropTypes.func,
  fromAdmin: PropTypes.bool,
};

export default UserAccountForm;
