import ApiWrapper from 'wrappers/api';
import fileDownload from 'js-file-download';

import {
  getUsersRequestSent,
  getUsersRequestSuccessful,
  updateUserFromTableRequestSent,
  updateUserFromTableRequestSuccessful,
  createUserRequestSent,
  createUserRequestSuccessful,
  activateUserRequestSent,
  activateUserRequestSuccessful,
  requestPasswordResetRequestSent,
  requestPasswordResetRequestSuccessful,
  resetPasswordRequestSent,
  resetPasswordRequestSuccessful,
  createUserFromTableRequestSent,
  createUserFromTableRequestSuccessful,
  updateCurrentUserRequestSent,
  updateCurrentUserRequestSuccessful,
} from 'actions/atomic/users';

export function getUsers() {
  return (dispatch) => {
    dispatch(getUsersRequestSent());
    return ApiWrapper.get('/users')
      .then(response => {
        dispatch(getUsersRequestSuccessful(response.data));
      });
  };
}

export function createUser(newUser) {
  return (dispatch) => {
    dispatch(createUserRequestSent());
    return ApiWrapper.post('/users', newUser)
      .then(response => {
        dispatch(createUserRequestSuccessful(response.data));
      });
  };
}

export function activateAccount(payload) {
  return (dispatch) => {
    dispatch(activateUserRequestSent());
    return ApiWrapper.post('/users/activate', payload)
      .then(response => {
        dispatch(activateUserRequestSuccessful(response.data));
      });
  };
}

export function requestPasswordReset(payload) {
  return (dispatch) => {
    dispatch(requestPasswordResetRequestSent());
    return ApiWrapper.post('/users/request-password-reset', payload)
      .then(response => {
        dispatch(requestPasswordResetRequestSuccessful(response.data));
      });
  };
}

export function resetPassword(payload) {
  return (dispatch) => {
    dispatch(resetPasswordRequestSent());
    return ApiWrapper.post('/users/reset-password', payload)
      .then(response => {
        dispatch(resetPasswordRequestSuccessful(response.data));
      });
  };
}

export function createUserFromTable(newUser) {
  return (dispatch) => {
    dispatch(createUserFromTableRequestSent());
    return ApiWrapper.post('/users/table', newUser)
      .then(response => {
        dispatch(createUserFromTableRequestSuccessful(response.data));
      });
  };
}

export function updateUserFromTable(newUser) {
  return (dispatch) => {
    dispatch(updateUserFromTableRequestSent());
    return ApiWrapper.put(`/users/table/${newUser.id}`, newUser)
      .then(response => {
        dispatch(updateUserFromTableRequestSuccessful(response.data));
      });
  };
}

export function updateCurrentUser(newUser) {
  return (dispatch) => {
    dispatch(updateCurrentUserRequestSent());
    return ApiWrapper.put('/users/current-user', newUser)
      .then(response => {
        dispatch(updateCurrentUserRequestSuccessful(response.data));
      });
  };
}

export function downloadUsers() {
  return () => ApiWrapper.get('/users/download', { responseType: 'blob' })
    .then(response => fileDownload(response.data, 'users.xlsx'));
}
