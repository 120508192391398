/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import ReusableTable from 'components/common/ReusableTable';
import ReusableSelect from 'components/common/ReusableSelect';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import AddEditApplicantDialog from 'components/pages/admin/AddEditApplicantDialog';
import { formatDate, formatTime } from 'utils/date';
import history from 'wrappers/history';
import styles from './style.module.scss';
import { yearOptions } from '../AdminUtils.ts';

const preferenceMap = {
  'preferred': 4,
  'acceptable': 3,
  'avoid': 2,
  'cantdo': 1,
};

class ApplicantsPage extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }

    this.state = {
      year: this.props.year,
      addCreateDialogOpen: false,
      selectedApplicant: null,
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: '',
      columns: [
        {
          title: 'Last Name',
          field: 'user.last_name',
          tooltip: 'The last name of the applicant.',
          defaultSort: 'asc',
        },
        {
          title: 'First Name',
          field: 'user.first_name',
          tooltip: 'The first name of the applicant.',
        },
        {
          title: 'Status',
          field: 'status.abbr',
          tooltip: 'The status of the applicant.',
          lookup: this.statusLookup,
        },
        {
          title: 'Affiliation',
          field: 'affiliation.name',
          tooltip: 'The department/institution that the applicant is affiliated with.',
          lookup: this.affiliationLookup,
        },
        {
          title: 'Preferred',
          tooltip: 'The tasks that the applicant prefers.',
          render: (rowData) => this.renderTaskPreference(preferenceMap['preferred'], rowData.task_preferences),
        },
        {
          title: 'Acceptable',
          tooltip: 'The tasks that the applicant considers acceptable.',
          render: (rowData) => this.renderTaskPreference(preferenceMap['acceptable'], rowData.task_preferences),
        },
        {
          title: 'Avoid',
          tooltip: 'The tasks that the applicant would rather avoid.',
          render: (rowData) => this.renderTaskPreference(preferenceMap['avoid'], rowData.task_preferences),
        },
        {
          title: 'Can\'t Do',
          tooltip: 'The tasks that the applicant cannot do.',
          render: (rowData) => this.renderTaskPreference(preferenceMap['cantdo'], rowData.task_preferences),
        },
        {
          title: 'Time Windows',
          tooltip: 'The time windows that the applicant entered.',
          render: (rowData) => this.renderTimeWindows(rowData.time_windows),
        },
        {
          title: 'YASP',
          field: 'youth_form_date',
          type: 'date',
          tooltip: 'The date that the applicant filled out the YASP form.',
          render: (applicant) => (<div className={styles['nowrap']}>{formatDate(applicant.youth_form_date)}</div>),
        },
        {
          title: 'SOR Check',
          field: 'sor_check',
          type: 'date',
          tooltip: 'The date that the applicant was checked against the SOR.',
          render: (applicant) => (<div className={styles['nowrap']}>{formatDate(applicant.sor_check)}</div>),
        },
        {
          title: 'Phone',
          field: 'user.phone_number',
          tooltip: 'The phone number of the applicant.',
          render: (applicant) => (<div className={styles['nowrap']}>{applicant.user ? applicant.user.phone_number : 'N/A'}</div>),
        },
        {
          title: 'Email',
          field: 'user.email',
          tooltip: 'The email of the applicant.',
        },
        {
          title: 'Comments',
          field: 'comments',
          tooltip: 'The comments left by the applicant.',
        },
        {
          title: 'Completed',
          field: 'application_finalized',
          render: (applicant) => this.applicantStatus(applicant),
          customSort: (a, b) => (a.application_finalized >= b.application_finalized ? -1 : 1),
          lookup: this.completedLookup,
        },
      ],
      options: {
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 700,
        selection: true,
        hideDeleteButton: true,
      },
      actions: [
        {
          icon: 'replay',
          tooltip: 'Refetch Data',
          isFreeAction: true,
          onClick: () => this.props.getApplicants(),
        },
        {
          icon: 'save_alt',
          tooltip: 'Export',
          isFreeAction: true,
          onClick: () => {
            this.props.downloadApplicants(this.state.year);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'info',
              snackbarMessage: 'Download in progress.',
            });
          },
        },
        {
          icon: 'add_box',
          tooltip: 'Add Applicant',
          isFreeAction: true,
          disabled: false,
          onClick: () => {
            this.setState({ addCreateDialogOpen: true });
          },
        },
        {
          icon: 'edit',
          tooltip: 'Edit Applicant',
          position: 'row',
          disabled: false,
          onClick: (event, applicant) => {
            this.setState({ addCreateDialogOpen: true, selectedApplicant: applicant });
          },
        },
        {
          icon: 'email',
          tooltip: 'Email Selected Applicants',
          displayDuringSelect: true,
          onClick: (event, data) => this.handleEmailApplicants(data),
        },
      ],
    };
  }

  async componentDidMount() {
    if (this.props.year === '') {
      await this.props.getYear();
    }
    if (this.props.eventDates.length === 0) {
      await this.props.getEventDates();
    }
    if (this.props.applicants.length === 0) {
      await this.props.getApplicants();
    }

    this.setState({ year: this.props.year });
    setTimeout(this.updateLookups, 50);
  }

  get applicantsForCurrentYear() {
    // For some reason loading the page for the first time, switching to a different page, and switching back
    // breaks the page. This fixes it.
    if (!this.state) {
      return [];
    }
    return this.props.applicants.filter(applicant => applicant.year === this.state.year);
  }

  get statusLookup() {
    const statusOptions = this.applicantsForCurrentYear.map((applicant) => applicant.status);
    return statusOptions.reduce((map, statusOption) => {
      if (statusOption) {
        map[statusOption.abbr] = statusOption.abbr; // eslint-disable-line no-param-reassign
      }
      return map;
    }, {});
  }

  get affiliationLookup() {
    const affiliationOptions = this.applicantsForCurrentYear.map((applicant) => applicant.affiliation);
    return affiliationOptions.reduce((map, affiliationOption) => {
      if (affiliationOption) {
        map[affiliationOption.name] = affiliationOption.name; // eslint-disable-line no-param-reassign
      }
      return map;
    }, {});
  }

  get completedLookup() {
    return { true: 'complete', false: 'incomplete' };
  }

  closeAddEditDialog = () => {
    this.setState({ addCreateDialogOpen: false, selectedApplicant: null });
  };

  applicantStatus = applicant => {
    if (applicant.application_finalized) {
      return <CheckCircleIcon className={styles['complete']} />;
    }

    return <CancelIcon className={styles['not-complete']} />;
  };

  getColumnByField = field => {
    const { columns } = this.state;
    return columns.filter((column) => column.field === field)[0];
  };

  updateLookups = () => {
    const { columns } = this.state;
    const statusColumn = this.getColumnByField('status.abbr');
    const affiliationColumn = this.getColumnByField('affiliation.name');
    const completedColumn = this.getColumnByField('application_finalized');
    statusColumn.lookup = this.statusLookup;
    affiliationColumn.lookup = this.affiliationLookup;
    completedColumn.lookup = this.completedLookup;
    this.setState({ columns });
  };

  handleYearChange = (event) => {
    this.setState(prevState => ({
      year: event.target.value,
      actions: [
        prevState.actions[0],
        prevState.actions[1],
        {
          ...prevState.actions[2],
          disabled: event.target.value !== this.props.year,
        },
        {
          ...prevState.actions[3],
          disabled: event.target.value !== this.props.year,
        },
        prevState.actions[4],
      ],
    }));
    setTimeout(this.updateLookups, 50);
  };

  renderYearOptions = () => (
    <ReusableSelect
      id="year"
      label="Event Date"
      className={styles['year-options']}
      value={this.state.year}
      options={yearOptions(this.props.eventDates)}
      onChange={(event) => this.handleYearChange(event)}
      excludeNoneOption
      useDefaultVariant
    />
  );

  renderTaskPreference = (preferenceValue, taskPreferences) => {
    const filteredTaskPreferences = taskPreferences
      ? taskPreferences.filter(taskPreference => taskPreference.preference === preferenceValue)
      : [];
    if (filteredTaskPreferences.length !== 0) {
      const taskPreferencesOptions = filteredTaskPreferences.map(taskPreference => ({
        display: taskPreference.task.abbr.trim() ? taskPreference.task.abbr : taskPreference.task.name,
        value: taskPreference.task.abbr.trim() ? taskPreference.task.abbr : taskPreference.task.name,
      }));
      return (
        <ReusableSelect
          id="preferred"
          className={styles['task-preference']}
          value=""
          emptyValue={filteredTaskPreferences.length}
          options={taskPreferencesOptions}
          useDefaultVariant
          isTransparent
          displayEmpty
        />
      );
    }

    return (
      <div>
        -
      </div>
    );
  };

  renderTimeWindows = (timeWindows) => {
    const filteredTimeWindows = timeWindows
      ? timeWindows.filter(timeWindow => timeWindow.year === this.state.year)
      : [];
    if (filteredTimeWindows.length !== 0) {
      const timeWindowOptions = filteredTimeWindows.map(timeWindow => ({
        display: `${formatTime(timeWindow.starts_at)}-${formatTime(timeWindow.ends_at)}`,
        value: `${formatTime(timeWindow.starts_at)}-${formatTime(timeWindow.ends_at)}`,
      }));
      return (
        <ReusableSelect
          id="preferred"
          className={styles['task-preference']}
          value=""
          emptyValue={filteredTimeWindows.length}
          options={timeWindowOptions}
          useDefaultVariant
          isTransparent
          displayEmpty
        />
      );
    }

    return (
      <div>
        -
      </div>
    );
  };

  handleEmailApplicants = (applicants) => {
    const applicantsSelected = {};
    applicants.forEach((applicant) => applicantsSelected[applicant.id] = true);
    this.props.setApplicantsSelected(applicantsSelected);
    history.push('/admin/email');
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  updateUserFromDialog = (user) => {
    this.props.updateUser(user);
    setTimeout(() => {
      this.props.getApplicants();
    }, 200);
  };

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['table-container']}>
              <ReusableTable
                title="Applicants"
                columns={this.state.columns}
                data={this.applicantsForCurrentYear}
                options={this.state.options}
                isLoading={this.props.loading}
                actions={this.state.actions}
                renderYearOptions={this.renderYearOptions}
              />
              <ReusableSnackbar
                open={this.state.snackbarOpen}
                onClose={this.handleSnackbarClose}
                variant={this.state.snackbarVariant}
                message={this.state.snackbarMessage}
              />
              <AddEditApplicantDialog
                open={this.state.addCreateDialogOpen}
                handleClose={this.closeAddEditDialog}
                findExistingUsers={this.props.findExistingUsers}
                existingUsers={this.props.existingUsers}
                resetDialog={this.props.resetDialog}
                applicants={this.props.applicants}
                applicant={this.state.selectedApplicant}
                genderOptions={this.props.genderOptions}
                createUser={this.props.createUser}
                updateUser={this.props.updateUser}
                getApplicants={this.props.getApplicants}
                statuses={this.props.statuses}
                getStatuses={this.props.getStatuses}
                affiliations={this.props.affiliations}
                getAffiliations={this.props.getAffiliations}
                recruiters={this.props.recruiters}
                getRecruiters={this.props.getRecruiters}
                classes={this.props.classes}
                getClasses={this.props.getClasses}
                tasks={this.props.tasks}
                getTasks={this.props.getTasks}
                year={this.props.year}
                createApplicant={this.props.createApplicant}
                updateApplicant={this.props.updateApplicant}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ApplicantsPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  getApplicants: PropTypes.func.isRequired,
  downloadApplicants: PropTypes.func.isRequired,
  setApplicantsSelected: PropTypes.func.isRequired,
  applicants: PropTypes.array.isRequired,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  eventDates: PropTypes.array.isRequired,
  getYear: PropTypes.func.isRequired,
  getEventDates: PropTypes.func.isRequired,
  findExistingUsers: PropTypes.func.isRequired,
  existingUsers: PropTypes.array,
  resetDialog: PropTypes.func.isRequired,
  genderOptions: PropTypes.array.isRequired,
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  statuses: PropTypes.array,
  getStatuses: PropTypes.func.isRequired,
  affiliations: PropTypes.array,
  getAffiliations: PropTypes.func.isRequired,
  recruiters: PropTypes.array,
  getRecruiters: PropTypes.func.isRequired,
  classes: PropTypes.array,
  getClasses: PropTypes.func.isRequired,
  tasks: PropTypes.array,
  getTasks: PropTypes.func.isRequired,
  createApplicant: PropTypes.func.isRequired,
  updateApplicant: PropTypes.func.isRequired,
};

export default ApplicantsPage;
