import { connect } from 'react-redux';

import VolunteerSignupProcessPage from 'components/pages/VolunteerSignupProcessPage';
import { selectIsLoggedIn, selectCurrentUser } from 'selectors/authenticationSelectors';
import { selectYear, selectYaspFormLink } from 'selectors/systemSettingsSelectors';
import { getYear, getYaspFormLink } from 'actions/thunks/systemSettings';
import { selectStatuses } from 'selectors/statusesSelectors';
import { getStatuses } from 'actions/thunks/statuses';
import { selectAffiliations } from 'selectors/affiliationsSelectors';
import { selectRecruiters } from 'selectors/recruitersSelectors';
import { selectClasses } from 'selectors/classesSelectors';
import { getAffiliations } from 'actions/thunks/affiliations';
import { getRecruiters } from 'actions/thunks/recruiters';
import { getClasses } from 'actions/thunks/classes';
import { getTasks } from 'actions/thunks/activities';
import { selectTasks } from 'selectors/activitiesSelectors';
import { createApplication, updateApplication } from 'actions/thunks/applicants';
import { selectEventDates } from 'selectors/eventDatesSelectors';
import { getEventDates } from 'actions/thunks/eventDates';

const mapStateToProps = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  currentUser: selectCurrentUser(state),
  currentYear: selectYear(state),
  yaspFormLink: selectYaspFormLink(state),
  statuses: selectStatuses(state),
  affiliations: selectAffiliations(state),
  recruiters: selectRecruiters(state),
  classes: selectClasses(state),
  tasks: selectTasks(state),
  eventDates: selectEventDates(state),
});

const mapDispatchToProps = (dispatch) => ({
  getYear: () => dispatch(getYear()),
  getYaspFormLink: () => dispatch(getYaspFormLink()),
  getStatuses: () => dispatch(getStatuses()),
  getAffiliations: () => dispatch(getAffiliations()),
  getRecruiters: () => dispatch(getRecruiters()),
  getClasses: () => dispatch(getClasses()),
  getTasks: () => dispatch(getTasks()),
  createApplication: (callback) => dispatch(createApplication(callback)),
  updateApplication: (updatedApplication) => dispatch(updateApplication(updatedApplication)),
  getEventDates: () => dispatch(getEventDates()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VolunteerSignupProcessPage);
