import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ReusableSelect from 'components/common/ReusableSelect';
import ReusableButton from 'components/common/ReusableButton';
import ReusableTextField from 'components/common/ReusableTextField';

class AddEmailTemplateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      template: props.templates[0].value,
      name: '',
    };
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleAddTemplate = () => {
    const payload = {
      name: this.state.name,
      copiedFrom: this.state.template,
    };
    this.props.handleAddTemplate(payload);
  };

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.handleCloseAddTemplate}>
        <DialogTitle>Add New Email Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You may copy fields from an existing template, or create a new one from scratch by copying from the empty template.
          </DialogContentText>
          <ReusableSelect
            id="copyFromTemplate"
            label="Copy From: "
            options={this.props.templates}
            value={this.state.template}
            onChange={this.handleChange('template')}
          />
          <ReusableTextField
            id="name"
            label="Template Name"
            value={this.state.name}
            onChange={this.handleChange('name')}
          />
        </DialogContent>
        <DialogActions>
          <ReusableButton
            onClick={this.props.handleCloseAddTemplate}
            value="Cancel"
          />
          <ReusableButton
            onClick={this.handleAddTemplate}
            value="Add Template"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

AddEmailTemplateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  templates: PropTypes.array.isRequired,
  handleCloseAddTemplate: PropTypes.func.isRequired,
  handleAddTemplate: PropTypes.func.isRequired,
};

export default AddEmailTemplateDialog;
