import React from 'react';

import styles from './style.module.scss';

const AboutPage = () => (
  <div id="maincontent">
    <div className="wdn-band">
      <div className="wdn-inner-wrapper">
        <div className={styles['info-container']}>
          <h1>
            About VITA-MathDay
          </h1>
          <div>
            VITA (Volunteer Intelligent Task Allocation) is a system designed for the UNL Department of Mathematics
            by undergraduates in the Constraint System Laboratory in the Department of Computer Science and Engineering.
            This system allows MathDay volunteers to sign up for tasks that the event coordinators post and facilitates
            communication between these two parties.
          </div>
          <h3>
            Acknowledgements
          </h3>
          <div>
            <p>
              This site is the result of projects conducted since October 2014 at the Constraint Systems Laboratory
              by the following CSE students: Tyler Bienhoff, Jasmine Boyer, Matthew DeHaven, Ian Howell, Denis Komissarov,
              Dylan Laible, Chris Lyons, Tim McCaslin, Zach Madsen, Khang Nhat Phan, Tyler Paul, Colin Richards, Gerald Thornton,
              and Trieu Hung Tran. The students were supervised by the following people: Stephanie Vendetti (CSMCE),
              Charles Daniel (CSE Computing Coordinator), Mohammad Rashedul Hasan, and Jeremy Suing (CSE Faculty),
              and Berthe Y. Choueiry (Constraint Systems Laboratory founder and director). Financial support includes a
              number of UCARE grants (UNL) and REU supplements to NSF grants RI-1117956 and RI-1619344.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AboutPage;
