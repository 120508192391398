import { fromJS } from 'immutable';

import {
  CONTACT_US_EMAIL_SENT,
  CONTACT_US_EMAIL_SUCCESSFUL,
} from 'actions/atomic/contactUs';

const initialState = fromJS({
  emailSuccessful: false,
});

export default function contactUs(state = initialState, action) {
  switch (action.type) {
  case CONTACT_US_EMAIL_SENT:
    return state.set('emailSuccessful', false);
  case CONTACT_US_EMAIL_SUCCESSFUL:
    return state.set('emailSuccessful', true);
  default:
    return state;
  }
}
