import { fromJS } from 'immutable';

import {
  GET_ACTIVITIES_SENT,
  GET_ACTIVITIES_SUCCESSFUL,
  UPDATE_ACTIVITY_SENT,
  UPDATE_ACTIVITY_SUCCESSFUL,
  CREATE_ACTIVITY_SENT,
  CREATE_ACTIVITY_SUCCESSFUL,
  GET_TASKS_SENT,
  GET_TASKS_SUCCESSFUL,
  UPDATE_TASK_SENT,
  UPDATE_TASK_SUCCESSFUL,
  CREATE_TASK_SENT,
  CREATE_TASK_SUCCESSFUL,
  DELETE_ACTIVITY_SENT,
  DELETE_ACTIVITY_SUCCESSFUL,
  DELETE_TASK_SENT,
  DELETE_TASK_SUCCESSFUL,
  GET_ASSIGNMENT_PRIORITIES_SENT,
  GET_ASSIGNMENT_PRIORITIES_SUCCESSFUL,
  UPDATE_ASSIGNMENT_PRIORITY_SENT,
  UPDATE_ASSIGNMENT_PRIORITY_SUCCESSFUL,
} from 'actions/atomic/activities';

const initialState = fromJS({
  activitiesLoading: false,
  tasksLoading: false,
  assignmentPriorities: [],
  activities: [],
  tasks: [],
});

export default function activities(state = initialState, action) {
  switch (action.type) {
  case GET_ASSIGNMENT_PRIORITIES_SENT:
    return state.merge({
      assignmentPrioritiesLoading: true,
      assignmentPriorities: fromJS([]),
    });
  case GET_ASSIGNMENT_PRIORITIES_SUCCESSFUL:
    return state.merge({
      assignmentPrioritiesLoading: false,
      assignmentPriorities: fromJS(action.data),
    });
  case UPDATE_ASSIGNMENT_PRIORITY_SENT:
    return state.set('assignmentPrioritiesLoading', true);
  case UPDATE_ASSIGNMENT_PRIORITY_SUCCESSFUL:
    const updatedAssignmentPriority = action.data;
    const indexOfAssignmentPriority = state.get('assignmentPriorities').findIndex(activity => activity.get('id') === updatedAssignmentPriority.id);
    return state.merge({
      assignmentPrioritiesLoading: false,
      assignmentPriorities: state.get('assignmentPriorities').set(indexOfAssignmentPriority, fromJS(updatedAssignmentPriority)),
    });
  case GET_ACTIVITIES_SENT:
    return state.merge({
      activitiesLoading: true,
      activities: fromJS([]),
    });
  case GET_ACTIVITIES_SUCCESSFUL:
    return state.merge({
      activitiesLoading: false,
      activities: fromJS(action.data),
    });
  case CREATE_ACTIVITY_SENT:
    return state.set('activitiesLoading', true);
  case CREATE_ACTIVITY_SUCCESSFUL:
    return state.merge({
      activitiesLoading: false,
      activities: state.get('activities').push(fromJS(action.data)),
    });
  case UPDATE_ACTIVITY_SENT:
    return state.set('activitiesLoading', true);
  case UPDATE_ACTIVITY_SUCCESSFUL:
    const updatedActivity = action.data;
    const index = state.get('activities').findIndex(activity => activity.get('id') === updatedActivity.id);
    return state.merge({
      activitiesLoading: false,
      activities: state.get('activities').set(index, fromJS(updatedActivity)),
    });
  case GET_TASKS_SENT:
    return state.set('tasksLoading', true);
  case GET_TASKS_SUCCESSFUL:
    return state.merge({
      tasksLoading: false,
      tasks: fromJS(action.data),
    });
  case CREATE_TASK_SENT:
    return state.set('tasksLoading', true);
  case CREATE_TASK_SUCCESSFUL:
    return state.merge({
      tasksLoading: false,
      tasks: state.get('tasks').push(fromJS(action.data)),
    });
  case UPDATE_TASK_SENT:
    return state.set('tasksLoading', true);
  case UPDATE_TASK_SUCCESSFUL:
    const updatedTask = action.data;
    const idx = state.get('tasks').findIndex(task => task.get('id') === updatedTask.id);
    return state.merge({
      tasksLoading: false,
      tasks: state.get('tasks').set(idx, fromJS(updatedTask)),
    });
  case DELETE_ACTIVITY_SENT:
    return state.set('activitiesLoading', true);
  case DELETE_ACTIVITY_SUCCESSFUL:
    return state.merge({
      'activitiesLoading': false,
      'activities': state.get('activities').filter((activity) => activity.get('id') !== parseInt(action.data.id, 10)),
      'tasks': state.get('tasks').filter((task) => task.get('activity_id') !== parseInt(action.data.id, 10)),
    });
  case DELETE_TASK_SENT:
    return state.set('tasksLoading', true);
  case DELETE_TASK_SUCCESSFUL:
    return state.merge({
      'tasksLoading': false,
      'tasks': state.get('tasks').filter((task) => task.get('id') !== parseInt(action.data.id, 10)),
    });
  default:
    return state;
  }
}
