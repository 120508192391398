import React from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';
import TextField from '@material-ui/core/TextField';

class ReusableTextFieldValidator extends React.Component {
  componentDidMount() {
    this.props.onError(false);
    this.updateError = debounce(this.updateError, 250);
  }

  handleChange = (event) => {
    this.props.onChange(event);
    this.updateError(event.target.value);
  };

  updateError = (text) => {
    if (!this.props.regExp.test(text)) {
      this.props.onError(true);
    } else if (this.props.error) {
      this.props.onError(false);
    }
  };

  render() {
    return (
      <TextField
        fullWidth
        multiline={this.props.multiline}
        value={this.props.text.value || ''}
        onChange={this.handleChange}
        error={this.props.error}
      />
    );
  }
}

ReusableTextFieldValidator.propTypes = {
  multiline: PropTypes.bool,
  text: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  regExp: PropTypes.object.isRequired,
  error: PropTypes.bool.isRequired,
  onError: PropTypes.func.isRequired,
};

export default ReusableTextFieldValidator;
