export const GET_ACTIVITIES_SENT = 'GET_ACTIVITIES_SENT';
export const GET_ACTIVITIES_SUCCESSFUL = 'GET_ACTIVITIES_SUCCESSFUL';

export const UPDATE_ACTIVITY_SENT = 'UPDATE_ACTIVITY_SENT';
export const UPDATE_ACTIVITY_SUCCESSFUL = 'UPDATE_ACTIVITY_SUCCESFUL';

export const CREATE_ACTIVITY_SENT = 'CREATE_ACTIVITY_SENT';
export const CREATE_ACTIVITY_SUCCESSFUL = 'CREATE_ACTIVITY_SUCCESSFUL';

export const GET_TASKS_SENT = 'GET_TASKS_SENT';
export const GET_TASKS_SUCCESSFUL = 'GET_TASKS_SUCCESSFUL';

export const UPDATE_TASK_SENT = 'UPDATE_TASK_SENT';
export const UPDATE_TASK_SUCCESSFUL = 'UPDATE_TASK_SUCCESSFUL';

export const CREATE_TASK_SENT = 'CREATE_TASK_SENT';
export const CREATE_TASK_SUCCESSFUL = 'CREATE_TASK_SUCCESSFUL';

export const GET_ASSIGNMENT_PRIORITIES_SENT = 'GET_ASSIGNMENT_PRIORITY_SENT';
export const GET_ASSIGNMENT_PRIORITIES_SUCCESSFUL = 'GET_ASSIGNMENT_PRIORITY_SUCCESSFUL';

export const UPDATE_ASSIGNMENT_PRIORITY_SENT = 'UPDATE_ASSIGNMENT_PRIORITY_SENT';
export const UPDATE_ASSIGNMENT_PRIORITY_SUCCESSFUL = 'UPDATE_ASSIGNMENT_PRIORITY_SUCCESSFUL';

export const DELETE_TASK_SENT = 'DELETE_TASK_SENT';
export const DELETE_TASK_SUCCESSFUL = 'DELETE_TASK_SUCCESSFUL';

export const DELETE_ACTIVITY_SENT = 'DELETE_ACTIVITY_SENT';
export const DELETE_ACTIVITY_SUCCESSFUL = 'DELETE_ACTIVITY_SUCCESSFUL';

export const getAssignmentPrioritiesRequestSent = () => ({ type: GET_ASSIGNMENT_PRIORITIES_SENT });

export const getAssignmentPrioritiesRequestSuccessful = (data) => ({ type: GET_ASSIGNMENT_PRIORITIES_SUCCESSFUL, data });

export const updateAssignmentPriorityRequestSent = () => ({ type: UPDATE_ASSIGNMENT_PRIORITY_SENT });

export const updateAssignmentPriorityRequestSuccessful = (data) => ({ type: UPDATE_ASSIGNMENT_PRIORITY_SUCCESSFUL, data });

export const getActivitiesRequestSent = () => ({ type: GET_ACTIVITIES_SENT });

export const getActivitiesRequestSuccessful = (data) => ({ type: GET_ACTIVITIES_SUCCESSFUL, data });

export const updateActivityRequestSent = () => ({ type: UPDATE_ACTIVITY_SENT });

export const updateActivityRequestSuccessful = (data) => ({ type: UPDATE_ACTIVITY_SUCCESSFUL, data });

export const createActivityRequestSent = () => ({ type: CREATE_ACTIVITY_SENT });

export const createActivityRequestSuccessful = (data) => ({ type: CREATE_ACTIVITY_SUCCESSFUL, data });

export const deleteActivityRequestSent = (data) => ({ type: DELETE_ACTIVITY_SENT, data });

export const deleteActivityRequestSuccessful = (data) => ({ type: DELETE_ACTIVITY_SUCCESSFUL, data });

export const getTasksRequestSent = () => ({ type: GET_TASKS_SENT });

export const getTasksRequestSuccessful = (data) => ({ type: GET_TASKS_SUCCESSFUL, data });

export const updateTaskRequestSent = () => ({ type: UPDATE_TASK_SENT });

export const updateTaskRequestSuccessful = (data) => ({ type: UPDATE_TASK_SUCCESSFUL, data });

export const createTaskRequestSent = () => ({ type: CREATE_TASK_SENT });

export const createTaskRequestSuccessful = (data) => ({ type: CREATE_TASK_SUCCESSFUL, data });

export const deleteTaskRequestSent = (data) => ({ type: DELETE_TASK_SENT, data });

export const deleteTaskRequestSuccessful = (data) => ({ type: DELETE_TASK_SUCCESSFUL, data });
