import ApiWrapper from 'wrappers/api';

import {
  getAnnouncementsRequestSent,
  getAnnouncementsRequestSuccessful,
  createAnnouncementRequestSent,
  createAnnouncementRequestSuccessful,
  updateAnnouncementRequestSent,
  updateAnnouncementRequestSuccessful,
} from 'actions/atomic/announcements';

export function getAnnouncements() {
  return (dispatch) => {
    dispatch(getAnnouncementsRequestSent());
    return ApiWrapper.get('/announcements')
      .then(response => {
        dispatch(getAnnouncementsRequestSuccessful(response.data));
      });
  };
}

export function createAnnouncement(newAnnouncement) {
  return (dispatch) => {
    dispatch(createAnnouncementRequestSent());
    return ApiWrapper.post('/announcements', newAnnouncement)
      .then(response => {
        dispatch(createAnnouncementRequestSuccessful(response.data));
      });
  };
}

export function updateAnnouncement(newAnnouncement) {
  return (dispatch) => {
    dispatch(updateAnnouncementRequestSent());
    return ApiWrapper.put(`/announcements/${newAnnouncement.id}`, newAnnouncement)
      .then(response => {
        dispatch(updateAnnouncementRequestSuccessful(response.data));
      });
  };
}
