import { fromJS } from 'immutable';

import {
  CREATE_ASSIGNMENT_SENT,
  CREATE_ASSIGNMENT_SUCCESSFUL,
  GET_ASSIGNMENTS_SENT,
  GET_ASSIGNMENTS_SUCCESSFUL,
  GET_LOCATIONS_SENT,
  GET_LOCATIONS_SUCCESSFUL,
  UPDATE_ASSIGNMENT_SENT,
  UPDATE_ASSIGNMENT_SUCCESSFUL,
  DELETE_ASSIGNMENT_SENT,
  DELETE_ASSIGNMENT_SUCCESSFUL,
} from 'actions/atomic/assignmentByUsers';

const initialState = fromJS({
  loading: false,
  data: [],
  locations: [],
});

export default function assignments(state = initialState, action) {
  switch (action.type) {
  case GET_ASSIGNMENTS_SENT:
    return state.merge({
      loading: true,
      data: fromJS([]),
    });
  case GET_ASSIGNMENTS_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: fromJS(action.data),
    });
  case GET_LOCATIONS_SENT:
    return state.merge({
      loading: true,
      locations: fromJS([]),
    });
  case GET_LOCATIONS_SUCCESSFUL:
    return state.merge({
      loading: false,
      locations: fromJS(action.data),
    });
  case CREATE_ASSIGNMENT_SENT:
    return state.merge({
      loading: true,
    });
  case CREATE_ASSIGNMENT_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: state.get('data').push(fromJS(action.data)),
    });
  case UPDATE_ASSIGNMENT_SENT:
    return state.set('loading', true);
  case UPDATE_ASSIGNMENT_SUCCESSFUL:
    const updatedAssignment = action.data;
    const index = state.get('data').findIndex(assignment => assignment.get('id') === parseInt(updatedAssignment.id, 10));
    return state.merge({
      loading: false,
      data: state.get('data').set(index, fromJS(updatedAssignment)),
    });
  case DELETE_ASSIGNMENT_SENT:
    return state.set('loading', true);
  case DELETE_ASSIGNMENT_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: state.get('data').filter((assignment => assignment.get('id') !== parseInt(action.data.id, 10))),
    });
  default:
    return state;
  }
}
