import HomePageContainer from 'containers/HomePageContainer';
import DashboardPageContainer from 'containers/DashboardPageContainer';
import ClassesPageContainer from 'containers/ClassesPageContainer';
import RecruitersPageContainer from 'containers/RecruitersPageContainer';
import AffiliationsPageContainer from 'containers/AffiliationsPageContainer';
import StatusesPageContainer from 'containers/StatusesPageContainer';
import AssignmentPrioritiesPageContainer from 'containers/AssignmentPrioritiesPageContainer';
import EventDatesPageContainer from 'containers/EventDatesPageContainer';
import LoginPageContainer from 'containers/LoginPageContainer';
import AnnouncementsPageContainer from 'containers/AnnouncementsPageContainer';
import SystemSettingsPageContainer from 'containers/SystemSettingsPageContainer';
import ErrorPageContainer from 'containers/ErrorPageContainer';
import ForbiddenPageContainer from 'containers/ForbiddenPageContainer';
import ActivitiesPageContainer from 'containers/ActivitiesPageContainer';
import AccountCreationPageContainer from 'containers/AccountCreationPageContainer';
import AccountActivationPageContainer from 'containers/AccountActivationPageContainer';
import PasswordResetRequestPageContainer from 'containers/PasswordResetRequestPageContainer';
import ResetPasswordPageContainer from 'containers/ResetPasswordPageContainer';
import UsersPageContainer from 'containers/UsersPageContainer';
import EmailPageContainer from 'containers/EmailPageContainer';
import EmailLogContainer from 'containers/EmailLogContainer';
import AboutPageContainer from 'containers/AboutPageContainer';
import ContactPageContainer from 'containers/ContactPageContainer';
import ApplicantsPageContainer from 'containers/ApplicantsPageContainer';
import AssignmentsPageContainer from 'containers/AssignmentsPageContainer';
import VolunteerDashboardPageContainer from 'containers/VolunteerDashboardPageContainer';
import VolunteerSignupProcessPageContainer from 'containers/VolunteerSignupProcessPageContainer';
import InteractiveAssignmentContainer from 'containers/InteractiveAssignmentContainer';
import VolunteerPastApplicationPageContainer from 'containers/VolunteerPastApplicationPageContainer';
import TaskPreferencesPageContainer from 'containers/TaskPreferencesPageContainer';
import ERAPageContainer from 'containers/ERAPageContainer';

const routes = [
  {
    path: '/',
    component: HomePageContainer,
  },
  {
    path: '/login',
    component: LoginPageContainer,
  },
  {
    path: '/create-account',
    component: AccountCreationPageContainer,
  },
  {
    path: '/about',
    component: AboutPageContainer,
  },
  {
    path: '/activate-account',
    component: AccountActivationPageContainer,
  },
  {
    path: '/contact-us',
    component: ContactPageContainer,
  },
  {
    path: '/forgot-password',
    component: PasswordResetRequestPageContainer,
  },
  {
    path: '/reset-password',
    component: ResetPasswordPageContainer,
  },
  {
    path: '/forbidden',
    component: ForbiddenPageContainer,
  },
  {
    path: '/error/:errorCode',
    component: ErrorPageContainer,
  },
  {
    path: '/volunteer/dashboard',
    component: VolunteerDashboardPageContainer,
  },
  {
    path: '/volunteer/process/:year',
    component: VolunteerSignupProcessPageContainer,
  },
  {
    path: '/volunteer/past/:year',
    component: VolunteerPastApplicationPageContainer,
  },
  {
    path: '/admin',
    component: DashboardPageContainer,
  },
  {
    path: '/admin/classes',
    component: ClassesPageContainer,
  },
  {
    path: '/admin/recruiters',
    component: RecruitersPageContainer,
  },
  {
    path: '/admin/affiliations',
    component: AffiliationsPageContainer,
  },
  {
    path: '/admin/statuses',
    component: StatusesPageContainer,
  },
  {
    path: '/admin/assignment-priorities',
    component: AssignmentPrioritiesPageContainer,
  },
  {
    path: '/admin/event-dates',
    component: EventDatesPageContainer,
  },
  {
    path: '/admin/announcements',
    component: AnnouncementsPageContainer,
  },
  {
    path: '/admin/activities',
    component: ActivitiesPageContainer,
  },
  {
    path: '/admin/system-settings',
    component: SystemSettingsPageContainer,
  },
  {
    path: '/admin/users',
    component: UsersPageContainer,
  },
  {
    path: '/admin/email',
    component: EmailPageContainer,
  },
  {
    path: '/admin/applicants',
    component: ApplicantsPageContainer,
  },
  {
    path: '/admin/final-assignment',
    component: AssignmentsPageContainer,
  },
  {
    path: '/admin/email-log',
    component: EmailLogContainer,
  },
  {
    path: '/admin/interactive-assignment',
    component: InteractiveAssignmentContainer,
  },
  {
    path: '/admin/task-preferences',
    component: TaskPreferencesPageContainer,
  },
  {
    path: '/admin/era',
    component: ERAPageContainer,
  },
];

export default routes;
