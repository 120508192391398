import ApiWrapper from 'wrappers/api';
import fileDownload from 'js-file-download';

import {
  getRecruitersRequestSent,
  getRecruitersRequestSuccessful,
  createRecruiterRequestSent,
  createRecruiterRequestSuccessful,
  updateRecruiterRequestSent,
  updateRecruiterRequestSuccessful,
} from 'actions/atomic/recruiters';

export function getRecruiters() {
  return (dispatch) => {
    dispatch(getRecruitersRequestSent());
    return ApiWrapper.get('/recruiters')
      .then(response => {
        dispatch(getRecruitersRequestSuccessful(response.data));
      });
  };
}

export function createRecruiter(newRecruiter) {
  return (dispatch) => {
    dispatch(createRecruiterRequestSent());
    return ApiWrapper.post('/recruiters', newRecruiter)
      .then(response => {
        dispatch(createRecruiterRequestSuccessful(response.data));
      });
  };
}

export function updateRecruiter(newRecruiter) {
  return (dispatch) => {
    dispatch(updateRecruiterRequestSent());
    return ApiWrapper.put(`/recruiters/${newRecruiter.id}`, newRecruiter)
      .then(response => {
        dispatch(updateRecruiterRequestSuccessful(response.data));
      });
  };
}

export function downloadRecruiters() {
  return () => ApiWrapper.get('/recruiters/download', { responseType: 'blob' })
    .then(response => fileDownload(response.data, 'recruiters.xlsx'));
}
