import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AnnouncementIcon from '@material-ui/icons/Announcement';
import BarChartIcon from '@material-ui/icons/BarChart';
import BusinessIcon from '@material-ui/icons/Business';
import EmailIcon from '@material-ui/icons/Email';
import InboxIcon from '@material-ui/icons/Inbox';
import EventIcon from '@material-ui/icons/Event';
import FlagIcon from '@material-ui/icons/Flag';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import LockIcon from '@material-ui/icons/Lock';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import TimelineIcon from '@material-ui/icons/Timeline';

import history from 'wrappers/history';
import styles from './style.module.scss';

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }
  }

  render() {
    return (
      <div id="#dashboardContainer" className="wdn-band">
        <div>
          <div className={styles['dashboard']}>
            <div className={styles['dashboard-header']}>
              <div className="wdn-inner-wrapper">
                <div className={styles['dashboard-title']}>
                  Dashboard
                </div>
              </div>
              <div className="wdn-inner-wrapper">
                <div className={styles['dashboard-user-display']}>
                  <div className={styles['dashboard-logged-in-as']}>
                    Administrator:
                  </div>
                  <div className={styles['dashboard-user-name']}>
                    {`${this.props.currentUser.preferred_name
                      ? this.props.currentUser.preferred_name
                      : this.props.currentUser.first_name}
                    ${this.props.currentUser.last_name}`}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles['dashboard-content']}>
              <div className={styles['dashboard-column']}>
                <div className={styles['dashboard-column-inner']}>
                  <div className={styles['dashboard-column-title']}>
                    Settings
                  </div>
                  <Link to="/admin/system-settings" className={`${styles['dashboard-tile']} ${styles['purple']}`}>
                    <SettingsIcon className={styles['icon']} fontSize="medium" />
                    <div className={styles['dashboard-tile-text']}>
                      System
                    </div>
                  </Link>
                </div>
              </div>
              <div className={styles['dashboard-column']}>
                <div className={styles['dashboard-column-inner']}>
                  <div className={styles['dashboard-column-title']}>
                    Event&nbsp;Setup
                  </div>
                  <div className={styles['two-columns']}>
                    <div className={styles['left-column']}>
                      <Link to="/admin/event-dates" className={`${styles['dashboard-tile']} ${styles['deep-orange']}`}>
                        <EventIcon className={styles['icon']} fontSize="medium" />
                        <div className={styles['dashboard-tile-text']}>
                          Event Dates
                        </div>
                      </Link>
                      <Link to="/admin/announcements" className={`${styles['dashboard-tile']} ${styles['deep-orange']}`}>
                        <AnnouncementIcon className={styles['icon']} fontSize="medium" />
                        <div className={styles['dashboard-tile-text']}>
                          Announcements
                        </div>
                      </Link>
                      <Link to="/admin/affiliations" className={`${styles['dashboard-tile']} ${styles['deep-orange']}`}>
                        <BusinessIcon className={styles['icon']} fontSize="medium" />
                        <div className={styles['dashboard-tile-text']}>
                          Affiliations
                        </div>
                      </Link>
                      <Link to="/admin/classes" className={`${styles['dashboard-tile']} ${styles['deep-orange']}`}>
                        <MenuBookIcon className={styles['icon']} fontSize="medium" />
                        <div className={styles['dashboard-tile-text']}>
                          Classes
                        </div>
                      </Link>
                      <Link to="/admin/recruiters" className={`${styles['dashboard-tile']} ${styles['deep-orange']}`}>
                        <PersonIcon className={styles['icon']} fontSize="medium" />
                        <div className={styles['dashboard-tile-text']}>
                          Recruiters
                        </div>
                      </Link>
                    </div>
                    {/* Right column */}
                    <div>
                      <Link to="/admin/statuses" className={`${styles['dashboard-tile']} ${styles['deep-orange']}`}>
                        <FlagIcon className={styles['icon']} fontSize="medium" />
                        <div className={styles['dashboard-tile-text']}>
                          Statuses
                        </div>
                      </Link>
                      <Link to="/admin/activities" className={`${styles['dashboard-tile']} ${styles['deep-orange']}`}>
                        <FolderOpenIcon className={styles['icon']} fontSize="medium" />
                        <div className={styles['dashboard-tile-text']}>
                          Activities
                        </div>
                      </Link>
                      <Link to="/admin/task-preferences" className={`${styles['dashboard-tile']} ${styles['deep-orange']}`}>
                        <FormatListBulletedIcon className={styles['icon']} fontSize="medium" />
                        <div className={styles['dashboard-tile-text']}>
                          Task Pref
                        </div>
                      </Link>
                      <Link to="/admin/assignment-priorities" className={`${styles['dashboard-tile']} ${styles['deep-orange']}`}>
                        <FormatListBulletedIcon className={styles['icon']} fontSize="medium" />
                        <div className={styles['dashboard-tile-text']}>
                          Assignment
                          Priorities
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles['dashboard-column']}>
                <div className={styles['dashboard-column-inner']}>
                  <div className={styles['dashboard-column-title']}>
                    Volunteers
                  </div>
                  <Link to="/admin/applicants" className={`${styles['dashboard-tile']} ${styles['blue']}`}>
                    <PersonIcon className={styles['icon']} fontSize="medium" />
                    <div className={styles['dashboard-tile-text']}>
                      Applicants
                    </div>
                  </Link>
                  <Link to="/admin/email" className={`${styles['dashboard-tile']} ${styles['blue']}`}>
                    <EmailIcon className={styles['icon']} fontSize="medium" />
                    <div className={styles['dashboard-tile-text']}>
                      Email
                    </div>
                  </Link>
                  <Link to="/admin/email-log" className={`${styles['dashboard-tile']} ${styles['blue']}`}>
                    <InboxIcon className={styles['icon']} fontSize="medium" />
                    <div className={styles['dashboard-tile-text']}>
                      Email Log
                    </div>
                  </Link>
                </div>
              </div>
              <div className={styles['dashboard-column']}>
                <div className={styles['dashboard-column-inner']}>
                  <div className={styles['dashboard-column-title']}>
                    Assignments
                  </div>
                  <Link to="/admin/interactive-assignment" className={`${styles['dashboard-tile']} ${styles['dashboard-tile']} ${styles['green']}`}>
                    <FormatListBulletedIcon className={styles['icon']} fontSize="medium" />
                    <div className={styles['dashboard-tile-text']}>
                      Interactive
                    </div>
                  </Link>
                  <Link to="/admin/final-assignment" className={`${styles['dashboard-tile']} ${styles['green']}`}>
                    <FormatListBulletedIcon className={styles['icon']} fontSize="medium" />
                    <div className={styles['dashboard-tile-text']}>
                      Final
                    </div>
                  </Link>
                  <Link to="/admin" className={`${styles['dashboard-tile']} ${styles['blue-grey']} ${styles['disabled']}`}>
                    <LockIcon className={styles['icon']} fontSize="medium" />
                    <div className={styles['dashboard-tile-text']}>
                      Future
                    </div>
                  </Link>
                </div>
              </div>
              <div className={styles['dashboard-column']}>
                <div className={styles['dashboard-column-inner']}>
                  <div className={styles['dashboard-column-title']}>
                    Work Zone
                  </div>
                  <Link to="/admin" className={`${styles['dashboard-tile']} ${styles['pink']}`}>
                    <BarChartIcon className={styles['icon']} fontSize="medium" />
                    <div className={styles['dashboard-tile-text']}>
                      Loads
                    </div>
                  </Link>
                  <Link to="/admin" className={`${styles['dashboard-tile']} ${styles['pink']}`}>
                    <TimelineIcon className={styles['icon']} fontSize="medium" />
                    <div className={styles['dashboard-tile-text']}>
                      Chart
                    </div>
                  </Link>
                  <Link to="/admin/users" className={`${styles['dashboard-tile']} ${styles['pink']}`}>
                    <PersonIcon className={styles['icon']} fontSize="medium" />
                    <div className={styles['dashboard-tile-text']}>
                      Users
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

DashboardPage.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default DashboardPage;
