import ApiWrapper from 'wrappers/api';

import {
  contactUsEmailRequestSent, contactUsEmailRequestSuccessful,
} from 'actions/atomic/contactUs';

export function sendContactUsEmail(email) {
  return (dispatch) => {
    dispatch(contactUsEmailRequestSent());
    return ApiWrapper.post('/contact_us', email)
      .then(response => {
        dispatch(contactUsEmailRequestSuccessful(response.data));
      });
  };
}
