import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { taskTimeInApplicantTime } from '../../InteractiveAssignmentPage/utils';

class ERA {
  /** The CSP problem instance */
  problemInstance;
  /** The variables for the CSP problem instance */
  variables;
  /** The variableMap for the CSP problem instance */
  variableMap;
  /** The constraints for the CSP problem instance */
  constraints;
  /** The constraintMap for the CSP problem instance */
  constraintMap;
  /** The ERA environment */
  environment;
  /** The current step the algorithm is on */
  step;
  /** How many steps each agent has been at 0 */
  stepsAt0;
  /** How many agents are currently at 0 positions */
  agentsAt0;
  /** The names of applicants to be use to keep track of applicants not assigned to any tasks */
  applicantNames;
  /** A copy of the applicants to be used to keep track of leftover times after assignments have been made */
  applicantsCopy;
  /** The number of violations for each agent */
  agentViolations;
  /** The position of each agent */
  agentPosition;
  /** The task names and their respective times */
  tasks;
  /** Maps admin preferences to associated values for measuring solution quality */
  adminPrefMap = {
    0: 1, 8: 3, 9: 4, 10: 5,
  };
  /** Maps volunteer preferences to associated values for measuring solution quality */
  volPrefMap = {
    1: 0.5, 0.5: 1.5, 2: 1, 3: 2, 4: 2.5,
  };
  /** Map of tasks to the intervals they are part of */
  taskIntervalMap = {};
  /** Map of intervals to their interval characteristics */
  intervals = {};
  /** Total steps to run in each iteration */
  totalSteps = 0;
  /** Total iterations to run */
  totalIterations = 0;
  /** Whether to log ERA performance in console or not */
  logging = false;

  constructor(problemInstance, iterations, steps) {
    this.problemInstance = problemInstance;
    this.variables = this.problemInstance.variables;
    this.variableMap = this.problemInstance.variableMap;
    this.constraints = this.problemInstance.constraints;
    this.constraintMap = this.problemInstance.constraintMap;
    this.intervals = this.problemInstance.intervals;
    this.taskIntervalMap = this.problemInstance.taskIntervalMap;
    this.environment = {};
    this.step = 0;
    this.stepsAt0 = [...Array(this.variables.length)].map(() => 0);
    this.agentViolations = [...Array(this.variables.length)].map(() => []);
    this.agentPosition = [...Array(this.variables.length)].map(() => []);
    this.agentsAt0 = [...Array(200)].map(() => 0);
    this.applicantNames = [];
    this.applicantsCopy = [];
    this.tasks = {};
    this.totalIterations = iterations;
    this.totalSteps = steps;
  }

  /** Removes the time from the applicant's availability */
  #removeTimeFromApplicantAvailability(time, applicant) {
    const adjustedTimeWindows = [];
    applicant.time_windows.sort((a, b) => (dayjs(a.starts_at) <= dayjs(b.starts_at) ? -1 : 1));
    applicant.time_windows.forEach(timeWindow => {
      // availability window starts before time window starts
      if (dayjs(timeWindow?.starts_at).isBefore(dayjs(time?.starts_at))) {
        // availability window completely before time window
        if (dayjs(timeWindow?.ends_at).isBefore(dayjs(time?.starts_at))) {
          adjustedTimeWindows.push(timeWindow);
        }
        // availability window starts before time window but ends within time window
        if ((dayjs(timeWindow?.ends_at).isAfter(dayjs(time?.starts_at))) && ((dayjs(timeWindow?.ends_at).isBefore(dayjs(time?.ends_at))) || dayjs(timeWindow?.ends_at).isSame(time?.ends_at))) {
          adjustedTimeWindows.push({
            ends_at: time?.starts_at,
            id: timeWindow.id,
            starts_at: timeWindow?.starts_at,
            year: timeWindow.year,
          });
        }
        // availability window contains the entire time window
        if (dayjs(timeWindow?.ends_at).isAfter(dayjs(time?.ends_at))) {
          adjustedTimeWindows.push({
            ends_at: time?.starts_at,
            id: timeWindow.id,
            starts_at: timeWindow?.starts_at,
            year: timeWindow.year,
          });
          adjustedTimeWindows.push({
            ends_at: timeWindow?.ends_at,
            id: timeWindow.id,
            starts_at: time?.ends_at,
            year: timeWindow.year,
          });
        }
      }
      // availability window starts after time window starts
      if (dayjs(timeWindow?.starts_at).isAfter(dayjs(time?.starts_at))) {
        // availability window is completely contained within the time window
        if (dayjs(timeWindow?.ends_at).isBefore(dayjs(time?.ends_at)) || dayjs(timeWindow?.ends_at).isSame(dayjs(time?.ends_at))) {
          // we no longer need this time window
        }
        // availability window starts within time window and ends after time window ends
        if (dayjs(timeWindow?.starts_at).isBefore(dayjs(time?.ends_at)) && dayjs(timeWindow?.ends_at).isAfter(dayjs(time?.ends_at))) {
          adjustedTimeWindows.push({
            ends_at: timeWindow?.ends_at,
            id: timeWindow.id,
            starts_at: time?.ends_at,
            year: timeWindow.year,
          });
        }
        // availability window is completely after time window
        if (dayjs(timeWindow?.starts_at).isAfter(dayjs(time?.ends_at))) {
          adjustedTimeWindows.push(timeWindow);
        }
      }

      // availability window starts exactly when time window starts
      if (dayjs(timeWindow?.starts_at).isSame(dayjs(time?.starts_at))) {
        // availability window ends on or before time window ends: do nothing

        // availability window ends after time window ends
        if (dayjs(timeWindow?.ends_at).isAfter(dayjs(time?.ends_at))) {
          adjustedTimeWindows.push({
            ends_at: timeWindow?.ends_at,
            id: timeWindow.id,
            starts_at: time?.ends_at,
            year: timeWindow.year,
          });
        }
      }
    });
    applicant.time_windows = adjustedTimeWindows;
    return applicant;
  }

  /**
   * Generates a random number between 0 and the max exclusive value you pass in
   * @param {Number} maxValueExclusive The exclusive max value of the range you would like to generate a random number between
   * @returns A random number between 0 and maxValueExclusive exclusive
   */
  #getRandomInt(maxValueExclusive) {
    return Math.floor(Math.random() * maxValueExclusive);
  }

  /**
   * Checks if the assignment of value v1 to variable var1 breaks a constraint between var1 and var2 when var2 is assigned value v2
   * @param {*} var1 The first variable
   * @param {*} v1 The value to be assigned to var1
   * @param {*} var2 The second variable
   * @param {*} v2 The value to be assigned to var2
   * @returns {boolean} Whether the variable-value-pairs does not break the constraint
   */
  #check(var1, v1, var2, v2) {
    let found = false;
    let var1var2Ord = true;
    if (!v1) {
      return false;
    }
    if (!v2) {
      return true;
    }
    let con;
    // Look for constraint between the variables
    if (this.constraintMap.get(`${var1} ${var2}`) !== undefined) {
      con = this.constraintMap.get(`${var1} ${var2}`);
      found = true;
    } else if (this.constraintMap.get(`${var2} ${var1}`) !== undefined) {
      con = this.constraintMap.get(`${var2} ${var1}`);
      found = true;
      var1var2Ord = false;
    }
    if (!found) {
      return true;
    }
    if (var1var2Ord) {
      return con.definition(v1, v2);
    }
    return con.definition(v2, v1);
  }

  /**
   * Creates the ERA environment, populating this.environment
   */
  #createEnvironment() {
    this.stepsAt0 = [...Array(this.variables.length)].map(() => 0);
    this.agentsAt0 = [...Array(200)].map(() => 0);
    const { variables } = this;
    let domainArray = [];
    const preferences = this.problemInstance.taskPreferences;
    variables.forEach(task => {
      this.environment[task.variableName] = {
        taskName: task.taskName,
        isBackup: task.isBackup,
        year: task.taskYear,
        time: {
          starts_at: task.startTime,
          ends_at: task.endTime,
        },
        currentPosition: this.#getRandomInt(task.currentDomain.length),
        values: [],
        id: task.id,
      };
      domainArray = task.currentDomain;
      domainArray.sort((a, b) => (this.#adminPref(a, task.id, preferences) >= this.#adminPref(b, task.id, preferences) ? -1 : 1));
      domainArray.forEach(domain => {
        if (!this.applicantNames.includes(domain.vName)) {
          this.applicantsCopy.push(domain);
          this.applicantNames.push(domain.vName);
        }
        this.environment[task.variableName].values.push({
          value: domain,
          violation: 0,
        });
      });
      if (domainArray.length === 0) {
        this.environment[task.variableName].values.push({
          value: false,
          violation: 0,
        });
      }
      if (!(task.taskName in this.tasks)) {
        this.tasks[task.taskName] = {
          starts_at: task.startTime,
          ends_at: task.endTime,
        };
      }
    });
  }

  /** Evaluates the environment and updates agent-value violations */
  #evaluation() {
    // For each agent
    Object.keys(this.environment).forEach(agent => {
      // For each value
      this.environment[agent].values.forEach(valueViolationPair => {
        let newViolation = 0;
        // Compute violation with other agents
        // This value with the current position of other agents
        Object.keys(this.environment).forEach(otherAgent => {
          if (agent !== otherAgent) {
            const violation = !this.#check(agent, valueViolationPair.value, otherAgent, this.environment[otherAgent].values[this.environment[otherAgent].currentPosition].value);
            newViolation = violation ? newViolation + 1 : newViolation;
            newViolation = valueViolationPair.value ? newViolation : 1;
          }
        });
        valueViolationPair.violation = newViolation;
      });
    });
  }

  /** Evaluates the environment and updates agent-value violations only for current positions */
  #evaluateCurrents() {
    // For each agent
    Object.keys(this.environment).forEach(agent => {
      const { currentPosition } = this.environment[agent];
      let newViolation = 0;
      // Compute violation of current position with the current position of other agents
      Object.keys(this.environment).forEach(otherAgent => {
        if (agent !== otherAgent) {
          const violation = !this.#check(agent, this.environment[agent].values[currentPosition].value, otherAgent, this.environment[otherAgent].values[this.environment[otherAgent].currentPosition].value);
          newViolation = violation ? newViolation + 1 : newViolation;
          newViolation = this.environment[agent].values[currentPosition].value ? newViolation : 1;
        }
      });
      this.environment[agent].values[currentPosition].violation = newViolation;
    });
  }

  /**
   * Evaluates the violations for a single agent
   * @param {*} agent The agent whose violations to evaluate and update
   */
  #evaluateCurrentRow(agent) {
    this.environment[agent].values.forEach(valueViolationPair => {
      let newViolation = 0;
      // Compute violation with other agents
      // This value with the current position of other agents
      Object.keys(this.environment).forEach(otherAgent => {
        if (agent !== otherAgent) {
          const violation = !this.#check(agent, valueViolationPair.value, otherAgent, this.environment[otherAgent].values[this.environment[otherAgent].currentPosition].value);
          newViolation = violation ? newViolation + 1 : newViolation;
          newViolation = valueViolationPair.value ? newViolation : 1;
        }
      });
      valueViolationPair.violation = newViolation;
    });
  }

  #evaluatePosition(agent, position) {
    let newViolation = 0;
    // Compute violation with other agents
    // This value with the current position of other agents
    const { value } = this.environment[agent].values[position];
    Object.keys(this.environment).forEach(otherAgent => {
      if (agent !== otherAgent) {
        const violation = !this.#check(agent, value, otherAgent, this.environment[otherAgent].values[this.environment[otherAgent].currentPosition].value);
        newViolation = violation ? newViolation + 1 : newViolation;
        newViolation = value ? newViolation : 1;
      }
    });
    this.environment[agent].values[position].violation = newViolation;
  }

  /**
   * Performs the leastMove operation for the agent
   * @param {*} agent The agent you would like to move
   * @returns The position the agent moved to after the operation completed
   */
  #leastMove(agent, agentName) {
    let i = 1;
    let minimum = 0;
    this.#evaluatePosition(agentName, 0);
    const { id } = agent;
    const preferences = this.problemInstance.taskPreferences;
    while (i < agent.values.length && agent.values[minimum].violation !== 0) { // (agent.values[minimum].violation !== 0 || this.#adminPref(agent.values[minimum].value, preferences.find(element => element.task.name === taskName && element.task.year === taskYear)) !== 10)) {
      this.#evaluatePosition(agentName, i);
      if (agent.values[minimum].violation >= agent.values[i].violation) {
        if (agent.values[minimum].violation > agent.values[i].violation) {
          minimum = i;
        } else {
          const applicant1 = agent.values[minimum].value;
          const applicant2 = agent.values[i].value;

          minimum = this.adminPrefMap[this.#adminPref(applicant1, id, preferences)] >= this.adminPrefMap[this.#adminPref(applicant2, id, preferences)] ? minimum : i;
        }
      }
      i++;
    }
    return minimum;
  }

  /**
   * Performs the better move operation for the inputted agent
   * @param {*} agent The agent to perform better move on
   * @returns The agent's position after the operation has been performed
   */
  #betterMove(agent, agentName) {
    let current = agent.currentPosition;
    const pNew = this.#getRandomInt(agent.values.length);
    this.#evaluatePosition(agentName, current);
    this.#evaluatePosition(agentName, pNew);
    if (agent.values[current].violation > agent.values[pNew].violation) {
      current = pNew;
    }
    return current;
  }

  /**
   * Performs rBLR for the specified agent
   * @param {*} r The number of times to try betterMove
   * @param {*} agent The agent to move
   * @returns The new position for the agent
   */
  #rBLR(r, agent, agentName) {
    let current = agent.currentPosition;
    let i = 0;
    // Try better move at least r times
    while (i < r && current === agent.currentPosition) {
      current = this.#betterMove(agent, agentName);
      i++;
    }
    // Better move failed everytime, do least move
    if (current === agent.currentPosition) {
      current = this.#leastMove(agent, agentName);
    }
    return current;
  }

  /**
   * Performs FrBLR on the inputted agent
   * @param {*} r The number of times to try best move in the first step
   * @param {*} agent The agent to be moved
   * @returns The new agent position
   */
  #FrBLR(r, agent, agentName) {
    let current = agent.currentPosition;
    if (this.step === 0) {
      current = this.#rBLR(r, agent, agentName);
    } else {
      current = this.#leastMove(agent, agentName);
    }
    return current;
  }

  /**
   * Moves the agent to a new position
   * @param {*} agent The agent to move
   * @returns The new agent position
   */
  #getPosition(agent, agentName) {
    const randomNumber = Math.random();
    if (randomNumber <= 0.5) {
      return this.#FrBLR(2, agent, agentName);
    }
    return this.#getRandomInt(agent.values.length);
  }

  /**
   * Moves all agents
   */
  #agentMove() {
    Object.keys(this.environment).forEach(agent => {
      const { currentPosition } = this.environment[agent];
      this.#evaluatePosition(agent, currentPosition);
      if (this.environment[agent].values[currentPosition].violation !== 0) {
        this.environment[agent].currentPosition = this.#getPosition(this.environment[agent], agent);
      }
    });
    this.#evaluateCurrents();
  }

  /**
   * Checks if all agents are at zero positions
   * @returns {boolean} Whether or not all the agents are at zero positions
   */
  #allAgentsZero() {
    const allAgents = Object.keys(this.environment);
    let i = 0;
    let allZero = true;
    while (allZero && i < allAgents.length) {
      const { currentPosition } = this.environment[allAgents[i]];
      if (this.environment[allAgents[i]].values[currentPosition].violation !== 0) {
        allZero = false;
      }
      i++;
    }
    return allZero;
  }

  /**
   * Counts the violations for each agent at their current position
   */
  #countViolationsPositions() {
    const allAgents = Object.keys(this.environment);
    let i = 0;
    while (i < allAgents.length) {
      const { currentPosition } = this.environment[allAgents[i]];
      this.agentViolations[i].push(this.environment[allAgents[i]].values[currentPosition].violation);
      this.agentPosition[i].push(currentPosition);
      i++;
    }
  }

  /**
   * Counts the number of agents not at zero positions
   * @returns The number of agents not at zero postions
   */
  #countNonZeros() {
    let zeroAgent = 0;
    const allAgents = Object.keys(this.environment);
    let i = 0;
    let count = 0;
    while (i < allAgents.length) {
      const { currentPosition } = this.environment[allAgents[i]];
      if (this.environment[allAgents[i]].values[currentPosition].violation !== 0) {
        count++;
      } else {
        this.stepsAt0[i]++;
        zeroAgent++;
      }
      i++;
    }
    this.agentsAt0[this.step] = zeroAgent;
    return count;
  }

  #measureTaskCoverage = (task, volTimes) => {
    let numAvail = 0;
    volTimes.forEach(v => {
      numAvail += taskTimeInApplicantTime(v, task) ? 1 : 0;
    });
    return numAvail;
  };

  #count(bestEnivronment) {
    let zeroAgent = 0;
    const allAgents = Object.keys(bestEnivronment);
    let i = 0;
    let count = 0;
    const agents = [];
    let totalViolations = 0;
    let multipleTasks = 0;
    const assignedTasks = [];
    const multipleTaskMap = {};
    const overConstrainedMap = {};
    const underConstrainedMap = {};
    const conflictingPeople = [];
    let countMap = 0;
    while (i < allAgents.length) {
      const { currentPosition } = bestEnivronment[allAgents[i]];
      // Finding applicants not assigned
      const applicantAssignedName = bestEnivronment[allAgents[i]].values[currentPosition].value.vName;
      this.applicantNames = this.applicantNames.filter(e => e !== applicantAssignedName);
      const index = this.applicantsCopy.findIndex(e => e.vName === applicantAssignedName);
      const taskTime = bestEnivronment[allAgents[i]].time;

      // Keep track of number of tasks assigned to someone already assigned another task
      if (!assignedTasks.includes(applicantAssignedName)) {
        assignedTasks.push(applicantAssignedName);
      } else {
        multipleTasks++;
      }
      // Keep track of number of applicants assigned to multiple tasks
      if (applicantAssignedName in multipleTaskMap) {
        if (!multipleTaskMap[applicantAssignedName]) {
          countMap++;
          multipleTaskMap[applicantAssignedName] = true;
        }
      } else {
        multipleTaskMap[applicantAssignedName] = 0;
      }
      // Keep track of number of applicants with conflicting tasks
      if (bestEnivronment[allAgents[i]].values[currentPosition].violation !== 0 && !conflictingPeople.includes(applicantAssignedName)) {
        conflictingPeople.push(applicantAssignedName);
      }
      // Zero and non zero positions
      if (bestEnivronment[allAgents[i]].values[currentPosition].violation !== 0) {
        const timeString = `${bestEnivronment[allAgents[i]]?.time?.starts_at},${bestEnivronment[allAgents[i]]?.time?.ends_at}`;
        const name = bestEnivronment[allAgents[i]]?.taskName;
        const key = `${name},${timeString}`;
        if (key in overConstrainedMap) {
          overConstrainedMap[key] += 1;
        } else {
          overConstrainedMap[key] = 1;
        }
        agents.push({
          agent: bestEnivronment[allAgents[i]],
          name: bestEnivronment[allAgents[i]].values[currentPosition].value.vName,
          violation: bestEnivronment[allAgents[i]].values[currentPosition].violation,
          task: allAgents[i],
        });
        count++;
        totalViolations += bestEnivronment[allAgents[i]].values[currentPosition].violation;
      } else {
        if (this.applicantsCopy[index]) {
          this.applicantsCopy[index] = this.#removeTimeFromApplicantAvailability(taskTime, this.applicantsCopy[index]);
        }
        zeroAgent++;
      }
      i++;
    }
    const v = {};
    agents.forEach(agent => {
      if (agent.name in v) {
        v[agent.name].push(agent);
      } else {
        v[agent.name] = [agent];
      }
    });

    this.intervals = this.updateIntervals(this.applicantsCopy);

    Object.keys(this.tasks).forEach(task => {
      underConstrainedMap[task] = this.#measureTaskCoverage(this.tasks[task], this.applicantsCopy);
    });

    const unassignedStatusMap = {};
    this.applicantNames.forEach(n => {
      const s = n.split('-');
      const status = s[0];
      if (status in unassignedStatusMap) {
        unassignedStatusMap[status] += 1;
      } else {
        unassignedStatusMap[status] = 1;
      }
    });

    if (this.logging) {
      console.log(`Number of tasks that have a valid assignment: ${zeroAgent}`);
      console.log(`Number of tasks that do not have a valid assignment: ${count}`);
      console.log('Tasks that do not have a valid assignment:');
      console.log(agents);
      console.log(v);
      console.log(`Total number of violations: ${totalViolations}`);
      console.log('Applicants not assigned to any tasks:');
      console.log(this.applicantNames);
      console.log(`Tasks assigned to people already assigned a task: ${multipleTasks}`);
      console.log(`Applicants assigned to multiple tasks: ${countMap}`);
      console.log('Over constrained tasks:');
      console.log(overConstrainedMap);
      console.log('Under constrained tasks:');
      console.log(underConstrainedMap);
      console.log(`Applicants assigned to conflicting tasks: ${conflictingPeople.length}`);
      console.log(`Avg tasks per conflicting person: ${count / conflictingPeople.length}`);
    }

    return [zeroAgent, count, unassignedStatusMap, overConstrainedMap, this.intervals];
  }

  #adminPref(applicant, taskId, tp) {
    const applicantStatusId = applicant.status.id;
    const taskPref = tp.find(
      t => t.task_id === taskId && t.status_id === applicantStatusId
    );
    if (!taskPref) {
      return 0;
    }
    return taskPref.preference;
  }

  #volPref(applicant, taskName) {
    if (applicant && taskName) {
      const pref = applicant.taskPreferences.find(t => t?.task?.name === taskName)?.preference;
      return pref || 0.5;
    }
    return 0.5;
  }

  /**
   * Evaluates the quality of the environment with the fewest violations
   * @param {*} bestEnivronment The environment that had the fewest violations
   */
  #evaluateSumAdminPref(bestEnivronment) {
    const allAgents = Object.keys(bestEnivronment);
    const preferences = this.problemInstance.taskPreferences;
    let i = 0;
    let count = 0;
    while (i < allAgents.length) {
      const { currentPosition } = bestEnivronment[allAgents[i]];
      const applicant = bestEnivronment[allAgents[i]].values[currentPosition].value;
      const { id } = bestEnivronment[allAgents[i]];
      if (bestEnivronment[allAgents[i]].values[currentPosition].violation === 0) {
        count += this.adminPrefMap[this.#adminPref(applicant, id, preferences)];
      }
      i++;
    }
    return count;
  }

  #evaluateSumVolAdminPref(bestEnivronment) {
    const allAgents = Object.keys(bestEnivronment);
    const preferences = this.problemInstance.taskPreferences;
    let i = 0;
    let count = 0;
    while (i < allAgents.length) {
      const { currentPosition } = bestEnivronment[allAgents[i]];
      const applicant = bestEnivronment[allAgents[i]].values[currentPosition].value;
      const { id } = bestEnivronment[allAgents[i]];
      const { taskName } = bestEnivronment[allAgents[i]];
      if (bestEnivronment[allAgents[i]].values[currentPosition].violation === 0) {
        count += this.adminPrefMap[this.#adminPref(applicant, id, preferences)];
        count += this.volPrefMap[this.#volPref(applicant, taskName)];
      }
      i++;
    }
    console.log(`Sum vol admin pref: ${count}`);
  }

  #evaluateProdAdminPref(bestEnivronment) {
    const allAgents = Object.keys(bestEnivronment);
    const preferences = this.problemInstance.taskPreferences;
    let i = 0;
    let count = 1;
    while (i < allAgents.length) {
      const { currentPosition } = bestEnivronment[allAgents[i]];
      const applicant = bestEnivronment[allAgents[i]].values[currentPosition].value;
      const { id } = bestEnivronment[allAgents[i]];
      if (bestEnivronment[allAgents[i]].values[currentPosition].violation === 0) {
        const pref = this.#adminPref(applicant, id, preferences);
        const mappedPref = this.adminPrefMap[pref];
        count *= mappedPref;
      }
      i++;
    }
    return Math.log(count);
  }

  #evaluateProdVolAdminPref(bestEnivronment) {
    const allAgents = Object.keys(bestEnivronment);
    const preferences = this.problemInstance.taskPreferences;
    let i = 0;
    let count = 1;
    while (i < allAgents.length) {
      const { currentPosition } = bestEnivronment[allAgents[i]];
      const applicant = bestEnivronment[allAgents[i]].values[currentPosition].value;
      const { id } = bestEnivronment[allAgents[i]];
      const { taskName } = bestEnivronment[allAgents[i]];
      if (bestEnivronment[allAgents[i]].values[currentPosition].violation === 0) {
        const pref = this.#adminPref(applicant, id, preferences);
        const mappedPref = this.adminPrefMap[pref];
        const volPref = this.#volPref(applicant, taskName);
        const mappedVolPref = this.volPrefMap[volPref];
        count *= (mappedPref * mappedVolPref);
      }
      i++;
    }
    console.log(`Ln vol admin prod pref: ${Math.log(count)}`);
    return count;
  }

  #evaluateCountAdminPref(bestEnivronment) {
    const allAgents = Object.keys(bestEnivronment);
    const preferences = this.problemInstance.taskPreferences;
    let i = 0;
    const numAdminPrefMap = {
      1: 0, 3: 0, 4: 0, 5: 0,
    };
    while (i < allAgents.length) {
      const { currentPosition } = bestEnivronment[allAgents[i]];
      const applicant = bestEnivronment[allAgents[i]].values[currentPosition].value;
      const { id } = bestEnivronment[allAgents[i]];
      const pref = this.#adminPref(applicant, id, preferences);
      const mappedPref = this.adminPrefMap[pref];
      numAdminPrefMap[mappedPref] += 1;
      i++;
    }
    console.log('Admin pref map:');
    console.log(numAdminPrefMap);
  }

  #evaluateCountVolAdminPref(bestEnivronment) {
    const allAgents = Object.keys(bestEnivronment);
    const preferences = this.problemInstance.taskPreferences;
    let i = 0;
    const numAdminPrefMap = {
      1: 0, 3: 0, 4: 0, 5: 0,
    };
    const numVolPrefMap = {
      0.5: 0, 1: 0, 1.5: 0, 2: 0, 2.5: 0,
    };
    while (i < allAgents.length) {
      const { currentPosition } = bestEnivronment[allAgents[i]];
      const applicant = bestEnivronment[allAgents[i]].values[currentPosition].value;
      const { id } = bestEnivronment[allAgents[i]];
      const { taskName } = bestEnivronment[allAgents[i]];
      if (bestEnivronment[allAgents[i]].values[currentPosition].violation === 0) {
        const pref = this.#adminPref(applicant, id, preferences);
        const mappedPref = this.adminPrefMap[pref];
        numAdminPrefMap[mappedPref] += 1;
        const volPref = this.#volPref(applicant, taskName);
        const mappedVolPref = this.volPrefMap[volPref];
        numVolPrefMap[mappedVolPref] += 1;
      }
      i++;
    }
    console.log('Admin pref map:');
    console.log(numAdminPrefMap);
    console.log('Vol pref map:');
    console.log(numVolPrefMap);
  }

  genereateResults(bestEnivronment) {
    const allAgents = Object.keys(bestEnivronment);
    let i = 0;
    const taskAssignments = {};
    const applicantAssignments = {};
    while (i < allAgents.length) {
      const { currentPosition } = bestEnivronment[allAgents[i]];
      const applicantId = bestEnivronment[allAgents[i]].values[currentPosition].value.id;
      const taskId = bestEnivronment[allAgents[i]].id;
      const backup = bestEnivronment[allAgents[i]].isBackup;
      const isViolation = bestEnivronment[allAgents[i]].values[currentPosition].violation !== 0;

      if (!isViolation) {
        const taskAssignment = {
          id: applicantId,
          backup,
        };
        const applicantAssignment = {
          id: taskId,
          backup,
        };
        if (taskId in taskAssignments) {
          taskAssignments[taskId].push(taskAssignment);
        } else {
          taskAssignments[taskId] = [taskAssignment];
        }
        if (applicantId in applicantAssignments) {
          applicantAssignments[applicantId].push(applicantAssignment);
        } else {
          applicantAssignments[applicantId] = [applicantAssignment];
        }
      }

      i++;
    }

    return [taskAssignments, applicantAssignments];
  }

  #checker(environment) {
    let violated = 0;
    this.constraints.forEach(constraint => {
      const scope = constraint.variables;
      const var1 = scope[0];
      let var2;

      // Unary constraints
      if (scope.length === 1) {
        const name = var1.variableName;
        const { currentPosition } = environment[name];
        const v1 = environment[name].values[currentPosition].value;
        let good;
        if (constraint.name.includes('pref')) {
          // Admin pref constraint
          const preferences = this.problemInstance.taskPreferences;
          const { id } = environment[name];
          const tp = preferences.find(p => p.task_id === id && p.status_id === v1.status.id);

          good = constraint.definition(v1, tp);
        } else {
          // Applicant in task time constraint
          const time = {
            starts_at: var1.startTime,
            ends_at: var1.endTime,
          };
          good = constraint.definition(time, v1);
        }

        if (!good) {
          violated += 1;
        }
      } else {
        // Binary constraint, two tasks at same time cannot be assigned the same applicant
        // eslint-disable-next-line prefer-destructuring
        var2 = scope[1];
        const v1Name = var1.variableName;
        const v1CurrentPosition = environment[v1Name].currentPosition;
        const v1 = environment[v1Name].values[v1CurrentPosition].value;

        const v2Name = var2.variableName;
        const v2CurrentPosition = environment[v2Name].currentPosition;
        const v2 = environment[v2Name].values[v2CurrentPosition].value;

        const good = constraint.definition(v1, v2);
        if (!good) {
          violated += 1;
        }
      }
    });

    if (this.logging) {
      console.log(violated);
    }
  }

  /** Updates the intervals after ERA finishes */
  updateIntervals(applicants) {
    const ints = cloneDeep(this.intervals);

    Object.keys(ints).forEach(k => {
      ints[k].dS = 0;
    });

    const zeroInts = cloneDeep(ints);

    Object.keys(zeroInts).forEach(k => {
      applicants.forEach(applicant => {
        const fit = taskTimeInApplicantTime(applicant, zeroInts[k].time);
        if (fit) {
          zeroInts[k].dS += 1;
        }
      });
    });

    return zeroInts;
  }

  /**
   * Runs the ERA algorithm
   * @returns An array formatted like [map of tasks to the applicants assigned to them, map of applicants to the tasks assigned to
   * them, # of satisfied tasks, # of unsatisfied tasks, map of statuses to how many applicants with that status is not assigned, map of overconstrained tasks, map of under constrained tasks]
   */
  era() {
    const start = new Date().toLocaleString();
    if (this.logging) {
      console.log(start);
      console.log(this.intervals);
      console.log(this.taskIntervalMap);
    }

    let minViolations = 400;
    let bestEnivronment = {};
    let i = 0;
    let highestBest = 0;
    while (i < this.totalIterations) {
      this.step = 0;
      this.#createEnvironment();
      this.#evaluation();
      // let thisIterationBest = 0;
      // const bests = [];
      const mins = [];
      const maxs = [];
      let max = 0;
      let roundMin = 400;
      while (!this.#allAgentsZero() && this.step < this.totalSteps) {
        this.#agentMove();
        const { environment } = this;
        const nonZero = this.#countNonZeros();
        if (roundMin > nonZero) {
          // bests.push(this.step);
          roundMin = nonZero;
        }
        if (minViolations >= nonZero) {
          // thisIterationBest = this.step;
          highestBest = highestBest > this.step ? highestBest : this.step;
          if (minViolations === nonZero && this.#evaluateSumAdminPref(bestEnivronment) < this.#evaluateSumAdminPref(environment)) {
            bestEnivronment = JSON.parse(JSON.stringify(environment));
          } else if (minViolations > nonZero) {
            minViolations = nonZero;
            bestEnivronment = JSON.parse(JSON.stringify(environment));
          }
        }
        // if (this.#evaluateProdAdminPref(environment) > max) {
        //   max = this.#evaluateProdAdminPref(environment);
        // }
        max = roundMin;
        mins.push(this.#evaluateProdAdminPref(environment));
        maxs.push(max);
        this.step++;
      }
      if (this.logging) {
      // console.log(`Iteration ${i} best: ${thisIterationBest}`);
      // console.log(bests);
        console.log(mins);
        console.log(maxs);
      }

      i++;
    }

    const ERAInfo = this.#count(bestEnivronment);
    if (this.logging) {
      console.log(minViolations);
      console.log(this.#evaluateSumAdminPref(bestEnivronment));
      this.#evaluateSumVolAdminPref(bestEnivronment);
      this.#evaluateProdAdminPref(bestEnivronment);
      this.#evaluateProdVolAdminPref(bestEnivronment);
      this.#evaluateCountVolAdminPref(bestEnivronment);
      console.log(bestEnivronment);
      console.log(highestBest);
    }

    this.#checker(bestEnivronment);
    const end = new Date().toLocaleString();
    if (this.logging) {
      console.log(end);
    }

    const assignments = this.genereateResults(bestEnivronment);

    return assignments.concat(ERAInfo);
  }
}

export default ERA;
