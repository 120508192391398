import { createBrowserHistory } from 'history';

// Public URL will be in the form https://website.domain/basename/ so take off the first 3 sections,
// join the remainder, and get rid of trailing slashes
const basename = process.env.PUBLIC_URL
  .split('/')
  .slice(3)
  .join('/')
  .trimRight('/');

const history = createBrowserHistory({ basename });
export default history;
