export const GET_AFFILIATIONS_SENT = 'GET_AFFILIATIONS_SENT';
export const GET_AFFILIATIONS_SUCCESSFUL = 'GET_AFFILIATIONS_SUCCESSFUL';
export const UPDATE_AFFILIATION_SENT = 'UPDATE_AFFILIATION_SENT';
export const UPDATE_AFFILIATION_SUCCESSFUL = 'UPDATE_AFFILIATION_SUCCESFUL';
export const CREATE_AFFILIATION_SENT = 'CREATE_AFFILIATION_SENT';
export const CREATE_AFFILIATION_SUCCESSFUL = 'CREATE_AFFILIATION_SUCCESSFUL';

export const getAffiliationsRequestSent = () => ({ type: GET_AFFILIATIONS_SENT });

export const getAffiliationsRequestSuccessful = (data) => ({ type: GET_AFFILIATIONS_SUCCESSFUL, data });

export const updateAffiliationRequestSent = () => ({ type: UPDATE_AFFILIATION_SENT });

export const updateAffiliationRequestSuccessful = (data) => ({ type: UPDATE_AFFILIATION_SUCCESSFUL, data });

export const createAffiliationRequestSent = () => ({ type: CREATE_AFFILIATION_SENT });

export const createAffiliationRequestSuccessful = (data) => ({ type: CREATE_AFFILIATION_SUCCESSFUL, data });
