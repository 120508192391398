import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import history from 'wrappers/history';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableSelect from 'components/common/ReusableSelect';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { formatTime } from 'utils/date';
import cloneDeep from 'lodash/cloneDeep';
import styles from './style.module.scss';
import { yearOptions } from '../AdminUtils.ts';

import { taskTimeInApplicantTime, renderApplicantTimeWindows, renderLoadBackup } from './utils';
import TaskTable from './Tables/TaskTable';
import ApplicantTable from './Tables/ApplicantTable';
import TaskApplicantAssignTable from './Tables/TaskApplicantAssignTable';
import ApplicantTaskAssignTable from './Tables/ApplicantTaskAssignTable';
import ApplicantTaskUnassignTable from './Tables/ApplicantTaskUnassignTable';
import TaskApplicantUnassignTable from './Tables/TaskApplicantUnassignTable';
import ApplicantTableFilterDialog from './Dialogs/ApplicantTableFilterDialog';
import TaskTableFilterDialog from './Dialogs/TaskTableFilterDialog';
import ATATableFilterDialog from './Dialogs/ATATableFilterDialog';
import TAATableFilterDialog from './Dialogs/TAATableFilterDialog';
import TAAConfirmTable from './Tables/TAAConfirmTable';
import ATAConfirmTable from './Tables/ATAConfirmTable';

const dialogTitleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(dialogTitleStyles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ToolTipTheme = withStyles({
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    fontFamily: 'Tungsten A,Tungsten B,Arial Narrow,Arial,sans-serif',
    fontSize: '1.5rem',
    whiteSpace: 'pre-line',
    height: 'fit-content',
    maxWidth: 'none',
    lineHeight: '1',
  },
})(Tooltip);

class InteractiveAssignmentPage extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }

    // Bindings needed so that the correct state is changed when passing in functions as props
    // Functions needed for TaskTable
    this.openAssigneeDialog = this.openAssigneeDialog.bind(this);
    this.handleOpenTaskFilter = this.handleOpenTaskFilter.bind(this);
    this.openTaskSolutionAssignmentsDialog = this.openTaskSolutionAssignmentsDialog.bind(this);

    // Functions needed for ApplicantTable
    this.openApplicantTaskAssignDialog = this.openApplicantTaskAssignDialog.bind(this);
    this.handleOpenApplicantFilter = this.handleOpenApplicantFilter.bind(this);
    this.openApplicantSolutionAssignmentsDialog = this.openApplicantSolutionAssignmentsDialog.bind(this);

    // Function needed for Applicant and Task Tables
    this.handleDownloadSnackbar = this.handleDownloadSnackbar.bind(this);

    // Functions needed for TAA Table
    this.openApplicantPrevTasksDialog = this.openApplicantPrevTasksDialog.bind(this);
    this.toggleApplicantPrevTasksTAA = this.toggleApplicantPrevTasksTAA.bind(this);
    this.handleAssign = this.handleAssign.bind(this);
    this.handlePreemptibleAssignTAA = this.handlePreemptibleAssignTAA.bind(this);
    this.handleTAAtoggle = this.handleTAAtoggle.bind(this);
    this.handleOpenTAAFilter = this.handleOpenTAAFilter.bind(this);

    // Functions needed for ATA Table
    this.handleApplicantTaskAssign = this.handleApplicantTaskAssign.bind(this);
    this.handleATAtoggle = this.handleATAtoggle.bind(this);
    this.updateTimeAvailabilityBasedOnSelection = this.updateTimeAvailabilityBasedOnSelection.bind(this);
    this.handleOpenATAFilter = this.handleOpenATAFilter.bind(this);

    // Function needed for TAA and ATA Unassign Tables
    this.handleUnassign = this.handleUnassign.bind(this);

    // Functions needed for Applicant Table Filter
    this.handleSelectNoneStatuses = this.handleSelectNoneStatuses.bind(this);
    this.handleSelectAllStatuses = this.handleSelectAllStatuses.bind(this);
    this.handleStatusCheckbox = this.handleStatusCheckbox.bind(this);
    this.handleClearApplicantTimeFilter = this.handleClearApplicantTimeFilter.bind(this);
    this.handleBeginningApplicantTimeChange = this.handleBeginningApplicantTimeChange.bind(this);
    this.handleEndingApplicantTimeChange = this.handleEndingApplicantTimeChange.bind(this);
    this.handleCloseApplicantFilter = this.handleCloseApplicantFilter.bind(this);

    // Functions needed for Task Table Filter
    this.handleSelectNoneTasks = this.handleSelectNoneTasks.bind(this);
    this.handleSelectAllTasks = this.handleSelectAllTasks.bind(this);
    this.handleTaskCheckbox = this.handleTaskCheckbox.bind(this);
    this.handleClearTaskTimeFilter = this.handleClearTaskTimeFilter.bind(this);
    this.handleBeginningTaskTimeChange = this.handleBeginningTaskTimeChange.bind(this);
    this.handleEndingTaskTimeChange = this.handleEndingTaskTimeChange.bind(this);
    this.handleCloseTaskFilter = this.handleCloseTaskFilter.bind(this);

    // Functions needed for both ATA/TAA Table Filter
    this.handleSelectNonePreferenceTypes = this.handleSelectNonePreferenceTypes.bind(this);
    this.handleSelectAllPreferenceTypes = this.handleSelectAllPreferenceTypes.bind(this);
    this.handlePreferenceTypeCheckbox = this.handlePreferenceTypeCheckbox.bind(this);

    // Functions needed for ATA Table Filter
    this.handleCloseATAFilter = this.handleCloseATAFilter.bind(this);

    // Functions needed for TAA Table Filter
    this.handleCloseTAAFilter = this.handleCloseTAAFilter.bind(this);

    // Functions needed for Edit Time Dialog
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);

    this.tableRefTAA = createRef();
    this.tableRefATA = createRef();
    this.tableRefTAAConfirm = createRef();
    this.tableRefATAConfirm = createRef();

    this.state = {
      year: this.props.year,
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: '',
      selectedTask: null,
      /** Map of tasks and the time to assign the applicant for, used in ATA */
      applicantTaskAssignMap: {},
      currentStartTimeMap: {},
      currentEndTimeMap: {},
      confirmDialogOpen: false,
      applicants: new Map(),
      checkedStatuses: {},
      checkedPreferenceTypes: {},
      checkedTasks: {},
      assigneesDialogOpen: false,
      taskSolutionAssignmentsDialogOpen: false,
      applicantSolutionAssignmentsDialogOpen: false,
      applicantTaskAssignDialogOpen: false,
      selectedApplicant: null,
      oldApplicantTaskAssignSelection: [],
      assignedTasksData: [],
      confirmApplicantTaskAssignDialogOpen: false,
      jobSelected: true,
      assigneesData: [],
      assigned: [],
      beginningTaskTimeFilter: '',
      endingTaskTimeFilter: '',
      beginningApplicantTimeFilter: '',
      endingApplicantTimeFilter: '',
      taskFilterOpen: false,
      applicantFilterOpen: false,
      ATAFilterOpen: false,
      TAAFilterOpen: false,
      applicantPrevTasksOpenATA: false,
      applicantPrevTasksOpenTAA: [],
      allSelected: false,
      // maps task id to a boolean: loadSelected, for the ATA toggle button
      taskLoadBackupToggle: {},
      // maps applicant id to a boolean: loadSelected, for the TAA toggle button
      applicantLoadBackupToggle: {},
      // a boolean for determing whether the assigned button has been clicked in the ATA table
      assignClicked: false,
      // a boolean for determining whether the confirm button has been clicked from the ATA table
      cancelClicked: false,
      ATAData: [],
      TAAData: [],
    };
  }

  async componentDidMount() {
    if (this.props.year === '') {
      await this.props.getYear();
    }
    if (this.props.applicants.length === 0) {
      await this.props.getApplicants();
    }
    if (this.props.tasks.length === 0) {
      await this.props.getTasks();
    }
    if (this.props.taskPreferences.length === 0) {
      await this.props.getTaskPreferences();
    }
    if (this.props.solutionAssignments.length === 0) {
      await this.props.getSolutionAssignments();
    }
    if (this.props.activities.length === 0) {
      await this.props.getActivities();
    }
    if (this.props.statuses.length === 0) {
      await this.props.getStatuses();
    }
    if (this.props.assignments.length === 0) {
      this.props.getAssignments();
    }

    this.setState({ year: this.props.year });

    // Statuses in the Applicant table filter are initially all checked
    const checkedStatuses = {};
    const approvedStatuses = this.props.statuses.filter(status => status.active && status.approved);
    approvedStatuses.forEach(status => { checkedStatuses[`${status.id}`] = true; });

    // Preference types for filter in ATA table are initally all checked
    const checkedPreferenceTypes = {};
    const preferenceTypes = ['Preferred', 'Acceptable', 'Avoid', "Can't Do", 'None'];
    preferenceTypes.forEach(type => { checkedPreferenceTypes[type] = true; });

    const TAAData = cloneDeep(this.applicantsForCurrentYear);
    const ATAData = cloneDeep(this.tasksForCurrentYear);

    // Tasks in the Task table filter are initially all checked
    const checkedTasks = {};
    this.tasksForCurrentYear.forEach(task => { checkedTasks[`${task.id}`] = true; });

    // a map that maps an applicant to their id
    const applicants = new Map();
    this.applicantsForCurrentYear.forEach(applicant => {
      if (applicant.year === this.state.year) {
        applicants.set(applicant.id, applicant);
      }
    });
    this.setState({ applicants, ATAData, TAAData });

    const tasks = this.props.tasks.filter(task => task.year === this.state.year);

    const taskLoadBackupToggle = new Map();
    tasks.forEach(task => {
      taskLoadBackupToggle.set(task.id, true);
    });

    const applicantLoadBackupToggle = new Map();
    this.props.applicants.forEach(applicant => {
      applicantLoadBackupToggle.set(applicant.id, true);
    });

    // Previous Task tooltips all start out closed
    const applicantPrevTasksOpenTAA = [];
    this.props.applicants.forEach(applicant => { applicantPrevTasksOpenTAA[`${applicant.user.id}`] = false; });

    this.setState({ applicants, taskLoadBackupToggle, applicantLoadBackupToggle });

    this.setState({
      checkedTasks, checkedStatuses, applicantPrevTasksOpenTAA, checkedPreferenceTypes,
    });

    setTimeout(this.updateLookups, 50);
  }

  /**
   * Returns the applicants whose year is the state's year
   */
  get applicantsForCurrentYear() {
    // For some reason loading the page for the first time, switching to a different page, and switching back
    // breaks the page. This fixes it.
    if (!this.state) {
      return [];
    }
    return this.props.applicants.filter(applicant => applicant.year === this.state.year);
  }

  /**
   * returns a map that maps applicant id to applicant object and loops through solution assignments removing assigned times from applicant availability
   */
  get applicantMapForCurrentYear() {
    // a map that maps an applicant to their id
    const applicants = new Map();
    this.props.applicants.forEach(applicant => {
      if (applicant.year === this.state.year) {
        applicants.set(applicant.id, applicant);
      }
    });

    this.props.solutionAssignments.forEach(solutionAssignment => {
      const applicant = this.applicantsForCurrentYear.find(app => app.id === solutionAssignment.applicant.id);
      const adjustedTimeWindows = this.removeTimeFromApplicantAvailability({ starts_at: solutionAssignment?.starts_at, ends_at: solutionAssignment?.ends_at }, applicants.get(solutionAssignment.applicant.id)?.time_windows);
      applicants.set(solutionAssignment.applicant.id, { ...applicant, time_windows: adjustedTimeWindows });
    });

    return applicants;
  }

  get solutionAssignmentsOfTask() {
    // For some reason loading the page for the first time, switching to a different page, and switching back
    // breaks the page. This fixes it.
    if (!this.state) {
      return [];
    }
    return this.props.solutionAssignments.filter(solutionAssignment => solutionAssignment.task.id === this.state.selectedTask?.id);
  }

  get solutionAssignmentsOfApplicant() {
    // For some reason loading the page for the first time, switching to a different page, and switching back
    // breaks the page. This fixes it.
    if (!this.state) {
      return [];
    }
    return this.props.solutionAssignments.filter(solutionAssignment => solutionAssignment.applicant.id === this.state.selectedApplicant?.id);
  }

  /**
   * Returns the tasks whose year is the state's year
   */
  get tasksForCurrentYear() {
    // For some reason loading the page for the first time, switching to a different page, and switching back
    // breaks the page. This fixes it.
    if (!this.state) {
      return [];
    }
    return this.props.tasks.filter(task => task.year === this.state.year);
  }

  get assignmentsWithNestedUsers() {
    const newAssignments = [];
    this.otherFinalAssignments.forEach(assignment => {
      const newAssignment = assignment;
      if (assignment.applicant) {
        newAssignment.startTime = dayjs(assignment?.starts_at);
        newAssignment.endTime = dayjs(assignment?.ends_at);
        newAssignment.applicant.user = assignment.user;
        newAssignments.push(newAssignment);
      }
    });
    return newAssignments;
  }

  get otherFinalAssignments() {
    if (!this.state) {
      return [];
    }
    return this.props.assignments.filter(assignment => assignment.task.year !== this.state.year);
  }

  handleOpenTaskFilter = () => {
    this.setState({ taskFilterOpen: true });
  };

  handleCloseTaskFilter = () => {
    this.setState({ taskFilterOpen: false });
  };

  handleOpenApplicantFilter = () => {
    this.setState({ applicantFilterOpen: true });
  };

  handleCloseApplicantFilter = () => {
    this.setState({ applicantFilterOpen: false });
  };

  /** Toggles the ATA filter open */
  handleOpenATAFilter = () => {
    this.setState({ ATAFilterOpen: true });
  };

  /** Toggles the ATA filter closed */
  handleCloseATAFilter = () => {
    this.setState({ ATAFilterOpen: false });
  };

  /** Toggles the TAA filter open */
  handleOpenTAAFilter = () => {
    this.setState({ TAAFilterOpen: true });
  };

  /** Toggles the TAA filter closed */
  handleCloseTAAFilter = () => {
    this.setState({ TAAFilterOpen: false });
  };

  /**
   * Handles changing the starting time for assignment in the TAA Confirm table to the first available window
   * the assignee has withing the preemptible task's time
   * @param {*} assignee The assignee potentially being assigned to a preemptible task
   */
  handlePreemptibleAssignTAA = (assignee) => {
    const task = this.state.selectedTask;
    const { id } = assignee.user;
    const timeWindows = this.state.applicants.get(assignee?.id).time_windows;
    timeWindows.sort((a, b) => (dayjs(a.starts_at) <= dayjs(b.starts_at) ? -1 : 1));
    timeWindows.find(timeWindow => {
      if (dayjs(timeWindow?.ends_at).isAfter(dayjs(task?.starts_at))) {
        if (dayjs(timeWindow?.ends_at).isBefore(dayjs(task?.ends_at)) || dayjs(timeWindow?.ends_at).isSame(dayjs(task?.ends_at))) {
          if (dayjs(timeWindow?.starts_at).isBefore(dayjs(task?.starts_at))) {
            this.setState(prevState => {
              const newStartTimeMap = { ...prevState.currentStartTimeMap };
              const newEndTimeMap = { ...prevState.currentEndTimeMap };
              newStartTimeMap[[id]][0] = task?.starts_at;
              newStartTimeMap[[id]][2] = 0;
              newEndTimeMap[[id]][0] = timeWindow?.ends_at;
              newEndTimeMap[[id]][2] = 0;
              return { currentStartTimeMap: newStartTimeMap, currentEndTimeMap: newEndTimeMap };
            });
            return true;
          }
          this.setState(prevState => {
            const newStartTimeMap = { ...prevState.currentStartTimeMap };
            const newEndTimeMap = { ...prevState.currentEndTimeMap };
            newStartTimeMap[[id]][0] = timeWindow?.starts_at;
            newStartTimeMap[[id]][2] = 0;
            newEndTimeMap[[id]][0] = timeWindow?.ends_at;
            newEndTimeMap[[id]][2] = 0;
            return { currentStartTimeMap: newStartTimeMap, currentEndTimeMap: newEndTimeMap };
          });
          return true;
        }
        if (dayjs(timeWindow?.starts_at).isBefore(dayjs(task?.ends_at))) {
          if (dayjs(timeWindow?.starts_at).isAfter(dayjs(task?.starts_at)) || dayjs(timeWindow?.starts_at).isSame(dayjs(task?.starts_at))) {
            this.setState(prevState => {
              const newStartTimeMap = { ...prevState.currentStartTimeMap };
              const newEndTimeMap = { ...prevState.currentEndTimeMap };
              newStartTimeMap[[id]][0] = timeWindow?.starts_at;
              newStartTimeMap[[id]][2] = 0;
              newEndTimeMap[[id]][0] = task?.ends_at;
              newEndTimeMap[[id]][2] = 0;
              return { currentStartTimeMap: newStartTimeMap, currentEndTimeMap: newEndTimeMap };
            });
            return true;
          }
          this.setState(prevState => {
            const newStartTimeMap = { ...prevState.currentStartTimeMap };
            const newEndTimeMap = { ...prevState.currentEndTimeMap };
            newStartTimeMap[[id]][0] = task?.starts_at;
            newStartTimeMap[[id]][2] = 0;
            newEndTimeMap[[id]][0] = task?.ends_at;
            newEndTimeMap[[id]][2] = 0;
            return { currentStartTimeMap: newStartTimeMap, currentEndTimeMap: newEndTimeMap };
          });
          return true;
        }
      }
      return false;
    });
  };

  /**
   * Hanldes clicking the check mark for TAA table
   * @param {*} assignees The selected rows containing applicant info
   */
  handleAssign = (assignees) => {
    this.setState({ assignClicked: true, cancelClicked: false });
    for (let i = 0; i < assignees?.length; i++) {
      const assigneeWithLB = { ...assignees[i] };
      assigneeWithLB.back_up = !this.state.applicantLoadBackupToggle.get(assignees[i].id);
      if (this.state.selectedTask.preemtable) {
        this.handlePreemptibleAssignTAA(assigneeWithLB);
      }
      this.state.assigneesData.push(assigneeWithLB);
    }
    this.clearSelectionTAA();
    this.setState({ assigneesDialogOpen: false });
    this.setState({ confirmDialogOpen: true });
  };

  /**
   * Handles clicking the checkmark in the ATA table to assign tasks to the user
   * @param {*} assignedTasks The tasks that are selected with the checkboxes in the ATA table
   */
  handleApplicantTaskAssign = (assignedTasks) => {
    this.setState({ assignClicked: true, cancelClicked: false });
    for (let i = 0; i < assignedTasks?.length; i++) {
      const updatedTime = this.state.applicantTaskAssignMap[assignedTasks[i]?.id] ? this.state.applicantTaskAssignMap[assignedTasks[i].id].time : { starts_at: assignedTasks[i].starts_at, ends_at: assignedTasks[i].ends_at };
      const taskWithLB = { ...assignedTasks[i] };
      taskWithLB.back_up = !this.state.taskLoadBackupToggle.get(assignedTasks[i]?.id);
      this.state.assignedTasksData.push([taskWithLB, updatedTime]);
    }
    this.setState({ applicantTaskAssignDialogOpen: false });
    this.setState({ confirmApplicantTaskAssignDialogOpen: true });
    this.clearSelectionATA();
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleYearChange = (event) => {
    this.setState({
      year: event.target.value,
    });

    const applicants = new Map();
    this.props.applicants.forEach(applicant => {
      if (applicant.year === event.target.value) {
        applicants.set(applicant.id, applicant);
      }
    });

    this.props.solutionAssignments.forEach(solutionAssignment => {
      const applicant = this.props.applicants.filter(app => app.year === event.target.value).find(app => app.id === solutionAssignment.applicant.id);
      const adjustedTimeWindows = this.removeTimeFromApplicantAvailability({ starts_at: solutionAssignment?.starts_at, ends_at: solutionAssignment?.ends_at }, applicants.get(solutionAssignment.applicant.id)?.time_windows);
      applicants.set(solutionAssignment.applicant.id, { ...applicant, time_windows: adjustedTimeWindows });
    });
    const TAAData = cloneDeep(this.props.applicants.filter(app => app.year === event.target.value));
    const ATAData = cloneDeep(this.props.tasks.filter(task => task.year === event.target.value));

    const checkedTasks = {};
    this.props.tasks.filter(task => task.year === event.target.value).forEach(task => { checkedTasks[`${task.id}`] = true; });
    this.setState({ checkedTasks, TAAData, ATAData });
  };

  handleATAtoggle = (taskID) => {
    this.state.taskLoadBackupToggle.set(taskID, !this.state.taskLoadBackupToggle.get(taskID));
    this.setState({ });
  };

  handleTAAtoggle = (applicantID) => {
    this.state.applicantLoadBackupToggle.set(applicantID, !this.state.applicantLoadBackupToggle.get(applicantID));
    this.setState({ });
  };

  handleStatusCheckbox = event => {
    const { checkedStatuses } = this.state;
    checkedStatuses[event.target.name] = event.target.checked;
    this.setState({ checkedStatuses });
  };

  handleTaskCheckbox = event => {
    const { checkedTasks } = this.state;
    checkedTasks[event.target.name] = event.target.checked;
    this.setState({ checkedTasks });
  };

  /** Handles a check event for a preference type in the ATA table filter */
  handlePreferenceTypeCheckbox = event => {
    const { checkedPreferenceTypes } = this.state;
    checkedPreferenceTypes[event.target.name] = event.target.checked;
    this.setState({ checkedPreferenceTypes });
  };

  /** Handles selecting all statuses for Applicant table filter */
  handleSelectAllStatuses = () => {
    const { checkedStatuses } = this.state;
    Object.keys(checkedStatuses).forEach(statusID => { checkedStatuses[statusID] = true; });
    this.setState({ checkedStatuses });
  };

  /** Handles deselecting all statuses for Applicant table filter */
  handleSelectNoneStatuses = () => {
    const { checkedStatuses } = this.state;
    Object.keys(checkedStatuses).forEach(statusID => { checkedStatuses[statusID] = false; });
    this.setState({ checkedStatuses });
  };

  /** Handles selecting all tasks for Task table filter */
  handleSelectAllTasks = () => {
    const { checkedTasks } = this.state;
    Object.keys(checkedTasks).forEach(taskID => { checkedTasks[taskID] = true; });
    this.setState({ checkedTasks });
  };

  /** Handles deselecting all tasks for Task table filter */
  handleSelectNoneTasks = () => {
    const { checkedTasks } = this.state;
    Object.keys(checkedTasks).forEach(taskID => { checkedTasks[taskID] = false; });
    this.setState({ checkedTasks });
  };

  /** Handles selecting all preference types for ATA filter */
  handleSelectAllPreferenceTypes = () => {
    const { checkedPreferenceTypes } = this.state;
    Object.keys(checkedPreferenceTypes).forEach(preferenceType => { checkedPreferenceTypes[preferenceType] = true; });
    this.setState({ checkedPreferenceTypes });
  };

  /** Handles deselecting all preference types for ATA filter */
  handleSelectNonePreferenceTypes = () => {
    const { checkedPreferenceTypes } = this.state;
    Object.keys(checkedPreferenceTypes).forEach(preferenceType => { checkedPreferenceTypes[preferenceType] = false; });
    this.setState({ checkedPreferenceTypes });
  };

  handleClearTaskTimeFilter = () => {
    this.setState({ beginningTaskTimeFilter: '', endingTaskTimeFilter: '' });
  };

  handleClearApplicantTimeFilter = () => {
    this.setState({ beginningApplicantTimeFilter: '', endingApplicantTimeFilter: '' });
  };

  applicantInsideTimeFilter = (applicant) => {
    let inside = false;
    for (let i = 0; i < applicant?.time_windows.length; i++) {
      const timeTokens = applicant.time_windows[i]?.ends_at.split(':');
      // parsing out the minute field in order to increment it by one
      // add a leading zero to single digit minute number
      // the minute is incremented in order to make sure the end time is not invalid due when it is equal to end of person's availability
      timeTokens[1] = (Number(timeTokens[1]) + 1) < 10 ? `0${Number(timeTokens[1]) + 1}` : `${Number(timeTokens[1]) + 1}`;
      const newTokens = timeTokens.join(':');

      let applicantStartsAtHour = dayjs(applicant.time_windows[i]?.starts_at).hour();
      let applicantStartsAtMinute = dayjs(applicant.time_windows[i]?.starts_at).minute();
      // add a zero if the number is less than 10, so that 01:01 won't appear as 1:1
      applicantStartsAtHour = applicantStartsAtHour < 10 ? `0${applicantStartsAtHour}` : applicantStartsAtHour;
      applicantStartsAtMinute = applicantStartsAtMinute < 10 ? `0${applicantStartsAtMinute}` : applicantStartsAtMinute;

      let applicantEndsAtHour = dayjs(newTokens).hour();
      let applicantEndsAtMinute = dayjs(newTokens).minute();
      // add a zero if the number is less than 10, so that 01:01 won't appear as 1:1
      applicantEndsAtHour = applicantEndsAtHour < 10 ? `0${applicantEndsAtHour}` : applicantEndsAtHour;
      applicantEndsAtMinute = applicantEndsAtMinute < 10 ? `0${applicantEndsAtMinute}` : applicantEndsAtMinute;

      const applicantStartsAt = `${applicantStartsAtHour}:${applicantStartsAtMinute}`;
      const applicantEndsAt = `${applicantEndsAtHour}:${applicantEndsAtMinute}`;

      inside = inside
        || (this.state.beginningApplicantTimeFilter <= applicantStartsAt
        && this.state.endingApplicantTimeFilter >= applicantEndsAt);
    }
    return inside;
  };

  taskInsideTimeFilter = (task) => {
    let taskStartsAtHour = dayjs(task?.starts_at).hour();
    let taskStartsAtMinute = dayjs(task?.starts_at).minute();
    // add a zero if the number is less than 10, so that 01:01 won't appear as 1:1
    taskStartsAtHour = taskStartsAtHour < 10 ? `0${taskStartsAtHour}` : taskStartsAtHour;
    taskStartsAtMinute = taskStartsAtMinute < 10 ? `0${taskStartsAtMinute}` : taskStartsAtMinute;

    let taskEndsAtHour = dayjs(task?.ends_at).hour();
    let taskEndsAtMinute = dayjs(task?.ends_at).minute();
    // add a zero if the number is less than 10, so that 01:01 won't appear as 1:1
    taskEndsAtHour = taskEndsAtHour < 10 ? `0${taskEndsAtHour}` : taskEndsAtHour;
    taskEndsAtMinute = taskEndsAtMinute < 10 ? `0${taskEndsAtMinute}` : taskEndsAtMinute;

    const taskStartsAt = `${taskStartsAtHour}:${taskStartsAtMinute}`;
    const taskEndsAt = `${taskEndsAtHour}:${taskEndsAtMinute}`;

    return (this.state.beginningTaskTimeFilter <= taskStartsAt
        && this.state.endingTaskTimeFilter >= taskEndsAt);
  };

  handleBeginningTaskTimeChange = (event) => {
    const newTime = event.target.value;
    this.setState({ beginningTaskTimeFilter: newTime });
  };

  handleEndingTaskTimeChange = (event) => {
    const newTime = event.target.value;
    this.setState({ endingTaskTimeFilter: newTime });
  };

  handleBeginningApplicantTimeChange = (event) => {
    const newTime = event.target.value;
    this.setState({ beginningApplicantTimeFilter: newTime });
  };

  handleEndingApplicantTimeChange = (event) => {
    const newTime = event.target.value;
    this.setState({ endingApplicantTimeFilter: newTime });
  };

  /** Handles closing the TAA table */
  handleCloseAssigneeDialog = () => {
    // Reset the preference filter
    const checkedPreferenceTypes = {};
    const preferenceTypes = ['Preferred', 'Acceptable', 'Avoid', "Can't Do", 'None'];
    preferenceTypes.forEach(type => { checkedPreferenceTypes[type] = true; });

    this.setState({
      currentStartTimeMap: {}, currentEndTimeMap: {}, assigneesDialogOpen: false, checkedPreferenceTypes,
    });
    this.resetTAAtoggles();
  };

  /** Handles closing the ATA table */
  handleCloseApplicantTaskAssignDialog = () => {
    // Reset the preference filter
    const checkedPreferenceTypes = {};
    const preferenceTypes = ['Preferred', 'Acceptable', 'Avoid', "Can't Do", 'None'];
    preferenceTypes.forEach(type => { checkedPreferenceTypes[type] = true; });

    if (!this.state.assignClicked) {
      this.setState({ cancelClicked: false });
      this.clearSelectionATA();
      this.resetATAtoggles();
    }
    this.setState({ applicantTaskAssignDialogOpen: false, checkedPreferenceTypes });
    this.closeApplicantPrevTasksATA();
  };

  handleCloseConfirmDialog = () => {
    this.setState({ confirmDialogOpen: false });
    this.setState({ assigneesData: [] });
  };

  handleCloseConfirmApplicantTaskAssignDialog = () => {
    this.setState({ confirmApplicantTaskAssignDialogOpen: false });
    this.setState({ assignedTasksData: [] });
    if (!this.state.cancelClicked) {
      this.setState({ assignClicked: false });
      this.clearSelectionATA();
      this.resetATAtoggles();
    }
  };

  handleCloseTaskSolutionAssignmentsDialog = () => {
    this.setState({ taskSolutionAssignmentsDialogOpen: false });
  };

  handleCloseApplicantSolutionAssignmentsDialog = () => {
    this.setState({ applicantSolutionAssignmentsDialogOpen: false });
  };

  /**
   * Handles confirm for TAA assignment
   */
  handleConfirm = (data) => {
    const checkedData = data.filter(row => row.tableData.checked);
    this.clearSelectionTAAConfirm();
    this.setState({ confirmDialogOpen: false });
    for (let i = 0; i < checkedData.length; i++) {
      const applicant = checkedData[i];
      if (this.state.currentStartTimeMap[[applicant.user.id]]?.length === 3 && this.state.currentEndTimeMap[[applicant.user.id]]?.length === 3) {
        const solutionAssignment = {
          'solution_id': 1,
          'applicant_id': applicant.id,
          'user_id': applicant.user.id,
          'task_id': this.state.selectedTask.id,
          'starts_at': this.state.currentStartTimeMap[[applicant.user.id]][0],
          'ends_at': this.state.currentEndTimeMap[[applicant.user.id]][0],
          'back_up': !this.state.applicantLoadBackupToggle.get(applicant.id),
          'assignment_number': 7,
        };
        this.props.createSolutionAssignment(solutionAssignment);
        if (this.state.assigned[[this.state.selectedTask.id]]) {
          this.state.assigned[[this.state.selectedTask.id]] += 1;
        } else {
          this.state.assigned[[this.state.selectedTask.id]] = 1;
        }
        const adjustedTimeWindows = this.removeTimeFromApplicantAvailability({ starts_at: this.state.currentStartTimeMap[[applicant.user.id]][0], ends_at: this.state.currentEndTimeMap[[applicant.user.id]][0] }, this.state.applicants.get(applicant.id)?.time_windows);
        this.state.applicants.set(applicant.id, { ...applicant, time_windows: adjustedTimeWindows });
      }
    }
    this.setState({ assigneesData: [], assigneesDialogOpen: true });
  };

  handleUnassign = (solutionAssignment) => {
    this.props.deleteSolutionAssignment(solutionAssignment);
    this.addTimeIntoApplicantAvailability({ starts_at: solutionAssignment?.starts_at, ends_at: solutionAssignment?.ends_at }, solutionAssignment.applicant.id);
  };

  /**
   * Handles confirm button for ATA table assignments
   */
  handleConfirmApplicantTaskAssign = (data) => {
    this.clearSelectionATAConfirm();
    for (let i = 0; i < data.length; i++) {
      const task = data[i][0];
      const time = data[i][1];
      const solutionAssignment = {
        'solution_id': 1,
        'applicant_id': this.state.selectedApplicant.id,
        'user_id': this.state.selectedApplicant.user.id,
        'task_id': task.id,
        'starts_at': time?.starts_at,
        'ends_at': time?.ends_at,
        'back_up': !this.state.taskLoadBackupToggle.get(task.id),
        'assignment_number': 7,
      };
      this.props.createSolutionAssignment(solutionAssignment);
      if (this.state.assigned[[task.id]]) {
        this.state.assigned[[task.id]] += 1;
      } else {
        this.state.assigned[[task.id]] = 1;
      }
      const adjustedTimeWindows = this.removeTimeFromApplicantAvailability({ starts_at: time?.starts_at, ends_at: time?.ends_at }, this.state.applicants.get(this.state.selectedApplicant.id)?.time_windows);
      this.state.applicants.set(this.state.selectedApplicant.id, { ...this.state.selectedApplicant, time_windows: adjustedTimeWindows });
    }
    this.resetATAtoggles();
    this.clearSelectionATA();
    this.setState({ confirmApplicantTaskAssignDialogOpen: false });
    this.setState({ assignedTasksData: [], applicantTaskAssignDialogOpen: true, applicantTaskAssignMap: {} });
  };

  handleCancelConfirmDialog = () => {
    this.setState({ assigneesData: [] });
    this.setState({ confirmDialogOpen: false });
    this.setState({ assigneesDialogOpen: true });
  };

  /**
   * Handles the cancel button for the ATA assign dialog
   */
  handleCancelApplicantTaskAssignDialog = () => {
    this.setState({ assignClicked: false, cancelClicked: true });
    this.setState({ assignedTasksData: [] });
    this.setState({ confirmApplicantTaskAssignDialogOpen: false });
    this.setState({ applicantTaskAssignDialogOpen: true });
    this.setState({ applicantTaskAssignMap: {} });
  };

  /**
   * Handles a change of an applicant's start time
   * @param {string} id The id of the applicant whose start time for a task you would like to change
   * @param {*} newTime The new start time
   */
  handleStartTimeChange = (id, newTime) => {
    // For some reason, the month setter uses zero indexing, but the year and date do not. Fun.
    // Actual date is 1941-12-07. We have to set the correct date here because the keyboard picker chooses the current date
    // while the popup picker chooses the original date.
    if (newTime !== null) {
      let newTimeWithFixedDate = newTime.year(1941);
      newTimeWithFixedDate = newTimeWithFixedDate.month(11);
      newTimeWithFixedDate = newTimeWithFixedDate.date(7);
      this.setState(prevState => {
        const newTimeMap = { ...prevState.currentStartTimeMap };
        newTimeMap[[id]][0] = newTimeWithFixedDate.format();
        newTimeMap[[id]][2] = 0;
        return { currentStartTimeMap: newTimeMap };
      });
    } else {
      this.setState(prevState => {
        const newTimeMap = { ...prevState.currentStartTimeMap };
        newTimeMap[[id]][0] = newTime;
        newTimeMap[[id]][2] = 0;
        return { currentStartTimeMap: newTimeMap };
      });
    }
  };

  /**
   * Handles a change of an applicant's end time
   * @param {string} id The id of the applicant whose end time for a task you would like to change
   * @param {*} newTime The new end time
   */
  handleEndTimeChange = (id, newTime) => {
    // For some reason, the month setter uses zero indexing, but the year and date do not. Fun.
    // Actual date is 1941-12-07. We have to set the correct date here because the keyboard picker chooses the current date
    // while the popup picker chooses the original date.
    if (newTime !== null) {
      let newTimeWithFixedDate = newTime.year(1941);
      newTimeWithFixedDate = newTimeWithFixedDate.month(11);
      newTimeWithFixedDate = newTimeWithFixedDate.date(7);
      this.setState(prevState => {
        const newTimeMap = { ...prevState.currentEndTimeMap };
        newTimeMap[[id]][0] = newTimeWithFixedDate.format();
        newTimeMap[[id]][2] = 0;
        return { currentEndTimeMap: newTimeMap };
      });
    } else {
      this.setState(prevState => {
        const newTimeMap = { ...prevState.currentEndTimeMap };
        newTimeMap[[id]][0] = newTime;
        newTimeMap[[id]][2] = 0;
        return { currentEndTimeMap: newTimeMap };
      });
    }
  };

  findAdminTaskPreference = (task, applicant) => {
    const applicantStatusId = applicant.status.id;
    const taskPref = this.props.taskPreferences.find(
      tp => tp.task_id === task.id && tp.status_id === applicantStatusId
    );
    if (!taskPref) {
      return 0;
    }
    return taskPref.preference;
  };

  sortTasksComparator = (task1, task2) => {
    const applicant = this.state.selectedApplicant;

    // sort by time availability
    const task1InTime = taskTimeInApplicantTime(applicant, task1);
    const task2InTime = taskTimeInApplicantTime(applicant, task2);

    if (task1InTime !== task2InTime) {
      return task1InTime ? -1 : 1;
    }

    // sort by preference
    const preferences = applicant.task_preferences;
    const appTask1Pref = preferences.find(element => task1.name === element.task.name)?.preference || 0;
    const appTask2Pref = preferences.find(element => task2.name === element.task.name)?.preference || 0;

    if (appTask1Pref !== appTask2Pref) {
      return appTask1Pref > appTask2Pref ? -1 : 1;
    }

    // sort by admin preference
    const adminTask1Pref = this.findAdminTaskPreference(task1, applicant);
    const adminTask2Pref = this.findAdminTaskPreference(task2, applicant);

    if (adminTask1Pref !== adminTask2Pref) {
      return adminTask1Pref > adminTask2Pref ? -1 : 1;
    }

    // sort alphabetically
    return task1.name > task2.name ? -1 : 1;
  };

  /**
   * Helper function to check whether an applicant should be showed in TAA table with filter
   * @param {*} applicant The applicant to be potentially showed
   * @returns {boolean} Whether the applicant should be shown in the table
   */
  filterApplicantsTAAFilter = (applicant) => {
    const task = this.state.selectedTask;
    if (task) {
      const preferences = applicant?.task_preferences;
      const pref = preferences?.find(element => task.name === element.task.name)?.preference || 0;
      const prefMap = ['None', "Can't Do", 'Avoid', 'Acceptable', 'Preferred'];
      if (this.state.checkedPreferenceTypes[prefMap[pref]]) {
        return true;
      }
      return false;
    }
    return true;
  };

  /**
   * Helper function to check whether a task should be showed as part of the ATA preference filter
   * @param {*} task The task to be potentially showed
   * @returns {boolean} Whether the task should be shown in the table
   */
  filterTasksATAFilter = (task) => {
    const applicant = this.state.selectedApplicant;
    if (applicant) {
      const preferences = applicant.task_preferences;
      const pref = preferences.find(element => task.name === element.task.name)?.preference || 0;
      const prefMap = ['None', "Can't Do", 'Avoid', 'Acceptable', 'Preferred'];
      if (this.state.checkedPreferenceTypes[prefMap[pref]]) {
        return true;
      }
      return false;
    }
    return true;
  };

  updateTimeAvailabilityBasedOnSelection = (selectedRows) => {
    const oldSelection = this.state.oldApplicantTaskAssignSelection;
    if (selectedRows.length === this.tasksForCurrentYear.length) {
      this.setState({ allSelected: true });
      this.state.applicants[[this.state.selectedApplicant.id]] = [];
      return;
    }
    if (selectedRows.length > oldSelection.length) {
      // there has been a new selection
      selectedRows.forEach(task => {
        if (!oldSelection.includes(task)) {
          let adjustedTimeWindows;
          if (task.preemtable) {
            adjustedTimeWindows = this.removePreemptibleTimeFromApplicantAvailability(task, this.state.applicants.get(this.state.selectedApplicant.id)?.time_windows);
          } else {
            adjustedTimeWindows = this.removeTimeFromApplicantAvailability(task, this.state.applicants.get(this.state.selectedApplicant.id)?.time_windows);
          }
          this.state.applicants.set(this.state.selectedApplicant.id, { ...this.state.selectedApplicant, time_windows: adjustedTimeWindows });
        }
      });
    } else {
      // there has been a deselection
      this.setState({ allSelected: false });
      oldSelection.forEach(task => {
        if (!selectedRows.includes(task)) {
          if (task.preemtable) {
            this.addPreemptibleTimeIntoApplicantAvailability(task, this.state.selectedApplicant.id);
          } else {
            this.addTimeIntoApplicantAvailability(task, this.state.selectedApplicant.id);
          }
        }
      });
    }
    this.setState({ oldApplicantTaskAssignSelection: selectedRows });
  };

  openAssigneeDialog = (task) => {
    const applicants = this.applicantsForCurrentYear;
    for (let i = 0; i < applicants.length; i++) {
      const app = this.applicantMapForCurrentYear.get(applicants[i]?.id);
      this.state.applicants.set(applicants[i]?.id, app);
      this.state.currentStartTimeMap[[applicants[i].user.id]] = [task?.starts_at, task?.starts_at, 1];
      this.state.currentEndTimeMap[[applicants[i].user.id]] = [task?.ends_at, task?.ends_at, 1];
    }
    this.setState({ assigneesDialogOpen: true, selectedTask: task });
  };

  /**
   * Opens the ATA table with the selected applicant set to the applicant
   * @param {*} applicant
   */
  openApplicantTaskAssignDialog = (applicant) => {
    this.setState(prevState => ({ applicantTaskAssignDialogOpen: true, selectedApplicant: { ...applicant, time_windows: prevState.applicants.get(applicant.id).time_windows } }));
    // set applicant to have current time windows
    const app = this.applicantMapForCurrentYear.get(applicant?.id);
    this.state.applicants.set(applicant?.id, app);
  };

  openTaskSolutionAssignmentsDialog = (task) => {
    this.setState({ selectedTask: task, taskSolutionAssignmentsDialogOpen: true });
  };

  openApplicantSolutionAssignmentsDialog = (applicant) => {
    this.setState({ selectedApplicant: applicant, applicantSolutionAssignmentsDialogOpen: true });
  };

  openApplicantPrevTasksDialog = (applicant) => {
    if (this.assignmentsWithNestedUsers && applicant) {
      // Filter out this years assignments if any using task.year
      const allPrevAssign = this.assignmentsWithNestedUsers;
      const appPrevAssign = allPrevAssign.filter(assign => assign.user.id === applicant.user.id);
      const yearMap = new Map();
      appPrevAssign.forEach(assign => {
        if (yearMap.get(assign.task.year)) {
          yearMap.get(assign.task.year).push(assign.task.abbr.trim() || assign.task.name);
        } else {
          yearMap.set(assign.task.year, [assign.task.abbr.trim() || assign.task.name]);
        }
      });
      let taskString = '';
      const sortedMap = new Map([...yearMap].sort((a, b) => String(b[0]).localeCompare(a[0])));
      sortedMap.forEach((value, key) => {
        taskString += `${key}: ${value.join(', ')}\n`;
      });
      if (taskString === '') {
        taskString = 'N/A';
      }
      return <div className={styles['prev-tasks-tooltip']}>{taskString}</div>;
    }
    return (
      <div />
    );
  };

  handleDownloadSnackbar = () => {
    this.setState({
      snackbarOpen: true,
      snackbarVariant: 'info',
      snackbarMessage: 'Download in progress.',
    });
  };

  toggleApplicantPrevTasksATA = () => {
    this.setState(prevState => ({ applicantPrevTasksOpenATA: !prevState.applicantPrevTasksOpenATA }));
  };

  closeApplicantPrevTasksATA = () => {
    this.setState({ applicantPrevTasksOpenATA: false });
  };

  toggleApplicantPrevTasksTAA = (applicant) => {
    const { applicantPrevTasksOpenTAA } = this.state;
    applicantPrevTasksOpenTAA[`${applicant.user.id}`] = !applicantPrevTasksOpenTAA[`${applicant.user.id}`];
    this.setState({ applicantPrevTasksOpenTAA });
  };

  sortApplicantsComparator = (applicant1, applicant2) => {
    // Fit time of task
    const applicant1Available = taskTimeInApplicantTime(this.state.applicants.get(applicant1.id), this.state.selectedTask);
    const applicant2Available = taskTimeInApplicantTime(this.state.applicants.get(applicant2.id), this.state.selectedTask);
    if (applicant1Available !== applicant2Available) {
      return applicant1Available ? -1 : 1;
    }

    // Admin's Preference
    const applicant1Status = applicant1?.status?.name;
    const applicant2Status = applicant2?.status?.name;
    const isDifferentStatus = applicant1Status !== applicant2Status;

    if (isDifferentStatus) {
      const adminPreferenceApplicant1 = this.findAdminTaskPreference(this.state.selectedTask, applicant1);
      const adminPreferenceApplicant2 = this.findAdminTaskPreference(this.state.selectedTask, applicant2);

      if (adminPreferenceApplicant1 !== adminPreferenceApplicant2) {
        return adminPreferenceApplicant1 > adminPreferenceApplicant2 ? -1 : 1;
      }
    }

    // Volunteer Preference
    let applicant1Preference = applicant1?.task_preferences?.find(element => element.task.name === this.state.selectedTask.name)?.preference;
    let applicant2Preference = applicant2?.task_preferences?.find(element => element.task.name === this.state.selectedTask.name)?.preference;
    applicant1Preference = applicant1Preference || 0;
    applicant2Preference = applicant2Preference || 0;
    const isDifferentPreference = applicant1Preference !== applicant2Preference;

    if (isDifferentPreference) {
      return applicant1Preference > applicant2Preference ? -1 : 1;
    }

    // Name
    const applicant1LastName = applicant1.user?.last_name;
    const applicant2LastName = applicant2.user?.last_name;
    const isDifferentLastName = applicant1LastName !== applicant2LastName;

    if (isDifferentLastName) {
      return applicant1LastName < applicant2LastName ? -1 : 1;
    }

    const applicant1FirstName = applicant1.user?.first_name;
    const applicant2FirstName = applicant2.user?.first_name;

    return applicant1FirstName <= applicant2FirstName ? -1 : 1;
  };

  /**
   * Clears the checkbox selection of the assignee table
   */
  clearSelectionTAA = () => {
    if (this.tableRefTAA.current) {
      this.tableRefTAA.current.onAllSelected(false);
    }
  };

  /** Clears the selected rows from ATA table */
  clearSelectionATA = () => {
    if (this.tableRefATA.current) {
      this.tableRefATA.current.onAllSelected(false);
    }
  };

  /** Clears the selected rows from TAA confirm table */
  clearSelectionTAAConfirm = () => {
    if (this.tableRefTAAConfirm.current) {
      this.tableRefTAAConfirm.current.onAllSelected(false);
    }
  };

  /** Clears the selected rows from ATA confirm table */
  clearSelectionATAConfirm = () => {
    if (this.tableRefATAConfirm.current) {
      this.tableRefATAConfirm.current.onAllSelected(false);
    }
  };

  /**
   * Checks whether the time in the time picker for the TAA assign is inside the tasks's time
   * @param {*} assignee The assignee that will do the task
   * @param {*} task The task that the assignee will be doing
   * @returns {boolean} Whether the time the applicant is being assigned for is inside the task time
   */
  timeInsideTaskTimeWindow = (assignee, task) => {
    if (task && assignee && this.state.currentStartTimeMap[[assignee.user.id]] && this.state.currentEndTimeMap[[assignee.user.id]]) {
      const timeTokens = task?.ends_at.split(':');
      timeTokens[1] = (Number(timeTokens[1]) + 1) < 10 ? `0${Number(timeTokens[1]) + 1}` : `${Number(timeTokens[1]) + 1}`;
      timeTokens.join(':');
      return (dayjs(this.state.currentStartTimeMap[[assignee.user.id]][0]) >= dayjs(task?.starts_at)
      && dayjs(this.state.currentEndTimeMap[[assignee.user.id]][0]) <= dayjs(timeTokens.join(':')));
    }
    return false;
  };

  /**
   * Checks whether the assigned time for assignee in TAA table is inside their availability windows
   * @param {*} assignee The person being assigned the task
   * @returns Whether the time the assignee is being assined in TAA table is within their availability windows
   */
  timeInsideAvailableWindows = (assignee) => {
    let inside = false;
    for (let i = 0; i < assignee?.time_windows.length; i++) {
      const timeTokens = assignee.time_windows[i]?.ends_at.split(':');
      // parsing out the minute field in order to increment it by one
      // add a leading zero to single digit minute number
      // the minute is incremented in order to make sure the end time is not invalid due when it is equal to end of person's availability
      timeTokens[1] = (Number(timeTokens[1]) + 1) < 10 ? `0${Number(timeTokens[1]) + 1}` : `${Number(timeTokens[1]) + 1}`;
      timeTokens.join(':');
      inside = inside
        || (dayjs(this.state.currentStartTimeMap[[assignee.user.id]][0]) >= dayjs(assignee.time_windows[i]?.starts_at)
        && dayjs(this.state.currentEndTimeMap[[assignee.user.id]][0]) <= dayjs(timeTokens.join(':')));
    }
    return inside;
  };

  /**
   * Checks whether a time assignment for an assignee to a task in the TAA table is valid
   * @param {*} assignee The person being assigned to the task in TAA table
   * @param {*} task The task that people are being assigned to in TAA table
   * @returns Whether the time assigned to the assignee fits in the assignee and task windows
   */
  timeEntryInvalid = (assignee, task) => {
    if (this.state.currentStartTimeMap[[assignee?.user.id]] && this.state.currentEndTimeMap[[assignee?.user.id]]) {
      return dayjs(this.state.currentStartTimeMap[[assignee?.user.id]][0]) >= dayjs(this.state.currentEndTimeMap[[assignee?.user.id]][0])
      || !this.timeInsideAvailableWindows(assignee)
      || !this.timeInsideTaskTimeWindow(assignee, task);
    }
    return true;
  };

  /** Generates data for TAA table using the table's filters */
  generateTAADataWithFilter = () => {
    const applicants = this.state.TAAData;
    const filtApplicants = applicants.filter(applicant => this.filterApplicantsTAAFilter(applicant));
    const sortFiltApplicants = this.sortApplicants(filtApplicants);
    return sortFiltApplicants;
  };

  /** Generates data for ATA table using the table's filters */
  generateATADataWithFilter = () => {
    const tasks = this.state.ATAData;
    const filtTasks = tasks.filter(task => this.filterTasksATAFilter(task));
    const sortFiltTasks = this.sortTasks(filtTasks);
    return sortFiltTasks;
  };

  resetATAtoggles() {
    [...this.state.taskLoadBackupToggle.keys()].forEach(taskID => {
      this.state.taskLoadBackupToggle.set(taskID, true);
    });
  }

  resetTAAtoggles() {
    [...this.state.applicantLoadBackupToggle.keys()].forEach(applicantID => {
      this.state.applicantLoadBackupToggle.set(applicantID, true);
    });
  }

  /**
   * Removes time from an applicant's availability when the task is a preemptible. It finds the first overlap
   * in the applicant's availability and the task's duration
   * @param {*} task The task being assigned to the applicant the applicant
   * @param {*} applicantID The id of the applicant that the task is being assigned to
   * @param {[{starts_at: time, ends_at: time}]} timeWindows The time windows for the applicant
   */
  removePreemptibleTimeFromApplicantAvailability(task, timeWindows) {
    let newTimeWindows = [];
    timeWindows.sort((a, b) => (dayjs(a.starts_at) <= dayjs(b.starts_at) ? -1 : 1));
    timeWindows.find(timeWindow => {
      if (dayjs(timeWindow?.ends_at).isAfter(dayjs(task?.starts_at))) {
        if (dayjs(timeWindow?.ends_at).isBefore(dayjs(task?.ends_at)) || dayjs(timeWindow?.ends_at).isSame(dayjs(task?.ends_at))) {
          if (dayjs(timeWindow?.starts_at).isBefore(dayjs(task?.starts_at))) {
            newTimeWindows = this.removeTimeFromApplicantAvailability({ starts_at: task?.starts_at, ends_at: timeWindow?.ends_at }, timeWindows);
            this.setState(prevState => {
              const newMap = { ...prevState.applicantTaskAssignMap };
              newMap[task.id] = { time: { starts_at: task?.starts_at, ends_at: timeWindow?.ends_at } };
              return { applicantTaskAssignMap: newMap };
            });
            return true;
          }
          newTimeWindows = this.removeTimeFromApplicantAvailability({ starts_at: timeWindow?.starts_at, ends_at: timeWindow?.ends_at }, timeWindows);
          this.setState(prevState => {
            const newMap = { ...prevState.applicantTaskAssignMap };
            newMap[task.id] = { time: { starts_at: timeWindow?.starts_at, ends_at: timeWindow?.ends_at } };
            return { applicantTaskAssignMap: newMap };
          });
          return true;
        }
        if (dayjs(timeWindow?.starts_at).isBefore(dayjs(task?.ends_at))) {
          if (dayjs(timeWindow?.starts_at).isAfter(dayjs(task?.starts_at)) || dayjs(timeWindow?.starts_at).isSame(dayjs(task?.starts_at))) {
            newTimeWindows = this.removeTimeFromApplicantAvailability({ starts_at: timeWindow?.starts_at, ends_at: task?.ends_at }, timeWindows);
            this.setState(prevState => {
              const newMap = { ...prevState.applicantTaskAssignMap };
              newMap[task.id] = { time: { starts_at: timeWindow?.starts_at, ends_at: task?.ends_at } };
              return { applicantTaskAssignMap: newMap };
            });
            return true;
          }
          newTimeWindows = this.removeTimeFromApplicantAvailability({ starts_at: task?.starts_at, ends_at: task?.ends_at }, timeWindows);
          this.setState(prevState => {
            const newMap = { ...prevState.applicantTaskAssignMap };
            newMap[task.id] = { time: { starts_at: task?.starts_at, ends_at: task?.ends_at } };
            return { applicantTaskAssignMap: newMap };
          });
          return true;
        }
      }
      return false;
    });
    return newTimeWindows;
  }

  /**
   * removes time from timeWindows and returns a new list of time windows with this modification
   * @param {{starts_at: time, ends_at: time}} time
   * @param {[{starts_at: time, ends_at: time}]} timeWindows
   * @returns timeWindows with time removed
   */
  removeTimeFromApplicantAvailability(time, timeWindows) {
    const adjustedTimeWindows = [];
    if (!timeWindows) {
      return null;
    }

    timeWindows.sort((a, b) => (dayjs(a.starts_at) <= dayjs(b.starts_at) ? -1 : 1));
    timeWindows.forEach(timeWindow => {
      // availability window starts before time window starts
      if (dayjs(timeWindow?.starts_at).isBefore(dayjs(time?.starts_at))) {
        // availability window completely before time window
        if (dayjs(timeWindow?.ends_at).isBefore(dayjs(time?.starts_at))) {
          adjustedTimeWindows.push(timeWindow);
        }
        // availability window starts before time window but ends within time window
        if ((dayjs(timeWindow?.ends_at).isAfter(dayjs(time?.starts_at))) && ((dayjs(timeWindow?.ends_at).isBefore(dayjs(time?.ends_at))) || dayjs(timeWindow?.ends_at).isSame(time?.ends_at))) {
          adjustedTimeWindows.push({
            ends_at: time?.starts_at,
            id: timeWindow.id,
            starts_at: timeWindow?.starts_at,
            year: timeWindow.year,
          });
        }
        // availability window contains the entire time window
        if (dayjs(timeWindow?.ends_at).isAfter(dayjs(time?.ends_at))) {
          adjustedTimeWindows.push({
            ends_at: time?.starts_at,
            id: timeWindow.id,
            starts_at: timeWindow?.starts_at,
            year: timeWindow.year,
          });
          adjustedTimeWindows.push({
            ends_at: timeWindow?.ends_at,
            id: timeWindow.id,
            starts_at: time?.ends_at,
            year: timeWindow.year,
          });
        }
      }
      // availability window starts after time window starts
      if (dayjs(timeWindow?.starts_at).isAfter(dayjs(time?.starts_at))) {
        // availability window is completely contained within the time window
        if (dayjs(timeWindow?.ends_at).isBefore(dayjs(time?.ends_at)) || dayjs(timeWindow?.ends_at).isSame(dayjs(time?.ends_at))) {
          // we no longer need this time window
        }
        // availability window starts within time window and ends after time window ends
        if (dayjs(timeWindow?.starts_at).isBefore(dayjs(time?.ends_at)) && dayjs(timeWindow?.ends_at).isAfter(dayjs(time?.ends_at))) {
          adjustedTimeWindows.push({
            ends_at: timeWindow?.ends_at,
            id: timeWindow.id,
            starts_at: time?.ends_at,
            year: timeWindow.year,
          });
        }
        // availability window is completely after time window
        if (dayjs(timeWindow?.starts_at).isAfter(dayjs(time?.ends_at))) {
          adjustedTimeWindows.push(timeWindow);
        }
      }

      // availability window starts exactly when time window starts
      if (dayjs(timeWindow?.starts_at).isSame(dayjs(time?.starts_at))) {
        // availability window ends on or before time window ends: do nothing

        // availability window ends after time window ends
        if (dayjs(timeWindow?.ends_at).isAfter(dayjs(time?.ends_at))) {
          adjustedTimeWindows.push({
            ends_at: timeWindow?.ends_at,
            id: timeWindow.id,
            starts_at: time?.ends_at,
            year: timeWindow.year,
          });
        }
      }
    });
    return adjustedTimeWindows;
  }

  /**
   * Adds time back into applicant's availability when unassigned from a task
   * @param {{starts_at, ends_at}} newTimeWindow The time to be added into the applicant's availability
   * @param {number} applicantID The ID of the applicant whose time is being adjust
   */
  addTimeIntoApplicantAvailability(newTimeWindow, applicantID) {
    const adjustedTimeWindows = [];
    if (!this.state.applicants.get(applicantID)) {
      return;
    }
    if (!this.state.applicants.get(applicantID)?.time_windows) {
      this.state.applicants.set(applicantID, [newTimeWindow]);
    }

    /** Whether the newTimeWindow has been inserted into the applicant's availability yet */
    let inserted = false;
    this.state.applicants.get(applicantID).time_windows.sort((a, b) => (dayjs(a.starts_at) <= dayjs(b.starts_at) ? -1 : 1));
    this.state.applicants.get(applicantID).time_windows.forEach(timeWindow => {
      // applicant's time window starts before input window starts
      if (dayjs(timeWindow?.starts_at).isBefore(dayjs(newTimeWindow?.starts_at))) {
        // applicant's time window is completely before input window starts
        if (dayjs(timeWindow?.ends_at).isBefore(dayjs(newTimeWindow?.starts_at))) {
          adjustedTimeWindows.push(timeWindow);
        }

        // applicant's time window ends when input window starts
        if (dayjs(timeWindow?.ends_at).isSame(dayjs(newTimeWindow?.starts_at))) {
          adjustedTimeWindows.push({
            ends_at: newTimeWindow?.ends_at,
            id: timeWindow.id,
            starts_at: timeWindow?.starts_at,
            year: timeWindow.year,
          });
          inserted = true;
        }
      }

      // applicant's time window ends after input window ends
      if (dayjs(timeWindow?.ends_at).isAfter(dayjs(newTimeWindow?.ends_at))) {
        // applicant's time window starts when input window ends
        if (dayjs(timeWindow?.starts_at).isSame(dayjs(newTimeWindow?.ends_at))) {
          const prevTimeWindow = adjustedTimeWindows.pop();
          // applicant's time window should be previous availability + input window
          if (inserted && dayjs(prevTimeWindow?.ends_at).isSame(dayjs(timeWindow?.starts_at))) {
            adjustedTimeWindows.push({
              ends_at: timeWindow?.ends_at,
              id: timeWindow.id,
              starts_at: prevTimeWindow?.starts_at,
              year: timeWindow.year,
            });
          } else {
            if (prevTimeWindow) {
              adjustedTimeWindows.push(prevTimeWindow);
            }
            adjustedTimeWindows.push({
              ends_at: timeWindow?.ends_at,
              id: timeWindow.id,
              starts_at: newTimeWindow?.starts_at,
              year: timeWindow.year,
            });
            inserted = true;
          }
        }

        // applicant's time window is completely after input window
        if (dayjs(timeWindow?.starts_at).isAfter(dayjs(newTimeWindow?.ends_at))) {
          adjustedTimeWindows.push(timeWindow);
        }
      }
    });
    if (!inserted) {
      adjustedTimeWindows.push(newTimeWindow);
    }
    this.state.applicants.get(applicantID).time_windows = adjustedTimeWindows;
  }

  /**
   * Adds time back into applicant's availability when unselected in ATA table
   * @param {*} task The task the applicant was assigned to
   * @param {*} applicantID The id of the applicant that was assigned
   */
  addPreemptibleTimeIntoApplicantAvailability(task, applicantID) {
    this.addTimeIntoApplicantAvailability(this.state.applicantTaskAssignMap[task.id].time, applicantID);
    this.setState(prevState => {
      const old = { ...prevState.applicantTaskAssignMap };
      delete old[task.id];
      return { applicantTaskAssignMap: old };
    });
  }

  sortApplicants(applicants) {
    if (!this.state.selectedTask) {
      return applicants;
    }
    return applicants.slice().sort((a, b) => this.sortApplicantsComparator(a, b));
  }

  sortTasks(tasks) {
    if (!this.state.selectedApplicant) {
      return tasks;
    }
    return tasks.slice().sort((a, b) => this.sortTasksComparator(a, b));
  }

  renderAssigneeName = (applicant) => {
    if (!this.state.selectedTask) {
      return 'No task selected';
    }

    const task = this.state.selectedTask;
    const applicantName = `${applicant.user?.first_name.charAt(0)}. ${applicant.user?.last_name}`;

    let color = '';
    applicant.task_preferences?.forEach(preference => {
      if (preference.task_id === task.id) {
        if (preference.preference === 4) {
          // preferred task (green)
          color = '#009E60';
        } else if (preference.preference === 3) {
          // acceptable task (yellow)
          color = '#FFC300';
        } else if (preference.preference === 2) {
          // avoid task (red)
          color = '#EE4B2B';
        } else if (preference.preference === 1) {
          // cant do (gray)
          color = '#D8D8D8';
        }
      }
    });
    return (
      <p className={styles['nowrap']} style={{ color, margin: 0 }}>{applicantName}</p>
    );
  };

  /**
   * returns a list of assignee names and a button to edit their times which they are assigned to a task in confirm menu
   */
  renderAssigneeNameList() {
    const namesAndTimes = [];
    this.state.assigneesData.forEach((assignee) => {
      namesAndTimes.push(
        <li>
          <div className={styles['confirm-menu-time-button-list']}>
            <div>
              {`${assignee.user.first_name} ${assignee.user.last_name}`}
            </div>
            <div>
              <Button onClick={() => this.openEditTimeDialog(assignee)}>
                {`${formatTime(this.state.currentStartTimeMap[[assignee.user.id]][0])}-${formatTime(this.state.currentEndTimeMap[[assignee.user.id]][0])}`}
              </Button>
            </div>
          </div>
        </li>
      );
    });
    return (
      <ol>
        {namesAndTimes}
      </ol>
    );
  }

  /**
   * returns a list of task names and times which are assigned to the applicant in the confirm menu
   */
  renderApplicantTasksList() {
    const tasksAndTimes = [];
    this.state.assignedTasksData.forEach((task) => {
      tasksAndTimes.push(
        <li>
          <div className={styles['confirm-menu-time-button-list']}>
            <div>
              { `${task?.[0].abbr.trim() ? task?.[0].abbr : task?.[0].name}` }
            </div>
            <div>
              <Button onClick={() => this.openEditTimeDialogATA(task?.[0])}>
                {`${formatTime(task?.[1].starts_at)}-${formatTime(task?.[1].ends_at)}`}
              </Button>
            </div>
          </div>
        </li>
      );
    });
    return (
      <ol>
        {tasksAndTimes}
      </ol>
    );
  }

  renderTaskLB(task) {
    if (task) {
      const loadFilled = renderLoadBackup(task, this.props.solutionAssignments, false);
      const backupFilled = renderLoadBackup(task, this.props.solutionAssignments, true);
      return `${loadFilled} (${backupFilled})`;
    }
    return <></>;
  }

  /**
   * renders the year dropdown with a ReusableSelect component
   * @returns <ReusableSelect /> year dropdown
   */
  renderYearOptions = () => (
    <ReusableSelect
      id="year"
      label="Event Date"
      className={styles['year-options']}
      value={this.state.year}
      options={yearOptions(this.props.eventDates)}
      onChange={(event) => this.handleYearChange(event)}
      excludeNoneOption
      useDefaultVariant
    />
  );

  render() {
    return (
      <div className={styles['interactive-table-container']}>
        <TaskTable
          data={this.tasksForCurrentYear.filter(task => this.state.checkedTasks[task.id] && (this.state.beginningTaskTimeFilter && this.state.endingTaskTimeFilter ? this.taskInsideTimeFilter(task) : true))}
          tasksLoading={this.props.tasksLoading}
          jobSelected={this.state.jobSelected}
          getTasks={this.props.getTasks}
          downloadSolutionAssignmentsTasks={this.props.downloadSolutionAssignmentsTasks}
          solutionAssignments={this.props.solutionAssignments}
          openAssigneeDialog={this.openAssigneeDialog}
          handleOpenTaskFilter={this.handleOpenTaskFilter}
          handleDownloadSnackbar={this.handleDownloadSnackbar}
          openTaskSolutionAssignmentsDialog={this.openTaskSolutionAssignmentsDialog}
          renderYearOptions={this.renderYearOptions}
          year={this.state.year}
        />
        <ReusableSnackbar
          open={this.state.snackbarOpen}
          onClose={this.handleSnackbarClose}
          variant={this.state.snackbarVariant}
          message={this.state.snackbarMessage}
        />
        <ApplicantTable
          data={this.applicantsForCurrentYear.filter(applicant => this.state.checkedStatuses[applicant.status?.id] && (this.state.beginningApplicantTimeFilter && this.state.endingApplicantTimeFilter ? this.applicantInsideTimeFilter(applicant) : true))}
          applicantsLoading={this.props.applicantsLoading}
          getApplicants={this.props.getApplicants}
          downloadSolutionAssignmentsApplicants={this.props.downloadSolutionAssignmentsApplicants}
          solutionAssignments={this.props.solutionAssignments}
          handleOpenApplicantFilter={this.handleOpenApplicantFilter}
          openApplicantTaskAssignDialog={this.openApplicantTaskAssignDialog}
          handleDownloadSnackbar={this.handleDownloadSnackbar}
          openApplicantSolutionAssignmentsDialog={this.openApplicantSolutionAssignmentsDialog}
          applicants={this.applicantMapForCurrentYear}
          renderYearOptions={this.renderYearOptions}
        />
        <Dialog
          fullWidth
          maxWidth={false}
          classes={{ paperWidthFalse: `${styles['applicant-task-assign-dialog']}` }}
          open={this.state.applicantTaskAssignDialogOpen}
          onClose={this.handleCloseApplicantTaskAssignDialog}
        >
          <DialogTitle onClose={this.handleCloseApplicantTaskAssignDialog} className={styles['applicant-task-assign-dialog-title']} style={{ whiteSpace: 'pre-line' }}>
            <ToolTipTheme
              PopperProps={{
                disablePortal: true,
              }}
              open={this.state.applicantPrevTasksOpenATA}
              placement="right"
              disableFocusListener
              disableHoverListener
              disableTouchListener
              className={styles['nowrap']}
              title={(
                <>
                  {this.openApplicantPrevTasksDialog(this.state.selectedApplicant)}
                </>
              )}
            >
              <Button className={styles['name-button']} onClick={() => { this.toggleApplicantPrevTasksATA(); }}>
                {`${this.state.selectedApplicant?.user.first_name} ${this.state.selectedApplicant?.user.last_name}, ${this.state.selectedApplicant?.status.name} `}
              </Button>
            </ToolTipTheme>
            <div className={styles['assignment-dialog-title']}>{`${renderApplicantTimeWindows(this.state.applicants.get(this.state.selectedApplicant?.id), this.state.allSelected)}`}</div>
            <p className={styles['legend']}>
              <span className={styles['preferred-legend']}>Preferred </span>
              <span className={styles['acceptable-legend']}>Acceptable </span>
              <span className={styles['avoid-legend']}>Avoid </span>
              <span className={styles['cant-legend']}>Can&apos;t Do </span>
              No Preference
            </p>
          </DialogTitle>
          <div className={styles['task-applicant-container']}>
            <ApplicantTaskAssignTable
              data={this.generateATADataWithFilter()}
              tasksLoading={this.props.tasksLoading}
              selectedApplicant={this.state.applicants.get(this.state.selectedApplicant?.id)}
              oldApplicantTaskAssignSelection={this.state.oldApplicantTaskAssignSelection}
              handleApplicantTaskAssign={this.handleApplicantTaskAssign}
              handleATAtoggle={this.handleATAtoggle}
              taskLoadBackupToggle={this.state.taskLoadBackupToggle}
              solutionAssignments={this.props.solutionAssignments}
              year={this.state.year}
              updateTimeAvailabilityBasedOnSelection={this.updateTimeAvailabilityBasedOnSelection}
              tableRefATA={this.tableRefATA}
              handleOpenATAFilter={this.handleOpenATAFilter}
            />
            <ApplicantTaskUnassignTable
              data={this.solutionAssignmentsOfApplicant}
              tasks={this.props.tasks}
              applicantsLoading={this.props.applicantsLoading}
              handleUnassign={this.handleUnassign}
            />
          </div>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth={false}
          classes={{ paperWidthFalse: `${styles['task-applicant-assign-dialog']}` }}
          open={this.state.assigneesDialogOpen}
          onClose={this.handleCloseAssigneeDialog}
        >
          <DialogTitle onClose={this.handleCloseAssigneeDialog} className={styles['task-applicant-assign-dialog-title']} style={{ whiteSpace: 'pre-line' }}>
            <div>
              <div>
                <span className={styles['assignment-dialog-title']} style={{ backgroundColor: this.state.selectedTask?.background_color, color: this.state.selectedTask?.text_color }}>{this.state.selectedTask?.abbr.trim() ? `${this.state.selectedTask.abbr.trim()}\t\t${this.state.selectedTask.preemtable ? '(P)' : ''}\t\t${this.renderTaskLB(this.state.selectedTask)} ` : `${this.state.selectedTask?.name}\t${this.renderTaskLB(this.state.selectedTask)} `}</span>
              </div>
              <div className={styles['assignment-dialog-title']}>{`${formatTime(this.state.selectedTask?.starts_at)}-${formatTime(this.state.selectedTask?.ends_at)}`}</div>
              <p className={styles['legend']}>
                <span className={styles['preferred-legend']}>Preferred </span>
                <span className={styles['acceptable-legend']}>Acceptable </span>
                <span className={styles['avoid-legend']}>Avoid </span>
                <span className={styles['cant-legend']}>Can&apos;t Do </span>
                No Preference
              </p>
            </div>
          </DialogTitle>
          {/* Assign Applicants to Task Menu */}
          <div className={styles['task-applicant-container']}>
            <TaskApplicantAssignTable
              data={this.generateTAADataWithFilter()}
              applicantMap={this.state.applicants}
              applicantsLoading={this.props.applicantsLoading}
              selectedTask={this.state.selectedTask}
              year={this.state.year}
              applicantPrevTasksOpenTAA={this.state.applicantPrevTasksOpenTAA}
              openApplicantPrevTasksDialog={this.openApplicantPrevTasksDialog}
              toggleApplicantPrevTasksTAA={this.toggleApplicantPrevTasksTAA}
              openEditTimeDialog={this.openEditTimeDialog}
              tableRefTAA={this.tableRefTAA}
              currentStartTimeMap={this.state.currentStartTimeMap}
              currentEndTimeMap={this.state.currentEndTimeMap}
              handleAssign={this.handleAssign}
              handleTAAtoggle={this.handleTAAtoggle}
              applicantLoadBackupToggle={this.state.applicantLoadBackupToggle}
              handleOpenTAAFilter={this.handleOpenTAAFilter}
            />
            <TaskApplicantUnassignTable
              data={this.solutionAssignmentsOfTask}
              applicantsLoading={this.props.applicantsLoading}
              handleUnassign={this.handleUnassign}
            />
          </div>
        </Dialog>
        <Dialog open={this.state.confirmDialogOpen}
          onClose={this.handleCloseConfirmDialog}
          fullWidth
          maxWidth={false}
          classes={{ paperWidthFalse: `${styles['task-applicant-assign-confirm-dialog']}` }}
        >
          <DialogTitle onClose={this.handleCancelConfirmDialog}>
            <div className={styles['assignment-dialog-title']}>{`${this.state.selectedTask?.preemtable ? `${this.state.selectedTask?.abbr || this.state.selectedTask?.name} (P)` : this.state.selectedTask?.abbr || this.state.selectedTask?.name} ${formatTime(this.state.selectedTask?.starts_at)}-${formatTime(this.state.selectedTask?.ends_at)}`}</div>
          </DialogTitle>
          {/* add back button here */}
          <TAAConfirmTable
            data={this.state.assigneesData}
            currentStartTimeMap={this.state.currentStartTimeMap}
            currentEndTimeMap={this.state.currentEndTimeMap}
            selectedTask={this.state.selectedTask}
            handleStartTimeChange={this.handleStartTimeChange}
            handleEndTimeChange={this.handleEndTimeChange}
            handleConfirm={this.handleConfirm}
            tableRefTAAConfirm={this.tableRefTAAConfirm}
            applicantMap={this.state.applicants}
          />
        </Dialog>
        <Dialog open={this.state.taskSolutionAssignmentsDialogOpen} onClose={this.handleCloseTaskSolutionAssignmentsDialog}>
          <TaskApplicantUnassignTable
            data={this.solutionAssignmentsOfTask}
            applicantsLoading={this.props.applicantsLoading}
            handleUnassign={this.handleUnassign}
          />
        </Dialog>
        <Dialog open={this.state.applicantSolutionAssignmentsDialogOpen} onClose={this.handleCloseApplicantSolutionAssignmentsDialog}>
          <ApplicantTaskUnassignTable
            data={this.solutionAssignmentsOfApplicant}
            tasks={this.props.tasks}
            applicantsLoading={this.props.applicantsLoading}
            handleUnassign={this.handleUnassign}
          />
        </Dialog>
        <Dialog
          open={this.state.confirmApplicantTaskAssignDialogOpen}
          onClose={this.handleCloseConfirmApplicantTaskAssignDialog}
          fullWidth
          maxWidth={false}
          classes={{ paperWidthFalse: `${styles['task-applicant-assign-confirm-dialog']}` }}
        >
          <DialogTitle onClose={this.handleCancelApplicantTaskAssignDialog}>
            <div className={styles['assignment-dialog-title']}>
              {`${this.state.selectedApplicant?.user.first_name} ${this.state.selectedApplicant?.user.last_name} ${renderApplicantTimeWindows(this.state.applicants.get(this.state.selectedApplicant?.id), this.state.allSelected)}`}
            </div>
          </DialogTitle>
          {/* add back button here */}
          <ATAConfirmTable
            assignedTasksData={this.state.assignedTasksData}
            selectedApplicant={this.state.selectedApplicant}
            handleConfirm={this.handleConfirmApplicantTaskAssign}
            year={this.state.year}
            tableRefATAConfirm={this.tableRefATAConfirm}
          />
        </Dialog>

        <TaskTableFilterDialog
          taskFilterOpen={this.state.taskFilterOpen}
          checkedTasks={this.state.checkedTasks}
          handleSelectNoneTasks={this.handleSelectNoneTasks}
          handleSelectAllTasks={this.handleSelectAllTasks}
          activitiesLoading={this.props.activitiesLoading}
          activities={this.props.activities}
          tasks={this.props.tasks}
          handleTaskCheckbox={this.handleTaskCheckbox}
          handleClearTaskTimeFilter={this.handleClearTaskTimeFilter}
          beginningTaskTimeFilter={this.state.beginningTaskTimeFilter}
          handleBeginningTaskTimeChange={this.handleBeginningTaskTimeChange}
          endingTaskTimeFilter={this.state.endingTaskTimeFilter}
          handleEndingTaskTimeChange={this.handleEndingTaskTimeChange}
          handleCloseTaskFilter={this.handleCloseTaskFilter}
          year={this.state.year}
        />

        <ATATableFilterDialog
          filterOpen={this.state.ATAFilterOpen}
          checkedPreferenceTypes={this.state.checkedPreferenceTypes}
          preferenceTypes={[{ label: 'Preferred', color: '#009E60' }, { label: 'Acceptable', color: '#FFC300' }, { label: 'Avoid', color: '#EE4B2B' }, { label: "Can't Do", color: '#D8D8D8' }, { label: 'None', color: '#000000' }]}
          handleSelectNonePreferenceTypes={this.handleSelectNonePreferenceTypes}
          handleSelectAllPreferenceTypes={this.handleSelectAllPreferenceTypes}
          handlePreferenceTypeCheckbox={this.handlePreferenceTypeCheckbox}
          handleCloseFilter={this.handleCloseATAFilter}
        />

        <TAATableFilterDialog
          filterOpen={this.state.TAAFilterOpen}
          checkedPreferenceTypes={this.state.checkedPreferenceTypes}
          preferenceTypes={[{ label: 'Preferred', color: '#009E60' }, { label: 'Acceptable', color: '#FFC300' }, { label: 'Avoid', color: '#EE4B2B' }, { label: "Can't Do", color: '#D8D8D8' }, { label: 'None', color: '#000000' }]}
          handleSelectNonePreferenceTypes={this.handleSelectNonePreferenceTypes}
          handleSelectAllPreferenceTypes={this.handleSelectAllPreferenceTypes}
          handlePreferenceTypeCheckbox={this.handlePreferenceTypeCheckbox}
          handleCloseFilter={this.handleCloseTAAFilter}
        />

        <ApplicantTableFilterDialog
          applicantFilterOpen={this.state.applicantFilterOpen}
          checkedStatuses={this.state.checkedStatuses}
          handleSelectNoneStatuses={this.handleSelectNoneStatuses}
          handleSelectAllStatuses={this.handleSelectAllStatuses}
          statusesLoading={this.props.statusesLoading}
          statuses={this.props.statuses}
          handleStatusCheckbox={this.handleStatusCheckbox}
          handleClearApplicantTimeFilter={this.handleClearApplicantTimeFilter}
          beginningApplicantTimeFilter={this.state.beginningApplicantTimeFilter}
          handleBeginningApplicantTimeChange={this.handleBeginningApplicantTimeChange}
          endingApplicantTimeFilter={this.state.endingApplicantTimeFilter}
          handleEndingApplicantTimeChange={this.handleEndingApplicantTimeChange}
          handleCloseApplicantFilter={this.handleCloseApplicantFilter}
        />
      </div>
    );
  }
}

InteractiveAssignmentPage.propTypes = {
  applicantsLoading: PropTypes.bool.isRequired,
  tasksLoading: PropTypes.bool.isRequired,
  activitiesLoading: PropTypes.bool.isRequired,
  statusesLoading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  getApplicants: PropTypes.func.isRequired,
  getTasks: PropTypes.func.isRequired,
  getStatuses: PropTypes.func.isRequired,
  getActivities: PropTypes.func.isRequired,
  applicants: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  taskPreferences: PropTypes.array.isRequired,
  getTaskPreferences: PropTypes.func.isRequired,
  getSolutionAssignments: PropTypes.func.isRequired,
  downloadSolutionAssignmentsTasks: PropTypes.func.isRequired,
  downloadSolutionAssignmentsApplicants: PropTypes.func.isRequired,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  getYear: PropTypes.func.isRequired,
  solutionAssignments: PropTypes.any,
  createSolutionAssignment: PropTypes.func.isRequired,
  statuses: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  deleteSolutionAssignment: PropTypes.func.isRequired,
  assignments: PropTypes.array.isRequired,
  getAssignments: PropTypes.func.isRequired,
  eventDates: PropTypes.array.isRequired,
};

export default InteractiveAssignmentPage;
