import { connect } from 'react-redux';

import { selectYear, selectEmailSignature } from 'selectors/systemSettingsSelectors';

import {
  selectEmailTemplatesLoading,
  selectEmailTemplates,
  selectApplicantsSelected,
  selectRecruitersSelected,
} from 'selectors/emailsSelectors';

import {
  selectApplicants,
  selectApplicantsLoading,
} from 'selectors/applicantsSelectors';

import {
  selectRecruiters,
  selectRecruitersLoading,
} from 'selectors/recruitersSelectors';

import { getYear, getEmailSignature } from 'actions/thunks/systemSettings';

import { getRecruiters } from 'actions/thunks/recruiters';

import {
  createEmailMessage,
  getEmailTemplates,
  updateEmailTemplate,
  createEmailTemplate,
  deleteEmailTemplate,
  setApplicantsSelected,
  setRecruitersSelected,
} from 'actions/thunks/emails';

import { getApplicants } from 'actions/thunks/applicants';

import EmailsPage from 'components/pages/admin/EmailsPage';
import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';

const mapStateToProps = state => ({
  emailSignature: selectEmailSignature(state),
  year: selectYear(state),
  recruiters: selectRecruiters(state),
  recruitersLoading: selectRecruitersLoading(state),
  applicantsLoading: selectApplicantsLoading(state),
  applicants: selectApplicants(state),
  emailTemplatesLoading: selectEmailTemplatesLoading(state),
  emailTemplates: selectEmailTemplates(state),
  applicantsSelected: selectApplicantsSelected(state),
  recruitersSelected: selectRecruitersSelected(state),
  isAdmin: selectIsAdmin(state),
  isLoggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  getEmailSignature: () => dispatch(getEmailSignature()),
  getRecruiters: () => dispatch(getRecruiters()),
  getYear: () => dispatch(getYear()),
  getApplicants: () => dispatch(getApplicants()),
  getEmailTemplates: () => dispatch(getEmailTemplates()),
  deleteEmailTemplate: (oldTemplate) => dispatch(deleteEmailTemplate(oldTemplate)),
  updateEmailTemplate: (newTemplate) => dispatch(updateEmailTemplate(newTemplate)),
  createEmailTemplate: (newTemplate) => dispatch(createEmailTemplate(newTemplate)),
  createEmailMessage: (newEmailMessage) => dispatch(createEmailMessage(newEmailMessage)),
  setApplicantsSelected: (applicantsSelected) => dispatch(setApplicantsSelected(applicantsSelected)),
  setRecruitersSelected: (recruitersSelected) => dispatch(setRecruitersSelected(recruitersSelected)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailsPage);
