import dayjs from 'dayjs';

/* eslint-disable no-restricted-syntax */
export const applicantFields = [
  { value: 'name', display: 'Name' },
  { value: 'assignment', display: 'Task Assignment' },
  { value: 'email', display: 'Email' },
];

export const generalFields = [
  { value: 'mathdaydate', display: 'Math Day Date' },
];

export const recruiterFields = [
  { value: 'name', display: 'Name' },
  { value: 'recruiterReport', display: 'Recruiter Report' },
  { value: 'email', display: 'Email' },
];

const getApplicantName = (applicant) => `${applicant.user.first_name} ${applicant.user.last_name}`;

const getApplicantEmail = (applicant) => applicant.user.email;

const getApplicantAssignment = (applicant) => {
  const assignments = applicant.final_assignments.map((assignment) => {
    let location = '';
    if (assignment.location) {
      location = assignment.location.location_name;
    }
    const startsAt = dayjs(assignment.task.starts_at).format('h:mm A');
    const endsAt = dayjs(assignment.task.ends_at).format('h:mm A');
    const taskName = assignment.task.name;
    const desc = assignment.task.description ? assignment.task.description : '';
    const instructionsLink = assignment.task.instructions_link ? assignment.task.instructions_link : '';
    return `<p>Task: <b>${taskName}</b></p>
    <ul>
    <li>Time:&nbsp;${startsAt}-${endsAt}</li>
    <li>Location:&nbsp;${location}</li>
    <li>Description:&nbsp;${desc}</li>
    <li>More Instructions: ${instructionsLink}</li>
    </ul>`;
  });
  let text = `You have been assigned to ${applicant.final_assignments.length} `;
  if (applicant.final_assignments.length === 1) {
    text += 'task. <br/>';
  } else if (applicant.final_assignments.length > 1) {
    text += 'tasks. <br/>';
  }
  // eslint-disable-next-line no-return-assign
  assignments.forEach((assignment) => text += assignment);
  return text;
};

const getRecruiterName = (recruiter) => `${recruiter.first_name} ${recruiter.last_name}`;

const getRecruiterEmail = (recruiter) => `${recruiter.email}`;

const getRecruiterReport = (recruiter) => {
  const mathClasses = {};
  recruiter.recruited_applicants.forEach((applicant) => {
    if (mathClasses[applicant.math_class]) {
      mathClasses[applicant.math_class].push(applicant.name);
    } else {
      mathClasses[applicant.math_class] = [applicant.name];
    }
  });
  let report = '';
  for (const k of Object.keys(mathClasses)) {
    report += `${k}:<br/>`;
    for (const name of mathClasses[k]) {
      report += `&nbsp;&nbsp;&nbsp;&nbsp;${name}<br/>`;
    }
    report += '\n';
  }
  return report;
};

const getMathDayDate = () => 'November 15, 2019';

const applicantFieldsToReplacementFns = {
  name: getApplicantName,
  email: getApplicantEmail,
  assignment: getApplicantAssignment,
  mathdaydate: getMathDayDate,
};

const recruiterFieldsToReplacementFns = {
  name: getRecruiterName,
  email: getRecruiterEmail,
  recruiterReport: getRecruiterReport,
  mathdaydate: getMathDayDate,
};

const replaceInsertableFields = (body, person, personType) => {
  let text = body;
  let newField = '';
  while (text.indexOf('{') !== -1) {
    const init = text.indexOf('{');
    const fin = text.indexOf('}');
    const field = text.substr(init + 1, fin - init - 1);
    if (personType === 'recruiters') {
      if (recruiterFieldsToReplacementFns[field] !== undefined) {
        newField = recruiterFieldsToReplacementFns[field](person);
      } else {
        newField = field;
      }
    } else if (personType === 'applicants') {
      if (applicantFieldsToReplacementFns[field] !== undefined) {
        newField = applicantFieldsToReplacementFns[field](person);
      } else {
        newField = field;
      }
    }
    text = `${text.slice(0, init)}${newField}${text.slice(fin + 1)}`;
  }
  return text;
};

export const replaceApplicantWithEmail = (messages) => messages.map((message) => (
  {
    to: message.recipient.user.email, body: message.body, sent_from: message.sent_from, subject: message.subject,
  }));

export const replaceRecruiterWithEmail = (messages) => messages.map((message) => (
  {
    to: message.recipient.email, body: message.body, sent_from: message.sent_from, subject: message.subject,
  }));

export const replaceCustomWithEmail = (messages) => messages.map((message) => (
  {
    to: message.recipient, body: message.body, sent_from: message.sent_from, subject: message.subject,
  }));

export const generateCustomEmails = (emailList, emailFields) => emailList.map((email) => (
  {
    recipient: email, body: emailFields.body, subject: emailFields.subject, sent_from: emailFields.sent_from,
  }));

export const generateApplicantEmails = (applicants, emailFields) => {
  const personType = 'applicants';
  if (emailFields.body.indexOf('{') !== -1) {
    return applicants.map((applicant) => (
      {
        recipient: applicant, body: replaceInsertableFields(emailFields.body, applicant, personType), subject: emailFields.subject, sent_from: emailFields.sent_from,
      }
    ));
  }
  return applicants.map((applicant) => (
    {
      recipient: applicant, body: emailFields.body, subject: emailFields.subject, sent_from: emailFields.sent_from,
    }
  ));
};

export const generateRecruiterEmails = (recruiters, emailFields) => {
  const personType = 'recruiters';
  if (emailFields.body.indexOf('{') !== -1) {
    return recruiters.map((recruiter) => (
      {
        recipient: recruiter, body: replaceInsertableFields(emailFields.body, recruiter, personType), subject: emailFields.subject, sent_from: emailFields.sent_from,
      }
    ));
  }
  return recruiters.map((recruiter) => (
    {
      recipient: recruiter, body: emailFields.body, subject: emailFields.subject, sent_from: emailFields.sent_from,
    }
  ));
};
