import { createSelector } from 'reselect';

export const selectTaskPreferencesLoading = createSelector(
  (state) => state.getIn(['taskPreferences', 'loading']),
  (loading) => loading,
);

export const selectTaskPreferences = createSelector(
  (state) => state.getIn(['taskPreferences', 'data']),
  (data) => data.toJS(),
);
