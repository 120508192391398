import React from 'react';
import PropTypes from 'prop-types';

import ReusableSelect from 'components/common/ReusableSelect';
import ReusableTextField from 'components/common/ReusableTextField';
import styles from './style.module.scss';

class SurveyStep extends React.Component {
  get surveyOptions() {
    return [
      { display: 'Excellent', value: 'Excellent' },
      { display: 'Good', value: 'Good' },
      { display: 'Fair', value: 'Fair' },
      { display: 'Poor', value: 'Poor' },
    ];
  }

  render() {
    return (
      <div className={styles['survey-container']}>
        <div>
          Please rate each of the following questions. This survey is optional and anonymous.
        </div>
        <div className={styles['survey-row']}>
          <u style={{ marginRight: '20px' }}>
            <b>
              Navigation
            </b>
          </u>
          <ReusableSelect
            id="surveyNavigationEasy"
            label="Ease of Use"
            value={this.props.navigationEasy}
            options={this.surveyOptions}
            onChange={this.props.handleChange('surveyNavigationEasy')}
            className={styles['survey-select']}
          />
          <ReusableSelect
            id="surveyNavigationFlexible"
            label="Flexibility"
            value={this.props.navigationFlexible}
            options={this.surveyOptions}
            onChange={this.props.handleChange('surveyNavigationFlexible')}
            className={styles['survey-select']}
          />
          <ReusableSelect
            id="surveyNavigationRobust"
            label="Robustness"
            value={this.props.navigationRobust}
            options={this.surveyOptions}
            onChange={this.props.handleChange('surveyNavigationRobust')}
            className={styles['survey-select']}
          />
        </div>
        <div className={styles['survey-row']}>
          <u style={{ marginRight: '23px' }}>
            <b>
              Data&nbsp;entry
            </b>
          </u>
          <ReusableSelect
            id="surveyDataEntryEasy"
            label="Ease of Use"
            value={this.props.dataEntryEasy}
            options={this.surveyOptions}
            onChange={this.props.handleChange('surveyDataEntryEasy')}
            className={styles['survey-select']}
          />
          <ReusableSelect
            id="surveyDataEntryFlexible"
            label="Flexibility"
            value={this.props.dataEntryFlexible}
            options={this.surveyOptions}
            onChange={this.props.handleChange('surveyDataEntryFlexible')}
            className={styles['survey-select']}
          />
          <ReusableSelect
            id="surveyDataEntryRobust"
            label="Robustness"
            value={this.props.dataEntryRobust}
            options={this.surveyOptions}
            onChange={this.props.handleChange('surveyDataEntryRobust')}
            className={styles['survey-select']}
          />
        </div>
        <div className={styles['survey-row']}>
          <div>
            Would you prefer to fill this form online or on paper?
          </div>
          <ReusableSelect
            id="surveyFormOnlinePaper"
            value={this.props.formOnlinePaper}
            options={[{ display: 'Online', value: false }, { display: 'Paper', value: true }]}
            onChange={this.props.handleChange('surveyFormOnlinePaper')}
            className={styles['survey-single-select']}
          />
        </div>
        <div className={styles['survey-row']}>
          <div>
            Were there any unnecessary questions? If yes, please clarify in the comments.
          </div>
          <ReusableSelect
            id="surveySuperfluousQuestions"
            value={this.props.superfluousQuestions}
            options={[{ display: 'Yes', value: true }, { display: 'No', value: false }]}
            onChange={this.props.handleChange('surveySuperfluousQuestions')}
            className={styles['survey-single-select']}
          />
        </div>
        <div className={styles['survey-row']}>
          <div>
            Were there any questions we missed? If yes, please clarify in the comments.
          </div>
          <ReusableSelect
            id="surveyMissedQuestions"
            value={this.props.missedQuestions}
            options={[{ display: 'Yes', value: true }, { display: 'No', value: false }]}
            onChange={this.props.handleChange('surveyMissedQuestions')}
            className={styles['survey-single-select']}
          />
        </div>
        <ReusableTextField
          id="surveyComments"
          label="Any other comments?"
          multiline
          rows="4"
          value={this.props.comments}
          onChange={this.props.handleChange('surveyComments')}
        />
      </div>
    );
  }
}

SurveyStep.propTypes = {
  navigationEasy: PropTypes.string,
  navigationFlexible: PropTypes.string,
  navigationRobust: PropTypes.string,
  dataEntryEasy: PropTypes.string,
  dataEntryFlexible: PropTypes.string,
  dataEntryRobust: PropTypes.string,
  formOnlinePaper: PropTypes.bool,
  superfluousQuestions: PropTypes.bool,
  missedQuestions: PropTypes.bool,
  comments: PropTypes.string,
  handleChange: PropTypes.func,
};

export default SurveyStep;
