import React from 'react';
import PropTypes from 'prop-types';

import ReusableTable from 'components/common/ReusableTable';
import Collapsible from 'react-collapsible';
import history from 'wrappers/history';
import styles from './style.module.scss';

class EmailLogPage extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }

    this.state = {
      columns: [
        {
          title: 'Sent At',
          field: 'sent_at',
          defaultGroupOrder: 0,
          defaultGroupSort: 'desc',
        },
        {
          title: 'To',
          field: 'to',
        },
        {
          title: 'Sent From',
          field: 'sent_from',
        },
        {
          title: 'Subject',
          field: 'subject',
        },
        {
          title: 'Body',
          field: 'body',
          render: (emailMessage) => (
            <Collapsible trigger={<div className={styles['trigger']}>View Full Text</div>}><div className={styles['body']} dangerouslySetInnerHTML={{ __html: emailMessage.body }} /></Collapsible>
          ),
        },
      ],
      options: {
        grouping: true,
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 600,
        selection: false,
        hideDeleteButton: true,
      },
      editable: {
        isEditable: () => false,
        isDeletable: () => false,
      },
      actions: [
        {
          icon: 'replay',
          tooltip: 'Refetch Data',
          isFreeAction: true,
          onClick: () => this.props.getEmailMessages(),
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.emailMessages.length === 0) {
      this.props.getEmailMessages();
    }
  }

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['table-container']}>
              <ReusableTable
                title="Email Log"
                columns={this.state.columns}
                data={this.props.emailMessages}
                options={this.state.options}
                editable={this.state.editable}
                isLoading={this.props.loading}
                actions={this.state.actions}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EmailLogPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  emailMessages: PropTypes.array.isRequired,
  getEmailMessages: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default EmailLogPage;
