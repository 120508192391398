export const isFieldEmpty = (field) => (
  field === '' || field === undefined || field === null
);

export const fields = {
  announcementAuthor: {
    regExp: /^.+$/,
    errorMessage: 'Author is empty.\n',
  },
  announcementMessage: {
    regExp: /^.+$/m,
    errorMessage: 'Message is empty.\n',
  },
  affiliationName: {
    regExp: /^.+$/,
    errorMessage: 'Name is empty\n',
  },
  className: {
    regExp: /^.+$/,
    errorMessage: 'Name is empty.\n',
  },
  statusName: {
    regExp: /^.+$/,
    errorMessage: 'Name is empty.\n',
  },
  statusAbbr: {
    regExp: /^.+$/,
    errorMessage: 'Abbr is empty.\n',
  },
  lastName: {
    regExp: /^.+$/,
    errorMessage: 'Last name is empty.\n',
  },
  firstName: {
    regExp: /^.+$/,
    errorMessage: 'First name is empty.\n',
  },
  email: {
    regExp: /\S+@\S+\.\S+/,
    errorMessage: 'Email is in an invalid format or is empty.\n',
  },
  phoneNumber: {
    regExp: /\(\d\d\d\) \d\d\d-\d\d\d\d/,
    errorMessage: 'Phone is in an invalid format or is empty.\n',
  },
};
