/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';

import ReusableTable from 'components/common/ReusableTable';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableSelect from 'components/common/ReusableSelect';
import ReusableTextField from 'components/common/ReusableTextField';
import TimePopup from 'components/common/TimePopup';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import dayjs from 'dayjs';
import history from 'wrappers/history';
import sortBy from 'lodash/sortBy';
import styles from './style.module.scss';
import { yearOptions } from '../AdminUtils.ts';

class AssignmentsPage extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }

    this.state = {
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: '',
      columns: [
        {
          title: 'Name',
          field: 'applicant',
          tooltip: 'Name of the volunteer',
          customSort: (a, b) => (a.applicant.user.last_name.localeCompare(b.applicant.user.last_name) !== 0
            ? a.applicant.user.last_name.localeCompare(b.applicant.user.last_name)
            : a.applicant.user.first_name.localeCompare(b.applicant.user.first_name)),
          customFilterAndSearch: (term, rowData) => (rowData.applicant.user.first_name.concat(' ', rowData.applicant.user.last_name).toLowerCase().indexOf(term.toLowerCase()) !== -1),
          render: (rowData) => rowData.applicant.user.first_name.concat(' ', rowData.applicant.user.last_name),
          defaultSort: 'asc',
          editComponent: applicant => (
            <Autocomplete
              options={sortBy(this.applicantsForCurrentYear, 'user.last_name', 'user.first_name')}
              filterOptions={(options, state) => options.filter(option => option.user.first_name.concat(' ', option.user.last_name).toLowerCase().indexOf(state.inputValue.toLowerCase()) !== -1)}
              getOptionLabel={option => (option.user ? option.user.first_name.concat(' ', option.user.last_name) : '')}
              value={applicant.value ? applicant.value : ''}
              style={{ width: 250 }}
              onChange={(event, value) => applicant.onChange(value)}
              renderInput={params => (
                <TextField {...params} placeholder="Applicant" variant="outlined" className={styles['input-field']} fullWidth />
              )}
            />
          ),
        },
        {
          title: 'Task',
          field: 'task',
          customSort: (a, b) => a.task.name.localeCompare(b.task.name),
          customFilterAndSearch: (term, rowData) => rowData.task.name.toLowerCase().indexOf(term.toLowerCase()) !== -1,
          render: (rowData) => (<div className={styles['nowrap']}>{rowData.task ? rowData.task.abbr : ''}</div>),
          editComponent: task => (
            <Autocomplete
              id="task"
              value={task.value ? task.value : ''}
              filterOptions={(options, state) => options.filter(option => option.name.toLowerCase().indexOf(state.inputValue) !== -1)}
              options={sortBy(this.tasksForCurrentYear, 'name')}
              getOptionLabel={option => option.name}
              style={{ width: 350 }}
              onChange={(event, value) => {
                const data = { ...task.rowData };
                data.task = value;
                if (value) {
                  data.startTime = dayjs(value.starts_at);
                  data.endTime = dayjs(value.ends_at);
                }
                task.onRowDataChange(data);
              }}
              renderInput={params => (
                <TextField {...params} placeholder="Task" variant="outlined" className={styles['input-field']} fullWidth />
              )}
            />
          ),
          tooltip: 'Name of the task',
        },
        {
          title: 'Status',
          field: 'status.abbr',
          editable: 'never',
          tooltip: 'Status of the volunteer',
        },
        {
          title: 'Time',
          field: 'startTime',
          tooltip: 'Time of the assignment (sorts by start time)',
          render: (rowData) => <div className={styles['nowrap']}>{dayjs(rowData.startTime).format('h:mm A').concat(' - ', rowData.endTime.format('h:mm A'))}</div>,
          customSort: (a, b) => this.dateCompare(a.startTime, b.startTime),
          customFilterAndSearch: (term, rowData) => dayjs(rowData.startTime).format('h:mm A').concat(' - ', rowData.endTime.format('h:mm A')).indexOf(term.toLowerCase()) !== -1,
          editComponent: time => (
            <div className={styles['div-row']}>
              <div className={styles['item']}>
                <TimePopup
                  className={styles['time-popup']}
                  id="starts"
                  label="Starts"
                  time={time ? time.rowData.startTime : ''}
                  handleTimeChange={value => {
                    const data = { ...time.rowData };
                    data.startTime = dayjs(value);
                    time.onRowDataChange(data);
                  }}
                />
              </div>
              <div className={styles['item']}>
                <TimePopup
                  className={styles['time-popup']}
                  id="ends"
                  label="Ends"
                  time={time ? time.rowData.endTime : ''}
                  handleTimeChange={value => {
                    const data = { ...time.rowData };
                    data.endTime = dayjs(value);
                    time.onRowDataChange(data);
                  }}
                />
              </div>
            </div>
          ),
        },
        {
          title: 'Location',
          field: 'location',
          // eslint-disable-next-line no-nested-ternary
          customSort: (a, b) => (a.location ? (b.location ? a.location.location_name.localeCompare(b.location.location_name) : -1) : 1),
          customFilterAndSearch: (term, rowData) => (rowData.location ? rowData.location.location_name.toLowerCase().indexOf(term.toLowerCase()) !== -1 : false),
          render: (rowData) => (<div className={styles['nowrap']}>{rowData.location ? rowData.location.location_name : ''}</div>),
          tooltip: 'Location of the assignment',
          editComponent: location => (
            <Autocomplete
              id="location"
              value={location.value ? location.value : ''}
              options={sortBy(this.props.locations, 'location_name')}
              getOptionLabel={option => option.location_name}
              onChange={(event, value) => location.onChange(value)}
              style={{ width: 250 }}
              renderInput={params => (
                <TextField {...params} variant="outlined" className={styles['input-field']} fullWidth />
              )}
            />
          ),
        },
        {
          title: 'Comments',
          field: 'comments',
          tooltip: 'Comments for this assignment',
          editComponent: comments => (
            <ReusableTextField
              id="comments"
              multiline
              value={comments.value}
              onChange={e => comments.onChange(e.target.value)}
              label="Comments"
            />
          ),
        },
      ],
      options: {
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 600,
        selection: true,
        hideDeleteButton: true,
      },
      editable: {
        isEditable: () => true,
        isDeletable: () => false,
        onRowAdd: (newAssignment) => new Promise((resolve, reject) => {
          if (this.validateAssignment(newAssignment)) {
            const payload = {
              applicant_id: newAssignment.applicant.id,
              task_id: newAssignment.task.id,
              starts_at: newAssignment.startTime,
              ends_at: newAssignment.endTime,
              comments: newAssignment.comments,
            };
            if (newAssignment.location) {
              payload.location_id = newAssignment.location.id;
            }
            this.createAssignment(payload);
            this.setState({
              snackbarVariant: 'success',
              snackbarMessage: 'Assignment successfully created.',
              snackbarOpen: true,
            });
            resolve();
          } else {
            this.setState({
              snackbarVariant: 'error',
              snackbarOpen: true,
            });
            reject();
          }
        }),
        onRowUpdate: (updatedAssignment) => new Promise((resolve, reject) => {
          if (this.validateAssignment(updatedAssignment)) {
            const payload = {
              id: updatedAssignment.id,
              applicant_id: updatedAssignment.applicant.id,
              task_id: updatedAssignment.task.id,
              starts_at: updatedAssignment.startTime,
              ends_at: updatedAssignment.endTime,
              comments: updatedAssignment.comments,
            };
            if (updatedAssignment.location) {
              payload.location_id = updatedAssignment.location.id;
            }
            this.updateAssignment(payload);
            resolve();
          } else {
            this.setState({
              snackbarVariant: 'error',
              snackbarOpen: true,
            });
            reject();
          }
        }),
        onRowDelete: () => new Promise((resolve) => {
          resolve();
        }),
      },
      actions: [
        {
          icon: 'replay',
          tooltip: 'Refetch Data',
          isFreeAction: true,
          onClick: () => this.refetch(),
        },
        {
          icon: 'save_alt',
          tooltip: 'Export',
          isFreeAction: true,
          onClick: () => {
            this.props.downloadAssignments(this.state.year);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'info',
              snackbarMessage: 'Download in progress.',
            });
          },
        },
        {
          icon: 'delete',
          tooltip: 'Delete',
          onClick: (event, rowData) => this.deleteAssignments(rowData),
        },
        {
          icon: 'email',
          tooltip: 'Email Selected Applicants',
          displayDuringSelect: true,
          onClick: (event, data) => this.handleEmailApplicants(data),
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.year === '') {
      this.props.getYear();
    }
    if (this.props.eventDates.length === 0) {
      this.props.getEventDates();
    }
    if (this.props.assignments.length === 0) {
      this.props.getAssignments();
    }
    if (this.props.applicants.length === 0) {
      this.props.getApplicants();
    }
    if (this.props.tasks.length === 0) {
      this.props.getTasks();
    }
    if (this.props.locations.length === 0) {
      this.props.getLocations();
    }
  }

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  handleTimeChange = time => (value) => {
    time.onChange(value);
  };

  handleEmailApplicants = (assignments) => {
    const applicantsSelected = {};
    assignments.forEach((assignment) => applicantsSelected[assignment.applicant.id] = true);
    this.props.setApplicantsSelected(applicantsSelected);
    history.push('/admin/email');
  };

  handleYearChange(event) {
    this.setState({ year: event.target.value });
  }

  get assignmentsWithNestedUsers() {
    const newAssignments = [];
    this.assignmentsForCurrentYear.forEach(assignment => {
      const newAssignment = assignment;
      if (assignment.applicant) {
        newAssignment.startTime = dayjs(assignment.starts_at);
        newAssignment.endTime = dayjs(assignment.ends_at);
        newAssignment.applicant.user = assignment.user;
        newAssignments.push(newAssignment);
      }
    });
    return newAssignments;
  }

  get assignmentsForCurrentYear() {
    if (!this.state) {
      return [];
    }
    return this.props.assignments.filter(assignment => assignment.task.year === this.state.year);
  }

  get applicantsForCurrentYear() {
    return this.props.applicants.filter(applicant => applicant.year === this.state.year);
  }

  get tasksForCurrentYear() {
    return this.props.tasks.filter(task => task.year === this.state.year);
  }

  validateAssignment = (payload) => {
    let validated = true;
    let errors = '';
    if (!payload.applicant) {
      validated = false;
      errors += 'Please choose an applicant.\n';
    }
    if (!payload.task) {
      validated = false;
      errors += 'Please choose a task.\n';
    }
    if (!payload.startTime) {
      validated = false;
      errors += 'Please choose a start time\n';
    }
    if (!payload.endTime) {
      validated = false;
      errors += 'Please choose an end time\n';
    }
    if (!validated) {
      this.setState({
        snackbarMessage: errors,
      });
    }
    return validated;
  };

  createAssignment = (newData) => {
    this.props.createAssignment(newData);
    this.setState({
      snackbarOpen: true,
      snackbarMessage: 'Assignment has been successfully created',
    });
  };

  updateAssignment = (newData) => {
    this.props.updateAssignment(newData);
    this.setState({
      snackbarOpen: true,
      snackbarVariant: 'success',
      snackbarMessage: 'Assignment has been successfully updated',
    });
  };

  deleteAssignments = (oldAssignments) => {
    oldAssignments.forEach(assignment => this.props.deleteAssignment(assignment));
  };

  dateCompare(a, b) {
    if (a.hour() < b.hour()) {
      return -1;
    }
    if (a.hour() === b.hour()) {
      if (a.minute() <= b.minute()) {
        return -1;
      }
      return 1;
    }
    return 1;
  }

  refetch() {
    this.props.getAssignments();
  }

  renderYearOptions = () => (
    <ReusableSelect
      id="year"
      label="Event Date"
      className={styles['year-options']}
      value={this.state.year}
      options={yearOptions(this.props.eventDates)}
      onChange={(event) => this.handleYearChange(event)}
      excludeNoneOption
      useDefaultVariant
    />
  );

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['table-container']}>
              <ReusableTable
                title="Assignments"
                columns={this.state.columns}
                data={this.assignmentsWithNestedUsers}
                options={this.state.options}
                editable={this.state.editable}
                isLoading={this.props.loading}
                actions={this.state.actions}
                renderYearOptions={this.renderYearOptions}
              />
              <ReusableSnackbar
                open={this.state.snackbarOpen}
                onClose={this.handleSnackbarClose}
                variant={this.state.snackbarVariant}
                message={this.state.snackbarMessage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AssignmentsPage.propTypes = {
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  assignmentsWithNestedUsers: PropTypes.array.isRequired,
  assignments: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getAssignments: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  eventDates: PropTypes.array.isRequired,
  getYear: PropTypes.func.isRequired,
  getEventDates: PropTypes.func.isRequired,
  getApplicants: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  applicants: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  getTasks: PropTypes.func.isRequired,
  tasks: PropTypes.array.isRequired,
  createAssignment: PropTypes.func.isRequired,
  updateAssignment: PropTypes.func.isRequired,
  deleteAssignment: PropTypes.func.isRequired,
  setApplicantsSelected: PropTypes.func.isRequired,
  downloadAssignments: PropTypes.func.isRequired,
};

export default AssignmentsPage;
