import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

import ReusableTable from 'components/common/ReusableTable';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { formatDate } from 'utils/date';
import DatePopup from 'components/common/DatePopup';
import history from 'wrappers/history';
import dayjs from 'dayjs';
import styles from './style.module.scss';

class EventDatesPage extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }

    this.state = {
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: '',
      columns: [
        {
          title: 'Event Date',
          field: 'held_on',
          type: 'date',
          defaultSort: 'desc',
          editComponent: row => <DatePopup value={row.value} onChange={row.onChange} />,
          render: (eventDate) => formatDate(eventDate.held_on),
        },
        {
          title: 'Soft Lock',
          field: 'soft_lock',
          type: 'boolean',
          tooltip: 'If selected, the manager will receive an email if a volunteer edits their application.',
        },
        {
          title: 'Open',
          field: 'application_open',
          type: 'date',
          tooltip: 'Volunteers can apply for the event starting from open date.',
          editComponent: row => <DatePopup value={row.value} onChange={row.onChange} />,
          render: (eventDate) => formatDate(eventDate.application_open),
        },
        {
          title: 'Close',
          field: 'hard_lock',
          type: 'date',
          tooltip: 'After the close data, volunteers will be blocked from editing their applications.',
          editComponent: row => <DatePopup value={row.value} onChange={row.onChange} />,
          render: (eventDate) => formatDate(eventDate.hard_lock),
        },
        {
          title: 'Status',
          tooltip: 'Whether the applications are open or not.',
          editable: 'never',
          render: (eventDate) => this.applicationStatus(eventDate),
        },
      ],
      options: {
        filtering: false,
        padding: 'dense',
        maxBodyHeight: 600,
        selection: false,
        hideDeleteButton: true,
      },
      editable: {
        isEditable: () => true,
        isDeletable: () => false,
        // It's not possible for users to enter invalid input so we don't
        // validate the fields of new or updated event dates.
        onRowAdd: (newEventDate) => new Promise((resolve, reject) => {
          // This prevents an empty object from being sent to the server.
          const today = dayjs();
          const defaultEventDate = {
            'held_on': today,
            'application_open': today,
            'hard_lock': today,
            'soft_lock': false,
          };
          newEventDate = { ...defaultEventDate, ...newEventDate };
          // convert datetime object to strings
          newEventDate.held_on = newEventDate.held_on.format('YYYY-MM-DD');
          newEventDate.application_open = newEventDate.application_open.format('YYYY-MM-DD');
          newEventDate.hard_lock = newEventDate.hard_lock.format('YYYY-MM-DD');
          // set hidden field event_id
          newEventDate.event_id = parseInt(moment(newEventDate.held_on, 'YYYY-MM-DD').format('YYYYMMDD'), 10);
          if (this.checkNoDuplicateEventDate(newEventDate)) {
            this.props.createEventDate(newEventDate);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'success',
              snackbarMessage: 'Event date successfully added.',
            });
            resolve();
            setTimeout(() => {
              // Force reload the whole page because we have updated lots of things on the database
              window.location.reload();
            }, 1000);
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: 'Duplicate event date detected.',
            });
            reject();
          }
        }),
        onRowUpdate: (updatedEventDate) => new Promise((resolve, reject) => {
          if (this.checkNoDuplicateEventDate(updatedEventDate)) {
            this.props.updateEventDate(updatedEventDate);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'success',
              snackbarMessage: 'Event date successfully updated.',
            });
            resolve();
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: 'Duplicate event date detected.',
            });
            reject();
          }
        }),
        onRowDelete: () => new Promise((resolve) => {
          resolve();
        }),
      },
      actions: [
        {
          icon: 'replay',
          tooltip: 'Refetch Data',
          isFreeAction: true,
          onClick: () => this.props.getEventDates(),
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.eventDates.length === 0) {
      this.props.getEventDates();
    }
  }

  applicationStatus = (eventDate) => {
    // The status is blank when adding a row.
    if (!eventDate) {
      return null;
    }

    const openDate = eventDate.application_open;
    const closeDate = eventDate.hard_lock;
    const today = dayjs().format('YYYY-MM-DD');

    return today >= openDate && closeDate > today
      ? (
        <div className={`${styles['open-status']} ${styles['status']}`}>
          <LockOpenIcon className={styles['icon']} />
          Open
        </div>
      )
      : (
        <div className={`${styles['close-status']} ${styles['status']}`}>
          <LockIcon className={styles['icon']} />
          Closed
        </div>
      );
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  checkNoDuplicateEventDate(newEventDate) {
    const sameYear = this.props.eventDates.filter(eventDate => eventDate.held_on === newEventDate.held_on && eventDate.id !== newEventDate.id);
    if (sameYear.length === 0) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['table-container']}>
              <ReusableTable
                title="Event Dates"
                columns={this.state.columns}
                data={this.props.eventDates}
                options={this.state.options}
                editable={this.state.editable}
                isLoading={this.props.loading}
                actions={this.state.actions}
              />
              <ReusableSnackbar
                open={this.state.snackbarOpen}
                onClose={this.handleSnackbarClose}
                variant={this.state.snackbarVariant}
                message={this.state.snackbarMessage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EventDatesPage.propTypes = {
  eventDates: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getEventDates: PropTypes.func.isRequired,
  createEventDate: PropTypes.func.isRequired,
  updateEventDate: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default EventDatesPage;
