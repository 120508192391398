import { connect } from 'react-redux';

import { selectApplicantsLoading, selectApplicants } from 'selectors/applicantsSelectors';
import { getApplicants } from 'actions/thunks/applicants';
import { selectClassesLoading, selectClasses } from 'selectors/classesSelectors';
import {
  getClasses,
  updateClass,
  createClass,
  downloadClasses,
} from 'actions/thunks/classes';
import ClassesPage from 'components/pages/admin/ClassesPage';
import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';
import { getYear } from 'actions/thunks/systemSettings';
import { getEventDates } from 'actions/thunks/eventDates';
import { selectYear } from 'selectors/systemSettingsSelectors';
import { selectEventDates } from 'selectors/eventDatesSelectors';

const mapStateToProps = state => ({
  classes: selectClasses(state),
  classesLoading: selectClassesLoading(state),
  isAdmin: selectIsAdmin(state),
  isLoggedIn: selectIsLoggedIn(state),
  year: selectYear(state),
  eventDates: selectEventDates(state),
  applicantsLoading: selectApplicantsLoading(state),
  applicants: selectApplicants(state),
});

const mapDispatchToProps = (dispatch) => ({
  getClasses: () => dispatch(getClasses()),
  updateClass: (newClass) => dispatch(updateClass(newClass)),
  createClass: (newClass) => dispatch(createClass(newClass)),
  downloadClasses: () => dispatch(downloadClasses()),
  getApplicants: () => dispatch(getApplicants()),
  getYear: () => dispatch(getYear()),
  getEventDates: () => dispatch(getEventDates()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClassesPage);
