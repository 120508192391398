import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import InfoIcon from '@material-ui/icons/Info';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import ReusableSelect from 'components/common/ReusableSelect';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableButton from 'components/common/ReusableButton';
import ReusableTextField from 'components/common/ReusableTextField';
import ReusableTooltip from 'components/common/ReusableTooltip';
import ReusableColorPicker from 'components/common/ReusableColorPicker';
import styles from './style.module.scss';

class TaskEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activity: {},
      name: '',
      abbr: '',
      displayString: '',
      load: undefined,
      backupLoad: undefined,
      description: '',
      preemtable: false,
      startsAt: null,
      endsAt: null,
      backgroundColor: '',
      textColor: '',
      visibleTo: [],
      capableTo: [],
      instructionsLink: '',
      mode: '',
      snackbarOpen: false,
      snackbarVariant: 'error',
      snackbarMessage: '',
    };
  }

  componentDidMount() {
    const { props } = this;
    if (props.mode === 'create') {
      this.setState({
        activity: {},
        name: '',
        abbr: '',
        displayString: '',
        load: '',
        backupLoad: '',
        description: '',
        preemtable: false,
        startsAt: null,
        endsAt: null,
        backgroundColor: '',
        textColor: '',
        visibleTo: [],
        capableTo: [],
        instructionsLink: '',
        mode: props.mode,
      });
    } else {
      this.setState({
        activity: props.activity,
        name: props.task.name,
        abbr: props.task.abbr,
        displayString: props.task.display_string,
        load: props.task.load,
        backupLoad: props.task.backup_load,
        description: props.task.description,
        preemtable: props.task.preemtable,
        startsAt: props.task.starts_at,
        endsAt: props.task.ends_at,
        backgroundColor: props.task.background_color,
        textColor: props.task.text_color,
        visibleTo: props.task.visible_to,
        capableTo: props.task.capable_to,
        instructionsLink: props.task.instructions_link,
        mode: props.mode,
      });
    }
  }

  /**
   * handles the time change with a reformatted time
   * @param {*} event the onChange event
   * @param {string} name the name of the state that is being changed
   */
  onTimeChange(event, name) {
    dayjs.extend(customParseFormat);
    const formattedTime = dayjs(event.target.value, 'HH:mm');
    this.handleTimeChange(name, formattedTime);
  }

  /**
   * changes the state value with the specified name to the new formatted time
   * @param {string} name the name of the state field being changed
   * @param {time} newTime the new formatted time that goes in the time input field
   */
  handleTimeChange = (name, newTime) => {
    // For some reason, the month setter uses zero indexing, but the year and date do not. Fun.
    // Actual date is 1941-12-07. We have to set the correct date here because the keyboard picker chooses the current date
    // while the popup picker chooses the original date.
    if (newTime !== null) {
      let newTimeWithFixedDate = newTime.year(1941);
      newTimeWithFixedDate = newTimeWithFixedDate.month(11);
      newTimeWithFixedDate = newTimeWithFixedDate.date(7);
      this.setState({ [name]: newTimeWithFixedDate });
    } else {
      this.setState({ [name]: newTime });
    }
  };

  get timeEntryInvalid() {
    return this.state.startsAt >= this.state.endsAt;
  }

  get onMathDayYear() {
    return this.props.year === this.props.currentMathDayYear;
  }

  get generateActivities() {
    const activitiesForYear = this.props.activities.filter(
      (activity) => activity.year === this.props.year
    );
    return activitiesForYear.map((activity) => ({
      value: activity,
      display: activity.name,
    }));
  }

  get visibleToIds() {
    return this.state.visibleTo.map((status) => status.id);
  }

  get capableToIds() {
    return this.state.capableTo.map((status) => status.id);
  }

  validateTask = () => {
    let validated = true;
    let errors = '';
    if (!this.state.name) {
      validated = false;
      errors += 'Please enter a name for the task.\n';
    }
    if (!this.state.abbr) {
      validated = false;
      errors += 'Please enter an abbreviation for the task.\n';
    }
    if (!this.state.description) {
      this.setState({ description: ' ' });
    }
    if (!this.state.backupLoad) {
      validated = false;
      errors += 'Please enter a backup load.\n';
    }
    if (!this.state.load) {
      validated = false;
      errors += 'Please enter a load.\n';
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(this.state.backupLoad)) {
      validated = false;
      errors += 'Please enter a numerical value for the backup load.\n';
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(this.state.load)) {
      validated = false;
      errors += 'Please enter a numerical value for the load.\n';
    }
    // Check if activity has been selected from the drop-down
    if (Object.keys(this.state.activity).length === 0) {
      validated = false;
      errors += 'Please select an activity.\n';
    }
    // Check if starting time has been defined
    if (this.state.startsAt === null) {
      validated = false;
      errors += 'Please input a start time.\n';
    }
    // Check if ending time has been defined
    if (this.state.endsAt === null) {
      validated = false;
      errors += 'Please input an end time.\n';
    }
    // Check if ending time is before starting time
    if (this.state.endsAt < this.state.startsAt) {
      validated = false;
      errors += 'Please make sure the end time is after the start time.\n';
    }

    if (!validated) {
      this.setState({
        snackbarMessage: errors,
      });
    }
    return validated;
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarMessage: '',
      snackbarOpen: false,
    });
  };

  handleChange = (name) => (event, value) => {
    if (name === 'startsAt' || name === 'endsAt') {
      this.setState({
        [name]: event,
      });
    } else if (name === 'textColor') {
      this.setState({
        textColor: value === 'black' ? '#000000' : '#ffffff',
      });
    } else if (name === 'visibleTo' || name === 'capableTo') {
      this.setState({
        [name]: value.map((v) => Object({ id: v })),
      });
    } else if (name === 'backgroundColor') {
      this.setState({
        backgroundColor: event.hex,
      });
    } else if (name === 'preemtable') {
      this.setState({
        preemtable: event.target.checked,
      });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleDeleteButtonClicked = () => {
    this.props.handleDeleteTask(this.props.task);
  };

  handleCreateButtonClicked = () => {
    if (this.validateTask()) {
      const payload = {
        activity_id: this.state.activity.id,
        name: this.state.name,
        abbr: this.state.abbr ? this.state.abbr : this.state.name,
        display_string: this.state.displayString
          ? this.state.displayString
          : this.state.name,
        load: this.state.load,
        backup_load: this.state.backupLoad,
        description: this.state.description,
        preemtable: this.state.preemtable,
        starts_at: this.state.startsAt,
        ends_at: this.state.endsAt,
        background_color: this.state.backgroundColor,
        text_color: this.state.textColor,
        visible_to: this.state.visibleTo,
        capable_to: this.state.capableTo,
        instructions_link: this.state.instructionsLink,
        year: this.props.year,
      };
      this.props.handleCreateTask(payload);
    } else {
      this.setState({
        snackbarVariant: 'error',
        snackbarOpen: true,
      });
    }
  };

  handleUpdateButtonClicked = () => {
    if (this.validateTask()) {
      const payload = {
        id: this.props.task.id,
        activity_id: this.state.activity.id,
        name: this.state.name,
        abbr: this.state.abbr,
        display_string: this.state.displayString,
        load: this.state.load,
        backup_load: this.state.backupLoad,
        description: this.state.description,
        preemtable: this.state.preemtable,
        starts_at: this.state.startsAt,
        ends_at: this.state.endsAt,
        background_color: this.state.backgroundColor,
        text_color: this.state.textColor,
        visible_to: this.state.visibleTo,
        capable_to: this.state.capableTo,
        instructions_link: this.state.instructionsLink,
      };
      this.props.handleUpdateTask(payload);
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarVariant: 'error',
      });
    }
  };

  render() {
    return (
      <div>
        <div className={styles['bottom-border']}>
          <Typography variant="h7">
            {this.props.mode === 'create' ? 'Create ' : 'Edit '}
            Task
          </Typography>
        </div>
        <div>
          <div className={styles['column']}>
            <ReusableSelect
              id="activity"
              label="Activity the task belongs to"
              value={this.state.activity}
              options={this.generateActivities}
              onChange={this.handleChange('activity')}
            />
            <div className={`${styles['row']} ${styles['field-container']}`}>
              <div
                className={`${styles['bordered-grouping']} ${styles['column']}`}
              >
                <ReusableTextField
                  id="name"
                  label="Name"
                  required
                  value={this.state.name}
                  className={`${styles['name-fields']} ${styles['has-spacing']}`}
                  onChange={this.handleChange('name')}
                />
                <ReusableTextField
                  id="abbr"
                  label="Abbr"
                  required
                  value={this.state.abbr}
                  className={`${styles['name-fields']} ${styles['has-spacing']}`}
                  onChange={this.handleChange('abbr')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <ReusableTooltip title="What the manager sees when browsing data">
                          <InfoIcon fontSize="small" />
                        </ReusableTooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                <ReusableTextField
                  id="displayString"
                  label="Volunteer sees"
                  value={this.state.displayString}
                  className={`${styles['name-fields']} ${styles['has-spacing']}`}
                  onChange={this.handleChange('displayString')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <ReusableTooltip title="What volunteer sees">
                          <InfoIcon fontSize="small" />
                        </ReusableTooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div
                className={`${styles['bordered-grouping']} ${styles['column']}`}
              >
                <div className={styles['row']}>
                  <ReusableTextField
                    id="load"
                    label="Load"
                    required
                    value={`${this.state.load}`}
                    className={`${styles['load']} ${styles['has-spacing']}`}
                    onChange={this.handleChange('load')}
                  />
                  <ReusableTextField
                    id="backup_load"
                    label="Backup"
                    required
                    value={this.state.backupLoad}
                    className={`${styles['load']} ${styles['has-spacing']}`}
                    onChange={this.handleChange('backupLoad')}
                  />
                </div>
                <div className={styles['row']}>
                  <div>
                    <div>Start</div>
                    <input
                      type="time"
                      id="startsAt"
                      min="06:00"
                      max="18:00"
                      value={dayjs(this.state.startsAt)?.hour() ? dayjs(this.state.startsAt).format('HH:mm') : undefined}
                      onChange={event => this.onTimeChange(event, 'startsAt')}
                      required
                      className={styles['has-spacing']}
                    />
                  </div>
                  <div>
                    <div>End</div>
                    <input
                      type="time"
                      id="endsAt"
                      min="06:00"
                      max="18:00"
                      value={dayjs(this.state.endsAt)?.hour() ? dayjs(this.state.endsAt).format('HH:mm') : undefined}
                      onChange={event => this.onTimeChange(event, 'endsAt')}
                      required
                    />
                  </div>
                </div>
                <div className={styles['row']}>
                  <FormControlLabel
                    id="preemtable"
                    label="Preemptible"
                    className={styles['preemtable-checkbox']}
                    control={<Checkbox color="primary" />}
                    value={this.state.preemtable}
                    checked={this.state.preemtable}
                    onChange={this.handleChange('preemtable')}
                    labelPlacement="end"
                  />
                </div>
              </div>
              <div
                className={`${styles['bordered-grouping']} ${styles['column']}`}
              >
                <Typography
                  className={styles['text-color-label']}
                  variant="caption"
                >
                  Text Color
                </Typography>
                <ToggleButtonGroup
                  id="text_color"
                  label="Text Color"
                  className={styles['text-color-buttons']}
                  size="small"
                  value={this.state.textColor === '#ffffff' ? 'white' : 'black'}
                  onChange={this.handleChange('textColor')}
                  exclusive
                >
                  <ToggleButton value="black">
                    Black
                  </ToggleButton>
                  <ToggleButton value="white">
                    White
                  </ToggleButton>
                </ToggleButtonGroup>
                <div style={{ paddingTop: '20px' }}>
                  <Typography
                    className={styles['background-color-label']}
                    variant="caption"
                  >
                    Color
                  </Typography>
                  <ReusableColorPicker
                    className={styles['color-picker']}
                    color={this.state.backgroundColor}
                    onChange={this.handleChange('backgroundColor')}
                  />
                </div>
              </div>
            </div>
            <ReusableTextField
              id="description"
              multiline
              required
              label="Description"
              onChange={this.handleChange('description')}
              value={this.state.description}
            />
            <ReusableTextField
              id="instructionsLink"
              label="Link to Instructions"
              onChange={this.handleChange('instructionsLink')}
              value={this.state.instructionsLink}
            />
          </div>
          <div className={styles['toggle-button-group']}>
            <Typography variant="body2">Capable to</Typography>
            <ToggleButtonGroup
              id="capableTo"
              label="capableTo"
              size="small"
              value={this.capableToIds}
              onChange={this.handleChange('capableTo')}
            >
              {this.props.statuses
                .filter((status) => status.approved)
                .map((status) => (
                  <ToggleButton
                    className={
                      this.capableToIds.includes(status.id)
                        ? `${styles['selected']} ${styles['toggle-button']}`
                        : `${styles['toggle-button']}`
                    }
                    value={status.id}
                  >
                    {status.abbr}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
          </div>
          <div className={styles['toggle-button-group']}>
            <Typography variant="body2">Visible to</Typography>
            <ToggleButtonGroup
              className={styles['has-spacing']}
              id="visibleTo"
              size="small"
              value={this.visibleToIds}
              onChange={this.handleChange('visibleTo')}
            >
              {this.props.statuses
                .filter((status) => status.approved)
                .map((status) => (
                  <ToggleButton
                    className={
                      this.visibleToIds.includes(status.id)
                        ? `${styles['toggle-button']} ${styles['selected']}`
                        : `${styles['toggle-button']}`
                    }
                    value={status.id}
                  >
                    {status.abbr}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
          </div>
          <div className={styles['row']}>
            <ReusableButton
              onClick={
                this.state.mode === 'create'
                  ? this.handleCreateButtonClicked
                  : this.handleUpdateButtonClicked
              }
              value={
                this.state.mode === 'create' ? 'Create Task' : 'Update Task'
              }
              className={styles['has-spacing']}
              hasTopMargin
            />
            <ReusableButton
              onClick={this.handleDeleteButtonClicked}
              value="Delete Task"
              hasTopMargin
            />
          </div>
          <ReusableSnackbar
            open={this.state.snackbarOpen}
            onClose={this.handleSnackbarClose}
            variant={this.state.snackbarVariant}
            message={this.state.snackbarMessage}
          />
        </div>
      </div>
    );
  }
}

TaskEditor.propTypes = {
  mode: PropTypes.oneOf(['create', 'edit']),
  statuses: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  task: PropTypes.object.isRequired,
  activity: PropTypes.object.isRequired,
  year: PropTypes.number.isRequired,
  currentMathDayYear: PropTypes.number.isRequired,
  handleUpdateTask: PropTypes.func.isRequired,
  handleCreateTask: PropTypes.func.isRequired,
  handleDeleteTask: PropTypes.func.isRequired,
};

export default TaskEditor;
