export const GET_ASSIGNMENTS_SENT = 'GET_ASSIGNMENTS_SENT';
export const GET_ASSIGNMENTS_SUCCESSFUL = 'GET_ASSIGNMENTS_SUCCESSFUL';

export const GET_LOCATIONS_SENT = 'GET_LOCATIONS_SENT';
export const GET_LOCATIONS_SUCCESSFUL = 'GET_LOCATIONS_SUCCESSFUL';

export const UPDATE_ASSIGNMENT_SENT = 'UPDATE_ASSIGNMENT_SENT';
export const UPDATE_ASSIGNMENT_SUCCESSFUL = 'UPDATE_ASSIGNMENT_SUCCESSFUL';

export const CREATE_ASSIGNMENT_SENT = 'CREATE_ASSIGNMENT_SENT';
export const CREATE_ASSIGNMENT_SUCCESSFUL = 'CREATE_ASSIGNMENT_SUCCESSFUL';

export const DELETE_ASSIGNMENT_SENT = 'DELETE_ASSIGNMENT_SENT';
export const DELETE_ASSIGNMENT_SUCCESSFUL = 'DELETE_ASSIGNMENT_SUCCESSFUL';

export const getLocationsRequestSent = () => ({ type: GET_LOCATIONS_SENT });

export const getLocationsRequestSuccessful = (data) => ({ type: GET_LOCATIONS_SUCCESSFUL, data });

export const getAssignmentsRequestSent = () => ({ type: GET_ASSIGNMENTS_SENT });

export const getAssignmentsRequestSuccessful = (data) => ({ type: GET_ASSIGNMENTS_SUCCESSFUL, data });

export const updateAssignmentRequestSent = () => ({ type: UPDATE_ASSIGNMENT_SENT });

export const updateAssignmentRequestSuccessful = (data) => ({ type: UPDATE_ASSIGNMENT_SUCCESSFUL, data });

export const createAssignmentRequestSent = () => ({ type: CREATE_ASSIGNMENT_SENT });

export const createAssignmentRequestSuccessful = (data) => ({ type: CREATE_ASSIGNMENT_SUCCESSFUL, data });

export const deleteAssignmentRequestSent = (data) => ({ type: DELETE_ASSIGNMENT_SENT, data });

export const deleteAssignmentRequestSuccessful = (data) => ({ type: DELETE_ASSIGNMENT_SUCCESSFUL, data });
