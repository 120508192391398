import { fromJS } from 'immutable';

import {
  LOGIN_REQUEST_SENT,
  LOGIN_REQUEST_SUCCESSFUL,
  LOGIN_REQUEST_FAILED,
  CLEAR_FAILED_LOGIN,
  VALIDATE_LOGIN_REQUEST_SUCCESSFUL,
  CLEAR_LOGIN_INFO,
  UPDATE_CURRENT_USER,
} from 'actions/atomic/authentication';

const initialState = fromJS({
  isLoggedIn: false,
  isAdmin: false,
  loginValidationComplete: false,
  loginRequestFailed: false,
  currentUser: null,
});

export default function authentication(state = initialState, action) {
  switch (action.type) {
  case LOGIN_REQUEST_SENT:
    return state;
  case LOGIN_REQUEST_SUCCESSFUL:
    return state.merge({
      isLoggedIn: true,
      isAdmin: action.data.isAdmin,
      currentUser: action.data.currentUser,
    });
  case LOGIN_REQUEST_FAILED:
    return state.merge({
      isLoggedIn: false,
      isAdmin: false,
      loginRequestFailed: true,
      currentUser: null,
    });
  case CLEAR_FAILED_LOGIN:
    return state.merge({
      loginRequestFailed: false,
    });
  case CLEAR_LOGIN_INFO:
    return state.merge({
      isLoggedIn: false,
      isAdmin: false,
      currentUser: null,
    });
  case VALIDATE_LOGIN_REQUEST_SUCCESSFUL:
    return state.merge({
      isLoggedIn: action.data.isLoggedIn,
      isAdmin: action.data.isAdmin,
      loginValidationComplete: true,
      currentUser: action.data.currentUser,
    });
  case UPDATE_CURRENT_USER:
    return state.merge({
      currentUser: action.data.currentUser,
    });
  default:
    return state;
  }
}
