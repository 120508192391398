import { connect } from 'react-redux';

import { selectLoading, selectAssignments, selectLocations } from 'selectors/assignmentsSelectors';
import AssignmentsPage from 'components/pages/admin/AssignmentsPage';
import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';
import { setApplicantsSelected } from 'actions/thunks/emails';
import { getYear } from 'actions/thunks/systemSettings';
import { getEventDates } from 'actions/thunks/eventDates';
import { selectYear } from 'selectors/systemSettingsSelectors';
import { selectEventDates } from 'selectors/eventDatesSelectors';
import { getApplicants } from 'actions/thunks/applicants';
import { selectApplicants } from 'selectors/applicantsSelectors';
import { selectTasks } from 'selectors/activitiesSelectors';
import { getTasks } from 'actions/thunks/activities';
import {
  getAssignments,
  createAssignment,
  updateAssignment,
  deleteAssignment,
  getLocations,
  downloadAssignments,
} from 'actions/thunks/assignmentByUsers';

const mapStateToProps = state => ({
  loading: selectLoading(state),
  assignments: selectAssignments(state),
  isAdmin: selectIsAdmin(state),
  isLoggedIn: selectIsLoggedIn(state),
  year: selectYear(state),
  eventDates: selectEventDates(state),
  applicants: selectApplicants(state),
  tasks: selectTasks(state),
  locations: selectLocations(state),
});

const mapDispatchToProps = dispatch => ({
  createAssignment: (newAssignment) => dispatch(createAssignment(newAssignment)),
  getAssignments: () => dispatch(getAssignments()),
  getYear: () => dispatch(getYear()),
  getEventDates: () => dispatch(getEventDates()),
  getApplicants: () => dispatch(getApplicants()),
  getTasks: () => dispatch(getTasks()),
  getLocations: () => dispatch(getLocations()),
  deleteAssignment: (oldAssignment) => dispatch(deleteAssignment(oldAssignment)),
  updateAssignment: (newAssignment) => dispatch(updateAssignment(newAssignment)),
  setApplicantsSelected: (applicantsSelected) => dispatch(setApplicantsSelected(applicantsSelected)),
  downloadAssignments: (year) => dispatch(downloadAssignments(year)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignmentsPage);
