import { createSelector } from 'reselect';

export const selectLoading = createSelector(
  (state) => state.getIn(['users', 'loading']),
  (loading) => loading,
);

export const selectUsers = createSelector(
  (state) => state.getIn(['users', 'data']),
  (data) => data.toJS(),
);

export const selectGenderOptions = createSelector(
  (state) => state.getIn(['users', 'genderOptions']),
  (data) => data.toJS(),
);

export const selectCreateUserRequestComplete = createSelector(
  (state) => state.getIn(['users', 'createUserRequestComplete']),
  (createUserRequestComplete) => createUserRequestComplete,
);

export const selectCreationSuccessful = createSelector(
  (state) => state.getIn(['users', 'creationSuccessful']),
  (creationSuccessful) => creationSuccessful,
);

export const selectUserExists = createSelector(
  (state) => state.getIn(['users', 'userExists']),
  (userExists) => userExists,
);

export const selectActivated = createSelector(
  (state) => state.getIn(['users', 'activated']),
  (activated) => activated,
);

export const selectAccountActivated = createSelector(
  (state) => state.getIn(['users', 'accountActivated']),
  (accountActivated) => accountActivated,
);

export const selectExpiredActivationToken = createSelector(
  (state) => state.getIn(['users', 'expiredActivationToken']),
  (expiredActivationToken) => expiredActivationToken,
);

export const selectInvalidActivationToken = createSelector(
  (state) => state.getIn(['users', 'invalidActivationToken']),
  (invalidActivationToken) => invalidActivationToken,
);

export const selectInvalidUser = createSelector(
  (state) => state.getIn(['users', 'invalidUser']),
  (invalidUser) => invalidUser,
);

export const selectPasswordResetEmailSent = createSelector(
  (state) => state.getIn(['users', 'passwordResetEmailSent']),
  (passwordResetEmailSent) => passwordResetEmailSent,
);

export const selectUserNotFound = createSelector(
  (state) => state.getIn(['users', 'userNotFound']),
  (userNotFound) => userNotFound,
);

export const selectPasswordReset = createSelector(
  (state) => state.getIn(['users', 'passwordReset']),
  (passwordReset) => passwordReset,
);

export const selectInvalidToken = createSelector(
  (state) => state.getIn(['users', 'invalidToken']),
  (invalidToken) => invalidToken,
);

export const selectUpdateCurrentUserRequestComplete = createSelector(
  (state) => state.getIn(['users', 'updateCurrentUserRequestComplete']),
  (updateCurrentUserRequestComplete) => updateCurrentUserRequestComplete,
);
