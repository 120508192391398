export const GET_TASK_PREFERENCES_SENT = 'GET_TASK_PREFERENCEES_SENT';
export const GET_TASK_PREFERENCES_SUCCESSFUL = 'GET_TASK_PREFERENCES_SUCCESSFUL';

export const UPDATE_TASK_PREFERENCE_SENT = 'UPDATE_TASK_PREFERENCE_SENT';
export const UPDATE_TASK_PREFERENCE_SUCCESSFUL = 'UPDATE_TASK_PREFERENCE_SUCCESSFUL';

export const CREATE_TASK_PREFERENCE_SENT = 'CREATE_TASK_PREFERENCE_SENT';
export const CREATE_TASK_PREFERENCE_SUCCESSFUL = 'CREATE_TASK_PREFERENCE_SUCCESSFUL';

export const getTaskPreferencesRequestSent = () => ({ type: GET_TASK_PREFERENCES_SENT });

export const getTaskPreferencesRequestSuccessful = (data) => ({ type: GET_TASK_PREFERENCES_SUCCESSFUL, data });

export const updateTaskPreferenceRequestSent = () => ({ type: UPDATE_TASK_PREFERENCE_SENT });

export const updateTaskPreferenceRequestSuccessful = (data) => ({ type: UPDATE_TASK_PREFERENCE_SUCCESSFUL, data });

export const createTaskPreferenceRequestSent = () => ({ type: CREATE_TASK_PREFERENCE_SENT });

export const createTaskPreferenceRequestSuccessful = (data) => ({ type: CREATE_TASK_PREFERENCE_SUCCESSFUL, data });
