import { connect } from 'react-redux';

import { selectAffiliationsLoading, selectAffiliations } from 'selectors/affiliationsSelectors';
import {
  getAffiliations,
  updateAffiliation,
  createAffiliation,
  downloadAffiliations,
} from 'actions/thunks/affiliations';
import AffiliationsPage from 'components/pages/admin/AffiliationsPage';
import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';
import { selectApplicantsLoading, selectApplicants } from 'selectors/applicantsSelectors';
import { getApplicants } from 'actions/thunks/applicants';
import { getYear } from 'actions/thunks/systemSettings';
import { getEventDates } from 'actions/thunks/eventDates';
import { selectYear } from 'selectors/systemSettingsSelectors';
import { selectEventDates } from 'selectors/eventDatesSelectors';

const mapStateToProps = state => ({
  loading: selectAffiliationsLoading(state),
  affiliations: selectAffiliations(state),
  isAdmin: selectIsAdmin(state),
  isLoggedIn: selectIsLoggedIn(state),
  year: selectYear(state),
  eventDates: selectEventDates(state),
  applicantsLoading: selectApplicantsLoading(state),
  applicants: selectApplicants(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAffiliations: () => dispatch(getAffiliations()),
  updateAffiliation: (newAffiliation) => dispatch(updateAffiliation(newAffiliation)),
  createAffiliation: (newAffiliation) => dispatch(createAffiliation(newAffiliation)),
  downloadAffiliations: () => dispatch(downloadAffiliations()),
  getApplicants: () => dispatch(getApplicants()),
  getYear: () => dispatch(getYear()),
  getEventDates: () => dispatch(getEventDates()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AffiliationsPage);
