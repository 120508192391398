import React from 'react';
import PropTypes from 'prop-types';

import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import ReusableButton from 'components/common/ReusableButton';

import ApplicationStep from 'components/signup/ApplicationStep';
import YaspStep from 'components/signup/YaspStep';
import TaskSignupStep from 'components/signup/TaskSignupStep';
import SurveyStep from 'components/signup/SurveyStep';
import SummaryStep from 'components/signup/SummaryStep';
import UnsavedChangesDialog from 'components/signup/UnsavedChangesDialog';

import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import history from 'wrappers/history';
import styles from './style.module.scss';
import { formatHumanDate } from '../admin/AdminUtils.ts';

class VolunteerSignupProcessPage extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.isLoggedIn) {
      history.push('/login');
    }

    this.state = {
      activeStep: 0,
      changesMadeForCurrentStep: false,
      unsavedChangesDialogOpen: false,
      pendingAction: null,

      // Application Fields
      applicationSpeaker: false,
      applicationDepartmentDisplay: false,
      applicationActivityWorker: true,
      applicationStatus: null,
      applicationAffiliation: null,
      applicationRecruiter: null,
      applicationRecruitedFrom: null,
      comments: '',

      // Yasp Form Fields
      yaspFormSigned: false,
      yaspFormSignedDate: null,

      // Task Signup Fields
      taskSignupCurrentStartTime: undefined,
      taskSignupCurrentEndTime: undefined,
      taskSignupEnteredTimeWindows: [],
      taskPreferences: {},

      // Survey Fields
      surveyNavigationEasy: '',
      surveyNavigationFlexible: '',
      surveyNavigationRobust: '',
      surveyDataEntryEasy: '',
      surveyDataEntryFlexible: '',
      surveyDataEntryRobust: '',
      surveyFormOnlinePaper: '',
      surveySuperfluousQuestions: '',
      surveyMissedQuestions: '',
      surveyComments: '',
    };
  }

  async componentDidMount() {
    if (this.props.currentYear === '') {
      await this.props.getYear();
    }

    if (this.props.yaspFormLink === '') {
      await this.props.getYaspFormLink();
    }

    if (this.props.eventDates.length === 0) {
      await this.props.getEventDates();
    }

    if (this.props.statuses.length === 0) {
      await this.props.getStatuses();
    }

    if (this.props.affiliations.length === 0) {
      await this.props.getAffiliations();
    }

    if (this.props.recruiters.length === 0) {
      await this.props.getRecruiters();
    }

    if (this.props.classes.length === 0) {
      await this.props.getClasses();
    }

    if (this.props.tasks.length === 0) {
      await this.props.getTasks();
    }

    const preferenceStateFields = {};
    this.currentYearTasks.forEach(task => {
      // Set a default value of acceptable
      preferenceStateFields[`task${task.id}`] = 3;
    });

    this.setState({
      taskPreferences: preferenceStateFields,
    });

    if (this.currentApplication) {
      const existingTimeWindows = this.currentApplication.time_windows.map(timeWindow => ({ start_at: dayjs(timeWindow.starts_at), end_at: dayjs(timeWindow.ends_at) }));
      const existingPreferences = {};
      this.currentApplication.task_preferences.forEach(preference => {
        existingPreferences[`task${preference.task_id}`] = preference.preference;
      });

      this.setState(prevState => ({
        applicationSpeaker: this.currentApplication.speaker,
        applicationDepartmentDisplay: this.currentApplication.department_display,
        applicationActivityWorker: this.currentApplication.activity_worker,
        applicationStatus: this.currentApplication.status ? this.statusList.find(status => status.id === this.currentApplication.status.id) : null,
        applicationAffiliation: this.currentApplication.affiliation ? this.affiliationList.find(affiliation => affiliation.id === this.currentApplication.affiliation.id) : null,
        applicationRecruiter: this.currentApplication.recruiter ? this.recruiterList.find(recruiter => recruiter.id === this.currentApplication.recruiter.id) : null,
        applicationRecruitedFrom: this.currentApplication.math_class ? this.classList.find(mathClass => mathClass.id === this.currentApplication.math_class.id) : null,
        comments: this.currentApplication.comments,
        yaspFormSigned: this.currentApplication.youth_form_date !== null,
        yaspFormSignedDate: dayjs(this.currentApplication.youth_form_date),
        taskSignupEnteredTimeWindows: existingTimeWindows,
        taskPreferences: {
          ...prevState.taskPreferences,
          ...existingPreferences,
        },
      }));
    }

    if (!this.currentApplication && this.urlYearMatchesCurrentYear) {
      this.props.createApplication(() => window.location.reload());
    }

    setTimeout(() => {
      this.setState({ activeStep: this.firstIncompleteStep });
    }, 50);
  }

  get steps() {
    return ['Application', 'YASP', 'Task Signup', 'Survey (optional)', 'Summary'];
  }

  get urlYear() {
    return parseInt(this.props.match.params.year, 10);
  }

  get currentApplication() {
    if (!this.props.currentUser) {
      return null;
    }
    const currentYearApplication = this.props.currentUser.applications.filter(application => application.year === this.urlYear);
    return currentYearApplication.length > 0 ? currentYearApplication[0] : null;
  }

  get urlYearMatchesCurrentYear() {
    return this.props.currentYear === this.urlYear;
  }

  get selectedRoles() {
    return [
      { name: 'Activity Worker', value: this.state.applicationActivityWorker },
      { name: 'Department Display', value: this.state.applicationDepartmentDisplay },
      { name: 'Speaker', value: this.state.applicationSpeaker },
    ].filter(role => role.value).map(role => role.name);
  }

  get statusList() {
    const sortedStatuses = sortBy(this.props.statuses.concat(), ['priority']);
    const approvedStatuses = sortedStatuses.filter(status => status.approved && status.active);
    return approvedStatuses;
  }

  get affiliationList() {
    const sortedAffiliations = sortBy(this.props.affiliations.concat(), ['priority', 'name']);
    const approvedAffiliations = sortedAffiliations.filter(affiliation => affiliation.approved);
    return approvedAffiliations.map(affiliation => ({ ...affiliation, grouping: `------ ${affiliation.priority} ------` }));
  }

  get recruiterList() {
    const sortedRecruiters = sortBy(this.props.recruiters.concat(), ['last_name', 'first_name']);
    const approvedRecruiters = sortedRecruiters.filter(recruiter => recruiter.approved);
    return approvedRecruiters.map(recruiter => ({ ...recruiter, display: `${recruiter.last_name}, ${recruiter.first_name}` }));
  }

  get classList() {
    const sortedClasses = sortBy(this.props.classes.concat(), ['name']);
    const approvedClasses = sortedClasses.filter(classRecruited => classRecruited.approved && classRecruited.active);
    return approvedClasses;
  }

  get currentEventDate() {
    return this.props.eventDates.filter(eventDate => dayjs(eventDate.held_on).year() === this.props.currentYear)[0];
  }

  get currentYearTasks() {
    return this.props.tasks.filter(task => task.year === this.urlYear);
  }

  get visibleSortedTasks() {
    const visibleTasks = this.currentYearTasks.filter(task => {
      const visibleToIds = task.visible_to.map(status => status.id);

      return visibleToIds.includes(this.state.applicationStatus ? this.state.applicationStatus.id : null);
    });

    const sortedTasks = sortBy(visibleTasks.concat(), ['starts_at', 'display_string']);
    return sortedTasks;
  }

  get tasksAvailableForApplicant() {
    return this.visibleSortedTasks.filter(task => this.isApplicantAvailableForTask(task));
  }

  get joinedVisibleTasks() {
    const tasksGroupedByDisplayString = {};
    this.visibleSortedTasks.forEach(task => {
      if (tasksGroupedByDisplayString[task.display_string] !== undefined) {
        if (tasksGroupedByDisplayString[task.display_string][this.formatTaskTimeWindow(task)] !== undefined) {
          tasksGroupedByDisplayString[task.display_string][this.formatTaskTimeWindow(task)].push(task);
        } else {
          tasksGroupedByDisplayString[task.display_string][this.formatTaskTimeWindow(task)] = [task];
        }
      } else {
        tasksGroupedByDisplayString[task.display_string] = { [this.formatTaskTimeWindow(task)]: [task] };
      }
    });

    const joinedVisibleTasks = [];
    Object.keys(tasksGroupedByDisplayString).forEach(taskDisplay => {
      const tasksByTime = tasksGroupedByDisplayString[taskDisplay];
      Object.keys(tasksByTime).forEach(taskTime => {
        const tasks = tasksByTime[taskTime];
        const taskGrouping = {
          starts_at: tasks[0].starts_at,
          ends_at: tasks[0].ends_at,
          display_string: taskDisplay,
          name: tasks[0].name,
          description: tasks[0].description,
          preemtable: tasks.some(task => task.preemtable),
          task_ids: tasks.map(task => task.id),
          tasks,
        };
        joinedVisibleTasks.push(taskGrouping);
      });
    });

    return joinedVisibleTasks;
  }

  get nextButtonDisabled() {
    return !this.canMoveOnForStep(this.state.activeStep);
  }

  get backButtonDisabled() {
    return false;
  }

  get nextButtonDisabledTooltip() {
    if (this.nextButtonDisabled) {
      switch (this.state.activeStep) {
      case 0:
        return 'You must fill out all required fields and save your progress before moving on.';
      case 1:
        return 'You must sign the YASP form before moving on.';
      case 2:
        return 'You must enter at least one time interval where you are available and save your progress before moving on.';
      case 3:
        return '';
      case 4:
        return '';
      default:
        return 'Unknown step';
      }
    }

    return '';
  }

  get timeEntryInvalid() {
    return this.state.taskSignupCurrentStartTime >= this.state.taskSignupCurrentEndTime
     || this.state.taskSignupCurrentStartTime < dayjs('1941-12-07 6:00')
     // The clock time picker adds seconds to the time. So choosing 5:30 may result in time of 1941-12-07 17:30:01 which will
     // cause the time to be invalid when it should be valid. So, new logic must be if it is greater than or equal to 17:31
     || this.state.taskSignupCurrentEndTime >= dayjs('1941-12-07 17:31')
     || this.state.taskSignupCurrentStartTime === undefined
     || this.state.taskSignupCurrentEndTime === undefined;
  }

  get firstIncompleteStep() {
    for (let i = 0; i < this.steps.length; i++) {
      if (!this.canMoveOnForStep(i)) {
        return i;
      }
    }
    // If all steps are complete then we start at the first step since we don't know what the user wants to edit
    return 0;
  }

  getStepContent = (step) => {
    switch (step) {
    case 0:
      return this.renderGeneralForm();
    case 1:
      return this.renderYaspForm();
    case 2:
      return this.renderTaskSignup();
    case 3:
      return this.renderSurvey();
    case 4:
      return this.renderSummary();
    default:
      return 'Unknown step';
    }
  };

  canMoveOnForStep = (step) => {
    switch (step) {
    case 0:
      return this.state.applicationStatus !== null
        && this.state.applicationAffiliation !== null
        && (this.currentApplication.application_step_completed
          || (!this.currentApplication.application_step_completed && !this.state.changesMadeForCurrentStep));
    case 1:
      return this.state.yaspFormSigned
        && (this.currentApplication.yasp_step_completed
          || (!this.currentApplication.yasp_step_completed && !this.state.changesMadeForCurrentStep));
    case 2:
      return this.state.taskSignupEnteredTimeWindows.length > 0
        && (this.currentApplication.task_signup_step_completed
          || (!this.currentApplication.task_signup_step_completed && !this.state.changesMadeForCurrentStep));
    case 3:
      return true;
    case 4:
      return true;
    default:
      return false;
    }
  };

  handleNext = () => {
    if (this.state.changesMadeForCurrentStep && this.state.pendingAction === null) {
      this.setState({
        unsavedChangesDialogOpen: true,
        pendingAction: 'next',
      });
      return;
    }

    if (this.state.activeStep === 4) {
      this.saveChanges();
    }

    if (this.canMoveOnForStep(this.state.activeStep)) {
      this.setState(prevState => ({
        activeStep: prevState.activeStep + 1,
      }));
    }
  };

  handleBack = () => {
    if (this.state.changesMadeForCurrentStep && this.state.pendingAction === null) {
      this.setState({
        unsavedChangesDialogOpen: true,
        pendingAction: 'back',
      });
      return;
    }

    if (this.state.activeStep === 0) {
      history.push('/volunteer/dashboard');
    } else {
      this.setState(prevState => ({
        activeStep: prevState.activeStep - 1,
      }));
    }
  };

  handlePendingAction = () => {
    if (this.state.pendingAction === 'back') {
      this.handleBack();
    } else if (this.state.pendingAction === 'next') {
      this.handleNext();
    }

    const existingTimeWindows = this.currentApplication.time_windows.map(timeWindow => ({ start_at: dayjs(timeWindow.starts_at), end_at: dayjs(timeWindow.ends_at) }));
    const existingPreferences = {};
    this.currentApplication.task_preferences.forEach(preference => {
      existingPreferences[`task${preference.task_id}`] = preference.preference;
    });

    this.setState(prevState => ({
      applicationSpeaker: this.currentApplication.speaker,
      applicationDepartmentDisplay: this.currentApplication.department_display,
      applicationActivityWorker: this.currentApplication.activity_worker,
      applicationStatus: this.currentApplication.status ? this.statusList.find(status => status.id === this.currentApplication.status.id) : null,
      applicationAffiliation: this.currentApplication.affiliation ? this.affiliationList.find(affiliation => affiliation.id === this.currentApplication.affiliation.id) : null,
      applicationRecruiter: this.currentApplication.recruiter ? this.recruiterList.find(recruiter => recruiter.id === this.currentApplication.recruiter.id) : null,
      applicationRecruitedFrom: this.currentApplication.math_class ? this.classList.find(mathClass => mathClass.id === this.currentApplication.math_class.id) : null,
      comments: this.currentApplication.comments,
      yaspFormSigned: this.currentApplication.youth_form_date !== null,
      yaspFormSignedDate: dayjs(this.currentApplication.youth_form_date),
      taskSignupEnteredTimeWindows: existingTimeWindows,
      taskPreferences: {
        ...prevState.taskPreferences,
        ...existingPreferences,
      },
      changesMadeForCurrentStep: false,
      pendingAction: null,
      unsavedChangesDialogOpen: false,
    }));
  };

  goToStep = step => this.setState({ activeStep: step });

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
      changesMadeForCurrentStep: true,
    });
  };

  handleCheckboxChange = name => event => {
    this.setState({
      [name]: event.target.checked,
      changesMadeForCurrentStep: true,
    });
  };

  handleAutocompleteChange = name => (event, value) => {
    this.setState({
      [name]: value,
      changesMadeForCurrentStep: true,
    });
  };

  signYaspForm = () => {
    this.setState({ yaspFormSigned: true, yaspFormSignedDate: dayjs() }, () => this.saveChanges());
  };

  handleTimeChange = (name, newTime) => {
    // For some reason, the month setter uses zero indexing, but the year and date do not. Fun.
    // Actual date is 1941-12-07. We have to set the correct date here because the keyboard picker chooses the current date
    // while the popup picker chooses the original date.
    if (newTime !== null) {
      let newTimeWithFixedDate = newTime.year(1941);
      newTimeWithFixedDate = newTimeWithFixedDate.month(11);
      newTimeWithFixedDate = newTimeWithFixedDate.date(7);
      this.setState({ [name]: newTimeWithFixedDate });
    } else {
      this.setState({ [name]: newTime });
    }
  };

  addTimeWindow = () => {
    const newTimeWindows = [...this.state.taskSignupEnteredTimeWindows, { start_at: this.state.taskSignupCurrentStartTime, end_at: this.state.taskSignupCurrentEndTime }];
    const sortedTimeWindows = sortBy(newTimeWindows, 'start_at');
    const mergedTimeWindows = [];

    sortedTimeWindows.forEach(timeWindow => {
      if (mergedTimeWindows.length === 0 || mergedTimeWindows[mergedTimeWindows.length - 1].end_at < timeWindow.start_at) {
        mergedTimeWindows.push(timeWindow);
      } else if (mergedTimeWindows[mergedTimeWindows.length - 1].end_at < timeWindow.end_at) {
        mergedTimeWindows[mergedTimeWindows.length - 1].end_at = timeWindow.end_at;
      }
    });

    this.setState({
      taskSignupEnteredTimeWindows: mergedTimeWindows,
      changesMadeForCurrentStep: true,
    });
  };

  removeTimeWindow = (removedTimeWindow) => {
    this.setState(prevState => ({
      taskSignupEnteredTimeWindows: prevState.taskSignupEnteredTimeWindows.filter(timeWindow => timeWindow !== removedTimeWindow),
      changesMadeForCurrentStep: true,
    }));
  };

  formatTimeWindow = (timeWindow) => `${timeWindow.start_at.format('h:mm A')} - ${timeWindow.end_at.format('h:mm A')}`;

  formatTaskTimeWindow = task => {
    const startTime = dayjs(task.starts_at);
    const endTime = dayjs(task.ends_at);

    return `${startTime.format('h:mm A')} - ${endTime.format('h:mm A')}`;
  };

  isApplicantAvailableForTask = task => {
    const taskStartTime = dayjs(task.starts_at);
    const taskEndTime = dayjs(task.ends_at);
    return this.state.taskSignupEnteredTimeWindows.some(timeWindow => {
      const taskContainedInTimeWindow = timeWindow.start_at <= taskStartTime && timeWindow.end_at >= taskEndTime;
      const taskPreemtableAndOverlapping = task.preemtable && taskStartTime < timeWindow.end_at && timeWindow.start_at < taskEndTime;
      return taskContainedInTimeWindow || taskPreemtableAndOverlapping;
    });
  };

  handleTaskPreferenceChange = taskGrouping => event => {
    const updatedTaskPreferences = {};
    taskGrouping.task_ids.forEach(taskId => {
      updatedTaskPreferences[`task${taskId}`] = event.target.value;
    });

    this.setState(prevState => ({
      taskPreferences: {
        ...prevState.taskPreferences,
        ...updatedTaskPreferences,
      },
      changesMadeForCurrentStep: true,
    }));
  };

  saveChanges = () => {
    const step = this.state.activeStep;
    let payload = {
      user_id: this.props.currentUser.id,
      application_id: this.currentApplication.id,
    };
    switch (step) {
    case 0:
      payload = {
        ...payload,
        step: 0,
        activity_worker: this.state.applicationActivityWorker,
        department_display: this.state.applicationDepartmentDisplay,
        speaker: this.state.applicationSpeaker,
        status: this.state.applicationStatus ? this.state.applicationStatus.id : null,
        affiliation: this.state.applicationAffiliation ? this.state.applicationAffiliation.id : null,
        recruiter: this.state.applicationRecruiter ? this.state.applicationRecruiter.id : null,
        math_class: this.state.applicationRecruitedFrom ? this.state.applicationRecruitedFrom.id : null,
        comments: this.state.comments,
      };
      break;
    case 1:
      payload = {
        ...payload,
        step: 1,
        yasp_signed_date: this.state.yaspFormSignedDate.format(),
      };
      break;
    case 2:
      const timeWindows = this.state.taskSignupEnteredTimeWindows.map(timeWindow => ({
        starts_at: timeWindow.start_at.format(),
        ends_at: timeWindow.end_at.format(),
      }));
      const taskPreferences = this.tasksAvailableForApplicant.map(task => ({
        task_id: task.id,
        preference: this.state.taskPreferences[`task${task.id}`],
      }));
      payload = {
        ...payload,
        step: 2,
        time_windows: timeWindows,
        task_preferences: taskPreferences,
        comments: this.state.comments,
      };
      break;
    case 3:
      payload = {
        ...payload,
        step: 3,
      };
      break;
    case 4:
      payload = {
        ...payload,
        step: 4,
      };
      break;
    default:
      throw Error('Invalid step');
    }
    this.props.updateApplication(payload);
    this.setState({ changesMadeForCurrentStep: false });
  };

  renderGeneralForm() {
    return (
      <ApplicationStep
        speaker={this.state.applicationSpeaker}
        departmentDisplay={this.state.applicationDepartmentDisplay}
        activityWorker={this.state.applicationActivityWorker}
        handleCheckboxChange={this.handleCheckboxChange}
        handleAutocompleteChange={this.handleAutocompleteChange}
        status={this.state.applicationStatus}
        statusList={this.statusList}
        affiliation={this.state.applicationAffiliation}
        affiliationList={this.affiliationList}
        recruiter={this.state.applicationRecruiter}
        recruiterList={this.recruiterList}
        recruitedFrom={this.state.applicationRecruitedFrom}
        recruitedFromList={this.classList}
        comments={this.state.comments}
        handleCommentsChange={this.handleChange}
      />
    );
  }

  renderYaspForm() {
    return (
      <YaspStep
        currentUser={this.props.currentUser}
        signYaspForm={this.signYaspForm}
        yaspFormSigned={this.state.yaspFormSigned}
        yaspFormSignedDate={this.state.yaspFormSignedDate}
        yaspFormLink={this.props.yaspFormLink}
      />
    );
  }

  renderTaskSignup() {
    return (
      <TaskSignupStep
        addTimeWindow={this.addTimeWindow}
        timeEntryInvalid={this.timeEntryInvalid}
        currentStartTime={this.state.taskSignupCurrentStartTime}
        currentEndTime={this.state.taskSignupCurrentEndTime}
        handleTimeChange={this.handleTimeChange}
        enteredTimeWindows={this.state.taskSignupEnteredTimeWindows}
        formatTimeWindow={this.formatTimeWindow}
        removeTimeWindow={this.removeTimeWindow}
        comments={this.state.comments}
        handleChange={this.handleChange}
        tasks={this.joinedVisibleTasks}
        isApplicantAvailableForTask={this.isApplicantAvailableForTask}
        formatTaskTimeWindow={this.formatTaskTimeWindow}
        handleTaskPreferenceChange={this.handleTaskPreferenceChange}
        taskPreferences={this.state.taskPreferences}
        currentEventDate={this.currentEventDate}
      />
    );
  }

  renderSurvey() {
    return (
      <SurveyStep
        navigationEasy={this.state.surveyNavigationEasy}
        navigationFlexible={this.state.surveyNavigationFlexible}
        navigationRobust={this.state.surveyNavigationRobust}
        dataEntryEasy={this.state.surveyDataEntryEasy}
        dataEntryFlexible={this.state.surveyDataEntryFlexible}
        dataEntryRobust={this.state.surveyDataEntryRobust}
        formOnlinePaper={this.state.surveyFormOnlinePaper}
        superfluousQuestions={this.state.surveySuperfluousQuestions}
        missedQuestions={this.state.surveyMissedQuestions}
        comments={this.state.surveyComments}
        handleChange={this.handleChange}
      />
    );
  }

  renderSummary() {
    return (
      <SummaryStep
        currentUser={this.props.currentUser}
        goToStep={this.goToStep}
        selectedRoles={this.selectedRoles}
        status={this.state.applicationStatus}
        affiliation={this.state.applicationAffiliation}
        recruiter={this.state.applicationRecruiter}
        recruitedFrom={this.state.applicationRecruitedFrom}
        comments={this.state.comments}
        yaspFormSignedDate={this.state.yaspFormSignedDate}
        enteredTimeWindows={this.state.taskSignupEnteredTimeWindows}
        formatTimeWindow={this.formatTimeWindow}
        tasksAvailableForApplicant={this.tasksAvailableForApplicant}
        taskPreferences={this.state.taskPreferences}
      />
    );
  }

  render() {
    if (!this.urlYearMatchesCurrentYear) {
      return (
        <div id="maincontent">
          <div className="wdn-band">
            <div className="wdn-inner-wrapper">
              You currently cannot edit your application for&nbsp;
              {this.props.match.params.year}
              .
              <ReusableButton
                hasTopMargin
                onClick={() => history.push('/volunteer/dashboard')}
                value="Return to Dashboard"
              />
            </div>
          </div>
        </div>
      );
    }

    if (!this.currentApplication) {
      return (
        <div id="maincontent">
          <div className="wdn-band">
            <div className="wdn-inner-wrapper">
              Starting your application for &nbsp;
              {this.props.match.params.year}
              . Please wait a few seconds.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['signup-process-container']}>
              <div className={styles['header-container']}>
                <h2 className={styles['page-header']}>
                  Application&nbsp;for
                  <br />
                  {formatHumanDate(this.props.match.params.year)}
                </h2>
                <Stepper
                  activeStep={this.state.activeStep}
                  alternativeLabel
                  classes={{
                    root: styles['stepper'],
                  }}
                >
                  {this.steps.map(label => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div className={styles['stepper-navigation-buttons-container']}>
                  <div className={styles['stepper-navigation-buttons']}>
                    <ReusableButton
                      variant="text"
                      color="default"
                      disabled={this.backButtonDisabled}
                      onClick={this.handleBack}
                      value="Back"
                    />
                    <ReusableButton
                      disabled={this.nextButtonDisabled}
                      onClick={this.handleNext}
                      tooltipText={this.nextButtonDisabledTooltip}
                      value={this.state.activeStep === this.steps.length - 1 ? 'Finish' : 'Next'}
                    />
                  </div>
                  <ReusableButton
                    value="Save Changes"
                    hasTopMargin
                    disabled={!this.state.changesMadeForCurrentStep}
                    tooltipText={!this.state.changesMadeForCurrentStep ? 'There are no changes to save.' : ''}
                    onClick={this.saveChanges}
                  />
                </div>
                <UnsavedChangesDialog
                  open={this.state.unsavedChangesDialogOpen}
                  handleAccept={this.handlePendingAction}
                  handleCloseDialog={() => this.setState({ unsavedChangesDialogOpen: false, pendingAction: null })}
                />
              </div>
              <div>
                <hr />
                {this.state.activeStep === this.steps.length ? (
                  <div>
                    Your application for&nbsp;
                    {this.props.match.params.year}
                    &nbsp;has been completed. You may edit it anytime from the volunteer dashboard.
                    <br />
                    Thank you for volunteering for Math Day!
                    <ReusableButton
                      hasTopMargin
                      onClick={() => history.push('/volunteer/dashboard')}
                      value="Return to Dashboard"
                    />
                  </div>
                ) : (
                  <div>
                    <div className={styles['stepper-container']}>
                      {this.getStepContent(this.state.activeStep)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VolunteerSignupProcessPage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentYear: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  getYear: PropTypes.func.isRequired,
  yaspFormLink: PropTypes.string,
  getYaspFormLink: PropTypes.func,
  match: PropTypes.object,
  statuses: PropTypes.array,
  getStatuses: PropTypes.func.isRequired,
  affiliations: PropTypes.array,
  getAffiliations: PropTypes.func.isRequired,
  recruiters: PropTypes.array,
  getRecruiters: PropTypes.func.isRequired,
  classes: PropTypes.array,
  getClasses: PropTypes.func.isRequired,
  tasks: PropTypes.array,
  getTasks: PropTypes.func.isRequired,
  createApplication: PropTypes.func.isRequired,
  updateApplication: PropTypes.func.isRequired,
  eventDates: PropTypes.array.isRequired,
  getEventDates: PropTypes.func.isRequired,
};

export default VolunteerSignupProcessPage;
