import React from 'react';
import PropTypes from 'prop-types';

import { formatDate } from 'utils/date';
import styles from './style.module.scss';

function AnnouncementsBar(props) {
  return (
    <div className="wdn-band wdn-light-triad-band">
      <div className="wdn-inner-wrapper">
        <div className="wdn-alt-header">
          Announcements
        </div>
        <hr className={styles['divider']} />
        {props.announcements.length > 0
          ? props.announcements.map((announcement) => (
            <div key={announcement.id}>
              <div className={styles['announcement']}>
                {announcement.content}
              </div>
              <div>
                {`Posted by ${announcement.author} on ${formatDate(announcement.start_at)}`}
              </div>
            </div>
          ))
          : <div className={styles['announcement']}>There are no new announcements.</div>}
      </div>
    </div>
  );
}

AnnouncementsBar.propTypes = {
  announcements: PropTypes.array.isRequired,
};

export default AnnouncementsBar;
