/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';
import history from 'wrappers/history';

import ReusableSelect from 'components/common/ReusableSelect';
import EmailSidebar from 'components/Email/EmailSidebar';
import EmailEditor from 'components/Email/EmailEditor';
import DeleteEmailTemplateDialog from 'components/Email/DeleteEmailTemplateDialog';
import AddEmailTemplateDialog from 'components/Email/AddEmailTemplateDialog';
import PreviewEmailDialog from 'components/Email/PreviewEmailDialog';
import {
  generateCustomEmails,
  generateApplicantEmails,
  generateRecruiterEmails,
  replaceApplicantWithEmail,
  replaceRecruiterWithEmail,
  replaceCustomWithEmail,
} from 'utils/emails';

import ReusableButton from 'components/common/ReusableButton';
import ReusableSnackbar from 'components/common/ReusableSnackbar';

import styles from './style.module.scss';
import 'react-multi-email/style.css';

const emptyTemplate = { id: -1, name: 'Custom Message', recipient_type: 'applicants' };

class EmailsPage extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }
    this.state = {
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: '',
      recipientType: Object.keys(this.props.applicantsSelected).length > 0 ? 'applicants' : 'recruiters',
      currentTemplate: emptyTemplate,
      deleteEmailTemplateDialogOpen: false,
      addEmailTemplateDialogOpen: false,
      previewDialogOpen: false,
      applicantsChecked: this.props.applicantsSelected ? this.props.applicantsSelected : {},
      recruitersChecked: this.props.recruitersSelected ? this.props.recruitersSelected : {},
      previewDialogProps: {
        messages: [],
        recipientType: 'applicants',
      },
    };

    // Refresh store once props have been passed in
    this.props.setApplicantsSelected({});
    this.props.setRecruitersSelected({});
  }

  componentDidMount() {
    if (!this.props.emailSignature) {
      this.props.getEmailSignature();
    }
    if (this.props.emailTemplates.length === 0) {
      this.props.getEmailTemplates();
    }
    if (this.props.applicants.length === 0) {
      this.props.getApplicants();
    }
    if (!this.props.year) {
      this.props.getYear();
    }
    if (this.props.recruiters.length === 0) {
      this.props.getRecruiters();
    }
    if (Object.keys(this.state.recruitersChecked) === 0) {
      const recruiters = {};
      this.recruiters.forEach((recruiter) => {
        recruiters[recruiter.id] = this.props.recruitersSelected ? this.props.recruitersSelected.includes(recruiter.id) : false;
      });
      this.setState({
        recruitersChecked: recruiters,
      });
    }
    if (Object.keys(this.state.applicantsChecked) === 0) {
      const applicants = {};
      this.applicants.forEach((applicant) => {
        applicants[applicant.id] = this.props.applicantsSelected ? this.props.applicantsSelected.includes(applicant.id) : false;
      });
      this.setState({
        applicantsChecked: applicants,
      });
    }
  }

  get generateTemplates() {
    const emailTemplates = this.props.emailTemplates.filter((template) => template.year === parseInt(this.props.year, 10));
    const templatesMapped = emailTemplates.map((template) => ({ value: template, display: template.name }));
    templatesMapped.unshift({ value: emptyTemplate, display: emptyTemplate.name });
    return templatesMapped;
  }

  get isEmptyTemplate() {
    return this.state.currentTemplate.id === -1;
  }

  get isLoading() {
    return this.props.emailTemplatesLoading || this.props.applicantsLoading || this.props.recruitersLoading || !this.props.emailSignature;
  }

  get recruiters() {
    return this.props.recruiters.filter((recruiter) => recruiter.approved);
  }

  get applicants() {
    return this.props.applicants.filter((app) => app.year === parseInt(this.props.year, 10));
  }

  get selectedApplicants() {
    const ids = Object.keys(this.state.applicantsChecked).filter((applicantId) => this.state.applicantsChecked[applicantId] === true);
    return this.applicants.filter(applicant => ids.includes(applicant.id.toString()));
  }

  get selectedRecruiters() {
    const ids = Object.keys(this.state.recruitersChecked).filter((recruiterId) => this.state.recruitersChecked[recruiterId] === true);
    return this.recruiters.filter(recruiter => ids.includes(recruiter.id.toString()));
  }

  get allSelected() {
    if (this.state.recipientType === 'recruiters') {
      return Object.values(this.state.recruitersChecked).every((val) => val === true) && Object.values(this.state.recruitersChecked).length === this.recruiters.length;
    }
    return Object.values(this.state.applicantsChecked).every((val) => val === true) && Object.values(this.state.applicantsChecked).length === this.applicants.length;
  }

  get allWithVolunteersSelected() {
    const withVolunteers = this.recruiters.filter((recruiter) => recruiter.recruited_applicants.length !== 0);
    if (withVolunteers.length === 0) {
      return false;
    }
    return withVolunteers.every((recruiter) => this.state.recruitersChecked[recruiter.id] === true);
  }

  get allWithAssignmentSelected() {
    const withAssignment = this.applicants.filter((applicant) => applicant.final_assignments.length !== 0);
    if (withAssignment.length === 0) {
      return false;
    }
    return withAssignment.every((applicant) => this.state.applicantsChecked[applicant.id] === true);
  }

  handleSelectAll = (param) => {
    if (param === 'all') {
      if (this.state.recipientType === 'applicants') {
        const newSelected = {};
        this.applicants.forEach((app) => newSelected[app.id] = !this.allSelected);
        this.setState({
          applicantsChecked: newSelected,
        });
      }
      const newSelected = {};
      this.recruiters.forEach((rec) => newSelected[rec.id] = !this.allSelected);
      this.setState({
        recruitersChecked: newSelected,
      });
    } else if (param === 'withVolunteers') {
      const newSelected = {};
      this.recruiters.forEach((rec) => {
        if (rec.recruited_applicants.length !== 0) {
          newSelected[rec.id] = !this.allWithVolunteersSelected;
        } else {
          newSelected[rec.id] = false;
        }
      });
      this.setState({
        recruitersChecked: newSelected,
      });
    } else if (param === 'withAssignment') {
      const newSelected = {};
      this.applicants.forEach((app) => {
        if (app.final_assignments.length !== 0) {
          newSelected[app.id] = !this.allWithAssignmentSelected;
        } else {
          newSelected[app.id] = false;
        }
      });
      this.setState({
        applicantsChecked: newSelected,
      });
    }
  };

  handleSendEmails = (messages, recipientType) => {
    let toBeSent = [];
    if (recipientType === 'custom') {
      toBeSent = replaceCustomWithEmail(messages);
    } else if (recipientType === 'applicants') {
      toBeSent = replaceApplicantWithEmail(messages);
    } else {
      toBeSent = replaceRecruiterWithEmail(messages);
    }
    this.setState({
      previewDialogOpen: false,
    });
    if (this.state.recipientType === 'custom') {
      this.editor.clearCustomFields();
    }
    this.props.createEmailMessage({ messages: toBeSent });
  };

  handlePreviewClicked = (emailFields) => {
    let emailMessages = [];
    if (emailFields.recipientType === 'applicants' && this.selectedApplicants.length > 0) {
      emailMessages = generateApplicantEmails(this.selectedApplicants, emailFields);
    } else if (emailFields.recipientType === 'recruiters' && this.selectedRecruiters.length > 0) {
      emailMessages = generateRecruiterEmails(this.selectedRecruiters, emailFields);
    } else if (emailFields.recipientType === 'custom') {
      emailMessages = generateCustomEmails(this.editor.state.emails, emailFields);
    } else {
      if (emailFields.recipientType === 'applicants' && this.selectedApplicants.length === 0) {
        this.setState({
          snackbarMessage: 'Please select at least one applicant to email.',
          snackbarOpen: true,
          snackbarVariant: 'error',
        });
      } else if (emailFields.recipientType === 'recruiters' && this.selectedApplicants.length === 0) {
        this.setState({
          snackbarMessage: 'Please select at least one recruiter to email.',
          snackbarOpen: true,
          snackbarVariant: 'error',
        });
      }
      return;
    }
    this.setState({
      previewDialogOpen: true,
      previewDialogProps: {
        hanldeSendEmail: this.handleSendEmails,
        messages: emailMessages,
        recipientType: emailFields.recipientType,
      },
    });
  };

  handleDialogClose = (name) => () => {
    if (name === 'previewDialogOpen') {
      this.setState({
        [name]: false,
        previewDialogProps: {
          messages: [],
          recipientType: 'applicants',
        },
      });
    } else {
      this.setState({
        [name]: false,
      });
    }
  };

  handleDialogOpen = (name) => () => {
    this.setState({
      [name]: true,
    });
  };

  handleCheckboxChanged = (personId) => {
    this.setState((previousState) => {
      if (previousState.recipientType === 'applicants') {
        const prevChecked = previousState.applicantsChecked;
        const checked = prevChecked[personId];
        prevChecked[personId] = !checked;
        return {
          'applicantsChecked': prevChecked,
        };
      }
      const prevChecked = previousState.recruitersChecked;
      const checked = prevChecked[personId];
      prevChecked[personId] = !checked;
      return {
        'recruitersChecked': prevChecked,
      };
    });
  };

  handleRecipientTypeChanged = (type) => {
    this.setState((previousState) => {
      if (previousState.type === 'recruiters') {
        const prevChecked = previousState.recruitersChecked;
        Object.keys(prevChecked).forEach(v => prevChecked[v] = false);
        return {
          'recruitersChecked': prevChecked,
          'recipientType': type,
        };
      }
      const prevChecked = previousState.applicantsChecked;
      Object.keys(prevChecked).forEach((v) => prevChecked[v] = false);
      return {
        'applicantsChecked': prevChecked,
        'recipientType': type,
      };
    });
  };

  handleEmailTemplateChange = (event) => {
    this.setState({
      currentTemplate: event.target.value,
      recipientType: event.target.value.recipient_type,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  deleteTemplate = (payload) => {
    this.props.deleteEmailTemplate(payload);
    this.props.getEmailTemplates();
    this.setState({
      deleteEmailTemplateDialogOpen: false,
      snackbarOpen: true,
      snackbarVariant: 'success',
      snackbarMessage: 'Template succuessfully deleted',
    });
  };

  validateEmailBody = () => {
    const openingLength = this.editor.state.body.split('{').length - 1;
    const closingLength = this.editor.state.body.split('}').length - 1;
    return openingLength - closingLength === 0;
  };

  updateTemplate = () => {
    if (this.validateEmailBody()) {
      this.setState((previousState) => {
        const payload = {
          id: previousState.currentTemplate.id,
          name: this.editor.state.name,
          sent_from: this.editor.state.from,
          body: this.editor.state.body,
          subject: this.editor.state.subject,
          recipient_type: this.editor.state.recipientType,
        };
        this.props.updateEmailTemplate(payload);
        return {
          'snackbarMessage': `Email Template ${payload.name} has been successfully updated.`,
          'snackbarOpen': true,
          'snackbarVariant': 'success',
          'currentTemplate': emptyTemplate,
        };
      });
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarVariant: 'warning',
        snackbarMessage: 'Number of opening curly braces must match the number of closing braces.',
      });
    }
  };

  createEmailTemplate = (payload) => {
    const newData = {
      name: payload.name,
      body: payload.copiedFrom === emptyTemplate ? '' : payload.copiedFrom.body,
      subject: payload.copiedFrom === emptyTemplate ? '' : payload.copiedFrom.subject,
      sent_from: payload.copiedFrom === emptyTemplate ? '' : payload.copiedFrom.sent_from,
      recipient_type: payload.copiedFrom === emptyTemplate ? this.state.recipientType : payload.copiedFrom.recipient_type,
      year: this.props.year,
    };
    this.props.createEmailTemplate(newData);
    this.setState({
      addEmailTemplateDialogOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: `Email Template ${payload.name} successfully created.`,
      snackbarOpen: true,
    });
  };

  displaySnackbarMessage = (msg, variant) => {
    this.setState({
      'snackbarMessage': msg,
      'snackbarOpen': true,
      'snackbarVariant': variant,
    });
  };

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={`${styles['page-header']} ${styles['row']}`}>
              <h2 className={styles['email-header']}>Emails</h2>
              <div className={`${styles['template-options-container']} ${styles['row']}`}>
                <ReusableSelect
                  id="template"
                  key={this.state.currentTemplate}
                  excludeNoneOption
                  className={styles['email-template']}
                  value={this.state.currentTemplate}
                  label="Template"
                  onChange={this.handleEmailTemplateChange}
                  options={this.loading ? [] : this.generateTemplates}
                />
                <ReusableButton
                  id="newTemplate"
                  value="New"
                  onClick={this.handleDialogOpen('addEmailTemplateDialogOpen')}
                  className={styles['top-button']}
                />
                <ReusableButton
                  id="updateTemplate"
                  value="Update"
                  disabled={this.isEmptyTemplate}
                  className={styles['top-button']}
                  onClick={this.updateTemplate}
                />
                <ReusableButton
                  id="deleteTemplate"
                  value="Delete"
                  className={styles['top-button']}
                  onClick={this.handleDialogOpen('deleteEmailTemplateDialogOpen')}
                />
              </div>
            </div>
            <div className={styles['row']}>
              <EmailSidebar
                className={styles['email-sidebar-container']}
                key={`${this.state.currentTemplate.id} ${this.state.recipientType}`}
                loading={this.isLoading}
                type={this.state.recipientType}
                recruiters={this.props.recruiters.length === 0 ? [] : this.recruiters}
                recruitersChecked={this.state.recruitersChecked}
                applicants={this.props.applicants.length === 0 ? [] : this.applicants}
                applicantsChecked={this.state.applicantsChecked}
                onCheckboxChanged={this.handleCheckboxChanged}
                onSelectAll={this.handleSelectAll}
                allSelected={this.allSelected}
                allWithVolunteersSelected={this.allWithVolunteersSelected}
                allWithAssignmentSelected={this.allWithAssignmentSelected}
              />
              <div className={styles['email-editor-container']}>
                <EmailEditor
                  ref={(editor) => this.editor = editor}
                  key={`${this.state.recipientType} ${this.state.currentTemplate.id}`}
                  loading={this.isLoading}
                  signature={this.props.emailSignature}
                  template={this.state.currentTemplate}
                  recipientType={this.state.recipientType}
                  onRecipientTypeChanged={this.handleRecipientTypeChanged}
                  updateTemplate={this.updateTemplate}
                  handlePreviewClicked={this.handlePreviewClicked}
                />
              </div>
            </div>
            <AddEmailTemplateDialog
              templates={this.loading ? [] : this.generateTemplates}
              open={this.state.addEmailTemplateDialogOpen}
              handleCloseAddTemplate={this.handleDialogClose('addEmailTemplateDialogOpen')}
              handleAddTemplate={this.createEmailTemplate}
            />
            <PreviewEmailDialog
              key={`${this.state.previewDialogProps.messages} ${this.state.previewDialogProps.recipientType}`}
              open={this.state.previewDialogOpen}
              handleClosePreviewEmail={this.handleDialogClose('previewDialogOpen')}
              handleSendEmails={this.handleSendEmails}
              recipientType={this.state.previewDialogProps.recipientType}
              messages={this.state.previewDialogProps.messages}
              displaySnackbarMessage={this.displaySnackbarMessage}
            />
            <DeleteEmailTemplateDialog
              open={this.state.deleteEmailTemplateDialogOpen}
              currentTemplate={this.loading ? [] : this.state.currentTemplate}
              handleCloseDeleteTemplate={this.handleDialogClose('deleteEmailTemplateDialogOpen')}
              handleDeleteTemplate={this.deleteTemplate}
              templates={this.props.emailTemplates.map((template) => Object({ display: template.name, value: template }))}
            />
            <ReusableSnackbar
              open={this.state.snackbarOpen}
              onClose={this.handleSnackbarClose}
              variant={this.state.snackbarVariant}
              message={this.state.snackbarMessage}
            />
          </div>
        </div>
      </div>
    );
  }
}

EmailsPage.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  emailSignature: PropTypes.string.isRequired,
  getEmailSignature: PropTypes.func.isRequired,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  getYear: PropTypes.func.isRequired,
  setApplicantsSelected: PropTypes.func.isRequired,
  applicantsSelected: PropTypes.object,
  applicants: PropTypes.array.isRequired,
  applicantsLoading: PropTypes.bool.isRequired,
  getApplicants: PropTypes.func.isRequired,
  setRecruitersSelected: PropTypes.func.isRequired,
  recruitersSelected: PropTypes.object,
  recruiters: PropTypes.array.isRequired,
  recruitersLoading: PropTypes.bool.isRequired,
  getRecruiters: PropTypes.func.isRequired,
  emailTemplates: PropTypes.array.isRequired,
  emailTemplatesLoading: PropTypes.bool.isRequired,
  getEmailTemplates: PropTypes.func.isRequired,
  updateEmailTemplate: PropTypes.func.isRequired,
  deleteEmailTemplate: PropTypes.func.isRequired,
  createEmailTemplate: PropTypes.func.isRequired,
  createEmailMessage: PropTypes.func.isRequired,
};

export default EmailsPage;
