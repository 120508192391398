import React from 'react';
import PropTypes from 'prop-types';

import ReusableButton from 'components/common/ReusableButton';
import styles from './style.module.scss';

class SummaryStep extends React.Component {
  get statusName() {
    return this.props.status ? this.props.status.name : 'N/A';
  }

  get statusId() {
    return this.props.status ? this.props.status.id : -1;
  }

  get affiliationName() {
    return this.props.affiliation ? this.props.affiliation.name : 'N/A';
  }

  formattedTaskPreferencesString = (preference) => {
    const tasksForPreference = this.props.tasksAvailableForApplicant.filter(task => this.props.taskPreferences[`task${task.id}`] === preference);
    const taskNamesForPreference = [];

    tasksForPreference.forEach(task => {
      const name = task.display_string ? task.display_string : task.name;
      if (!taskNamesForPreference.includes(name)) {
        taskNamesForPreference.push(name);
      }
    });

    return taskNamesForPreference.join(', ');
  };

  render() {
    return (
      <div className={styles['summary-container']}>
        <div className={styles['summary-section-header']}>
          <h4>
            Application
          </h4>
          {!this.props.onPastApplicationPage
          && (
            <ReusableButton
              className={styles['edit-button']}
              onClick={() => this.props.goToStep(0)}
              value="Edit"
            />
          )}
        </div>
        <div className={styles['summary-section']}>
          <div>
            <b>
              Roles:&nbsp;
            </b>
            {this.props.selectedRoles.join(', ')}
          </div>
          <div>
            <b>
              Status:&nbsp;
            </b>
            {this.statusName}
          </div>
          <div>
            <b>
              Affiliation:&nbsp;
            </b>
            {this.affiliationName}
          </div>
          {this.statusId === 1
          && (
            <>
              <div>
                <b>
                  Recruiter:&nbsp;
                </b>
                {this.props.recruiter ? `${this.props.recruiter.last_name}, ${this.props.recruiter.first_name}` : 'N/A'}
              </div>
              <div>
                <b>
                  Recruited from:&nbsp;
                </b>
                {this.props.recruitedFrom ? this.props.recruitedFrom.name : 'N/A'}
              </div>
            </>
          )}
          <div>
            <b>
              Comments:&nbsp;
            </b>
            {this.props.comments ? this.props.comments : 'N/A'}
          </div>
        </div>
        <div className={styles['summary-section-header']}>
          <h4>
            YASP
          </h4>
        </div>
        <div className={styles['summary-section']}>
          YASP signed by&nbsp;
          {`${this.props.currentUser.first_name} ${this.props.currentUser.last_name}`}
          &nbsp;on&nbsp;
          {this.props.yaspFormSignedDate.format('MM/DD/YYYY')}
          &nbsp;at&nbsp;
          {this.props.yaspFormSignedDate.format('hh:mm A')}
          .
        </div>
        <div className={styles['summary-section-header']}>
          <h4>
            Task Signup
          </h4>
          {!this.props.onPastApplicationPage
          && (
            <ReusableButton
              className={styles['edit-button']}
              onClick={() => this.props.goToStep(2)}
              value="Edit"
            />
          )}
        </div>
        <div className={styles['summary-section']}>
          <div>
            <b>
              Available during:&nbsp;
            </b>
            {this.props.enteredTimeWindows.map(timeWindow => this.props.formatTimeWindow(timeWindow)).join(', ')}
          </div>
          <div>
            <b>
              Task preferences:&nbsp;
            </b>
            <br />
            Preferred:&nbsp;
            {this.formattedTaskPreferencesString(4)}
            <br />
            Acceptable:&nbsp;
            {this.formattedTaskPreferencesString(3)}
            <br />
            Avoid:&nbsp;
            {this.formattedTaskPreferencesString(2)}
            <br />
            Can&apos;t Do:&nbsp;
            {this.formattedTaskPreferencesString(1)}
          </div>
          <div>
            <b>
              Comments:&nbsp;
            </b>
            {this.props.comments ? this.props.comments : 'N/A'}
          </div>
        </div>
        {this.props.onPastApplicationPage
        && (
          <>
            <div className={styles['summary-section-header']}>
              <h4>
                Task Assignments
              </h4>
            </div>
            <div className={styles['summary-section']}>
              {/* TODO: Implement the displaying of task assignments here once that is done. */}
              You have not been assigned to any tasks yet.
            </div>
          </>
        )}
      </div>
    );
  }
}

SummaryStep.propTypes = {
  currentUser: PropTypes.object.isRequired,
  goToStep: PropTypes.func,
  selectedRoles: PropTypes.array.isRequired,
  status: PropTypes.object,
  affiliation: PropTypes.object,
  recruiter: PropTypes.object,
  recruitedFrom: PropTypes.object,
  comments: PropTypes.string,
  yaspFormSignedDate: PropTypes.object,
  enteredTimeWindows: PropTypes.array,
  formatTimeWindow: PropTypes.func.isRequired,
  tasksAvailableForApplicant: PropTypes.array,
  taskPreferences: PropTypes.object,
  onPastApplicationPage: PropTypes.bool,
};

export default SummaryStep;
