import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button } from '@material-ui/core/';
import PropTypes from 'prop-types';

import styles from '../style.module.scss';

const dialogTitleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(dialogTitleStyles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const taskFilterCheckboxRowStyle = {
  marginTop: '-7px',
  marginBottom: '-7px',
  marginLeft: '5px',
};

class TaskTableFilterDialog extends React.Component {
  render() {
    return (
      <Dialog open={this.props.taskFilterOpen} onClose={this.props.handleCloseTaskFilter} className={styles['task-filter']}>
        <DialogTitle onClose={this.props.handleCloseTaskFilter} />
        <DialogContent className={styles['confirm-dialog']}>
          <div className={`${styles['filter-non-checkbox-list-row']}`}>
            <b> Tasks: </b>
            {Object.keys(this.props.checkedTasks).every(taskID => this.props.checkedTasks[taskID] === true)
              ? <Button disableRipple variant="contained" onClick={this.props.handleSelectNoneTasks}>None</Button>
              : <Button disableRipple variant="contained" onClick={this.props.handleSelectAllTasks}>All</Button>}
          </div>
          <FormGroup>
            {(!this.props.activitiesLoading) && (
              this.props.activities.filter(activity => activity.year === this.props.year).flatMap(activity => {
                const tasks = [];
                tasks.push(<div className={`${styles['filter-checkbox-activity-name']}`}>{activity.name}</div>);
                this.props.tasks.filter(task => task.year === this.props.year).forEach(task => {
                  if (task.activity_id === activity.id) {
                    tasks.push(
                      <FormControlLabel
                        key={task.id}
                        control={(
                          <Checkbox
                            color="default"
                            size="small"
                            disableRipple
                            checked={this.props.checkedTasks[task.id] ?? true}
                            name={`${task.id}`}
                            onChange={this.props.handleTaskCheckbox}
                          />
                        )}
                        label={task.abbr.trim() || task.name}
                        style={taskFilterCheckboxRowStyle}
                      />
                    );
                  }
                });

                return tasks;
              }))}
          </FormGroup>
          <br />
          <b>
            <div className={`${styles['filter-non-checkbox-list-row']}`}>
              <b>Time Filter</b>
              <Button disableRipple variant="contained" onClick={this.props.handleClearTaskTimeFilter}>Clear</Button>
            </div>
          </b>
          <input
            type="time"
            id="begin_time"
            min="08:00"
            max="18:00"
            value={this.props.beginningTaskTimeFilter}
            onChange={this.props.handleBeginningTaskTimeChange}
            required
          />
          to
          <input
            type="time"
            id="end_time"
            min="08:00"
            max="18:00"
            value={this.props.endingTaskTimeFilter}
            onChange={this.props.handleEndingTaskTimeChange}
            required
          />
        </DialogContent>
      </Dialog>
    );
  }
}

TaskTableFilterDialog.propTypes = {
  taskFilterOpen: PropTypes.bool.isRequired,
  checkedTasks: PropTypes.object.isRequired,
  handleSelectNoneTasks: PropTypes.func.isRequired,
  handleSelectAllTasks: PropTypes.func.isRequired,
  activitiesLoading: PropTypes.bool.isRequired,
  activities: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  handleTaskCheckbox: PropTypes.func.isRequired,
  handleClearTaskTimeFilter: PropTypes.func.isRequired,
  beginningTaskTimeFilter: PropTypes.string.isRequired,
  handleBeginningTaskTimeChange: PropTypes.func.isRequired,
  endingTaskTimeFilter: PropTypes.string.isRequired,
  handleEndingTaskTimeChange: PropTypes.func.isRequired,
  handleCloseTaskFilter: PropTypes.func.isRequired,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

export default TaskTableFilterDialog;
