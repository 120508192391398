import ApiWrapper from 'wrappers/api';
import fileDownload from 'js-file-download';

import {
  getAssignmentsRequestSent,
  getAssignmentsRequestSuccessful,
  createAssignmentRequestSent,
  createAssignmentRequestSuccessful,
  deleteAssignmentRequestSent,
  deleteAssignmentRequestSuccessful,
  updateAssignmentRequestSent,
  updateAssignmentRequestSuccessful,
  getLocationsRequestSent,
  getLocationsRequestSuccessful,
} from 'actions/atomic/assignmentByUsers';

export function getAssignments() {
  return (dispatch) => {
    dispatch(getAssignmentsRequestSent());
    return ApiWrapper.get('/assignments')
      .then(response => {
        dispatch(getAssignmentsRequestSuccessful(response.data));
      });
  };
}

export function createAssignment(newAssignment) {
  return (dispatch) => {
    dispatch(createAssignmentRequestSent());
    return ApiWrapper.post('/assignments', newAssignment)
      .then(response => {
        dispatch(createAssignmentRequestSuccessful(response.data));
      });
  };
}

export function updateAssignment(newAssignment) {
  return (dispatch) => {
    dispatch(updateAssignmentRequestSent());
    return ApiWrapper.put(`/assignments/${newAssignment.id}`, newAssignment)
      .then(response => {
        dispatch(updateAssignmentRequestSuccessful(response.data));
      });
  };
}

export function deleteAssignment(oldAssignment) {
  return (dispatch) => {
    dispatch(deleteAssignmentRequestSent());
    return ApiWrapper.delete(`/assignments/${oldAssignment.id}`, oldAssignment)
      .then((response) => {
        dispatch(deleteAssignmentRequestSuccessful(response.data));
      });
  };
}

export function getLocations() {
  return (dispatch) => {
    dispatch(getLocationsRequestSent());
    return ApiWrapper.get('/locations')
      .then(response => {
        dispatch(getLocationsRequestSuccessful(response.data));
      });
  };
}

export function downloadAssignments(year) {
  return () => ApiWrapper.get(`/assignments/download?year=${year}`, { responseType: 'blob' })
    .then(response => fileDownload(response.data, 'assignments.xlsx'));
}
