import { connect } from 'react-redux';

import ApplicantsPage from 'components/pages/admin/ApplicantsPage';
import { selectIsAdmin, selectIsLoggedIn } from 'selectors/authenticationSelectors';
import { selectApplicantsLoading, selectApplicants, selectExistingUsers } from 'selectors/applicantsSelectors';
import { selectGenderOptions } from 'selectors/usersSelectors';
import { setApplicantsSelected } from 'actions/thunks/emails';
import {
  getApplicants,
  downloadApplicants,
  findExistingUsers,
  resetDialog,
  createApplicantFromTable,
  updateApplicantFromTable,
  createUserFromDialog,
} from 'actions/thunks/applicants';
import { getYear } from 'actions/thunks/systemSettings';
import { getEventDates } from 'actions/thunks/eventDates';
import { selectYear } from 'selectors/systemSettingsSelectors';
import { selectEventDates } from 'selectors/eventDatesSelectors';
import { updateUserFromTable } from 'actions/thunks/users';
import { selectStatuses } from 'selectors/statusesSelectors';
import { getStatuses } from 'actions/thunks/statuses';
import { selectAffiliations } from 'selectors/affiliationsSelectors';
import { selectRecruiters } from 'selectors/recruitersSelectors';
import { selectClasses } from 'selectors/classesSelectors';
import { getAffiliations } from 'actions/thunks/affiliations';
import { getRecruiters } from 'actions/thunks/recruiters';
import { getClasses } from 'actions/thunks/classes';
import { getTasks } from 'actions/thunks/activities';
import { selectTasks } from 'selectors/activitiesSelectors';

const mapStateToProps = state => ({
  isAdmin: selectIsAdmin(state),
  isLoggedIn: selectIsLoggedIn(state),
  loading: selectApplicantsLoading(state),
  applicants: selectApplicants(state),
  year: selectYear(state),
  eventDates: selectEventDates(state),
  existingUsers: selectExistingUsers(state),
  genderOptions: selectGenderOptions(state),
  statuses: selectStatuses(state),
  affiliations: selectAffiliations(state),
  recruiters: selectRecruiters(state),
  classes: selectClasses(state),
  tasks: selectTasks(state),
});

const mapDispatchToProps = dispatch => ({
  getApplicants: () => dispatch(getApplicants()),
  getYear: () => dispatch(getYear()),
  getEventDates: () => dispatch(getEventDates()),
  downloadApplicants: (year) => dispatch(downloadApplicants(year)),
  setApplicantsSelected: (applicantsSelected) => dispatch(setApplicantsSelected(applicantsSelected)),
  findExistingUsers: (payload) => dispatch(findExistingUsers(payload)),
  resetDialog: () => dispatch(resetDialog()),
  createUser: (payload) => dispatch(createUserFromDialog(payload)),
  updateUser: (payload) => dispatch(updateUserFromTable(payload)),
  getStatuses: () => dispatch(getStatuses()),
  getAffiliations: () => dispatch(getAffiliations()),
  getRecruiters: () => dispatch(getRecruiters()),
  getClasses: () => dispatch(getClasses()),
  getTasks: () => dispatch(getTasks()),
  createApplicant: (payload) => dispatch(createApplicantFromTable(payload)),
  updateApplicant: (payload) => dispatch(updateApplicantFromTable(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplicantsPage);
