import React from 'react';
import ReusableTable from 'components/common/ReusableTable';
import PropTypes from 'prop-types';
import { renderAssignedTaskName, renderAssignedType, renderAssignedTime } from '../utils';
import styles from '../style.module.scss';

class ApplicantTaskUnassignTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Applicant Assigned Table (Unassign Menu) from Applicant Table
      assignedApplicantColumns: [
        {
          title: 'Task',
          tooltip: 'Task name',
          render: (solutionAssignment) => renderAssignedTaskName(solutionAssignment),
          customFilterAndSearch: (term, solutionAssignment) => (term === renderAssignedTaskName(solutionAssignment).slice(0, term.length)) || (term === `${solutionAssignment.task?.name}`.slice(0, term.length)),
        },
        {
          title: 'L/B',
          field: 'back_up',
          tooltip: 'The assignment type for the applicant (L or B)',
          render: (solutionAssignment) => renderAssignedType(solutionAssignment),
        },
        {
          title: 'Time',
          tooltip: 'The time windows that the applicant entered.',
          render: (solutionAssignment) => renderAssignedTime(solutionAssignment),
        },
      ],
      assignedApplicantOptions: {
        filtering: false,
        padding: 'dense',
        maxBodyHeight: 700,
        selection: false,
        hideDeleteButton: true,
        paging: false,
        search: false,
        showColumns: false,
        // actionsColumnIndex: -1,
        rowStyle: rowData => ({
          backgroundColor: this.props.tasks.find(task => task.id === rowData.task.id).background_color ? this.props.tasks.find(task => task.id === rowData.task.id).background_color : '',
          color: this.props.tasks.find(task => task.id === rowData.task.id).text_color ? this.props.tasks.find(task => task.id === rowData.task.id).text_color : '',
        }),
        headerStyle: {
          fontFamily: 'Gotham SSm A, Gotham SSm B, Verdana, sans-serif',
          backgroundColor: '#eae9e6',
          color: '#d00000',
          whiteSpace: 'nowrap',
        },
      },
      assignedApplicantActions: [
        {
          icon: 'delete',
          tooltip: 'Unassign',
          position: 'row',
          onClick: (event, solutionAssignment) => this.props.handleUnassign(solutionAssignment),
        },
      ],
    };
  }

  render() {
    return (
      <div className={styles['applicant-task-unassign-table-container']}>
        <ReusableTable
          title={`Assigned (${this.props.data.length})`}
          columns={this.state.assignedApplicantColumns}
          options={this.state.assignedApplicantOptions}
          data={this.props.data}
          isLoading={this.props.applicantsLoading}
          actions={this.state.assignedApplicantActions}
        />
      </div>
    );
  }
}

ApplicantTaskUnassignTable.propTypes = {
  data: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  applicantsLoading: PropTypes.bool.isRequired,
  handleUnassign: PropTypes.func.isRequired,
};

export default ApplicantTaskUnassignTable;
