import { fromJS } from 'immutable';

import {
  GET_USERS_SENT,
  GET_USERS_SUCCESSFUL,
  UPDATE_USER_FROM_TABLE_SENT,
  UPDATE_USER_FROM_TABLE_SUCCESSFUL,
  CREATE_USER_SENT,
  CREATE_USER_SUCCESSFUL,
  RESET_CREATE_USER_FIELDS,
  ACTIVATE_USER_SENT,
  ACTIVATE_USER_SUCCESSFUL,
  REQUEST_PASSWORD_RESET_REQUEST_SENT,
  REQUEST_PASSWORD_RESET_REQUEST_SUCCESSFUL,
  RESET_USER_NOT_FOUND,
  RESET_PASSWORD_REQUEST_SENT,
  RESET_PASSWORD_REQUEST_SUCCESSFUL,
  CREATE_USER_FROM_TABLE_SENT,
  CREATE_USER_FROM_TABLE_SUCCESSFUL,
  UPDATE_CURRENT_USER_SENT,
  UPDATE_CURRENT_USER_SUCCESSFUL,
} from 'actions/atomic/users';

const initialState = fromJS({
  loading: false,
  data: [],
  genderOptions: [
    {
      value: 'Male',
      display: 'Male',
    },
    {
      value: 'Female',
      display: 'Female',
    },
    {
      value: 'Other',
      display: 'Other',
    },
    {
      value: 'Prefer not to answer',
      display: 'Prefer not to answer',
    },
  ],
  createUserRequestComplete: false,
  creationSuccessful: undefined,
  userExists: undefined,
  activated: undefined,
  accountActivated: false,
  expiredActivationToken: undefined,
  invalidActivationToken: undefined,
  invalidUser: undefined,
  passwordResetEmailSent: false,
  userNotFound: undefined,
  passwordReset: false,
  invalidToken: undefined,
  updateCurrentUserRequestComplete: false,
});

export default function users(state = initialState, action) {
  switch (action.type) {
  case GET_USERS_SENT:
    return state.merge({
      loading: true,
      data: fromJS([]),
    });
  case GET_USERS_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: fromJS(action.data),
    });
  case CREATE_USER_SENT:
    return state.merge({
      createUserRequestComplete: false,
      creationSuccessful: undefined,
      userExists: undefined,
      activated: undefined,
    });
  case CREATE_USER_SUCCESSFUL:
    return state.merge({
      createUserRequestComplete: true,
      creationSuccessful: action.data.creationSuccessful,
      userExists: action.data.userExists,
      activated: action.data.activated,
    });
  case RESET_CREATE_USER_FIELDS:
    return state.merge({
      createUserRequestComplete: false,
      creationSuccessful: undefined,
      userExists: undefined,
      activated: undefined,
    });
  case ACTIVATE_USER_SENT:
    return state.merge({
      accountActivated: false,
      expiredActivationToken: undefined,
      invalidActivationToken: undefined,
      invalidUser: undefined,
    });
  case ACTIVATE_USER_SUCCESSFUL:
    return state.merge({
      accountActivated: action.data.accountActivated,
      expiredActivationToken: action.data.expiredActivationToken,
      invalidActivationToken: action.data.invalidActivationToken,
      invalidUser: action.data.invalidUser,
    });
  case REQUEST_PASSWORD_RESET_REQUEST_SENT:
    return state.merge({
      passwordResetEmailSent: false,
      userNotFound: undefined,
    });
  case REQUEST_PASSWORD_RESET_REQUEST_SUCCESSFUL:
    return state.merge({
      passwordResetEmailSent: action.data.passwordResetEmailSent,
      userNotFound: action.data.userNotFound,
    });
  case RESET_USER_NOT_FOUND:
    return state.merge({
      passwordResetEmailSent: false,
      userNotFound: undefined,
    });
  case RESET_PASSWORD_REQUEST_SENT:
    return state.merge({
      passwordReset: false,
      invalidToken: undefined,
    });
  case RESET_PASSWORD_REQUEST_SUCCESSFUL:
    return state.merge({
      passwordReset: action.data.passwordReset,
      invalidToken: action.data.invalidToken,
    });
  case CREATE_USER_FROM_TABLE_SENT:
    return state.set('loading', true);
  case CREATE_USER_FROM_TABLE_SUCCESSFUL:
    return state.merge({
      loading: false,
      data: state.get('data').push(fromJS(action.data)),
    });
  case UPDATE_USER_FROM_TABLE_SENT:
    return state.set('loading', true);
  case UPDATE_USER_FROM_TABLE_SUCCESSFUL:
    const updatedUser = action.data;
    const index = state.get('data').findIndex(status => status.get('id') === updatedUser.id);
    return state.merge({
      loading: false,
      data: state.get('data').set(index, fromJS(updatedUser)),
    });
  case UPDATE_CURRENT_USER_SENT:
    return state.set('updateCurrentUserRequestComplete', false);
  case UPDATE_CURRENT_USER_SUCCESSFUL:
    return state.set('updateCurrentUserRequestComplete', true);
  default:
    return state;
  }
}
