import React from 'react';
import PropTypes from 'prop-types';
import { Router, Route } from 'react-router-dom';

import LoadingOverlay from 'react-loading-overlay';
import history from 'wrappers/history';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import NotFoundPageContainer from 'containers/NotFoundPageContainer';
import routes from 'routes';
import styles from './style.module.scss';

const RouteWithSubRoutes = (route) => (
  <Route
    exact
    path={route.path}
    render={(props) => (
      <route.component {...props} routes={route.routes} />
    )}
  />
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.props.validateLogin();
  }

  render() {
    if (this.props.loginValidationComplete) {
      return (
        <Router history={history}>
          <Header
            isLoggedIn={this.props.isLoggedIn}
            isAdmin={this.props.isAdmin}
            logout={this.props.logout}
            currentYear={this.props.currentYear}
            getYear={this.props.getYear}
            eventDates={this.props.eventDates}
            getEventDates={this.props.getEventDates}
          />
          <div className={styles['body']}>
            <div>
              {routes.map((route) => (
                <RouteWithSubRoutes key={route.path} {...route} />
              ))}
              <Route component={NotFoundPageContainer} />
            </div>
          </div>
          <Footer />
        </Router>
      );
    }

    return (
      <LoadingOverlay active spinner text="Please wait..." />
    );
  }
}

App.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  validateLogin: PropTypes.func.isRequired,
  loginValidationComplete: PropTypes.bool.isRequired,
  currentYear: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  getYear: PropTypes.func.isRequired,
  eventDates: PropTypes.array.isRequired,
  getEventDates: PropTypes.func.isRequired,
};

export default App;
