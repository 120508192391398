/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import ApplicantsTable from 'components/common/ApplicantsTable';
import ReusableTable from 'components/common/ReusableTable';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableTextFieldValidator from 'components/common/ReusableTextFieldValidator';
import { isFieldEmpty, fields } from 'utils/validation';
import history from 'wrappers/history';
import styles from './style.module.scss';

class RecruitersPage extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }

    this.state = {
      lastNameError: false,
      firstNameError: false,
      emailError: false,
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: '',
      applicants: [],
      applicantsTableOpen: false,
      columns: [
        {
          title: 'Last Name',
          field: 'last_name',
          tooltip: 'The last name of the faculty recruiter.',
          editComponent: (lastName) => (
            <ReusableTextFieldValidator
              text={lastName}
              onChange={(event) => lastName.onChange(event.target.value)}
              regExp={fields.lastName.regExp}
              error={this.state.lastNameError}
              onError={(error) => this.setState({ lastNameError: error })}
            />
          ),
        },
        {
          title: 'First Name',
          field: 'first_name',
          tooltip: 'The first name of the faculty recruiter.',
          editComponent: (firstName) => (
            <ReusableTextFieldValidator
              text={firstName}
              onChange={(event) => firstName.onChange(event.target.value)}
              regExp={fields.firstName.regExp}
              error={this.state.firstNameError}
              onError={(error) => this.setState({ firstNameError: error })}
            />
          ),
        },
        {
          title: 'Email',
          field: 'email',
          tooltip: 'The email of the faculty recruiter.',
          editComponent: (email) => (
            <ReusableTextFieldValidator
              text={email}
              onChange={(event) => email.onChange(event.target.value)}
              regExp={fields.email.regExp}
              error={this.state.emailError}
              onError={(error) => this.setState({ emailError: error })}
            />
          ),
        },
        {
          title: 'Active',
          field: 'approved',
          type: 'boolean',
          tooltip: 'Students are only able to select recruiters that are marked as active.',
        },
        {
          title: '#Volunteers',
          editable: 'never',
          customSort: (a, b) => this.numberOfApplicants(a) - this.numberOfApplicants(b),
          render: recruiter => (
            <div className={styles['applicant-button']}>
              <Button onClick={() => this.openApplicantsTable(recruiter)}>
                {this.numberOfApplicants(recruiter)}
              </Button>
            </div>
          ),
        },
      ],
      options: {
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 600,
        selection: true,
        hideDeleteButton: true,
      },
      editable: {
        isEditable: () => true,
        isDeletable: () => false,
        onRowAdd: (newRecruiter) => new Promise((resolve, reject) => {
          const errorMessage = this.validateFields(newRecruiter);
          if (errorMessage === '') {
            this.props.createRecruiter(newRecruiter);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'success',
              snackbarMessage: `Recruiter ${newRecruiter.first_name} ${newRecruiter.last_name} successfully added.`,
            });
            resolve();
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: errorMessage,
            });
            reject();
          }
        }),
        onRowUpdate: (updatedRecruiter) => new Promise((resolve, reject) => {
          const errorMessage = this.validateFields(updatedRecruiter);
          if (errorMessage === '') {
            this.props.updateRecruiter(updatedRecruiter);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'success',
              snackbarMessage: `Recruiter ${updatedRecruiter.first_name} ${updatedRecruiter.last_name} successfully updated.`,
            });
            resolve();
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: errorMessage,
            });
            reject();
          }
        }),
        onRowDelete: () => new Promise((resolve) => {
          resolve();
        }),
      },
      actions: [
        {
          icon: 'replay',
          tooltip: 'Refetch Data',
          isFreeAction: true,
          onClick: () => this.props.getRecruiters(),
        },
        {
          icon: 'save_alt',
          tooltip: 'Export',
          isFreeAction: true,
          onClick: () => {
            this.props.downloadRecruiters();
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'info',
              snackbarMessage: 'Download in progress.',
            });
          },
        },
        {
          icon: 'email',
          tooltip: 'Email Selected Recruiters',
          displayDuringSelect: true,
          onClick: (event, data) => this.handleEmailRecruiters(data),
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.year === '') {
      this.props.getYear();
    }
    if (this.props.recruiters.length === 0) {
      this.props.getRecruiters();
    }
    if (this.props.applicants.length === 0) {
      this.props.getApplicants();
    }
  }

  numberOfApplicants = (recruiter) => {
    let numberOfApplicants = 0;
    this.props.applicants.filter(applicant => applicant.year === this.props.year).forEach(applicant => {
      if (applicant.recruiter && applicant.recruiter.id === recruiter.id) {
        ++numberOfApplicants;
      }
    });
    return numberOfApplicants;
  };

  openApplicantsTable = (recruiter) => {
    const recruiterApplicants = [];
    this.props.applicants.forEach(applicant => {
      if (applicant.recruiter && applicant.recruiter.id === recruiter.id) {
        recruiterApplicants.push(applicant);
      }
    });
    this.setState({ applicantsTableOpen: true, applicants: recruiterApplicants });
  };

  closeApplicantsTable = () => {
    this.setState({ applicantsTableOpen: false });
  };

  handleEmailRecruiters = (recruiters) => {
    const allActive = recruiters.every((recruiter) => recruiter.approved);
    if (allActive) {
      const recruitersSelected = {};
      recruiters.forEach((recruiter) => recruitersSelected[recruiter.id] = true);
      this.props.setRecruitersSelected(recruitersSelected);
      history.push('/admin/email');
    } else {
      this.setState({
        snackbarVariant: 'error',
        snackbarOpen: true,
        snackbarMessage: 'Unable to email inactive recruiters.\n  Please make another selection or switch the recruiter\'s status.',
      });
    }
  };

  validateFields = (recruiter) => {
    let errorMessage = '';
    if (this.state.lastNameError || isFieldEmpty(recruiter.last_name)) {
      errorMessage += fields.lastName.errorMessage;
    }
    if (this.state.firstNameError || isFieldEmpty(recruiter.first_name)) {
      errorMessage += fields.firstName.errorMessage;
    }
    if (this.state.emailError || isFieldEmpty(recruiter.email)) {
      errorMessage += fields.email.errorMessage;
    }

    return errorMessage;
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['table-container']}>
              <ReusableTable
                title="Recruiters"
                columns={this.state.columns}
                data={this.props.recruiters}
                options={this.state.options}
                editable={this.state.editable}
                isLoading={this.props.recruitersLoading || this.props.applicantsLoading}
                actions={this.state.actions}
              />
              <ReusableSnackbar
                open={this.state.snackbarOpen}
                onClose={this.handleSnackbarClose}
                variant={this.state.snackbarVariant}
                message={this.state.snackbarMessage}
              />
              <Dialog
                fullWidth
                maxWidth="lg"
                open={this.state.applicantsTableOpen}
                onClose={this.closeApplicantsTable}
              >
                <ApplicantsTable
                  loading={this.props.recruitersLoading || this.props.applicantsLoading}
                  applicants={this.state.applicants}
                  year={this.props.year}
                  eventDates={this.props.eventDates}
                  getYear={this.props.getYear}
                  getEventDates={this.props.getEventDates}
                />
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RecruitersPage.propTypes = {
  applicants: PropTypes.array.isRequired,
  applicantsLoading: PropTypes.bool.isRequired,
  getApplicants: PropTypes.func.isRequired,
  recruiters: PropTypes.array.isRequired,
  recruitersLoading: PropTypes.bool.isRequired,
  getRecruiters: PropTypes.func.isRequired,
  createRecruiter: PropTypes.func.isRequired,
  updateRecruiter: PropTypes.func.isRequired,
  downloadRecruiters: PropTypes.func.isRequired,
  setRecruitersSelected: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  getYear: PropTypes.func.isRequired,
  eventDates: PropTypes.array.isRequired,
  getEventDates: PropTypes.func.isRequired,
};

export default RecruitersPage;
