import { connect } from 'react-redux';

import {
  selectIsLoggedIn,
} from 'selectors/authenticationSelectors';
import {
  selectAccountActivated,
  selectInvalidActivationToken,
  selectExpiredActivationToken,
  selectInvalidUser,
} from 'selectors/usersSelectors';
import {
  activateAccount,
} from 'actions/thunks/users';
import AccountActivationPage from 'components/pages/AccountActivationPage';

const mapStateToProps = state => ({
  isLoggedIn: selectIsLoggedIn(state),
  accountActivated: selectAccountActivated(state),
  invalidActivationToken: selectInvalidActivationToken(state),
  expiredActivationToken: selectExpiredActivationToken(state),
  invalidUser: selectInvalidUser(state),
});

const mapDispatchToProps = dispatch => ({
  activateAccount: payload => dispatch(activateAccount(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountActivationPage);
