import ApiWrapper from 'wrappers/api';
import fileDownload from 'js-file-download';

import {
  getActivitiesRequestSent,
  getActivitiesRequestSuccessful,
  updateActivityRequestSent,
  updateActivityRequestSuccessful,
  createActivityRequestSent,
  createActivityRequestSuccessful,
  getTasksRequestSent,
  getTasksRequestSuccessful,
  createTaskRequestSent,
  createTaskRequestSuccessful,
  updateTaskRequestSent,
  updateTaskRequestSuccessful,
  deleteActivityRequestSent,
  deleteActivityRequestSuccessful,
  deleteTaskRequestSent,
  deleteTaskRequestSuccessful,
  getAssignmentPrioritiesRequestSent,
  getAssignmentPrioritiesRequestSuccessful,
  updateAssignmentPriorityRequestSent,
  updateAssignmentPriorityRequestSuccessful,
} from 'actions/atomic/activities';

export function getAssignmentPriorities() {
  return (dispatch) => {
    dispatch(getAssignmentPrioritiesRequestSent());
    return ApiWrapper.get('/tasks/assignment-priorities')
      .then(response => {
        dispatch(getAssignmentPrioritiesRequestSuccessful(response.data));
      });
  };
}

export function updateAssignmentPriority(newTask) {
  return (dispatch) => {
    dispatch(updateAssignmentPriorityRequestSent());
    return ApiWrapper.put(`/tasks/assignment-priorities/${newTask.id}`, newTask)
      .then(response => {
        dispatch(updateAssignmentPriorityRequestSuccessful(response.data));
      });
  };
}

export function getActivities() {
  return (dispatch) => {
    dispatch(getActivitiesRequestSent());
    return ApiWrapper.get('/activities')
      .then(response => {
        dispatch(getActivitiesRequestSuccessful(response.data));
      });
  };
}

export function updateActivity(newActivity) {
  return (dispatch) => {
    dispatch(updateActivityRequestSent());
    return ApiWrapper.put(`/activities/${newActivity.id}`, newActivity)
      .then(response => {
        dispatch(updateActivityRequestSuccessful(response.data));
      });
  };
}

export function deleteActivity(oldActivity) {
  return (dispatch) => {
    dispatch(deleteActivityRequestSent());
    return ApiWrapper.delete(`/activities/${oldActivity.id}`, oldActivity)
      .then((response) => {
        dispatch(deleteActivityRequestSuccessful(response.data));
      });
  };
}

export function createActivity(newActivity) {
  return (dispatch) => {
    dispatch(createActivityRequestSent());
    return ApiWrapper.post('/activities', newActivity)
      .then(response => {
        dispatch(createActivityRequestSuccessful(response.data));
      });
  };
}

export function downloadActivities(year) {
  return () => ApiWrapper.get(`/activities/download?year=${year}`, { responseType: 'blob' })
    .then(response => fileDownload(response.data, 'activities.xlsx'));
}

export function getTasks() {
  return (dispatch) => {
    dispatch(getTasksRequestSent());
    return ApiWrapper.get('/tasks')
      .then(response => {
        dispatch(getTasksRequestSuccessful(response.data));
      });
  };
}

export function createTask(newTask) {
  return (dispatch) => {
    dispatch(createTaskRequestSent());
    return ApiWrapper.post('/tasks', newTask)
      .then(response => {
        dispatch(createTaskRequestSuccessful(response.data));
      });
  };
}

export function updateTask(newTask) {
  return (dispatch) => {
    dispatch(updateTaskRequestSent());
    return ApiWrapper.put(`/tasks/${newTask.id}`, newTask)
      .then(response => {
        dispatch(updateTaskRequestSuccessful(response.data));
      });
  };
}

export function downloadTasks(year) {
  return () => ApiWrapper.get(`/tasks/download?year=${year}`, { responseType: 'blob' })
    .then(response => fileDownload(response.data, 'tasks.xlsx'));
}

export function deleteTask(oldTask) {
  return (dispatch) => {
    dispatch(deleteTaskRequestSent());
    return ApiWrapper.delete(`/tasks/${oldTask.id}`, oldTask)
      .then((response) => {
        dispatch(deleteTaskRequestSuccessful(response.data));
      });
  };
}
