/**
 * Enforces node consistency on the problem instance
 * @param {*} problemInstance The problem instance you would like node consistency enforced on
 * @returns The node consistent problemInstance
 */
export function nodeConsistency(problemInstance) {
  const { constraints } = problemInstance;
  let variables = null;
  constraints.forEach(con => {
    variables = con.variables;
    if (variables.length === 1) {
      const { currentDomain } = variables[0];
      const variableTask = variables[0].varRef.task;
      if (con.name.includes('pref')) {
        const tp = problemInstance.taskPreferences.filter(element => element.task_id === variableTask.id);
        for (let i = currentDomain.length - 1; i >= 0; i--) {
          const d = currentDomain[i];
          const pref = tp.find(element => element.status_id === d.status.id);
          if (!con.definition(d, pref)) {
            currentDomain.splice(i, 1);
          }
        }
      } else if (con.name.includes('vol')) {
        for (let i = currentDomain.length - 1; i >= 0; i--) {
          const d = currentDomain[i];
          if (!con.definition(d, variableTask.name)) {
            currentDomain.splice(i, 1);
          }
        }
      } else {
        for (let i = currentDomain.length - 1; i >= 0; i--) {
          const d = currentDomain[i];
          if (!con.definition(variableTask, d)) {
            currentDomain.splice(i, 1);
          }
        }
      }
    }
  });

  return problemInstance;
}
