import React from 'react';
import ReusableTable from 'components/common/ReusableTable';
import PropTypes from 'prop-types';
import { renderAssignedName, renderAssignedType, renderAssignedTime } from '../utils';
import styles from '../style.module.scss';

class TaskApplicantUnassignTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Assigned Table (Unassign Menu)
      assignedColumns: [
        {
          title: 'Name',
          tooltip: 'First initial. Last name',
          render: (solutionAssignment) => renderAssignedName(solutionAssignment),
          customFilterAndSearch: (term, solutionAssignment) => (term === renderAssignedName(solutionAssignment).slice(0, term.length)) || (term === `${solutionAssignment.user?.first_name} ${solutionAssignment.user?.last_name}`.slice(0, term.length)),
        },
        {
          title: 'L/B',
          field: 'back_up',
          tooltip: 'The assignment type for the applicant (L or B)',
          render: (solutionAssignment) => renderAssignedType(solutionAssignment),
        },
        {
          title: 'Time',
          tooltip: 'The time windows that the applicant entered.',
          render: (solutionAssignment) => renderAssignedTime(solutionAssignment),
        },
      ],
      assignedOptions: {
        filtering: false,
        padding: 'dense',
        maxBodyHeight: 700,
        selection: false,
        hideDeleteButton: true,
        paging: false,
        search: false,
        showColumns: false,
        // actionsColumnIndex: -1,
        headerStyle: {
          fontFamily: 'Gotham SSm A, Gotham SSm B, Verdana, sans-serif',
          backgroundColor: '#eae9e6',
          color: '#d00000',
          whiteSpace: 'nowrap',
        },
      },
      assignedActions: [
        {
          icon: 'delete',
          tooltip: 'Unassign',
          position: 'row',
          onClick: (event, solutionAssignment) => this.props.handleUnassign(solutionAssignment),
        },
      ],
    };
  }

  render() {
    return (
      <div className={styles['task-applicant-unassign-table-container']}>
        <ReusableTable
          title={`Assigned (${this.props.data.length})`}
          columns={this.state.assignedColumns}
          options={this.state.assignedOptions}
          data={this.props.data}
          isLoading={this.props.applicantsLoading}
          actions={this.state.assignedActions}
        />
      </div>
    );
  }
}

TaskApplicantUnassignTable.propTypes = {
  data: PropTypes.array.isRequired,
  applicantsLoading: PropTypes.bool.isRequired,
  handleUnassign: PropTypes.func.isRequired,
};

export default TaskApplicantUnassignTable;
