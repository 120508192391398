import React from 'react';
import PropTypes from 'prop-types';

import ReusableButton from 'components/common/ReusableButton';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableTextField from 'components/common/ReusableTextField';
import history from 'wrappers/history';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.isLoggedIn) {
      history.push('/');
    }

    this.state = {
      email: '',
      emailError: false,
      password: '',
      passwordError: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.loginRequestFailed && this.props.loginRequestFailed) {
      this.setState({ emailError: true, passwordError: true });
    }
  };

  componentWillUnmount = () => {
    // Close the snackbar when leaving the page
    this.props.resetFailedLogin();
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
    this.setState({ emailError: false, passwordError: false });
  };

  handleLoginButtonClicked = () => {
    const payload = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.attemptLogin(payload);
  };

  handleOnKeyDownEmail = event => {
    if (event.key === 'Enter') {
      this.passwordField.focus();
    }
  };

  handleOnKeyDownPassword = event => {
    if (event.key === 'Enter') {
      this.handleLoginButtonClicked();
    }
  };

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['login-form-container']}>
              <h2 className={styles['page-header']}>
                Log In
              </h2>
              <ReusableTextField
                id="email"
                label="Email"
                value={this.state.email}
                onChange={this.handleChange('email')}
                onKeyDown={this.handleOnKeyDownEmail}
                error={this.state.emailError}
              />
              <ReusableTextField
                id="password"
                label="Password"
                inputRef={ref => { this.passwordField = ref; }}
                value={this.state.password}
                onChange={this.handleChange('password')}
                onKeyDown={this.handleOnKeyDownPassword}
                type="password"
                error={this.state.passwordError}
              />
              <Link to="/forgot-password" className={styles['include-margin-top']}>
                Forgot your password?
              </Link>
              <ReusableButton
                value="Login"
                hasTopMargin
                onClick={this.handleLoginButtonClicked}
              />
              <div className={styles['include-margin-top']}>
                Don&apos;t have an account?
              </div>
              <ReusableButton
                value="Create New Account"
                hasTopMargin
                onClick={() => history.push('/create-account')}
              />
            </div>
            <ReusableSnackbar
              open={this.props.loginRequestFailed}
              onClose={this.props.resetFailedLogin}
              variant="error"
              message="The credentials you entered are incorrect, or your account has not been activated."
            />
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  attemptLogin: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  loginRequestFailed: PropTypes.bool.isRequired,
  resetFailedLogin: PropTypes.func.isRequired,
};

export default LoginPage;
