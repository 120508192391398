const months = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

export function formatDate(dateString) {
  if (!dateString) {
    return '-';
  }
  const year = dateString.slice(0, 4);
  const month = dateString.slice(5, 7);
  const monthName = months[month];
  const day = dateString.slice(8, 10);

  return `${monthName} ${day}, ${year}`;
}

export function formatTime(dateString) {
  if (!dateString) {
    return '-';
  }
  // TODO: This is ugly, is there a better way to do this?
  const lastCharOfString = dateString[dateString.length - 1];
  let hour = dateString.slice(11, 13) === '00' ? '18' : dateString.slice(11, 13) - 6;
  // Checks if string is already in local time and adds back the 6 that was subtracted
  if (lastCharOfString !== 'Z') {
    hour += 6;
  }
  const minutes = dateString.slice(14, 16);

  return `${hour}:${minutes}`;
}
