import React from 'react';
import PropTypes from 'prop-types';

import ReusableTable from 'components/common/ReusableTable';
import ReusableSnackbar from 'components/common/ReusableSnackbar';
import ReusableSelect from 'components/common/ReusableSelect';
import ReusableTextFieldValidator from 'components/common/ReusableTextFieldValidator';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { isFieldEmpty, fields } from 'utils/validation';
import history from 'wrappers/history';
import { yearOptions } from '../AdminUtils.ts';
import styles from './style.module.scss';

class AssignmentPrioritiesPage extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.isLoggedIn) {
      history.push('/login');
    } else if (!this.props.isAdmin) {
      history.push('/forbidden');
    }

    this.state = {
      nameError: false,
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: '',
      year: this.props.year,
      columns: [
        {
          title: 'Priority',
          field: 'assignment_priority',
          tooltip: 'Order in which to fill tasks',
          editable: 'never',
          filtering: false,
          defaultSort: 'asc',
          render: task => <div className={styles['priority']}>{task.assignment_priority}</div>,
        },
        {
          title: 'Name',
          field: 'name',
          tooltip: 'The name of the task.',
          editComponent: (taskName) => (
            <ReusableTextFieldValidator
              text={taskName}
              onChange={(event) => taskName.onChange(event.target.value)}
              regExp={fields.taskName.regExp}
              error={this.state.nameError}
              onError={(error) => this.setState({ nameError: error })}
            />
          ),
        },
      ],
      options: {
        filtering: true,
        padding: 'dense',
        maxBodyHeight: 600,
        selection: false,
        hideDeleteButton: true,
      },
      editable: {
        isEditable: () => true,
        isDeletable: () => true,
        onRowUpdate: (updatedAssignmentPriority) => new Promise((resolve, reject) => {
          const errorMessage = this.validateFields(updatedAssignmentPriority);
          if (errorMessage === '') {
            this.props.updateAssignmentPriority(updatedAssignmentPriority);
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'success',
              snackbarMessage: `AssignmentPriority ${updatedAssignmentPriority.name} successfully updated.`,
            });
            resolve();
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: errorMessage,
            });
            reject();
          }
        }),
        onRowDelete: () => new Promise((resolve) => {
          resolve();
        }),
      },
      actions: [
        {
          icon: KeyboardArrowUpIcon,
          tooltip: 'Increase priority',
          position: 'row',
          onClick: (event, rowData) => this.incrementAssignmentPriority(event, rowData),
        },
        {
          icon: KeyboardArrowDownIcon,
          tooltip: 'Decrease priority',
          position: 'row',
          onClick: (event, rowData) => this.decrementAssignmentPriority(event, rowData),
        },
        {
          icon: 'replay',
          tooltip: 'Refetch Data',
          isFreeAction: true,
          onClick: () => this.props.getAssignmentPriorities(),
        },
      ],
    };
  }

  async componentDidMount() {
    if (this.props.assignmentPriorities.length === 0) {
      await this.props.getAssignmentPriorities();
    }
    if (this.props.year === '') {
      await this.props.getYear();
    }
  }

  /**
   * change the value of the year in the state to be the year selected in the dropdown
   * @param {*} event
   */
  handleYearChange(event) {
    this.setState({ year: event.target.value });
  }

  /**
   * get all possible years in the dropdown by taking them from the event dates
   */
  get yearOptions() {
    const years = this.props.eventDates.map((eventDate) => eventDate.held_on.slice(0, 4)).sort().reverse();
    return years.map(year => ({ display: year, value: parseInt(year, 10) }));
  }

  /**
   * get assignment priority where the task year is the same as the current year
   */
  get assignmentPrioritiesForCurrentYear() {
    if (!this.state) {
      return [];
    }
    return this.props.assignmentPriorities.filter(assignmentPriority => assignmentPriority.year === this.state.year);
  }

  decrementAssignmentPriority = (event, task) => {
    const oldPriority = task.assignment_priority;
    if (oldPriority !== this.props.assignmentPriorities.length) { // The upper bound on priorities is the number of tasks.
      const newPriority = oldPriority + 1;
      const decrementedTask = { ...task, assignment_priority: newPriority };
      this.props.updateAssignmentPriority(decrementedTask);
    }
  };

  incrementAssignmentPriority = (event, task) => {
    const oldPriority = task.assignment_priority;
    if (oldPriority > 1) { // The lower bound on priorities is 1.
      const newPriority = oldPriority - 1;
      const incrementedTask = { ...task, assignment_priority: newPriority };
      this.props.updateAssignmentPriority(incrementedTask);
    }
  };

  validateFields = (task) => {
    let errorMessage = '';
    if (this.state.nameError || isFieldEmpty(task.name)) {
      errorMessage += fields.taskName.errorMessage;
      this.setState({
        nameError: true,
      });
    }
    return errorMessage;
  };

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  };

  /**
   * renders the year dropdown with a ReusableSelect component
   * @returns <ReusableSelect /> year dropdown
   */
  renderYearOptions = () => (
    <ReusableSelect
      id="year"
      label="Event Date"
      className={styles['year-options']}
      value={this.state.year}
      options={yearOptions(this.props.eventDates)}
      onChange={(event) => this.handleYearChange(event)}
      excludeNoneOption
      useDefaultVariant
    />
  );

  render() {
    return (
      <div id="maincontent">
        <div className="wdn-band">
          <div className="wdn-inner-wrapper">
            <div className={styles['table-container']}>
              <ReusableTable
                title="Assignment Priorities"
                columns={this.state.columns}
                data={this.assignmentPrioritiesForCurrentYear}
                options={this.state.options}
                editable={this.state.editable}
                isLoading={this.props.assignmentPrioritiesLoading}
                actions={this.state.actions}
                renderYearOptions={this.renderYearOptions}
              />
              <ReusableSnackbar
                open={this.state.snackbarOpen}
                onClose={this.handleSnackbarClose}
                variant={this.state.snackbarVariant}
                message={this.state.snackbarMessage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AssignmentPrioritiesPage.propTypes = {
  updateAssignmentPriority: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  assignmentPriorities: PropTypes.array.isRequired,
  assignmentPrioritiesLoading: PropTypes.bool.isRequired,
  getAssignmentPriorities: PropTypes.func.isRequired,
  year: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  getYear: PropTypes.func.isRequired,
  eventDates: PropTypes.array.isRequired,
};

export default AssignmentPrioritiesPage;
